import React from 'react'
import {
  Group,
  Line,
} from 'react-konva'

type GridProps = {
  offsetX: number
  offsetY: number
  width: number
  height: number
  majorTicks: number
}

export const Grid: React.FC<GridProps> = ({ offsetX, offsetY, width, height, majorTicks }) => {
  const cols = Array.from(Array(Math.floor(width / majorTicks) + 1), (_v, i) => i)
  const rows = Array.from(Array(Math.floor(height / majorTicks) + 1), (_v, i) => i)
  return (
    <Group>
    {cols.map(i => (
      <Line
        key={`grid-row-${i}`}
        points={[i * majorTicks + offsetX, 0, i * majorTicks + offsetX, height]}
        stroke="lightgray"
        strokeWidth={1}
      />
    ))}
    {rows.map(i => (
      <Line
        key={`grid-col-${i}`}
        points={[0, i * majorTicks + offsetY, width, i * majorTicks + offsetY]}
        stroke="lightgray"
        strokeWidth={1}
      />
    ))}
    </Group>
  )
}
