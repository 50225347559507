import React from 'react'
import {
  Box, 
  Button,
} from '@mui/material'
import {
  ArrowBackIos,
} from '@mui/icons-material'

interface BackButtonProps {
  marginLeft?: any
  marginRight?: any
  onClick: () => void
}

export const BackButton: React.FC<BackButtonProps> = ({
  marginLeft,
  marginRight,
  onClick,
}) => {
  return (
    <Box marginLeft={marginLeft} marginRight={marginRight}>
      <Button startIcon={<ArrowBackIos />} onClick={onClick}>
        戻る
      </Button>
    </Box>
  )
}
