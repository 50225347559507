import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  Typography,
  Box,
} from '@mui/material';
import MaterialTable from '@material-table/core'
import {
  useApp,
} from 'contexts'
import {
  Walkthrough,
  WalkthroughMovie,
  Action,
  actionIdToSegment,
} from 'models'
import {
  Loading,
  ReloadButton,
  MovieCard,
  TimestampLabel,
  ActionStatusLabel,
  WeightLabel,
  MaterialTableOptions,
  MaterialTableComponents,
  MaterialTableLocalization,
} from 'components'

export interface ConfirmPersonDetailProps {
  walkthroughId: string
}

export const ConfirmPersonDetail: React.FC<ConfirmPersonDetailProps> = ({ walkthroughId }) => {
  const [walkthrough, setWalkthrough] = useState<Walkthrough>()
  const [movies, setMovies] = useState<WalkthroughMovie[]>()
  const [actions, setActions] = useState<Action[]>([])
  const { getApi, defaultApiErrorHandler } = useApp()
  const [reload, setReload] = useState(false)

  const load = useCallback(() => {
    Promise.all([
      getApi().fetchWalkthrough(walkthroughId),
      getApi().fetchWalkthroughMovies(walkthroughId),
      getApi().fetchActionsByWalkthroughId(walkthroughId),
    ])
      .then(([wtResult, moviesResult, actionsResult]) => {
        setWalkthrough(wtResult)
        setMovies(moviesResult.items)
        setActions(actionsResult.items)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, walkthroughId])

  useEffect(() => {
    load()
  }, [load])
  // reload
  useEffect(() => {
    if (!reload) return
    load()
    setReload(false)
  }, [load, reload])

  const handleReload = useCallback(() => {
    setWalkthrough(undefined)
    setMovies(undefined)
    setActions([])
    setReload(true)
  }, [])

  return (
    <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <Box sx={{ display: 'flex' }}>
        <Typography variant="h6" sx={{}} >{walkthroughId}</Typography>
        <ReloadButton
          marginLeft="auto"
          marginRight={2}
          size="small"
          onClick={handleReload}
        />
      </Box>
      <Loading
        visible={movies === undefined}
        marginTop={0}
        marginBottom={5}
      />
      <Box sx={{ display: 'flex', marginTop: 0.5, marginBottom: 1 }}>
        {movies && movies.map(movie => (
          <MovieCard
            key={movie.cam_code}
            movieUrl={movie.url}
            title={movie.cam_code}
            maxWidth={600}
            imgHeight={400}
          />
        ))}
        {movies && movies.length === 0 && (
          <div>動画はありません</div>
        )}
      </Box>
      {walkthrough && (
        <Box sx={{ marginBottom: 0.5 }}>
          <div>入店: <TimestampLabel value={walkthrough.timestamp} format="yyyy/MM/dd HH:mm:ss" /></div>
          <div>退店: <TimestampLabel value={walkthrough.exit_at} format="yyyy/MM/dd HH:mm:ss" /></div>
          <div>ユーザーID: {walkthrough.user_id}</div>
          <MaterialTable
            data={actions}
            columns={[
              {
                title: '時刻',
                field: 'timestamp',
                render: (d) => (
                  <TimestampLabel value={d.timestamp} />
                ),
              },
              {
                title: '状態',
                field: 'status',
                render: (d) => (<ActionStatusLabel personStatus={d.person_status} itemStatus={d.item_status} />),
              },
              {
                title: '棚',
                field: 'segment',
                render: (d) => (<span>{actionIdToSegment(d.action_id)}</span>),
              },
              {
                title: '重量変化',
                field: 'value_diff',
                render: (d) => (<WeightLabel value={d.value_diff} />),
              },
              {
                title: '商品',
                field: 'item_name',
              },
              {
                title: '数量',
                field: 'qty',
              },
            ]}
            options={{
              ...MaterialTableOptions<Action>({
                search: false,
                paging: false,
                toolbar: false,
              }),
            }}
            components={{
              ...MaterialTableComponents,
            }}
            localization={{
              ...MaterialTableLocalization
            }}
          />
        </Box>
      )}
    </Box>
  )
}
