import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  useNavigate,
} from 'react-router-dom'
import {
  Toolbar,
  Tooltip,
  IconButton,
} from '@mui/material';
import {
  Add,
} from '@mui/icons-material'
import MaterialTable from '@material-table/core'
import {
  useApp,
} from 'contexts'
import {
  CredentialWithoutDetails,
} from 'models'
import {
  MainContents,
  Loading,
  ReloadButton,
  SimpleMaterialTableOptions,
  MaterialTableComponents,
  MaterialTableLocalization,
} from 'components'
import {
  CredentialAddDialog,
} from './components'

export const Credentials: React.FC = () => {
  const { getApi, defaultApiErrorHandler } = useApp()
  const navigate = useNavigate()
  const [reload, setReload] = useState(false)
  const [items, setItems] = useState<CredentialWithoutDetails[]>()
  const [addDialogOpen, setAddDialogOpen] = useState(false)

  const load = useCallback(() => {
    getApi()
      .fetchCredentials()
      .then((result) => {
        setItems(result.items)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler])
  useEffect(() => {
    load()
  }, [load])
  // reload
  useEffect(() => {
    if (!reload) return
    load()
    setReload(false)
  }, [load, reload])

  const handleReload = useCallback(() => {
    setItems(undefined)
    setReload(true)
  }, [])
  const handleAddDialogOpen = useCallback(() => {
    setAddDialogOpen(true)
  }, [])
  const handleAddDialogClosed = useCallback(() => {
    setItems(undefined)
    setReload(true)
    setAddDialogOpen(false)
  }, [])

  return (
    <MainContents>
      <Toolbar variant="dense" disableGutters>
        <Tooltip title="認証情報追加">
          <IconButton
            aria-label="add"
            color="primary"
            sx={{ marginLeft: 'auto' }}
            onClick={handleAddDialogOpen}
            size="small"
          >
            <Add />
          </IconButton>
        </Tooltip>
        <ReloadButton onClick={handleReload} />
      </Toolbar>
      <Loading visible={items === undefined} />
      {items &&
        <MaterialTable
          data={items}
          columns={[
            {
              title: 'クライアント名',
              field: 'client_name',
              render: (d) => d.client_name
            },
            {
              title: 'クライアントID',
              field: 'client_id',
              render: (d) => d.client_id
            },
          ]}
          onRowClick={(_, rowData) => {
            navigate(`/credentials/${rowData?.client_id}`)
          }}
          options={SimpleMaterialTableOptions<CredentialWithoutDetails>({
            toolbar: false,
          })}
          components={{
            ...MaterialTableComponents,
          }}
          localization={{
            ...MaterialTableLocalization
          }}
        />
      }
      <CredentialAddDialog
        open={addDialogOpen}
        onClose={handleAddDialogClosed}
      />
    </MainContents>
  )
}
