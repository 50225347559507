import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  Toolbar,
} from '@mui/material'
import MaterialTable from '@material-table/core'
import {
  useApp,
} from 'contexts'
import {
  useSelectedStoreCode,
} from 'hooks'
import {
  Store,
  MonthlySystemStatistics,
  SystemStatisticsData,
  totalSystemStatisticsDetailsCount,
} from 'models'
import {
  MainContents,
  StoresSelector,
  Loading,
  ReloadButton,
  MonthPicker,
  MaterialTableOptions,
  MaterialTableComponents,
  MaterialTableLocalization,
  DateLabel,
  StatisticsNumberLabel,
} from 'components'
import {
  StatisticsSystemDetailPanel,
} from './components'
import { DateTime } from 'luxon'

export const StatisticsSystem: React.FC = () => {
  const {storeCode, setStoreCode} = useSelectedStoreCode()
  const [reload, setReload] = useState(false)
  const [date, setDate] = useState(DateTime.now())
  const [statisticsData, setStatisticsData] = useState<MonthlySystemStatistics>()
  const { getApi, defaultApiErrorHandler } = useApp()

  const load = useCallback(() => {
    if (storeCode === undefined) return
    getApi().fetchMonthlySystemStatistics(storeCode, date)
      .then((data) => {
        setStatisticsData(data)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, storeCode, date])
  useEffect(() => {
    load()
  }, [load])
  // reload
  useEffect(() => {
    if (!reload) return
    console.log('reloading...')
    load()
    setReload(false)
  }, [load, reload])

  const onStoreChanged = useCallback((store: Store) => {
    setStatisticsData(undefined)
    setStoreCode(store.store_code)
  }, [setStoreCode])
  const handleDateChange = useCallback((dt: DateTime) => {
    setStatisticsData(undefined)
    setDate(dt)
  }, [])
  const handleReload = useCallback(() => {
    setStatisticsData(undefined)
    setReload(true)
  }, [])

  return (
    <MainContents>
      <Toolbar variant="dense" disableGutters>
        <StoresSelector
          storeCode={storeCode}
          onSeleced={onStoreChanged}
        />
        <MonthPicker
          onChange={handleDateChange}
        />
        <ReloadButton marginLeft="auto" onClick={handleReload} />
      </Toolbar>
      <Loading visible={statisticsData === undefined} />
      {statisticsData && (
      <MaterialTable
        data={statisticsData.data}
        columns={[
          {
            title: '日',
            field: 'date',
            sorting: false,
            render: (d) => (<DateLabel date={d.date}/>),
          },
          {
            title: '障害',
            field: 'fatal',
            sorting: true,
            render: (d) => (<StatisticsNumberLabel value={totalSystemStatisticsDetailsCount(d.fatal)} errorThreshold={2} warnThreshold={1}/>),
          },
          {
            title: 'エラー',
            field: 'error',
            sorting: true,
            render: (d) => (<StatisticsNumberLabel value={totalSystemStatisticsDetailsCount(d.error)} errorThreshold={10} warnThreshold={3}/>),
          },
          {
            title: '警告',
            field: 'warn',
            sorting: true,
            render: (d) => (<StatisticsNumberLabel value={totalSystemStatisticsDetailsCount(d.warn)} disableWarnAndError/>),
          },
          {
            title: '通知',
            field: 'info',
            sorting: true,
            render: (d) => (<StatisticsNumberLabel value={totalSystemStatisticsDetailsCount(d.info)} disableWarnAndError/>),
          },
        ]}
        options={MaterialTableOptions<SystemStatisticsData>({
          toolbar: false,
          paging: false,
          pageSize: 31,
        })}
        components={{
          ...MaterialTableComponents,
        }}
        localization={{
          ...MaterialTableLocalization
        }}
        detailPanel={({ rowData }) => (<StatisticsSystemDetailPanel data={rowData}/>)}
      />
      )}
    </MainContents>
  )
}
