import React, {
  useState,
} from 'react'
import {
  Link as RouterLink,
  useMatch,
} from 'react-router-dom'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Icon,
  Collapse,
} from '@mui/material';
import {
  ArrowForward,
} from '@mui/icons-material'

type NavItemProps = {
  nav: {
    label: string
    path: string
    pattern?: string
    excludes?: string[]
    icon: string
    children?: {
      label: string
      path: string
      excludes?: string[]
      pattern?: string
    }[]
  }
}

export const NavItem: React.FC<NavItemProps> = ({ nav }) => {
  if (nav.children === undefined) {
    return (<DefaultNavItem {...nav} />)
  } else {
    return (<NestedNavItems children={nav.children} {...nav}/>)
  }
}

type DefaultNavItemProps = {
  label: string
  path: string
  pattern?: string
  excludes?: string[]
  icon: string
}

const DefaultNavItem: React.FC<DefaultNavItemProps> = ({ label, path, pattern, excludes, icon }) => {
  const routeMatch = useMatch(pattern ? { path: pattern } : { path: `${path}/*` })
  const color = routeMatch && !excludes?.find(exclude => routeMatch.pathname.startsWith(exclude)) ? 'secondary' : 'inherit'
  return (
    <ListItem
      key={path}
      button
      component={RouterLink}
      to={path}
      dense
      disableGutters
    >
      <ListItemIcon sx={{ minWidth: 24 }}>
        <Icon color={color} style={{ fontSize: 18 }}>{icon}</Icon>
      </ListItemIcon>
      <ListItemText primary={<Typography variant="button" color={color}>{label}</Typography>} />
    </ListItem>
  )
}

type NestedNavItemsProps = {
  label: string
  path: string
  pattern?: string
  excludes?: string[]
  icon: string
  children: {
    label: string
    path: string
    pattern?: string
    excludes?: string[]
  }[]
}

const NestedNavItems: React.FC<NestedNavItemsProps> = ({ label, path, pattern, excludes, icon, children }) => {
  const routeMatch = useMatch(pattern ? { path: pattern } : { path: `${path}/*` })
  const [open, setOpen] = useState(routeMatch !== null)
  const color = routeMatch && !excludes?.find(exclude => routeMatch.pathname.startsWith(exclude)) ? 'secondary' : 'inherit'
  const handleOpen = () => {
    setOpen(!open)
  }
  return (
    <>
      <ListItem key={path} button dense disableGutters onClick={handleOpen} >
        <ListItemIcon sx={{ minWidth: 24 }}>
          <Icon color={color} style={{ fontSize: 18 }}>{icon}</Icon>
        </ListItemIcon>
        <ListItemText primary={<Typography variant="button" color={color}>{label}</Typography>}/>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ marginLeft: 1.5 }}>
        {children.map(item => (
          <NestedNavItem key={item.path} {...item}/>
        ))}
        </List>
      </Collapse>
    </>
  )
}

type NestedNavItemProps = {
  label: string
  path: string
  pattern?: string
  excludes?: string[]
}

const NestedNavItem: React.FC<NestedNavItemProps> = ({ label, path, excludes, pattern }) => {
  const routeMatch = useMatch(pattern ? { path: pattern } : { path: `${path}/*` })
  const color = routeMatch && !excludes?.find(exclude => routeMatch.pathname.startsWith(exclude)) ? 'secondary' : 'inherit'
  return (
    <ListItem button component={RouterLink} to={path} dense disableGutters>
      <ArrowForward fontSize="small" color={color} sx={{ minWidth: 24 }}/>
      <ListItemText primary={<Typography variant="button" color={color}>{label}</Typography>}/>
    </ListItem>
  )
}