import React, {
  useState,
  useCallback,
} from 'react'
import {
  Button,
  IconButton,
  Typography,
  TextFieldProps,
} from '@mui/material'
import {
  NavigateBefore,
  NavigateNext,
} from '@mui/icons-material'
import {
  DesktopDatePicker,
  LocalizationProvider,
} from '@mui/lab'
import { DateTime } from 'luxon'
import { isSameOrAfterMonth } from 'utils'
import LuxonUtils from '@date-io/luxon'
import { DateIOFormats } from "@date-io/core/IUtils";

// CalendarPickerのヘッダを自然にするためにフォーマットをカスタマイズする
class AdapterLuxon extends LuxonUtils {

  constructor({
    locale,
    formats,
  }: { formats?: Partial<DateIOFormats>; locale?: string } = {}) {
    super({ formats, locale });
  }

  public format = (date: DateTime, formatKey: keyof DateIOFormats) => {
    if (formatKey === 'month') return ''
    if (formatKey === 'year') return this.formatByString(date, 'yyyy年');
    if (formatKey === 'monthShort') return this.formatByString(date, 'MMM月');
    return this.formatByString(date, this.formats[formatKey]);
  };
}

type MonthPickerProps = {
  disabled?: boolean
  onChange: (dateTime: DateTime) => void
}

export const MonthPicker: React.FC<MonthPickerProps> = ({ disabled, onChange }) =>  {
  const [date, setDate] = useState(DateTime.now())
  const [isOpen, setIsOpen] = useState(false)

  const handleDateChange = useCallback((date: unknown) => {
    console.log(date)
    setIsOpen(false)
    setDate(date as DateTime)
    onChange(date as DateTime)
  }, [onChange])
  //
  const handleNavigateBefore = useCallback(() => {
    const newDate = date.minus({ month: 1 })
    setDate(newDate)
    onChange(newDate)
  }, [date, onChange])
  //
  const handleNavigateNext = useCallback(() => {
    const newDate = date.plus({ month: 1 })
    setDate(newDate)
    onChange(newDate)
  }, [date, onChange])

  const DateField: React.FC<TextFieldProps> = useCallback((props) =>  {
    const handle = () => {
      console.log('handle', isOpen)
      setIsOpen(true)
    }
    return (
      <Button ref={props.inputRef} onClick={handle} disabled={disabled}>
        <Typography variant="subtitle1" >
        {`${props.inputProps?.value}`}
      </Typography>
      </Button>
    )
  }, [isOpen, disabled])

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} locale="ja-JP">
      <IconButton
        aria-label="前月"
        disabled={disabled}
        onClick={handleNavigateBefore}
        size="large">
        <NavigateBefore />
      </IconButton>
      <DesktopDatePicker
        views={['month', 'year']}
        inputFormat="y/M"
        value={date}
        minDate={DateTime.fromISO('2020-01-01')}
        renderInput={(props) => <DateField {...props}/>}
        disabled={disabled}
        disableFuture={true}
        onChange={handleDateChange}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
      />
      <IconButton
        aria-label="翌月"
        disabled={disabled || isSameOrAfterMonth(date)}
        onClick={handleNavigateNext}
        size="large">
        <NavigateNext />
      </IconButton>
    </LocalizationProvider>
  )
}