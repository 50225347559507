import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react'
import {
  Container,
  Toolbar,
  IconButton,
  Button,
  Tooltip,
  Menu,
  MenuItem,
  ListItemText,
  Divider,
  Slider,
  SxProps,
  Theme,
} from '@mui/material';
import {
  PlayArrow as StartIcon,
  Pause as PauseIcon,
  Replay as ReplayIcon,
} from '@mui/icons-material'
import {
  StoreLayoutProvider,
} from 'contexts'
import {
  UnixTimestamp,
  TrackingPlayerControl,
  TrackingPlayerControlModel,
} from 'models'
import { StoreLayout } from './StoreLayout'
import { PersonModel } from './Person'
import { FlowLineModel } from './FlowLine'
import { TimestampLabel } from './TimestampLabel'

const sxPlayButton: SxProps<Theme> = {
  marginLeft: 1,
  marginRight: 1,
}

interface TrackingPlayerProps {
  storeCode: string
  defaultSpeed?: 0.5 | 1 | 2 | 3 | 10 | 20 | 30
  model: TrackingPlayerControlModel | undefined
  onTimestampChanged?: (ts: UnixTimestamp) => void
  flowLines?: FlowLineModel[]
  height?: number
}

export const TrackingPlayer: React.FC<TrackingPlayerProps> = ({
  storeCode,
  defaultSpeed = 10,
  model,
  onTimestampChanged,
  flowLines,
  height = 450,
}) => {
  const controlRef = useRef<TrackingPlayerControl>()
  const [persons, setPersons] = useState<PersonModel[]>([])
  const [status, setStatus] = useState<'pause' | 'playing' | 'end'>('pause')
  const [speed, setSpeed] = useState(defaultSpeed)
  const [speedMenuAnchorEl, setSpeedMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [timestamp, setTimestamp] = useState<UnixTimestamp>()
  useEffect(() => {
    setStatus('pause')
    setTimestamp(model?.timestamp)
    if (model === undefined) return
    const control = new TrackingPlayerControl(
      (persons: PersonModel[], ms: number) => {
        setPersons(persons)
        setTimestamp(Math.floor(ms / 1000))
      },
      () => {
        setStatus('end')
      },
      onTimestampChanged,
    )
    controlRef.current = control
    control.preset(model.frames, model.timestamp * 1000)
    return () => {
      console.log('clean up')
      control.tearDown()
    }
  }, [model, onTimestampChanged])

  const handleStartButtonClicked = useCallback(() => {
    if (controlRef.current === undefined) return
    setStatus('playing')
    controlRef.current.start(speed)
  }, [speed])
  const handlePauseButtonClicked = useCallback(() => {
    if (controlRef.current === undefined) return
    setStatus('pause')
    controlRef.current.pause()
  }, [])
  const handleReplayButtonClicked = useCallback(() => {
    if (controlRef.current === undefined) return
    setStatus('playing')
    controlRef.current.restart(speed)
  }, [speed])
  const handleSpeedClicked = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    setSpeedMenuAnchorEl(evt.currentTarget)
  }, [])
  const handleSpeedMenuClosed = useCallback(() => {
    setSpeedMenuAnchorEl(null)
  }, [])
  const handleSpeedChanged = useCallback((newSpeed: 0.5 | 1 | 2 | 3 | 10 | 20 | 30) => {
    setSpeedMenuAnchorEl(null)
    setSpeed(newSpeed)
    if (controlRef.current === undefined) return
    controlRef.current.setSpeedRate(newSpeed)
  }, [])
  const handleSliderChanged = useCallback((_, newValue) => {
    if (model === undefined || controlRef.current === undefined) return
    controlRef.current.setSeconds(newValue - model.timestamp)
  }, [model])

  return (
    <Container disableGutters>
      <StoreLayoutProvider storeCode={storeCode}>
        <StoreLayout
          width={870}
          height={height}
          hiddenAreas
          persons={persons}
          lines={flowLines}
        />
        <Toolbar variant="dense" disableGutters sx={{ paddingRight: 2 }}>
          {status === 'pause' && (
            <IconButton size="small" onClick={handleStartButtonClicked} sx={sxPlayButton}>
              <Tooltip title="再生"><StartIcon /></Tooltip>
            </IconButton>
          )}
          {status === 'playing' && (
            <IconButton size="small" onClick={handlePauseButtonClicked} sx={sxPlayButton}>
              <Tooltip title="一時停止"><PauseIcon /></Tooltip>
            </IconButton>
          )}
          {status === 'end' && (
            <IconButton size="small" onClick={handleReplayButtonClicked} sx={sxPlayButton}>
              <Tooltip title="もう一度再生する"><ReplayIcon /></Tooltip>
            </IconButton>
          )}
          <TimestampLabel value={timestamp} format="HH:mm:ss" blankText="00:00:00" sx={{ marginRight: 0 }} />
          <Tooltip title="再生速度">
            <Button size="small" disableRipple onClick={handleSpeedClicked}>x{speed}</Button>
          </Tooltip>
          <Menu
            anchorEl={speedMenuAnchorEl}
            open={Boolean(speedMenuAnchorEl)}
            onClose={handleSpeedMenuClosed}
          >
            <MenuItem disabled>
              <ListItemText>再生速度</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem value={0.5} onClick={() => handleSpeedChanged(0.5)}>x1/2</MenuItem>
            <MenuItem value={1} onClick={() => handleSpeedChanged(1)}>x1</MenuItem>
            <MenuItem value={2} onClick={() => handleSpeedChanged(2)}>x2</MenuItem>
            <MenuItem value={3} onClick={() => handleSpeedChanged(3)}>x3</MenuItem>
            <MenuItem value={10} onClick={() => handleSpeedChanged(10)}>x10</MenuItem>
            <MenuItem value={20} onClick={() => handleSpeedChanged(20)}>x20</MenuItem>
            <MenuItem value={30} onClick={() => handleSpeedChanged(30)}>x30</MenuItem>
          </Menu>
          <Slider
            // size="small" not support this version
            min={model?.timestamp}
            max={model ? model.timestamp + model.frames.length : undefined}
            value={timestamp || 0}
            onChange={handleSliderChanged}
            sx={{ marginLeft: 2 }}
          />
        </Toolbar>
      </StoreLayoutProvider>
    </Container>
  )
}
