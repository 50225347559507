import React, {
  useState,
  useCallback,
} from 'react'
import { useForm } from 'react-hook-form'
import {
  useParams,
} from 'react-router-dom'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
} from '@mui/material';
import {
  useApp,
} from 'contexts'
import {
  ShelfLayout,
} from 'models'
import {
  ProgressScreen,
} from 'components'
import { ShelfRegisterFormData, ShelfRegisterFormValidations, getSegmentCodesFromLayout } from './forms'
import { ShelfEditor } from './ShelfEditor'

type AddDialogProps = {
  open: boolean
  onClose: () => void
  onCreate: () => void
}


export const AddDialog: React.FC<AddDialogProps> = ({ open, onClose, onCreate }) => {
  const { storeCode } = useParams()
  if (storeCode === undefined) throw new Error('unhandled routing')
  const { getApi } = useApp()
  // TODO reset
  const [layout, setLayout] = useState<ShelfLayout>({
    shelfCode: `${storeCode}_X`,
    segments: [
      [{ segmentCode: `${storeCode}_X_1` }, { segmentCode: `${storeCode}_X_2` }],
      [{ segmentCode: `${storeCode}_X_3` }, { segmentCode: `${storeCode}_X_4` }],
    ],
  })
  const [isUpdating, setIsUpdating] = useState(false)
  const { register, handleSubmit, formState, formState: { errors } } = useForm<ShelfRegisterFormData>({
    mode: 'onBlur',
  })

  // 保存
  const onSubmit = useCallback((data: ShelfRegisterFormData) => {
    const shelfCode = `${storeCode}_${data.shelf_code_short}`
    const segmentCodes = getSegmentCodesFromLayout(shelfCode, layout)
    setIsUpdating(true)
    console.log(shelfCode, segmentCodes)
    getApi()
      .addShelf(shelfCode)
      .then(_ => {
        getApi()
          .updateShelfSegments(shelfCode, segmentCodes)
          .then(_ => {
            onCreate()
          })
          .finally(() => {
            setIsUpdating(false)
          })
      })
  }, [getApi, storeCode, layout, onCreate])

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>棚を作成</DialogTitle>
        <DialogContent>
          <Box sx={{ margin: 0 }}>
            <TextField
              label="棚コード"
              size="small"
              margin="dense"
              inputProps={{
                maxLength: 4
              }}
              required
              {...register(`shelf_code_short`, {
                ...ShelfRegisterFormValidations.shelf_code_short,
                validate: async (value: string) => {
                  const shelfCode = `${storeCode}_${value}`
                  const shelves = await getApi().fetchShelves(storeCode)
                  const shelfCodes = shelves?.items.map(shelf => shelf.shelf_code)
                  console.log(shelfCodes, shelfCode)
                  return shelfCodes?.indexOf(shelfCode) === -1 || `${value}は既に登録されています`
                },
              })}
              error={Boolean(errors?.shelf_code_short)}
              helperText={errors?.shelf_code_short?.message}
            />
          </Box>
          <Box sx={{ display: 'flex', width: 'fit-content', margin: 0.5 }}>
            <ShelfEditor
              layout={layout}
              onUpdate={setLayout}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={onClose}>キャンセル</Button>
          <Button color="primary" variant="contained" type="submit" disabled={!formState.isValid} >追加</Button>
        </DialogActions>
      </form>
      <ProgressScreen open={isUpdating} />
    </Dialog>
  )
}