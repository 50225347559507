import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import { PassregiApi } from 'api/passregi.api'
import {
  Store,
  NullStore,
} from 'models'
import {
  Iot,
} from 'iot'
import {
  IdToken,
} from '@auth0/auth0-spa-js'
import { useAuth0 } from '@auth0/auth0-react'
import { Auth } from 'aws-amplify'
import { ICredentials } from '@aws-amplify/core'
import jwt_decode from 'jwt-decode'
import * as AWS from 'aws-sdk'

const ENV_ERROR = (name: string): string => {
  throw new Error(`${name} is not defined.`);
}

const AUTH0_DOMAIN: string = process.env.REACT_APP_AUTH0_DOMAIN || ENV_ERROR('REACT_APP_AUTH0_DOMAIN')
const APP_SERVER_BASE_URL: string = process.env.REACT_APP_SERVER_BASE_URL || ENV_ERROR('REACT_APP_SERVER_BASE_URL')
const REGION: string = process.env.REACT_APP_AWS_REGION || ENV_ERROR('REACT_APP_AWS_REGION')
const IDENTITY_POOL_ID: string = process.env.REACT_APP_IDENTITY_POOL_ID || ENV_ERROR('REACT_APP_IDENTITY_POOL_ID')
const IOT_ENDPOINT: string = process.env.REACT_APP_IOT_ENDPOINT || ENV_ERROR('REACT_APP_IOT_ENDPOINT')
const IOT_POLICY_NAME: string = process.env.REACT_APP_IOT_POLICY_NAME || ENV_ERROR('REACT_APP_IOT_POLICY_NAME')

interface App {
  isReady: boolean
  getApi: () => PassregiApi
  getIot: () => Iot
  getStores: () => Store[]
  getInitalStore: () => Store
  getStore: (storeCode: string) => Store
  apiErrorHandler: (handlers: {[name: string]: (reason?: any) => void }) => ((reason: any) => void)
  defaultApiErrorHandler: (reason: any) => void
  reloadStores: () => Promise<void>
}

const stub = (): never => {
  throw new Error('is not ready yet.');
}

const initalApp: App = {
  isReady: false,
  getApi: stub,
  getIot: stub,
  getStores: stub,
  getInitalStore: stub,
  getStore: stub,
  apiErrorHandler: stub,
  defaultApiErrorHandler: stub,
  reloadStores: stub,
}

// create the context
export const AppContext = createContext<App>(initalApp)
export const useApp = () => useContext(AppContext)

const handleErrors = (reason: any) => {
  console.log(reason)
  switch(reason.name) {
    case 'Unauthorized':
      window.location.pathname = '/unauthorized'
      break
    case 'Forbidden':
      window.location.pathname = '/no_permissions'
      break
    default:
      window.location.pathname = '/system_error'
  }
}

// AppProvider
export const AppProvider: React.FC = ({ children }) => {
  const { getIdTokenClaims, user } = useAuth0()
  const [app, setApp] = useState<App>(initalApp)
  console.log(`##AppProvider`, app, getIdTokenClaims)

  const loadAppAsync = useCallback(async (): Promise<App> => {
    if (!user || !user?.name || !user?.email) throw new Error('Unauthoricated');
    console.log('AppProvider#loadAppAsync', user.name)
    const claims = await getIdTokenClaims()
    if (!claims) throw new Error('IdTokenClaims is undefined');
    const credentials = await getCredentials(claims, user.name, user.email)
    console.log('credentials ready', credentials.authenticated)
    // initalize clients
    const api = new PassregiApi(APP_SERVER_BASE_URL, claims.__raw)
    const iot = new Iot(REGION, IOT_ENDPOINT)
    const cognito = new AWS.CognitoIdentity({
      credentials,
      region: REGION,
    })
    const cache = new Cache()
    // initalize
    console.log('AppProvider#initalize...')
    await Promise.all([
      cache.reloadAll(api),
      attachPolicy(claims, iot, cognito),
    ])
    console.log('AppProvider#initalize...done: ')
    return {
      isReady: true,
      getApi: () => api,
      getIot: () => iot,
      getStore: (storeCode: string) => cache.getStore(storeCode),
      getInitalStore: () => {
        const stores = cache.getStores()
        if (stores.length === 0) throw new Error('Store is not found');
        return stores[0]      
      },
      getStores: () => cache.getStores(),
      apiErrorHandler: (handlers: {[name: string]: (reason?: any) => void }) =>  (reason: any) => {
        if (handlers[reason.name]) {
          handlers[reason.name](reason)
        } else {
          handleErrors(reason)
        }
      },
      defaultApiErrorHandler: handleErrors,
      reloadStores: async () => { await cache.reloadStore(api) },
    }
  }, [getIdTokenClaims, user])
  useEffect(() => {
    loadAppAsync()
      .then((app) => {
        console.log(app)
        setApp(app)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [loadAppAsync])

  return <AppContext.Provider value={app}>{children}</AppContext.Provider>
}

const getCredentials = async (claims: IdToken, name: string, email: string): Promise<ICredentials> => {
  try {
    const credentials = await Auth.currentCredentials()
    if (credentials.authenticated) return Auth.essentialCredentials(credentials)  
  } catch (err) {
    console.log('Auth.currentCredentials failed', err)
  }
  console.log('## login ##')
  const { exp } = jwt_decode(claims.__raw)
  console.log(`expires_at: ${exp * 1000}`)
  const cred = await Auth.federatedSignIn(
    AUTH0_DOMAIN, // The Auth0 Domain,
    { // FederatedResponse
      token: claims.__raw, // The id token from Auth0
      expires_at: exp * 1000, // the expiration timestamp
    },
    { // FederatedUser (from the Auth0)
      name, // the user name
      email, // Optional, the email address
    }
  )
  return Auth.essentialCredentials(cred)
}

const attachPolicy = async (claims: IdToken, iot: Iot, cognito: AWS.CognitoIdentity) => {
  const logins: AWS.CognitoIdentity.LoginsMap = {}
  logins[AUTH0_DOMAIN] = claims.__raw
  const idResult = await cognito.getId({
    IdentityPoolId: IDENTITY_POOL_ID,
    Logins: logins,
  }).promise()
  const identityId = idResult.IdentityId
  console.log('identity_id', identityId)
  if (!identityId) return
  await iot.attachPolicy(identityId, IOT_POLICY_NAME)
}

class Cache {
  
  private stores: Store[] = []
  private hash: {[storeCode: string]: Store} = {}

  async reloadAll(api: PassregiApi): Promise<Store[]> {
    return this.reloadStore(api)
  }

  async reloadStore(api: PassregiApi): Promise<Store[]> {
    const stores = await api.fetchStores()
    stores.items.forEach(s => {
      this.hash[s.store_code] = s
    })
    this.stores = stores.items.sort((a, b) => a.store_code.localeCompare(b.store_code))
    return this.getStores()
  }

  getStores(): Store[] {
    return this.stores
  }

  getStore(storeCode: string): Store {
    if (storeCode === NullStore.store_code) return NullStore
    return this.hash[storeCode]
  }

}