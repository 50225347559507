import React from 'react'
import {
  Box,
  SxProps,
  Theme,
} from '@mui/material';
import { DateTime } from 'luxon'

type TimestampLabelProps = {
  value?: number
  color?: string
  format?: string
  sx?: SxProps<Theme>
  blankText?: string
}

export const TimestampLabel: React.FC<TimestampLabelProps> = ({ value, color, sx, format = 'HH:mm:ss', blankText = '-' }) => (
  value
    ? (<Box component="span" style={{ color }} sx={sx}>{DateTime.fromSeconds(value).toFormat(format)}</Box>)
    : (<Box component="span">{blankText}</Box>)
)
