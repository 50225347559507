import React, {
  useState,
  useCallback,
} from 'react'
import {
  useDropzone,
} from 'react-dropzone'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  SxProps,
  Theme,
} from '@mui/material';
import {
  useStoreLayout,
} from 'contexts'
import {
  WarnigMessage,
} from 'components'
import {
  Direction,
} from 'models'
import {
  AreaModel,
} from './Area'

interface UploadDialogProps {
  open: boolean
  onClose: () => void
  onUpload: () => void
}

const sxDrop: SxProps<Theme> = {
  margin: 1,
  paddingTop: 3,
  paddingBottom: 3,
  backgroundColor: 'lightgray',
  textAlign: 'center',
}

interface AreaData {
  x: number
  y: number
  width: number
  height: number
}
interface ShelfData extends AreaData {
  direction: Direction
  heights: number[]
}
interface LayoutData {
  STORE?: AreaData
  LOCATIONS?: { [name: string]: AreaData }
  SHELVES?: { [name: string]: ShelfData }
  SHELVES_LOW?: { [name: string]: ShelfData }
  ITEMS?: { [name: string]: AreaData }
}

const checkInteger = (v: any) => {
  if (v === undefined || Number.isInteger(v) === false) throw new Error(`Invalid file format.`)
}

const readFile = (file: File): Promise<LayoutData> => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader()
    reader.onload = (evt) => {
      resolve(evt.target?.result as string)
    }
    reader.readAsText(file)
  })
    .then((text: string) => {
      const data: any = JSON.parse(text)
      console.log(data)
      if (data.STORE) {
        checkInteger(data.STORE.x)
        checkInteger(data.STORE.y)
        checkInteger(data.STORE.width)
        checkInteger(data.STORE.height)
      }
      if (data.LOCATIONS) {
        const keys = Object.keys(data.LOCATIONS)
        for (let i = 0, len = keys.length; i < len; i++) {
          const area: any = data.LOCATIONS[keys[i]]
          checkInteger(area.x)
          checkInteger(area.y)
          checkInteger(area.width)
          checkInteger(area.height)
        }
      }
      if (data.SHELVES) {
        const keys = Object.keys(data.SHELVES)
        for (let i = 0, len = keys.length; i < len; i++) {
          const area: any = data.SHELVES[keys[i]]
          checkInteger(area.x)
          checkInteger(area.y)
          checkInteger(area.width)
          checkInteger(area.height)
          if (/^(North|South|East|West)$/.test(area.direction) === false) throw new Error(`Invalid file format.`)
          if (Array.isArray(area.heights) === false) throw new Error(`Invalid file format.`)
          for (let j = 0, len = area.heights.length; j < len; j++) {
            checkInteger(area.heights[j])
          }
        }
      }
      if (data.SHELVES_LOW) {
        const keys = Object.keys(data.SHELVES_LOW)
        for (let i = 0, len = keys.length; i < len; i++) {
          const area: any = data.SHELVES_LOW[keys[i]]
          checkInteger(area.x)
          checkInteger(area.y)
          checkInteger(area.width)
          checkInteger(area.height)
          if (/^(North|South|East|West)$/.test(area.direction) === false) throw new Error(`Invalid file format.`)
          if (Array.isArray(area.heights) === false) throw new Error(`Invalid file format.`)
          for (let j = 0, len = area.heights.length; j < len; j++) {
            checkInteger(area.heights[j])
          }
        }
      }
      if (data.ITEMS) {
        const keys = Object.keys(data.ITEMS)
        for (let i = 0, len = keys.length; i < len; i++) {
          const area: any = data.ITEMS[keys[i]]
          checkInteger(area.x)
          checkInteger(area.y)
          checkInteger(area.width)
          checkInteger(area.height)
        }
      }
      return data as LayoutData
    });
}

export const toJsonString = (store: AreaModel, locationAreas: AreaModel[], shelfAreas: AreaModel[], shelfLowAreas: AreaModel[], itemAreas: AreaModel[]): string => {
  const obj: any = {
    STORE: {
      x: store.x,
      y: store.y,
      width: store.width,
      height: store.height,
    },
    LOCATIONS: {},
    SHELVES: {},
    SHELVES_LOW: {},
    ITEMS: {},
  }
  for (let i = 0, len = locationAreas.length; i < len; i++) {
    const area = locationAreas[i]
    obj.LOCATIONS[area.name] = {
      x: area.x,
      y: area.y,
      width: area.width,
      height: area.height,
    }
  }
  for (let i = 0, len = shelfAreas.length; i < len; i++) {
    const area = shelfAreas[i]
    obj.SHELVES[area.name] = {
      x: area.x,
      y: area.y,
      width: area.width,
      height: area.height,
      direction: area.direction,
      heights: area.heights,
    }
  }
  for (let i = 0, len = shelfLowAreas.length; i < len; i++) {
    const area = shelfLowAreas[i]
    obj.SHELVES_LOW[area.name] = {
      x: area.x,
      y: area.y,
      width: area.width,
      height: area.height,
      direction: area.direction,
      heights: area.heights,
    }
  }
  for (let i = 0, len = itemAreas.length; i < len; i++) {
    const area = itemAreas[i]
    obj.ITEMS[area.name] = {
      x: area.x,
      y: area.y,
      width: area.width,
      height: area.height,
    }
  }
  return JSON.stringify(obj, null, 2)
}

export const UploadDialog: React.FC<UploadDialogProps> = ({ open, onClose, onUpload }) => {
  const [data, setData] = useState<LayoutData>()
  const { store, locationAreas, shelfAreas, shelfLowAreas, itemAreas, setStore, setLocationAreas, setShelfAreas, setShelfLowAreas, setItemAreas } = useStoreLayout()
  const [hasFormatError, setHasFormatError] = useState(false)
  const onDrop = useCallback((files: File[]) => {
    setHasFormatError(false)
    if (files.length < 1) return
    readFile(files[0])
      .then((newData) => {
        setData(newData)
      })
      .catch(err => {
        console.log(err)
        setHasFormatError(true)
      })
  }, [])
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  })
  const handleApply = useCallback(() => {
    console.log('handleApply')
    if (!data) return
    if (data.STORE) {
      setStore({
        ...store,
        x: data.STORE.x,
        y: data.STORE.y,
        width: data.STORE.width,
        height: data.STORE.height,
      })
    }
    if (data.LOCATIONS) {
      const newLocationAreas = [...locationAreas]
      for (let i = 0, len = newLocationAreas.length; i < len; i++) {
        const area = newLocationAreas[i]
        const dArea = data.LOCATIONS[area.name]
        if (dArea) {
          area.x = dArea.x
          area.y = dArea.y
          area.width = dArea.width
          area.height = dArea.height
        }
      }
      setLocationAreas(newLocationAreas)
    }
    if (data.SHELVES) {
      const newShelfAreas = [...shelfAreas]
      for (let i = 0, len = newShelfAreas.length; i < len; i++) {
        const area = newShelfAreas[i]
        const dArea = data.SHELVES[area.name]
        if (dArea) {
          area.x = dArea.x
          area.y = dArea.y
          area.width = dArea.width
          area.height = dArea.height
          area.direction = dArea.direction
          area.heights = dArea.heights
        }
      }
      setShelfAreas(newShelfAreas)
    }
    if (data.SHELVES_LOW) {
      const newShelfLowAreas = [...shelfLowAreas]
      for (let i = 0, len = newShelfLowAreas.length; i < len; i++) {
        const area = newShelfLowAreas[i]
        const dArea = data.SHELVES_LOW[area.name]
        if (dArea) {
          area.x = dArea.x
          area.y = dArea.y
          area.width = dArea.width
          area.height = dArea.height
          area.direction = dArea.direction
          area.heights = dArea.heights
        }
      }
      setShelfLowAreas(newShelfLowAreas)
    }
    if (data.ITEMS) {
      const newItemAreas = [...itemAreas]
      for (let i = 0, len = newItemAreas.length; i < len; i++) {
        const area = newItemAreas[i]
        const dArea = data.ITEMS[area.name]
        if (dArea) {
          area.x = dArea.x
          area.y = dArea.y
          area.width = dArea.width
          area.height = dArea.height
        }
      }
      setItemAreas(newItemAreas)
    }
    onUpload()
  }, [onUpload, data, store, locationAreas, shelfAreas, shelfLowAreas, itemAreas, setStore, setLocationAreas, setShelfAreas, setShelfLowAreas, setItemAreas])

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
    >
      <DialogTitle>レイアウトアップロード</DialogTitle>
      <DialogContent>
        <WarnigMessage
          hidden={!hasFormatError}
          title="ファイルエラー"
          message="ファイルフォーマットが正しくありません"
        />
        <section>
          <Box {...getRootProps()} sx={sxDrop}>
            <input {...getInputProps()} />
            <div>レイアウトファイルをドロップするか、クリックしてファイルを選択してください</div>
          </Box>
        </section>
        <Typography hidden={!Boolean(data)}>
          存在するエリアの座標を上書きします（未定義のエリアの追加、削除は行いません）。
          「適用」後、保存してください。
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>キャンセル</Button>
        <Button color="primary" variant="contained" disabled={!Boolean(data)} onClick={handleApply}>適用</Button>
      </DialogActions>
    </Dialog>
  )
}