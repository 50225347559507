import React, {
  useEffect,
  useRef,
} from 'react'
import {
  PastValues,
} from 'models'
import * as d3 from 'd3'
import { DateTime } from 'luxon'

interface WeightData {
  us: number
  value: number
}

const toWeightDatum = (pastValues: PastValues): WeightData[] => pastValues.values
  .map((value, idx) => ({
    us: pastValues.us + pastValues.interval * idx,
    value,
  }))

const tickValues = (pastValues: PastValues): number[] => {
  const len = Math.floor((pastValues.interval * pastValues.values.length) / 1000000)
  const base = Math.floor(pastValues.us / 1000000) * 1000000
  return [...new Array(len)].map((_, idx) => base + idx * 1000000)
}

type WeightDiffInfoProps = {
  value: number
  pastValues: PastValues
  color?: 'green' | 'blue' | 'red'
  width?: number
  height?: number
  marginTop?: number
  marginBottom?: number
  marginLeft?: number
  marginRight?: number
}

export const WeightDiffInfo: React.FC<WeightDiffInfoProps> = ({
  pastValues,
  color = 'green',
  width = 850,
  height = 300,
  marginTop = 10,
  marginBottom = 20,
  marginLeft = 50,
  marginRight = 10,
  }) => {
  
  const ref = useRef<SVGSVGElement | null>(null)
  useEffect(() => {
    const svgElm = ref.current
    if (svgElm === null) return

    const datum = toWeightDatum(pastValues)
    const xScale = d3.scaleLinear()
      .domain([pastValues.us, pastValues.us + pastValues.interval * (pastValues.values.length - 1)])
      .range([0, width - marginLeft - marginRight])
    const yScale = d3.scaleLinear()
      .domain([d3.max(pastValues.values) || 1000, d3.min(pastValues.values) || 0])
      .range([0, height - marginTop - marginBottom])
      .nice()

    const xAxis = d3
      .axisBottom(xScale)
      .tickValues(tickValues(pastValues))
      .tickFormat(us => `${DateTime.fromMillis(us.valueOf() / 1000).toFormat('HH:mm:ss')}`)
    const yAxis = d3
      .axisLeft(yScale)

    const svg = d3.select(svgElm)
    const xAxisG = svg
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(${marginLeft}, ${height - marginBottom})`)
    xAxisG.call(xAxis)
    const yAxisG = svg
      .append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(${marginLeft}, ${marginTop})`)
    yAxisG.call(yAxis)
    const dataArea = svg
      .append('g')
      .attr('class', 'd')
      .attr('transform', `translate(${marginLeft}, ${marginTop})`)

    dataArea
      .append('path')
      .datum(datum)
      .attr('fill', 'none')
      .attr('stroke', color)
      .attr('stroke-width', 1.5)
      .attr('d', d3.line<WeightData>().curve(d3.curveBasis).x(d => xScale(d.us)).y(d => yScale(d.value)))
  }, [ref, pastValues, color, width, height, marginTop, marginBottom, marginLeft, marginRight])

  return (
    <svg
      ref={ref}
      width={width}
      height={height}
    />
  )
}
