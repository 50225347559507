import React, {
  useCallback,
} from 'react'
import {
  Select,
  SelectChangeEvent,
  MenuItem,
} from '@mui/material'
import {
  useApp,
} from 'contexts'

interface StoresSelectProps {
  storeCode: string
  onChange: (storeCode: string) => void
}

export const StoresSelect: React.FC<StoresSelectProps> = ({ storeCode, onChange }) => {
  const { getStores } = useApp()
  const onChanged = useCallback((evt: SelectChangeEvent<string>) => {
    const newValue = evt.target.value as string
    onChange(newValue)
  }, [onChange])
  return (
  <Select size="small" value={storeCode || '_'} onChange={onChanged}>
    <MenuItem value="_">未設定</MenuItem>
    {getStores().map(store => (
    <MenuItem key={store.store_code} value={store.store_code}>{store.store_name}</MenuItem>
    ))}
  </Select>
  )
}
