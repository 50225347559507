import React, {
  useState,
  useEffect,
} from 'react'
import MaterialTable from '@material-table/core'
import {
  useApp,
} from 'contexts'
import {
  Notification,
} from 'models'
import {
  MainContents,
  MaterialTableComponents,
  MaterialTableOptions,
  MaterialTableLocalization,
  TimestampLabel,
} from 'components'
import {
  subscribe,
} from 'iot'

export const LogsStreams: React.FC = () => {
  const { getStore } = useApp()
  const [notifications, setNotifications] = useState<Notification[]>([])
  console.log('LogsStreams##')
  useEffect(() => {
    console.log('LogsStreams##useEffect')
    const notifications: Notification[] = []
    const subscriber = subscribe<any>(`notifications/#`, (d) => {
      if (getStore(d.value.store_code) === undefined) return
      console.log(d.value)
      notifications.push(d.value)
      setNotifications([...notifications])  
    })
    return () => {
      console.log('out')
      subscriber.disconnect()
    }
  }, [getStore])

  return (
    <MainContents>
      <MaterialTable
        columns={[
          {
            title: '店舗',
            field: 'timestamp',
            width: '25%',
            render: (d) => (getStore(d.store_code).store_name),
          },
          {
            title: '時刻',
            field: 'timestamp',
            width: '15%',
            defaultSort: 'asc',
            render: (d) => (<TimestampLabel value={d.timestamp} />),
          },
          {
            title: '優先度',
            field: 'priority',
            width: '15%',
          },
          {
            title: '種別',
            field: 'type',
            width: '15%',
          },
          {
            title: 'メッセージ',
            field: 'message',
          },
        ]}
        data={notifications}
        components={{
          ...MaterialTableComponents,
        }}
        options={MaterialTableOptions<Notification>()}
        localization={MaterialTableLocalization}
      />
    </MainContents>
  )
}
