import React, {
} from 'react'
import {
  Typography,
} from '@mui/material';
import {
  StoreShadowStatus,
  UnixTimestamp,
} from 'models'
import {
  DurationLabel,
} from 'components'

type StoreStatusDurationLabelProps = {
  now: UnixTimestamp,
  storeStatus: StoreShadowStatus, 
}

export const StoreStatusDurationLabel: React.FC<StoreStatusDurationLabelProps> = ({ now, storeStatus }) => {
  if (storeStatus.devices === 'running' && storeStatus.boot_at) {
    const getValue = () => {
      if (storeStatus.restart_service_at && storeStatus.restart_at) {
        return now - Math.max(storeStatus.restart_service_at, storeStatus.restart_at)
      } else if (storeStatus.restart_at) {
        return now - storeStatus.restart_at
      } else if (storeStatus.restart_service_at) {
        return now - storeStatus.restart_service_at
      } else if (storeStatus.boot_at) {
        return now - storeStatus.boot_at
      } else {
        throw new Error();
      }
    }
    return (
    <>
      <Typography variant="caption">稼動</Typography>
      <Typography><DurationLabel value={(getValue())}/></Typography>
    </>
    )
  }
  if (storeStatus.devices === 'starting' && storeStatus.starting_at) {
    return (
    <>
      <Typography variant="caption">起動処理</Typography>
      <Typography><DurationLabel value={(now - storeStatus.starting_at)}/></Typography>
    </>
    )
  }
  if (storeStatus.devices === 'stopping' && storeStatus.stopping_at) {
    return (
    <>
      <Typography variant="caption">停止処理</Typography>
      <Typography><DurationLabel value={(now - storeStatus.stopping_at)}/></Typography>
    </>
    )
  }
  if (storeStatus.devices === 'stop' && storeStatus.stop_at) {
    return (
    <>
      <Typography variant="caption">停止</Typography>
      <Typography><DurationLabel value={(now - storeStatus.stop_at)}/></Typography>
    </>
    )
  }
  if (storeStatus.devices === 'restarting' && storeStatus.restarting_at) {
    return (
    <>
      <Typography variant="caption">再起動</Typography>
      <Typography><DurationLabel value={(now - storeStatus.restarting_at)}/></Typography>
    </>
    )
  }
  if (storeStatus.devices === 'restarting_service' && storeStatus.restarting_service_at) {
    return (
    <>
      <Typography variant="caption">サービス再起動</Typography>
      <Typography><DurationLabel value={(now - storeStatus.restarting_service_at)}/></Typography>
    </>
    )
  }
  if (storeStatus.devices === 'service_error' && storeStatus.service_error_at) {
    return (
    <>
      <Typography variant="caption" color="error">サービスエラー</Typography>
      <Typography color="error"><DurationLabel value={(now - storeStatus.service_error_at)}/></Typography>
    </>
    )
  }
  if (storeStatus.devices === 'error' && storeStatus.error_at) {
    const getValue = () => {
      if (storeStatus.service_error_at && storeStatus.error_at) {
        return now - Math.min(storeStatus.service_error_at, storeStatus.error_at)
      } else if (storeStatus.error_at) {
        return now - storeStatus.error_at
      } else {
        throw new Error()
      }
    }
    return (
    <>
      <Typography variant="caption" color="error">障害</Typography>
      <Typography color="error"><DurationLabel value={(getValue())}/></Typography>
    </>
    )
  }
  return (<></>)
}