import React, {
  useEffect,
  useState,
  useCallback,
} from 'react'
import {
  useParams,
} from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Paper,
  Radio,
  Collapse,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
import {
  useApp,
} from 'contexts'
import {
  ShelfType,
  ShelfConfigUpdatePart,
} from 'models'
import {
  ProgressScreen,
} from 'components'
import { ShelfConfigFormData, ShelfConfigFormValidations } from './forms'

type ConfigDialogProps = {
  shelfType: ShelfType
  weightThreshold: number | undefined
  defaultWeightThreshold: number
  disableAutoModifyWeightThreshold: boolean
  weightFluctuation: boolean
  disabled: boolean
  open: boolean
  onClose: () => void
  onUpdated: () => void
}

export const ConfigDialog: React.FC<ConfigDialogProps> = ({
  weightThreshold,
  defaultWeightThreshold,
  disableAutoModifyWeightThreshold,
  weightFluctuation,
  disabled,
  open,
  onClose,
  onUpdated,
}) => {
  const { storeCode, shelfCode } = useParams()
  if (storeCode === undefined || shelfCode === undefined) throw new Error('unhandled routing')
  const { getApi, apiErrorHandler } = useApp()
  const [useDefaultWeightThreshold, setUseDefaultWeightThreshold] = useState(Boolean(weightThreshold === undefined))
  const [weightFluctuationChecked, setWeightFluctuationChecked] = useState(weightFluctuation)
  const [disableAutoModifyWeightThresholdChecked, setDisableAutoModifyWeightThresholdChecked] = useState(disableAutoModifyWeightThreshold)
  const [disabledChecked, setDisabledChecked] = useState(disabled)

  const [isUpdating, setIsUpdating] = useState(false)
  const { register, handleSubmit, setValue, formState, formState: { errors } } = useForm<ShelfConfigFormData>({
    mode: 'onBlur',
  })

  useEffect(() => {
    setUseDefaultWeightThreshold(Boolean(weightThreshold === undefined))
  }, [setValue, weightThreshold])
  useEffect(() => {
    setWeightFluctuationChecked(weightFluctuation)
  }, [weightFluctuation])
  useEffect(() => {
    setDisableAutoModifyWeightThresholdChecked(disableAutoModifyWeightThreshold)
  }, [disableAutoModifyWeightThreshold])
  useEffect(() => {
    setDisabledChecked(disabled)
  }, [disabled])

  const weightThresholdChenged = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    setUseDefaultWeightThreshold(evt.target.checked)
  }, [])
  const weightFluctuationChanged = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    setWeightFluctuationChecked(evt.target.checked)
  }, [])
  const disabledChenged = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    setDisabledChecked(evt.target.checked)
  }, [])
  const disableAutoModifyWeightThresholdClicked = useCallback(() => {
    setDisableAutoModifyWeightThresholdChecked(true)
  }, [])
  const enableAutoModifyWeightThresholdClicked = useCallback(() => {
    setDisableAutoModifyWeightThresholdChecked(false)
  }, [])

  // 保存
  const onSubmit = useCallback((data: ShelfConfigFormData) => {
    setIsUpdating(true)
    const wt = (() => {
      if (!disableAutoModifyWeightThresholdChecked) return weightThreshold // not change
      if (!useDefaultWeightThreshold && data.weight_threshold !== undefined) return Number(data.weight_threshold) // not default value
      return undefined // default value
    })()
    const payload: ShelfConfigUpdatePart = {
      type: 'standard',
      disabled: disabledChecked,
      weight_fluctuation: weightFluctuationChecked,
      weight_threshold: wt,
      disable_auto_modify_weight_threshold: disableAutoModifyWeightThresholdChecked,
    }
    getApi()
      .updateShelfConfig(`${storeCode}_${shelfCode}`, payload)
      .then(() => {
        setIsUpdating(false)
        onUpdated()
      })
      .catch(apiErrorHandler)
  }, [getApi, apiErrorHandler, onUpdated, storeCode, shelfCode, weightThreshold, useDefaultWeightThreshold, weightFluctuationChecked, disabledChecked, disableAutoModifyWeightThresholdChecked])

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>棚設定</DialogTitle>
        <DialogContent>
          <Grid container sx={{ margin: 0 }}>
            <Grid item xs={6} sx={{ padding: 1 }}>
              <Paper variant={!disableAutoModifyWeightThresholdChecked ? "outlined" : "elevation"}
                elevation={0}
                onClick={enableAutoModifyWeightThresholdClicked}
                sx={{ padding: 2 }}>
                <Radio checked={!disableAutoModifyWeightThresholdChecked} color="primary" />重量変化しきい値を自動で設定する
              </Paper>
            </Grid>
            <Grid item xs={6} sx={{ padding: 1 }}>
              <Paper variant={disableAutoModifyWeightThresholdChecked ? "outlined" : "elevation"}
                elevation={0}
                onClick={disableAutoModifyWeightThresholdClicked}
                sx={{ padding: 2 }}>
                <Radio checked={disableAutoModifyWeightThresholdChecked} color="primary" />重量変化しきい値を手動で設定する
              </Paper>
            </Grid>

            <Collapse in={disableAutoModifyWeightThresholdChecked}>
              <Grid item xs={12}>
                <FormControlLabel
                  label={`重量変化しきい値に店舗のデフォルト値（${defaultWeightThreshold}g）を利用する`}
                  control={(
                    <Checkbox
                      checked={useDefaultWeightThreshold}
                      onChange={weightThresholdChenged}
                      color="primary"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="重量変化しきい値"
                  size="small"
                  margin="dense"
                  type="number"
                  inputProps={{ step: '0.1' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">g</InputAdornment>
                    ),
                  }}
                  disabled={useDefaultWeightThreshold}
                  defaultValue={`${weightThreshold}`}
                  {...register(`weight_threshold`, {
                    ...ShelfConfigFormValidations.weight_threshold,
                    validate: (value: string) => {
                      if (!useDefaultWeightThreshold && value.length === 0) return '最低重量変化を入力してください'
                      return true
                    }
                  })}
                  error={Boolean(errors?.weight_threshold)}
                  helperText={errors?.weight_threshold?.message}
                />
              </Grid>
            </Collapse>

            <Grid item xs={12}>
              <FormControlLabel
                label="重量の個体差が大きい商品を含む"
                control={(
                  <Checkbox
                    checked={weightFluctuationChecked}
                    onChange={weightFluctuationChanged}
                    color="primary"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                label="この棚の購入判定を無効化する"
                control={(
                  <Checkbox
                    checked={disabledChecked}
                    onChange={disabledChenged}
                    color="primary"
                  />
                )}
              />
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={onClose}>キャンセル</Button>
          <Button color="primary" variant="contained" type="submit" disabled={!formState.isValid}>保存</Button>
        </DialogActions>
      </form>
      <ProgressScreen open={isUpdating} />
    </Dialog>
  )
}

