import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  useNavigate,
  useLocation,
} from 'react-router-dom'
import {
  useQueryParam,
  StringParam,
  withDefault,
} from 'use-query-params'
import { Toolbar, Tooltip, IconButton } from '@mui/material';
import {
  AddBox as Add,
} from '@mui/icons-material'
import MaterialTable from '@material-table/core'
import {
  useApp,
} from 'contexts'
import {
  Store,
  Replay,
} from 'models'
import {
  MainContents,
  StoresSelector,
  Loading,
  ReloadButton,
  TimestampLabel,
  DataSizeLabel,
  MaterialTableOptions,
  MaterialTableComponents,
  MaterialTableLocalization,
} from 'components'
import {
  ReplayDetail,
  ReplayRequestDialog,
} from './components'

export const AnalysisReplay: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { getApi, defaultApiErrorHandler, getInitalStore } = useApp()
  const [storeCode, setStoreCode] = useQueryParam(
    's',
    withDefault(StringParam, getInitalStore().store_code),
  )
  const [reload, setReload] = useState(false)
  const [items, setItems] = useState<Replay[]>()
  const [requestDialogOpen, setRequestDialogOpen] = useState(false)

  const load = useCallback(() => {
    if (!storeCode) return
    getApi()
      .fetchReplayList(storeCode)
      .then((result) => {
        console.log('fetchAsync done')
        setItems(result.items)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, storeCode])
  useEffect(() => {
    load()
  }, [load])
  // reload
  useEffect(() => {
    if (!reload) return
    console.log('reloading...')
    load()
    setReload(false)
  }, [load, reload])

  const onStoreChanged = useCallback((store: Store) => {
    setStoreCode(store?.store_code)
    setItems(undefined)
  }, [setStoreCode])

  const handleReload = useCallback(() => {
    setReload(true)
    setItems(undefined)
  }, [])
  const handleRequested = useCallback((replay: Replay) => {
    console.log(replay)
    setRequestDialogOpen(false)
    setReload(true)
    setItems(undefined)
  }, [])

  return (
    <MainContents>
      <Toolbar variant="dense" disableGutters>
        <StoresSelector
          storeCode={storeCode}
          onSeleced={onStoreChanged}
        />
        <Tooltip title="リプデイデータ作成">
          <IconButton
            aria-label="request"
            color="primary"
            sx={{ marginLeft: 'auto' }}
            onClick={() => setRequestDialogOpen(true)}
            size="small"
          >
            <Add />
          </IconButton>
        </Tooltip>
        <ReplayRequestDialog
          storeCode={storeCode}
          open={requestDialogOpen}
          onClose={() => setRequestDialogOpen(false)}
          onRequested={handleRequested}
        />
        <ReloadButton onClick={handleReload} />
      </Toolbar>
      <Loading visible={items === undefined} />
      {items && (
        <MaterialTable
          data={items}
          columns={[
            {
              title: '日時',
              field: 'timestamp',
              sorting: true,
              render: (d) => (<TimestampLabel value={d.timestamp} format={"MM/dd HH:mm"} />),
            },
            {
              title: '期間',
              field: 'duration_min',
              sorting: false,
              render: (d) => (<>{d.duration_min}分</>),
            },
            {
              title: 'ステータス',
              field: 'stat',
              sorting: false,
            },
            {
              title: '追跡スキャンデータ',
              field: 'tracking_data_scanned_in_bytes',
              sorting: false,
              render: (d) => (<DataSizeLabel value={d.tracking_query_execution.data_scanned_in_bytes} />),
            },
            {
              title: '重量スキャンデータ',
              field: 'weight_data_scanned_in_bytes',
              sorting: false,
              render: (d) => (<DataSizeLabel value={d.weight_query_execution.data_scanned_in_bytes} />),
            },
          ]}
          actions={[
            rowData => ({
              icon: 'show_chart',
              tooltip: '詳細を表示',
              disabled: Boolean(rowData.stat !== 'SUCCEEDED'),
              onClick: () => {
                navigate(`/analysis/replay/${rowData.replay_id}${location.search}`)
              },
            }),
          ]}
          detailPanel={(({ rowData }) => (<ReplayDetail data={rowData} />))}
          options={MaterialTableOptions<Replay>({
            toolbar: false,
          })}
          components={{
            ...MaterialTableComponents,
          }}
          localization={{
            ...MaterialTableLocalization
          }}
        />
      )}
    </MainContents>
  )
}