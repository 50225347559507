import { UnixTimestamp } from './types'
import { Iot } from 'iot'

export type DeviceType = 'ws' | 'gk' | 'edge'
export type DevicePowerStatus = 'off' | 'on' | 'error'
export type DeviceServiceStatus = 'running' | 'stop' | 'starting' | 'stopping'
export type EdgeStatus = 'running' | 'stop' | 'error'

export interface Device {
  type: DeviceType
  thing_name: string
  store_code: string
  properties: DeviceProperties
  agent_properties: DeviceAgentProperties
  service_properties: DeviceServiceProperties
  power_status?: DevicePowerStatus
  service_status?: DeviceServiceStatus
  service_error?: DeviceServiceError
  controlled?: boolean
  ping_status?: string
  ip_address?: string
  buildinfo_agent?: string
  buildinfo_service?: string
}
export interface DeviceProperties {
  store_code?: string // TODO あとで消す
}
export type AwslogsLevel = 'INFO' | 'WARN' | 'ERROR' | 'NONE';
export interface DeviceServiceProperties {
  awslogs_level: AwslogsLevel;
  interval: number;
}
export interface DeviceAgentProperties extends DeviceServiceProperties {
  service_start_timeout: number;
}

// WeightSensor Devices
export interface WeightSensorDevice extends Device {
  properties: WeightSensorProperties
  service_properties: WeightSensorServiceProperties
}
export interface WeightSensorProperties extends DeviceProperties {
  name: string
}
export interface WeightSensorServiceProperties extends DeviceServiceProperties {
  enable_publish_data: boolean
  weight_data_interval: number
  enable_weight_file: boolean
  enable_rawdata_file: boolean
  nodes: WeightSensorNode[]
}
export interface WeightSensorNode {
  node_id: string;
  segments: WeightSensorSegment[]
}
export type WeightSensorSegment = string | null;

// GateKeeper Devices
export interface GateKeeperDevice extends Device {
  properties: GateKeeperProperties
  service_properties: GateKeeperServiceProperties
}
export interface GateKeeperProperties extends DeviceProperties {
}
export interface GateKeeperServiceProperties extends DeviceServiceProperties {
  enter_area: string
  exit_area: string
  module_gate: string
  module_checkin: string
  module_audio: string
  test_mode: boolean
}

// Edge Devices
export interface EdgeDevice extends Device {
  properties: EdgeProperties
  service_properties: EdgeServiceProperties
}
export interface EdgeProperties extends DeviceProperties {
  ssm_instance_id?: string
}
export interface EdgeServiceProperties extends DeviceServiceProperties {
  check_stat_interval: number;
  action_awslogs_level: AwslogsLevel;
  action_debug: boolean;
  action_post_api: boolean;
  passage_awslogs_level: AwslogsLevel;
  passage_debug: boolean;
  passage_post_api: boolean;
  weight_awslogs_level: AwslogsLevel;
  weight_enable_mqtt: boolean;
  weight_enable_firehose: boolean;
  tracking_awslogs_level: AwslogsLevel;
  tracking_enable_mqtt: boolean;
  tracking_enable_firehose: boolean;
  movie_awslogs_level: AwslogsLevel;
  logs_notify_slack_channel: string;
}

export interface DeviceServiceError {
  error_code: string
}

export interface DeviceImage {
  url: string
  timestamp: UnixTimestamp
  content_type?: string
  content_length?: number
}

export const attachStatusToDevices = async <T extends Device>(devices: T[], iot: Iot): Promise<T[]> => {
  const result = [...devices]
  await Promise.all(devices.map(async (device) => {
    const result = await iot.getThingShadow({
      thingName: device.thing_name,
      shadowName: 'status',
    })
    const state = JSON.parse(result.payload?.toString() || '{}')?.state
    const reported = state?.reported
    const desired = state?.desired
    device.power_status = reported?.power
    device.service_status = reported?.service
    device.service_error = reported?.service_error
    device.controlled = (() => {
      if (device.controlled !== undefined) return device.controlled
      if (desired?.controlled !== undefined) return desired?.controlled
      return reported?.controlled
    })()
  }))
  return result
}

export const findEdgeDevice = (devices: Device[]): (EdgeDevice | undefined) => {
  const edgeDevices = devices.filter(d => d.type == 'edge');
  if (edgeDevices.length === 0) {
    return undefined;
  } else {
    return edgeDevices[0] as EdgeDevice;
  }
}
