import React, {
  useCallback,
} from 'react'
import {
  Toolbar,
  ImageList,
  ImageListItem,
  Typography,
} from '@mui/material';
import {
  useSelectedStoreCode,
  useShelvesLayout,
} from 'hooks'
import {
  Store,
} from 'models'
import {
  MainContents,
  StoresSelector,
  Loading,
  ShelfTile,
  WarnigMessage,
} from 'components'

export const MonitorWeight: React.FC = () => {
  const { storeCode, setStoreCode } = useSelectedStoreCode()
  const { layout, totalSegmentsCount } = useShelvesLayout(storeCode)

  const onStoreChanged = useCallback((store: Store | undefined) => {
    if (store) {
      setStoreCode(store.store_code)
    }
  }, [setStoreCode])

  return (
    <MainContents>
      <Toolbar variant="dense" disableGutters>
        <StoresSelector
          storeCode={storeCode}
          onSeleced={onStoreChanged}
        />
      </Toolbar>
      <Loading visible={layout === null} />
      {layout &&
        <>
          <ImageList
            sx={{ alignItems: 'flex-end' }}
            cols={2}
            rowHeight="auto"
          >
            {layout.shelves.map(shelf => (
              <ImageListItem key={shelf.shelfCode}>
                <Typography>{shelf.shelfCode}</Typography>
                <ShelfTile
                  model={shelf}
                  variant="monitor"
                />
              </ImageListItem>
            ))}
          </ImageList>
          {0 < layout.shelves.length &&
            (<Typography variant="body2" sx={{ marginTop: 0.5, marginRight: 1, textAlign: 'right' }}>合計棚数: {totalSegmentsCount}</Typography>)
          }
          <WarnigMessage
            hidden={0 < layout.shelves.length}
            title="棚が登録されていません"
          />
        </>
      }
    </MainContents>
  )
}