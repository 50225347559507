import React, {
  useCallback,
} from 'react'
import { Toolbar, IconButton, Tooltip, Dialog, Typography, Box } from '@mui/material';
import {
  Save,
  Close,
} from '@mui/icons-material'
import {
  useApp,
  useStoreLayout,
} from 'contexts'
import {
  Location,
  FloorItem,
  Shelf,
  ShelfAreaUpdatePart,
} from 'models'
import {
  StoreLayout,
  AreaModel,
  DiagonalAreaModel,
} from 'components'


interface LayoutEditDialogProps {
  open: boolean
  onClose: () => void
  onSave: () => void
}

const toLocations = (storeCode: string, store: AreaModel, locationAreas: AreaModel[]): Location[] => {
  const locations: Location[] = locationAreas.map(area => ({
    store_code: storeCode,
    location_code: `${storeCode}_${area.name}`,
    area: [Math.floor(area.x * 10), Math.floor(area.y * 10), Math.floor(area.width * 10), Math.floor(area.height * 10)],
  }))
  return [...locations, {
    store_code: storeCode,
    location_code: `${storeCode}_STORE`,
    area: [Math.floor(store.x * 10), Math.floor(store.y * 10), Math.floor(store.width * 10), Math.floor(store.height * 10)],
  }]
}

const toShelfAreaUpdatePart = (shelf: Shelf, shelfAreas: AreaModel[], shelfLowAreas: AreaModel[], diagonalShelfAreas: DiagonalAreaModel[], diagonalShelfLowAreas: DiagonalAreaModel[]): ShelfAreaUpdatePart => {
  const area = shelfAreas.find(a => shelf.shelf_code === `${shelf.store_code}_${a.name}`)
  const areaLow = shelfLowAreas.find(a => shelf.shelf_code === `${shelf.store_code}_${a.name}`)
  const diagonalArea = diagonalShelfAreas.find(a => shelf.shelf_code === `${shelf.store_code}_${a.name}`)
  const diagonalAreaLow = diagonalShelfLowAreas.find(a => shelf.shelf_code === `${shelf.store_code}_${a.name}`)
  if (area !== undefined && areaLow !== undefined) {
    return {
      area: [Math.floor(area.x * 10), Math.floor(area.y * 10), Math.floor(area.width * 10), Math.floor(area.height * 10)],
      area_low: [Math.floor(areaLow.x * 10), Math.floor(areaLow.y * 10), Math.floor(areaLow.width * 10), Math.floor(areaLow.height * 10)],
      direction: area.direction || shelf.direction,
      heights: area.heights || shelf.heights,
    }
  } else if (diagonalArea !== undefined && diagonalAreaLow !== undefined) {
    return {
      area: [Math.floor(diagonalArea.x1 * 10), Math.floor(diagonalArea.y1 * 10), Math.floor(diagonalArea.x2 * 10), Math.floor(diagonalArea.y2 * 10)],
      area_low: [Math.floor(diagonalAreaLow.x1 * 10), Math.floor(diagonalAreaLow.y1 * 10), Math.floor(diagonalAreaLow.x2 * 10), Math.floor(diagonalAreaLow.y2 * 10)],
      direction: 'Diagonal',
      heights: diagonalArea.heights || shelf.heights,
    }
  } else {
    throw new Error()
  }
}

export const LayoutEditDialog: React.FC<LayoutEditDialogProps> = ({ open, onSave, onClose }) => {
  const { getApi } = useApp()
  const { storeCode, locations, shelves, store, locationAreas, itemAreas, shelfAreas, shelfLowAreas, diagonalShelfAreas, diagonalShelfLowAreas, reset } = useStoreLayout()
  const handleSaved = useCallback(() => {
    const updateLocations = toLocations(storeCode, store, locationAreas)
    const deleteKeys = locations.map(l => l.location_code).filter(lc => !updateLocations.find(l => l.location_code === lc))
    const floorItems: FloorItem[] = itemAreas.map(item => ({
      name: item.name,
      x: Math.floor(item.x * 10),
      y: Math.floor(item.y * 10),
      width: Math.floor(item.width * 10),
      height: Math.floor(item.height * 10),
    }))
    console.log(updateLocations, deleteKeys, floorItems)
    Promise.all([
      getApi().updateLocations(updateLocations, deleteKeys),
      getApi().updateFloorItems(storeCode, floorItems),
      Promise.all(
        shelves.map(shelf => getApi().updateShelfArea(
          shelf.shelf_code,
          toShelfAreaUpdatePart(shelf, shelfAreas, shelfLowAreas, diagonalShelfAreas, diagonalShelfLowAreas),
        ))
      )
    ]).then(() => {
      onSave()
      reset()
    })
  }, [storeCode, store, locationAreas, itemAreas, shelves, shelfAreas, shelfLowAreas, diagonalShelfAreas, diagonalShelfLowAreas, getApi, locations, onSave, reset])
  const handleClosed = useCallback(() => {
    reset()
    onClose()
  }, [onClose, reset])

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <Toolbar variant="dense" disableGutters sx={{ marginTop: 0.5 }}>
        <Typography variant="h4" sx={{ marginLeft: 2 }}>
          レイアウトの編集
        </Typography>
        <Tooltip title="保存">
          <IconButton
            color="primary"
            sx={{ marginLeft: 'auto' }}
            onClick={handleSaved}
            size="large">
            <Save />
          </IconButton>
        </Tooltip>
        <Tooltip title="キャンセル">
          <IconButton
            color="primary"
            sx={{ marginRight: 0.5 }}
            onClick={handleClosed}
            size="large">
            <Close />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <Box paddingLeft={1} paddingRight={1} paddingBottom={1}>
        <StoreLayout
          width={1000}
          height={700}
          marginTop={30}
          marginLeft={50}
          marginBottom={50}
          marginRight={50}
          editable
        />
      </Box>
    </Dialog>
  );
}
