import React from 'react'
import { Item } from 'models'

interface ItemImgProps {
  item?: Item
  imageUrl?: string
  itemName?: string
  maxWidth?: string|number
}

export const ItemImg: React.FC<ItemImgProps>  = ({ item, itemName, imageUrl,  maxWidth }) => {
  return (
  <img
    src={item ? item.image_url : imageUrl}
    alt={item ? item.item_name.ja : itemName}
    style={{
      maxWidth: maxWidth || '100%',
    }}/>
  )
}
