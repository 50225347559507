import {
  Plug,
} from 'models'

export type PlugFormData = {
  name: string;
  desc: string;
}

export const toFormData = (plug: Plug): PlugFormData => ({
  name: plug.name,
  desc: plug.desc,
})

export const PlugFormValidations = {
  name: {
    required: {
      value: true,
      message: 'プラグ名を入力してください',
    },
  },
  desc: {
  },
}
