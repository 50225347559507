import React from 'react'
import {
  Tooltip,
} from '@mui/material'
import {
  ErrorOutline as Error,
  Done as Ok,
} from '@mui/icons-material'
import {
  Action,
} from 'models'

type PersonStatusLabelProps = {
  action: Action
}

export const PersonStatusLabel: React.FC<PersonStatusLabelProps> = ({ action }) => {
  if (action.person_id === 0) {
    return (<Tooltip title="不明"><Error fontSize="small" color="error"/></Tooltip>)
  }
  return (<Tooltip title="確定"><Ok fontSize="small" color="primary"/></Tooltip>)
}

