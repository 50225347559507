import React from 'react'
import {
  Tooltip,
} from '@mui/material'
import {
  DeleteForever as Ignored,
  Help as Obscure,
  Done,
} from '@mui/icons-material'

import {
  ActionPersonStatus,
  ActionItemStatus,
} from 'models'

type ActionStatusLabelProps = {
  personStatus: ActionPersonStatus
  itemStatus: ActionItemStatus
}

export const ActionStatusLabel: React.FC<ActionStatusLabelProps> = ({ personStatus, itemStatus }) => {
  if (personStatus === 'ignored' || itemStatus === 'ignored') {
    return (<Tooltip title="無視"><Ignored fontSize="small" /></Tooltip>)
  }
  if (personStatus === 'obscure' || itemStatus === 'obscure') {
    return (<Tooltip title="確認中"><Obscure fontSize="small" color="secondary" /></Tooltip>)
  }
  return (<Tooltip title="確定"><Done fontSize="small" color="primary" /></Tooltip>)
}

