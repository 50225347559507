import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  Link,
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Toolbar,
  Tabs,
  Tab,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  SxProps,
  Theme,
} from '@mui/material';
import {
  useApp,
} from 'contexts'
import {
  useShelvesLayout,
} from 'hooks'
import {
  Flow,
  FlowBehaviorAction,
  ShelfLayout,
  UnixTimestamp,
  TrackingPlayerControlModel,
  flowToTrackingPlayerControlModel,
} from 'models'
import {
  MainContents,
  Loading,
  TimestampLabel,
  BackButton,
  ReloadButton,
  ShelfTile,
  TrackingPlayer,
  FlowLineModel,
  toFlowLineModel,
} from 'components'
import {
  getStoreCodeIn,
  toSegment,
} from 'utils'
import { DateTime } from 'luxon'

const sxTitle: SxProps<Theme> = {
  color: (t) => t.palette.text.secondary
}

type Type = 'stay' | 'reach_hand' | 'move'
interface Model {
  type: Type
  storeCode: string
  timestamp: UnixTimestamp
  duration: number
  targets: string[]
  actions: FlowBehaviorAction[]
}

const toModels = (flow: Flow): Model[] => {
  return flow.behaviors.map(b => {
    const duration = Math.floor((b.toMs - b.fromMs) / 100) / 10;
    const type = (() => {
      if (duration === 0) return 'move'
      return b.segments.length === 0 ? 'stay' : 'reach_hand'
    })()
    const targets = type === 'reach_hand' ? b.segments : b.shelves
    return {
      type,
      storeCode: flow.store_code,
      timestamp: Math.floor(b.fromMs / 1000),
      duration,
      targets,
      actions: b.actions || [],
    }
  })
}

const typeDesc = (type: Type): string => {
  if (type === 'stay') return '静止'
  if (type === 'reach_hand') return '手伸ばし'
  return '移動'
}

export const AnalysisFlowDetail: React.FC = () => {
  const { flowId } = useParams()
  if (flowId === undefined) throw new Error('unhandled routing')
  const storeCode = getStoreCodeIn(flowId)
  const location = useLocation()
  const navigate = useNavigate()
  const { getApi, defaultApiErrorHandler } = useApp()
  const { getShelfLayout } = useShelvesLayout(storeCode)
  const [flow, setFlow] = useState<Flow>()
  const [models, setModels] = useState<Model[]>()
  const [trackingModel, setTrackingModel] = useState<TrackingPlayerControlModel>()
  const [flowLines, setFlowLines] = useState<FlowLineModel[]>()
  const [reload, setReload] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)

  const load = useCallback(() => {
    getApi().fetchFlow(flowId)
      .then((flow) => {
        setFlow(flow)
        setModels(toModels(flow))
        setTrackingModel(flowToTrackingPlayerControlModel(flow))
        setFlowLines([toFlowLineModel(flow)])
        console.log(flowToTrackingPlayerControlModel(flow))
        console.log(flow.behaviors)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, flowId])
  useEffect(() => {
    load()
  }, [load])
  // reload
  useEffect(() => {
    if (!reload) return
    console.log('reloading...')
    load()
    setReload(false)
  }, [load, reload])

  const handleReload = useCallback(() => {
    setFlow(undefined)
    setReload(true)
  }, [])
  const handleTabChanged = useCallback((_, newValue) => {
    setTabIndex(newValue)
  }, [])

  const handleBack = useCallback(() => {
    navigate(`/analysis/flows${location.search}`)
  }, [navigate, location])

  return (
    <MainContents>
      <Toolbar variant="dense" disableGutters>
        <BackButton onClick={handleBack} />
        <Typography variant="h5" sx={{ marginLeft: 1 }} >{flowId}</Typography>
        <ReloadButton marginLeft="auto" onClick={handleReload} />
      </Toolbar>
      <Loading visible={flow === undefined} />
      {flow && (
        <>
          <Grid container spacing={1} sx={{ margin: 0.5 }}>
            <Grid item xs={4} sm={2} sx={sxTitle}>
              <Typography>入店日時</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>
                <TimestampLabel value={flow.from} format="yyyy/MM/dd HH:mm:ss" />
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2} sx={sxTitle}>
              <Typography>退店日時</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>
                <TimestampLabel value={flow.to} format="yyyy/MM/dd HH:mm:ss" />
              </Typography>
            </Grid>

            <Grid item xs={4} sm={2} sx={sxTitle}>
              <Typography>人物ID</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>{flow.person_id}</Typography>
            </Grid>
            <Grid item xs={4} sm={2} sx={sxTitle}>
              <Typography>ウォークスルーID</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>{flow.walkthrough_id && (<Link to={`/walkthrough/${flow.walkthrough_id}`} target="_new">{flow.walkthrough_id}</Link>)}</Typography>
            </Grid>

          </Grid>
          <Tabs value={tabIndex} onChange={handleTabChanged} sx={{ marginBottom: 0.5 }}>
            <Tab label="顧客動線" />
            <Tab label="顧客行動" />
          </Tabs>
          <div hidden={Boolean(tabIndex !== 0)}>
            <TrackingPlayer storeCode={storeCode} model={trackingModel} flowLines={flowLines} height={600} />
          </div>
        </>
      )}

      {models && (
        <div hidden={Boolean(tabIndex !== 1)}>
          {0 < models.length && (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ textAlign: 'center' }}>時刻</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>滞在(s)</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>行動</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>棚</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>判定</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {models.map(model => (
                  <TableRow key={model.timestamp}>
                    <TableCell sx={{ textAlign: 'center' }}>{DateTime.fromSeconds(model.timestamp).toFormat('HH:mm:ss')}</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>{0 < model.duration ? model.duration : '-'}</TableCell>
                    <TableCell>{typeDesc(model.type)}</TableCell>
                    <TableCell>
                      <ShelfList model={model} getShelfLayout={getShelfLayout} />
                    </TableCell>
                    <TableCell>
                      <List dense disablePadding>
                        {model.actions.map(act => (
                          <ListItem key={act.timestamp}>
                            <ListItemText primary={`[${act.segment}] ${act.item_name} ×${act.qty}`} />
                          </ListItem>
                        ))}
                      </List>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          {models.length === 0 && (
            <Typography sx={{ marginTop: 1, marginBottom: 2 }}>行動ははありません</Typography>
          )}
        </div>
      )}
    </MainContents>
  )
}

interface ShelfListProps {
  model: Model
  getShelfLayout: (shelfCode: string) => ShelfLayout | undefined
}

const ShelfList: React.FC<ShelfListProps> = ({ model, getShelfLayout }) => {
  if (model.type === 'stay' || model.type === 'move') {
    return (
      <>
        {model.targets.map((shelf, idx) => (
          <Typography key={`${model.timestamp}_${idx}`}>{shelf}</Typography>
        ))}
      </>
    )
  }
  const shelfCodes = model.targets
    .map(segment => segment.split('_')[0])
    .filter((x, i, self) => self.indexOf(x) === i) // unique
  return (
    <>
      {shelfCodes.map((shelfCode, idx) => {
        const layout = getShelfLayout(`${model.storeCode}_${shelfCode}`)
        if (layout) {
          layout.segments.flat().forEach(seg => {
            if (model.targets.indexOf(toSegment(seg.segmentCode)) !== -1) {
              seg.marked = true
            }
          })
          return (
            <React.Fragment key={`${model.timestamp}_${idx}`}>
              <Typography>{shelfCode}</Typography>
              <ShelfTile
                model={layout}
                width={150}
                minHeight={30}
                markedColor="pink"
              />
            </React.Fragment>
          )
        } else {
          return (<Typography key={`${model.timestamp}_${idx}`}>{shelfCode}</Typography>)
        }
      })}
    </>
  )
}