import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'

interface ConfirmDialogProps {
  title?: string
  contentText?: string
  open: boolean
  okText?: string
  cancelText?: string
  color?: string
  handleClose: (isOk: boolean) => void
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ open, title, contentText, okText, cancelText, color, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
    >
      <DialogTitle>{title || '確認'}</DialogTitle>
      <DialogContent>
        <DialogContentText color={color}>{contentText || 'よろしいですか？'}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          {cancelText || 'キャンセル'}
        </Button>
        <Button onClick={() => handleClose(true)} color="primary">
          {okText || 'OK'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}