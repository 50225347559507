
export type StoreAddFormData = {
  store_code: string
  merchant_code: string
  store_name: string
}

export const StoreAddFormValidations = {
  store_code: {
    required: {
      value: true,
      message: '店舗コードを入力してください',
    },
    minLength: {
      value: 4,
      message: '店舗コードは4文字以上で入力してください',
    },
    maxLength: {
      value: 17,
      message: '店舗コードは17文字以内で入力してください',
    },
    pattern: {
      value: /^[a-z][a-z0-9-]{3,17}$/,
      message: '店舗コードは英小文字と数字/ハイフン（先頭不可）で入力してください',
    },
  },
  merchant_code: {
  },
  store_name: {
    required: {
      value: true,
      message: '店舗名を入力してください',
    },
  },
}