import React, {
  useState,
  useEffect,
  Fragment,
} from 'react'
import {
  Button,
  IconButton,
  Box,
} from '@mui/material';
import {
  AddBox,
  IndeterminateCheckBox,
} from '@mui/icons-material'
import {
  ShelfLayout,
} from 'models'
import {
  Segment,
  SegmentMonitor,
} from './ShelfTileSegment'
import {
  getStoreCodeIn,
  toShelf,
} from 'utils'
import {
  subscribe,
} from 'iot'
import { DateTime } from 'luxon'

interface ShelfTileProps {
  model: ShelfLayout
  width?: number
  minHeight?: number
  onClick?: () => void
  variant?: 'view' | 'edit' | 'monitor'
  showItems?: boolean
  showItemsWeight?: boolean
  onAddRow?: () => void
  onRemoveRow?: () => void
  maxRows?: number
  minRows?: number
  onAddSegment?: (row: number) => void
  onRemoveSegment?: (row: number) => void
  maxSegments?: number
  minSegments?: number
  markedColor?: string
}

interface WeightData {
  timestamp: number,
  values: { [key: string]: number }
}

const toWeightData = (segments: any, storeCode: string, shelfName: string): WeightData | undefined => {
  const segment = segments.find((s: any) => s.shelf === shelfName)
  if (segment === undefined) return undefined
  const d: { [key: string]: number } = {}
  for (let i = 0, len = segment.values.length; i < len; i++) {
    const value = segment.values[i]
    if (value !== null) d[`${storeCode}_${shelfName}_${i + 1}`] = value
  }
  return {
    timestamp: DateTime.now().toSeconds(),
    values: d,
  }
}

const getValue = (data: WeightData | undefined, segmentCode: string): number | null => {
  if (data === undefined) return null
  const value = data.values[segmentCode]
  return value !== undefined ? value : null
}

export const ShelfTile: React.FC<ShelfTileProps> = ({
  model,
  width,
  minHeight,
  onClick,
  variant,
  showItems,
  showItemsWeight,
  onAddRow,
  onRemoveRow,
  maxRows,
  minRows,
  onAddSegment,
  onRemoveSegment,
  maxSegments,
  minSegments,
  markedColor,
}) => {
  const [data, setData] = useState<WeightData>()
  useEffect(() => {
    if (variant !== 'monitor') return
    const storeCode = getStoreCodeIn(model.shelfCode)
    const shelfName = toShelf(model.shelfCode)
    console.log(`weight/${storeCode} ${shelfName}`)
    const subscriber = subscribe<any>(`weight/${storeCode}`, (d) => {
      const data = toWeightData(d.value.segments, storeCode, shelfName)
      if (data !== undefined) {
        console.log(shelfName, data)
        setData(data)
      }
    })
    return () => {
      subscriber.disconnect()
    }
  }, [model, variant])

  return (
    <Box sx={{ width: width }} onClick={onClick}>
      {model.segments.map((row, idx) => (
        <Box
          key={`row-${idx}`}
          sx={{ display: 'flex', margin: 0.2, alignItems: 'center' }}
        >
          {row.map(segment => (
            <Fragment key={segment.segmentCode}>
              {variant === 'monitor' ? (
                <SegmentMonitor
                  model={segment}
                  timestamp={data?.timestamp || DateTime.now().toSeconds()}
                  value={getValue(data, segment.segmentCode)}
                />
              ) : (
                <Segment
                  model={segment}
                  showItem={showItems}
                  showItemWeight={showItemsWeight}
                  minHeight={minHeight}
                  backgroundColor={markedColor}
                />
              )}
            </Fragment>
          ))}
          {variant === 'edit' && (
            <Box sx={{ marginLeft: 0.2 }}>
              <IconButton
                color="primary"
                size="small"
                edge="end"
                disabled={(maxSegments || 8) <= row.length}
                onClick={() => onAddSegment && onAddSegment(idx)}
              >
                <AddBox />
              </IconButton>
              <IconButton
                color="secondary"
                size="small"
                edge="end"
                disabled={row.length <= (minSegments || 2)}
                onClick={() => onRemoveSegment && onRemoveSegment(idx)}
              >
                <IndeterminateCheckBox />
              </IconButton>
            </Box>
          )}
        </Box>
      ))}
      {variant === 'edit' && (
        <div>
          <Button
            color="primary"
            startIcon={<AddBox />}
            disabled={(maxRows || 5) <= model.segments.length}
            onClick={onAddRow}
          >
            棚を追加
          </Button>
          <Button
            color="secondary"
            startIcon={<IndeterminateCheckBox />}
            disabled={model.segments.length <= (minRows || 2)}
            onClick={onRemoveRow}
          >
            最下段を削除
          </Button>
        </div>
      )}
    </Box>
  )
}

