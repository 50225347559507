import {
  Components,
  Localization,
  Options,
} from '@material-table/core'

export const SimpleMaterialTableOptions = <RowData extends Object> (opts?: Options<RowData>): Options<RowData> => ({
  showTitle: false,
  padding: 'dense',
  search: false,
  sorting: false,
  paging: false,
  pageSize: 20,
  headerStyle: {
    backgroundColor: 'transparent',
    fontWeight: 'bold',
  },
  actionsColumnIndex: -1,
  ...opts,
})

export const MaterialTableOptions = <RowData extends Object> (opts?: Options<RowData>): Options<RowData> => ({
  showTitle: false,
  padding: 'dense',
  search: true,
  sorting: true,
  paging: true,
  pageSize: 20,
  headerStyle: {
    backgroundColor: 'transparent',
    fontWeight: 'bold',
  },
  actionsColumnIndex: -1,
  ...opts,
})

export const MaterialTableComponents: Components = {
  Container: props => (
    <div {...props}></div>
  ),
}

export const MaterialTableLocalization: Localization = {
  header: {
    actions: '',
  },
  body: {
    editTooltip: '編集',
    emptyDataSourceMessage: 'データがありません',
    filterRow: {
      filterTooltip: 'Filter',
    },
    editRow: {
      cancelTooltip: 'キャンセル',
      saveTooltip: '更新',
    },
  },
  pagination: {
    firstAriaLabel: '最初のページ',
    firstTooltip: '最初のページ',
    previousAriaLabel: '前のページ',
    previousTooltip: '前のページ',
    nextAriaLabel: '次のページ',
    nextTooltip: '次のページ',
    lastAriaLabel: '最後のページ',
    lastTooltip: '最後のページ',
    labelDisplayedRows: '{from}-{to} / {count}',
    labelRowsSelect: '件/ページ',
    labelRowsPerPage: '',
  },
}
