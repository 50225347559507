import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react'
import Konva from 'konva'
import {
  Group,
  Rect,
  Text,
  Transformer,
  Line,
} from 'react-konva'
import {
  Direction,
} from 'models'

export interface AreaModel {
  name: string
  variant: 'store' | 'area' | 'shelf'  | 'shelf_low'| 'object'
  x: number
  y: number
  width: number
  height: number
  color: string
  direction?: Direction
  heights?: number[]
}

type AreaProps = {
  model: AreaModel
  offsetX: number
  offsetY: number
  minSize?: number
  opacities?: [number, number]
  isSelected: boolean
  draggable?: boolean
  rotateEnabled?: boolean
  snap?: boolean
  showLable?: boolean
  onSelect: () => void
  onChange: (area: AreaModel) => void
}

const directionPoints = (width: number, height: number, direction: Direction) => {
  const [x0, x1, y0, y1] = [0, width, 0, height]
  switch(direction) {
    case 'North':
      return [x0, y0, x0, y1, x1, y1, x1, y0]
    case 'East': // R
      return [x1, y0, x0, y0, x0, y1, x1, y1]
    case 'West': // L
      return [x0, y0, x1, y0, x1, y1, x0, y1]
    default: /* South */
      return [x0, y1, x0, y0, x1, y0, x1, y1]
  }
}

const applyRotation = (x: number, y: number, width: number, height: number, rotation: number): [number, number, number, number] => {
  if (rotation <= -180) {
    return [x - width, y - height, width, height]
  } else if (rotation <= -90) {
    return [x, y - width, height, width]
  } else if (rotation <= 0) {
    return [x, y, width, height]
  } else if (rotation <= 90) {
    return [x - height, y, height, width]
  } else {
    console.log('180')
    return [x - width, y - height, width, height]
  }
}

const applyRotationToDirection = (direction: Direction | undefined, rotation: number): Direction | undefined => {
  if (direction === undefined) return undefined
  switch(direction) {
    case 'North':
      if (rotation <= -180) {
        return 'South'
      } else if (rotation <= -90) {
        return 'West'
      } else if (rotation <= 0) {
        return 'North'
      } else if (rotation <= 90) {
        return 'East'
      } else {
        return 'South'
      }
    case 'East':
      if (rotation <= -180) {
        return 'West'
      } else if (rotation <= -90) {
        return 'North'
      } else if (rotation <= 0) {
        return 'East'
      } else if (rotation <= 90) {
        return 'South'
      } else {
        return 'West'
      }
    case 'West':
      if (rotation <= -180) {
        return 'East'
      } else if (rotation <= -90) {
        return 'South'
      } else if (rotation <= 0) {
        return 'West'
      } else if (rotation <= 90) {
        return 'North'
      } else {
        return 'East'
      }
    default: /* South */
      if (rotation <= -180) {
        return 'North'
      } else if (rotation <= -90) {
        return 'East'
      } else if (rotation <= 0) {
        return 'South'
      } else if (rotation <= 90) {
        return 'West'
      } else {
        return 'North'
      }
  }
}

export const Area: React.FC<AreaProps> = ({
  model,
  offsetX,
  offsetY,
  minSize = 50,
  opacities = [0.2, 0.6],
  draggable,
  rotateEnabled = false,
  isSelected,
  snap,
  showLable = false,
  onSelect,
  onChange
}) => {
  const [d, setD] = useState<[number, number]>([0, 0])
  const grpRef = useRef<Konva.Group>(null)
  const trRef = useRef<Konva.Transformer>(null)
  const snapPos = useCallback((value: number): number => {
    if (!snap) return Math.floor(value)
    return Math.round(value / 10) * 10
  }, [snap])
  useEffect(() => {
    if (!isSelected) return
    if (grpRef.current) {
      trRef.current?.nodes([grpRef.current])
      trRef.current?.getLayer()?.batchDraw()
    }
  }, [isSelected])
  return (
    <Group x={-offsetX} y={-offsetY}>
      <Group
        ref={grpRef}
        x={model.x}
        y={model.y}
        width={model.width}
        height={model.height}
        draggable={draggable}
        onClick={onSelect}
        onDragMove={(evt: Konva.KonvaEventObject<DragEvent>) => {
          setD([Math.floor(evt.target.x() - model.x), Math.floor(evt.target.y() - model.y)])
        }}
        onDragEnd={(evt: Konva.KonvaEventObject<DragEvent>) => {
          setD([0, 0])
          onChange({
            ...model,
            x: snapPos(evt.target.x()) - offsetX,
            y: snapPos(evt.target.y()) - offsetY,
          })
        }}
        onTransformEnd={() => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = grpRef.current
          if (node === null) return
          const scaleX = node.scaleX()
          const scaleY = node.scaleY()
          const rotation = Math.round(node.rotation())
          console.log('@@', trRef.current?.rotation(), rotation)
          node.scaleX(1)
          node.scaleY(1)
          if (rotation !== 0) {
            node.rotation(0)
            // trRef.current?.rotation(0)
            trRef.current?.detach()
          }
          const newDirection = applyRotationToDirection(model.direction, rotation)
          const [newX, newY, newWidth, newHeight] = applyRotation(node.x(), node.y(), model.width * scaleX, model.height * scaleY, rotation)
          console.log('@', Math.round(node.x()), Math.round(node.y()), Math.round(newX) - offsetX, Math.round(newY) - offsetY, rotation)
          onChange({
            ...model,
            x: snapPos(newX) - offsetX,
            y: snapPos(newY) - offsetY,
            width: snapPos(newWidth),
            height: snapPos(newHeight),
            direction: newDirection,
          })
          node.getLayer()?.batchDraw()
        }}
      >
        <Rect
          width={model.width}
          height={model.height}
          fill={model.color}
          opacity={isSelected ? opacities[1] : opacities[0]}
        />
        {/* direction for Shelf */}
        {model.direction &&
        <Line
          points={directionPoints(model.width, model.height, model.direction)}
          stroke={model.color}
          strokeWidth={3}
        />
        }
      </Group>
      <Group
        x={model.x + d[0]}
        y={model.y + d[1]}
      >
        {/* Area Name */}
        <Text
          text={model.name}
          x={0}
          y={model.height}
          width={model.width}
          padding={2}
          align="center"
          onClick={onSelect}
          fill={model.color}
          visible={showLable && d[0] === 0 && d[1] === 0}
        />
        {/* (x, y) */}
        <Text
          text={`(${model.x + d[0]}, ${model.y + d[1]})`}
          x={-100}
          y={-40}
          width={100}
          height={40}
          padding={10}
          align="right"
          verticalAlign="bottom"
          fill={model.color}
          visible={isSelected}
        />
        {/* width */}
        <Text
          text={`${model.width}`}
          x={0}
          y={-50}
          width={model.width}
          height={50}
          padding={10}
          align="center"
          verticalAlign="bottom"
          fill={model.color}
          visible={isSelected}
        />
        {/* height */}
        <Text
          text={`${model.height}`}
          x={-100}
          y={0}
          width={100}
          height={model.height}
          padding={10}
          align="right"
          verticalAlign="middle"
          fill={model.color}
          visible={isSelected}
        />
        {/* heights (shelf) */}
        {model.heights &&
        <Text
          text={model.heights.join('\n')}
          x={model.width + 15}
          y={-15}
          padding={0}
          align="right"
          verticalAlign="top"
          fill={model.color}
          visible={isSelected}
        />
        }
      </Group>
      {isSelected &&
      <Transformer
        ref={trRef}
        rotateEnabled={rotateEnabled}
        rotationSnaps={[0, 90, 180, 270]}
        rotationSnapTolerance={45}
        boundBoxFunc={(oldBox, newBox) => {
          if (newBox.width < minSize || newBox.height < minSize) return oldBox
          return newBox
        }}
      />
      }
    </Group>
  )
}