import React, {
  useCallback,
} from 'react'
import {
  Button,
  Typography,
  Box,
} from '@mui/material';
import BuildInfo from 'BuildInfo.json'
import logo from 'logo.png'
import { useAuth0 } from '@auth0/auth0-react'

export const Login: React.FC = () => {
  console.log(`Login##`)
  const { loginWithRedirect } = useAuth0()

  const handleLogin = useCallback(() => {
    console.log(`handleLogin`)
    loginWithRedirect({
      appState: {
        returnTo: `${window.location.pathname}${window.location.search}`
      },
    })
  }, [loginWithRedirect])

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <Box
        component="img"
        src={logo}
        alt="logo"
        sx={{
          margin: '50px',
          width: '400px',
        }}
      />
      <Button onClick={handleLogin} variant="contained" color="primary">
        ログイン
      </Button>
      <Typography sx={{
        marginTop: (t) => t.spacing(4),
      }}>
        ver: {BuildInfo.version}
      </Typography>
    </Box>
  )
}
