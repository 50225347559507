import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  Toolbar,
  Tooltip,
  IconButton,
  Switch,
} from '@mui/material';
import {
  Add,
} from '@mui/icons-material'
import MaterialTable from '@material-table/core'
import {
  useApp,
} from 'contexts'
import {
  Operator,
} from 'models'
import {
  MainContents,
  Loading,
  ReloadButton,
  SimpleMaterialTableOptions,
  MaterialTableComponents,
  MaterialTableLocalization,
  ConfirmDialog,
} from 'components'
import {
  OperatorAddDialog,
} from './components'

const mask = (phoneNo: string): string => {
  // +818012345678 => 080-****-5678
  return `0${phoneNo.substr(3, 2)}-****-${phoneNo.substr(9)}`
}

export const Operators: React.FC = () => {
  const [reload, setReload] = useState(false)
  const [items, setItems] = useState<Operator[]>()
  const [deleteSelected, setDeleteSelected] = useState<Operator>()
  const [testSelected, setTestSelected] = useState<Operator>()
  const [addDialogOpen, setAddDialogOpen] = useState(false)
  const { getApi, defaultApiErrorHandler } = useApp()

  const load = useCallback(() => {
    getApi()
      .fetchOperators()
      .then((result) => {
        console.log('fetchAsync done')
        setItems(result.items)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler])
  useEffect(() => {
    load()
  }, [load])
  // reload
  useEffect(() => {
    if (!reload) return
    load()
    setReload(false)
  }, [load, reload])

  const handleReload = useCallback(() => {
    setItems(undefined)
    setReload(true)
  }, [])

  const handleAdded = useCallback(() => {
    setAddDialogOpen(false)
    setReload(true)
  }, [])
  const handleActive = useCallback((op: Operator) => {
    op.active = !op.active
    setItems(undefined)
    getApi()
      .updateOperator(op)
      .then(() => {
        setReload(true)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler])
  const handleTestNotify = useCallback((isOk: boolean) => {
    setTestSelected(undefined)
    if (testSelected === undefined || isOk === false) return
    getApi()
      .testNotifyOperator(testSelected.operator_code)
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, testSelected])
  const hadleDelete = useCallback((isOk: boolean) => {
    setDeleteSelected(undefined)
    if (deleteSelected === undefined || isOk === false) return
    setItems(undefined)
    getApi()
      .deleteOperator(deleteSelected.operator_code)
      .then(() => {
        setReload(true)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, deleteSelected])

  return (
    <MainContents>
      <Toolbar variant="dense" disableGutters>
        <Tooltip title="オペレータ追加">
          <IconButton
            aria-label="add"
            color="primary"
            sx={{ marginLeft: 'auto' }}
            onClick={() => setAddDialogOpen(true)}
            size="small"
          >
            <Add />
          </IconButton>
        </Tooltip>
        <OperatorAddDialog
          open={addDialogOpen}
          onClose={() => setAddDialogOpen(false)}
          onCreate={handleAdded}
        />
        <ReloadButton onClick={handleReload} />
      </Toolbar>
      <Loading visible={items === undefined} />
      {items &&
        <MaterialTable
          data={items}
          columns={[
            {
              title: 'オペレータ名',
              field: 'operator_name',
              render: (d) => d.operator_name
            },
            {
              title: '通知先番号',
              field: 'operator_code',
              render: (d) => mask(d.operator_code)
            },
            {
              title: '通知',
              field: 'active',
              render: (d) => (
                <Switch
                  checked={d.active}
                  color="primary"
                  size="small"
                  name="active"
                  onClick={() => handleActive(d)}
                />
              )
            },
          ]}
          actions={[
            (d) => ({
              icon: 'send',
              tooltip: 'テスト通知',
              onClick: () => {
                setTestSelected(d)
              },
            }),
            (d) => ({
              icon: 'delete',
              tooltip: '削除',
              onClick: () => {
                setDeleteSelected(d)
              },
            })
          ]}
          options={SimpleMaterialTableOptions<Operator>({
            toolbar: false,
          })}
          components={{
            ...MaterialTableComponents,
          }}
          localization={{
            ...MaterialTableLocalization
          }}
        />
      }
      <ConfirmDialog
        open={Boolean(testSelected !== undefined)}
        title="テスト通知"
        handleClose={handleTestNotify}
      />
      <ConfirmDialog
        open={Boolean(deleteSelected !== undefined)}
        title="オペレータ削除"
        handleClose={hadleDelete}
      />
    </MainContents>
  )
}
