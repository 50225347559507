import React, {
  useEffect,
  useState,
  useCallback,
} from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
} from '@mui/material';
import {
  DatePicker,
} from 'components'
import { DateTime } from 'luxon'
import {
  sx,
} from '../sx'

interface AddExcludeDateDialogProps {
  open: boolean
  onClose: () => void
  onAdded: (date: string) => void
  excludeDates: string[]
}

export const AddExcludeDateDialog: React.FC<AddExcludeDateDialogProps> = ({ open, onClose, onAdded, excludeDates }) => {
  const [date, setDate] = useState<Date>(new Date())
  const [error, setError] = useState<string>()

  useEffect(() => {
    if (!open) return
    const dateStr = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
    if (excludeDates.indexOf(dateStr) === -1) {
      setError(undefined)
    } else {
      setError('登録済みの除外日です')
    }    
  }, [date, open, excludeDates])

  const handleDateChange = useCallback((dt: DateTime) => {
    const newDate = dt.toJSDate()
    setDate(newDate)
    const dateStr = DateTime.fromJSDate(newDate).toFormat('yyyy-MM-dd')
    if (excludeDates.indexOf(dateStr) === -1) {
      setError(undefined)
    } else {
      setError('登録済みの除外日です')
    }
  }, [setDate, excludeDates])

  const handleOkClicked = useCallback(() => {
    onAdded(DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'))
  }, [date, onAdded])
  const handleCanceled = useCallback(() => {
    console.log('handleCanceled')
    onClose()
  }, [onClose])

  return (
  <Dialog
    maxWidth="xs"
    fullWidth
    open={open}
    onClose={onClose}
    disableEscapeKeyDown
  >
    <DialogTitle>除外日追加</DialogTitle>
    <DialogContent>
      <Box>
        <DatePicker
          onChange={handleDateChange}
          defaultDate={DateTime.fromJSDate(date)}
          inputFormat="yyyy-MMM-dd"
          disableNavigate
          showUnderline
          disableFuture={false}
          disablePast
        />
      </Box>
      <Box sx={{ marginTop: 1, marginLeft: 2 }}>
        <Typography variant="body1" color="error">{error}</Typography>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button
        color="inherit"
        variant="contained"
        onClick={handleCanceled}
        sx={sx.action}
      >
        キャンセル
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={handleOkClicked}
        disabled={Boolean(error)}
        sx={sx.action}
      >
        OK
      </Button>
    </DialogActions>
  </Dialog>
  )

}