import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  Link,
  useNavigate,
} from 'react-router-dom'
import {
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  CardActions,
  Avatar,
  IconButton,
  Tooltip,
  Box,
} from '@mui/material';
import {
  green,
  pink,
  orange,
} from '@mui/material/colors'
import {
  ShoppingCart,
  RemoveShoppingCart,
  Settings,
  MoneyOff,
  Router as DeviceIcon,
} from '@mui/icons-material'
import {
  useApp,
} from 'contexts'
import {
  useInterval,
} from 'hooks'
import {
  Store,
  Configuration,
} from 'models'
import {
  getStateFromThingShadow,
} from 'utils'

interface StoreStatus {
  devices: 'stop' | 'strating' | 'running' | 'stopping' | 'restarting' | 'error' | 'service_error' | 'shadow_not_found'
}

const deviceColor = (status: StoreStatus) => {
  if (status.devices === 'running') return 'primary'
  if (status.devices === 'error' || status.devices === 'shadow_not_found') return 'secondary'
  return 'default'
}

interface StoreCardProps {
  store: Store
}

export const StoreCard: React.FC<StoreCardProps> = ({ store }) => {
  const navigate = useNavigate()
  const [configuration, setConfiguration] = useState<Configuration>()
  const [storeStatus, setStoreStatus] = useState<StoreStatus>({
    devices: 'stop',
  });
  const { getApi, getIot, defaultApiErrorHandler } = useApp()
  const [reload, setReload] = useState(false)

  const load = useCallback(() => {
    getApi()
      .fetchConfiguration(store.store_code)
      .then((result) => {
        setConfiguration(result)
      })
      .catch(defaultApiErrorHandler)
    getIot()
      .getThingShadow({
        thingName: `store_${store.store_code}`,
        shadowName: 'status',
      })
      .then(result => {
        const state = getStateFromThingShadow(result)
        console.log(state)
        const shadow: StoreStatus = {
          devices: state?.devices,
        }
        setStoreStatus(shadow);
      })
      .catch(_e => {
        console.log(`Shadow not found: ${store.store_code}`)
        setStoreStatus({
          devices: 'shadow_not_found',
        })
      })
  }, [store, getApi, getIot, defaultApiErrorHandler])
  useEffect(() => {
    load()
  }, [load])
  // reload
  useEffect(() => {
    if (!reload) return
    console.log('reloading...')
    load()
    setReload(false)
  }, [load, reload, setReload])

  // auto reload
  useInterval(() => {
    setReload(true)
  }, 60000)

  const handleNavigateToStoreDashboard = useCallback(() => {
    navigate(`/stores/${store.store_code}/`)
  }, [store, navigate])

  return (
    <Card sx={{ margin: 1 }} raised>
      <CardActionArea onClick={handleNavigateToStoreDashboard}>
        <CardHeader
          title={store.store_name}
          subheader={store.store_code}
          avatar={<StoreAvatar store={store} configuration={configuration} />}
        />      
        <CardContent sx={{ padding: 0, textAlign: 'center' }}>
          <Box component="img" src={`https://${process.env.REACT_APP_DOMAIN}/images/${store.store_code}/banner.png`} alt={store.store_name} sx={{ width: '240px' }}/>
        </CardContent>
      </CardActionArea>
      <CardActions disableSpacing>
        {!Boolean(configuration?.disable_sensor) && (
        <Tooltip title={storeStatus.devices}>
          <IconButton size="small" color={deviceColor(storeStatus)} sx={{ cursor: 'default', marginLeft: 'auto'}}>
            <DeviceIcon />
          </IconButton>
        </Tooltip>
        )}
        <Tooltip title="設定" sx={configuration?.disable_sensor ? { marginLeft: 'auto' } : { marginLeft: 8 }}>
          <Link to={`/stores/${store.store_code}/config`}>
            <IconButton size="small" >
              <Settings />
            </IconButton>
          </Link>
        </Tooltip>
      </CardActions>
    </Card>
  )
}

interface StoreAvatarProps {
  store: Store
  configuration?: Configuration
}

const StoreAvatar: React.FC<StoreAvatarProps> = ({ store, configuration }) => {
  if (configuration?.store_mode === 'maintenance') {
    return (
      <Tooltip title="メンテナンス中">
        <Avatar sx={{ color: (t) => t.palette.getContrastText(pink[500]), backgroundColor: pink[500]}}><Settings/></Avatar>
      </Tooltip>
    )
  }
  if (store.status === 'open') {
    return (
      <Tooltip title="開店">
        <Avatar sx={{ color: '#FFF', backgroundColor: green[500] }}><ShoppingCart/></Avatar>
      </Tooltip>
    )
  } else if (store.status === 'event') {
    return (
      <Tooltip title="イベントモード">
        <Avatar sx={{ color: '#FFF', backgroundColor: orange[500] }}><MoneyOff/></Avatar>
      </Tooltip>
    )
  } else {
    return (
      <Tooltip title="閉店">
        <Avatar sx={{ color: (t) => t.palette.getContrastText(pink[500]), backgroundColor: pink[500] }}><RemoveShoppingCart/></Avatar>
      </Tooltip>
    )
  }
}
