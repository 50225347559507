import React, {
  useState,
  useCallback,
} from 'react'
import {
  Box,
  Typography,
  Popover,
} from '@mui/material'
import {
  HelpOutline as HelpIcon,
} from '@mui/icons-material'

interface HelpProps {

}

export const Help: React.FC<HelpProps> = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement|null>(null)
  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }, [])
  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])
  return (
    <Box padding={1}>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        
      >
        <Box padding={1}>{children}</Box>
      </Popover>
      <Typography
        onMouseEnter={handleOpen}
      >
        <HelpIcon
          fontSize="small"
        />
      </Typography>
    </Box>
  )
}