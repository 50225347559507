import {
  EventName,
  EventType,
  EventHook,
} from 'models'

export type EventHookFormData = {
  options_arn: string
}

export const toFormData = (hook: EventHook): EventHookFormData => ({
  options_arn: hook.options.arn,
})

export const toEventHook = (storeCode: string, eventName: EventName, eventType: EventType, dryRun: boolean, data: EventHookFormData): EventHook => ({
  store_code: storeCode,
  event_name: eventName,
  type: eventType,
  options: {
    arn: data.options_arn,
    dry_run: eventType === 'lambda' ? dryRun : undefined,
  },
})

export const EventHookFormValidations = {
  options_arn: {
    required: {
      value: true,
      message: 'ARNを入力してください',
    },
  },
}
