import {
  BootConfiguration,
  Plug,
} from 'models'

export type StoreBootConfigFormData = {
  boot_timeout_sec: string;
  shutdown_timeout_sec: string;
  reboot_timeout_sec: string;
  restart_service_timeout_sec: string;
  slack_path: string;
  slack_notify_channel: string;
  slack_alert_channel: string;
}

export const toFormData = (config: BootConfiguration): StoreBootConfigFormData => ({
  boot_timeout_sec: `${config.boot_timeout_sec}`,
  shutdown_timeout_sec: `${config.shutdown_timeout_sec}`,
  reboot_timeout_sec: `${config.reboot_timeout_sec}`,
  restart_service_timeout_sec: `${config.restart_service_timeout_sec}`,
  slack_path: config.slack_path,
  slack_notify_channel: config.slack_notify_channel,
  slack_alert_channel: config.slack_alert_channel,
})

export const toBootConfiguration = (storeCode: string, plugs: Plug[], data: StoreBootConfigFormData): BootConfiguration => ({
  store_code: storeCode,
  plugs,
  boot_timeout_sec: Number(data.boot_timeout_sec),
  shutdown_timeout_sec: Number(data.shutdown_timeout_sec),
  reboot_timeout_sec: Number(data.reboot_timeout_sec),
  restart_service_timeout_sec: Number(data.restart_service_timeout_sec),
  slack_path: data.slack_path,
  slack_notify_channel: data.slack_notify_channel,
  slack_alert_channel: data.slack_alert_channel,
})

export const StoreBootConfigFormValidations = {
  boot_timeout_sec: {
    required: {
      value: true,
      message: '起動タイムアウトを入力してください',
    },
    min: {
      value: 30,
      message: '起動タイムアウトは30秒以上600秒以内で入力してください',
    },
    max: {
      value: 600,
      message: '起動タイムアウトは30秒以上600秒以内で入力してください',
    },
  },
  shutdown_timeout_sec: {
    required: {
      value: true,
      message: '停止タイムアウトを入力してください',
    },
    min: {
      value: 30,
      message: '停止タイムアウトは30秒以上600秒以内で入力してください',
    },
    max: {
      value: 600,
      message: '停止タイムアウトは30秒以上600秒以内で入力してください',
    },
  },
  reboot_timeout_sec: {
    required: {
      value: true,
      message: '再起動タイムアウトを入力してください',
    },
    min: {
      value: 30,
      message: '再起動タイムアウトは30秒以上600秒以内で入力してください',
    },
    max: {
      value: 600,
      message: '再起動タイムアウトは30秒以上600秒以内で入力してください',
    },
  },
  restart_service_timeout_sec: {
    required: {
      value: true,
      message: 'サービス再起動タイムアウトを入力してください',
    },
    min: {
      value: 30,
      message: 'サービス再起動タイムアウトは30秒以上600秒以内で入力してください',
    },
    max: {
      value: 600,
      message: 'サービス再起動タイムアウトは30秒以上600秒以内で入力してください',
    },
  },
  slack_path: {
  },
  slack_notify_channel: {
  },
  slack_alert_channel: {
  },
}
