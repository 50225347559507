import React from 'react'
import {
  Check,
} from '@mui/icons-material'

type CheckLabelProps = {
  value: boolean | undefined
}

export const CheckLabel: React.FC<CheckLabelProps> = ({ value }) => (
  value ? (<Check fontSize="small" style={{ verticalAlign: 'middle' }}/>) : (<></>)
)

