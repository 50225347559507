import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  Box,
  Toolbar,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Tooltip,
  IconButton,
} from '@mui/material'
import {
  RestartAlt as RestartServiceIcon,
  PowerSettingsNew as RebootIcon,
} from '@mui/icons-material'
import {
  useApp,
} from 'contexts'
import {
  useSelectedStoreCode,
} from 'hooks'
import {
  subscribe,
  DeviceStatusValue,
} from 'iot'
import { PassregiApi } from 'api'
import {
  Store,
  Device,
  WeightSensorDevice,
  DevicePowerStatus,
  DeviceServiceStatus,
  DeviceServiceError,
} from 'models'
import {
  MainContents,
  StoresSelector,
  Loading,
  ConfirmDialog,
  DeviceStatusMark,
  ProgressScreen,
  ReloadButton,
} from 'components'

const getDeviceName = (device: Device): string => {
  switch (device.type) {
    case 'edge':
      return device.thing_name
    case 'ws':
      return (device as WeightSensorDevice).properties.name
    case 'gk':
      return 'Gate'
    default:
      return 'Unknown'
  }
}

export const MonitorDevice: React.FC = () => {
  const { storeCode, setStoreCode } = useSelectedStoreCode()
  const [reload, setReload] = useState(false)
  const [devices, setDevices] = useState<Device[]>()
  const [updating, setUpdating] = useState(false)
  const { getApi, getIot, defaultApiErrorHandler } = useApp()

  const load = useCallback(() => {
    getApi()
      .fetchDevices(storeCode)
      .then(result => {
        setDevices(result.items)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, getIot, defaultApiErrorHandler, storeCode])
  useEffect(() => {
    load()
  }, [load])
  // reload
  useEffect(() => {
    if (!reload) return
    setDevices(undefined)
    load()
    setReload(false)
  }, [load, reload])

  const onStoreChanged = useCallback((store: Store | undefined) => {
    if (store) {
      setDevices(undefined)
      setStoreCode(store.store_code)
    }
  }, [setStoreCode])
  const handleReload = useCallback(() => {
    setDevices(undefined)
    setReload(true)
  }, [])

  return (
    <MainContents>
      <Toolbar variant="dense" disableGutters>
        <StoresSelector
          storeCode={storeCode}
          onSeleced={onStoreChanged}
        />
        <ReloadButton size="small" marginLeft="auto" onClick={handleReload} />
      </Toolbar>
      <Loading visible={devices === undefined} />
      {devices && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox"></TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>デバイス名</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>店舗コード</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>名称</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>エラーコード</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {devices.map(d => (
                <DeviceTableRow key={d.thing_name} device={d} getApi={getApi} setUpdating={setUpdating} />
              ))}
            </TableBody>
          </Table>
          {devices.length === 0 && (
            <Box sx={{ margin: 1 }}>デバイスがありません</Box>
          )}
        </TableContainer>
      )}
      <ProgressScreen open={updating} />
    </MainContents>
  )
}

interface DeviceTableRowProps {
  device: Device,
  getApi: () => PassregiApi,
  setUpdating: (updating: boolean) => void,
}

const DeviceTableRow: React.FC<DeviceTableRowProps> = ({ device, getApi, setUpdating }) => {
  const [powerStatus, setPowerStatus] = useState<DevicePowerStatus | undefined>(device.power_status)
  const [serviceStatus, setServiceStatus] = useState<DeviceServiceStatus | undefined>(device.service_status)
  const [serviceError, setServiceError] = useState<DeviceServiceError | undefined>(device.service_error)
  const [openRestartServiceDialog, setOpenRestartServiceDialog] = useState(false)
  const [openRebootDialog, setOpenRebootDialog] = useState(false)
  // subscribe device shadow update
  useEffect(() => {
    const subscriber = subscribe<DeviceStatusValue>(`$aws/things/${device.thing_name}/shadow/name/status/update/documents`, (d) => {
      const status = d.value.current.state.reported;
      console.log(status)
      setPowerStatus(status.power)
      setServiceStatus(status.service)
      setServiceError(status.service_error)
      device.power_status = status.power
      device.service_status = status.service
      device.service_error = status.service_error
    })
    return () => {
      subscriber.disconnect()
    }
  }, [device])

  const handleRestartServiceButtonClicked = useCallback(() => {
    setOpenRestartServiceDialog(true)
  }, [])
  const handleRestartService = useCallback((isOk: boolean) => {
    setOpenRestartServiceDialog(false)
    if (!isOk) return
    setUpdating(true)
    getApi()
      .restartServiceDevice(device.thing_name)
      .then(() => {
      })
      .finally(() => {
        setUpdating(false)
      })
  }, [device, getApi, setUpdating])

  const handleRebootButtonClicked = useCallback(() => {
    setOpenRebootDialog(true)
  }, [])
  const handleReboot = useCallback((isOk: boolean) => {
    setOpenRebootDialog(false)
    if (!isOk) return
    setUpdating(true)
    getApi()
      .rebootDevice(device.thing_name)
      .then(() => {
      })
      .finally(() => {
        setUpdating(false)
      })
  }, [device, getApi, setUpdating])

  return (
    <React.Fragment>
      <TableRow>
        <TableCell padding="none" align="center" sx={{ width: 30 }}>
          <DeviceStatusMark
            powerStatus={powerStatus}
            serviceStatus={serviceStatus}
            serviceError={serviceError}
          />
        </TableCell>
        <TableCell>
          {device.thing_name}
        </TableCell>
        <TableCell>
          {device.store_code}
        </TableCell>
        <TableCell>
          {getDeviceName(device)}
        </TableCell>
        <TableCell>
          {serviceError?.error_code}
        </TableCell>
        <TableCell padding="none" align="right">
          <Tooltip title={powerStatus !== 'on' ? 'デバイス停止中' : 'サービス再起動'}>
            <Box component="span">
              <IconButton size="small" color={powerStatus !== 'on' ? 'default' : 'primary'} onClick={handleRestartServiceButtonClicked} disabled={powerStatus !== 'on'}>
                <RestartServiceIcon />
              </IconButton>
            </Box>
          </Tooltip>
          <Tooltip title={powerStatus !== 'on' ? 'デバイス停止中' : 'デバイス再起動'}>
            <Box component="span">
              <IconButton size="small" color={powerStatus !== 'on' ? 'default' : 'primary'} onClick={handleRebootButtonClicked} disabled={powerStatus !== 'on'}>
                <RebootIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </TableCell>
        <ConfirmDialog
          title="サービス再起動"
          open={openRestartServiceDialog}
          handleClose={handleRestartService}
        />
        <ConfirmDialog
          title="デバイス再起動"
          open={openRebootDialog}
          handleClose={handleReboot}
        />
      </TableRow>
    </React.Fragment>
  )
}