import React, {
  useCallback,
} from 'react'
import {
  useNavigate,
} from 'react-router-dom'
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material';
import { AppProvider } from 'contexts'
import { AppRouter } from './AppRouter'
import { UnauthenticatedAppRouter } from './UnauthenticatedAppRouter'
import {
  Loading,
} from 'components'
import { createTheme } from 'utils'
import { Auth0Provider, AppState, useAuth0 } from '@auth0/auth0-react'

const ENV_ERROR = (name: string): string => { throw new Error(`${name} is not defined.`) }
const AUTH0_DOMAIN: string = process.env.REACT_APP_AUTH0_DOMAIN || ENV_ERROR('REACT_APP_AUTH0_DOMAIN')
const AUTH0_CLIENT_ID: string = process.env.REACT_APP_AUTH0_CLIENT_ID || ENV_ERROR('REACT_APP_AUTH0_CLIENT_ID')
const AUTH0_REDIRECT_URI: string = process.env.REACT_APP_AUTH0_REDIRECT_URI || ENV_ERROR('REACT_APP_AUTH0_REDIRECT_URI')
const AUTH0_AUDIENCE: string = process.env.REACT_APP_AUTH0_AUDIENCE || ENV_ERROR('REACT_APP_AUTH0_AUDIENCE')

export const App: React.FC = () => {
  console.log(`App##`)
  const navigate = useNavigate()
  const onRedirectCallback = useCallback((appState?: AppState) => {
    const returnTo = appState?.returnTo
    console.log(`onRedirectCallback: ${returnTo}`)
    if (returnTo) {
      navigate(returnTo)
    }
  }, [navigate])
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={AUTH0_REDIRECT_URI}
      audience={AUTH0_AUDIENCE}
      scope="openid profile email"
      useRefreshTokens={true}
      connection="google-oauth2"
      onRedirectCallback={onRedirectCallback}
    >
      <AppWithAuth0Provider />
    </Auth0Provider>
  )
}

const AppWithAuth0Provider: React.FC = () => {
  const { isAuthenticated, logout, isLoading } = useAuth0()
  console.log(`App## ${isAuthenticated}`)
  if (window.location.pathname === '/unauthorized') logout()
  if (isLoading) return (<Loading />)
  // use <React.StrictMode> https://github.com/mui-org/material-ui/issues/13394
  const theme = createTheme()
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isAuthenticated ? (
          <AppProvider>
            <AppRouter />
          </AppProvider>
        ) : (
          <UnauthenticatedAppRouter />
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
