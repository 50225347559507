import React, {
} from 'react'
import {
  Box,
  SxProps,
  Theme,
} from '@mui/material';
import {
  common,
  green,
  lightGreen,
  amber,
} from '@mui/material/colors'
import {
  StoreMode,
} from 'models'

const normalSx: SxProps<Theme> = {
  paddingTop: 0.5,
  paddingBottom: 0.5,
  paddingLeft: 3,
  paddingRight: 3,
  backgroundColor: green[600],
  color: common['white'],
  fontSize: 13,
}
const eventSx: SxProps<Theme> = {
  paddingTop: 0.5,
  paddingBottom: 0.5,
  paddingLeft: 3,
  paddingRight: 3,
  backgroundColor: lightGreen[100],
  fontSize: 13,
}
const maintenanceSx: SxProps<Theme> = {
  paddingTop: 0.5,
  paddingBottom: 0.5,
  paddingLeft: 3,
  paddingRight: 3,
  backgroundColor: amber[300],
  fontSize: 13,
}

type StoreModeLabelProps = {
  mode: StoreMode
}

export const StoreModeLabel: React.FC<StoreModeLabelProps> = ({ mode }) => {
  switch (mode) {
    case 'normal':
      return (<Box component="span" sx={normalSx}>通常営業</Box>)
    case 'maintenance':
      return (<Box component="span" sx={maintenanceSx} >メンテナンス</Box>)
    case 'event':
      return (<Box component="span" sx={eventSx} >イベント</Box>)
    default:
      return (<Box component="span">{status}</Box>)
  }
}