import {
  WeightSensorDevice,
  WeightSensorDeviceUpdatePayload,
  AwslogsLevel,
  WeightSensorNode,
} from 'models'

export interface WeightSensorFormData {
  name: string
  agent_interval: number
  agent_service_start_timeout: number
  service_interval: number
  service_weight_data_interval: number
}

export const toFormData = (device: WeightSensorDevice): WeightSensorFormData => {
  return {
    name: device.properties.name,
    agent_interval: device.agent_properties.interval,
    agent_service_start_timeout: device.agent_properties.service_start_timeout || 120,
    service_interval: device.service_properties.interval || 60,
    service_weight_data_interval: device.service_properties.weight_data_interval || 1,
  }
}

export const dataToPayload = (
  data: WeightSensorFormData,
  storeCode: string,
  agentAwslogsLevel: AwslogsLevel,
  serviceAwslogsLevel: AwslogsLevel,
  enable_publish_data: boolean,
  enable_weight_file: boolean,
  enable_rawdata_file: boolean,
  nodes: WeightSensorNode[],
): WeightSensorDeviceUpdatePayload => {
  return {
    store_code: storeCode !== '_' ? storeCode : '',
    properties: {
      name: data.name,
    },
    agent_properties: {
      awslogs_level: agentAwslogsLevel,
      interval: Number(data.agent_interval),
      service_start_timeout: Number(data.agent_service_start_timeout),
    },
    service_properties: {
      awslogs_level: serviceAwslogsLevel,
      interval: Number(data.service_interval),
      weight_data_interval: Number(data.service_weight_data_interval),
      enable_publish_data: enable_publish_data,
      enable_weight_file: enable_weight_file,
      enable_rawdata_file: enable_rawdata_file,
      nodes,
    }
  }
}

export const WeightSensorFormValidations = {
  name: {
    required: {
      value: true,
      message: '名称を入力してください',
    },
  },
  agent_interval: {
    required: {
      value: true,
      message: '送信間隔を入力してください',
    },
    min: {
      value: 50,
      message: '送信間隔は50〜300で入力してください',
    },
    max: {
      value: 300,
      message: '送信間隔は50〜300で入力してください',
    },
  },
  agent_service_start_timeout: {
    required: {
      value: true,
      message: 'サービス起動タイムアウトを入力してください',
    },
    min: {
      value: 60,
      message: 'サービス起動タイムアウトは60〜600で入力してください',
    },
    max: {
      value: 600,
      message: 'サービス起動タイムアウトは60〜600で入力してください',
    },
  },
  service_interval: {
    required: {
      value: true,
      message: '送信間隔を入力してください',
    },
    min: {
      value: 50,
      message: '送信間隔は50〜300で入力してください',
    },
    max: {
      value: 300,
      message: '送信間隔は50〜300で入力してください',
    },
  },
  service_weight_data_interval: {
    required: {
      value: true,
      message: '重量データ送信間隔を入力してください',
    },
    min: {
      value: 1,
      message: '重量データ送信間隔は1〜600で入力してください',
    },
    max: {
      value: 600,
      message: '重量データ送信間隔は1〜600で入力してください',
    },
  },
}

export interface WeightSensorNodeAddFormData {
  node_id: string
}

export const WeightSensorNodeFormValidations = {
  node_id: {
    required: {
      value: true,
      message: 'ノードIDを入力してください',
    },
    pattern: {
      value: /^[a-z0-9]{2}$/,
      message: 'ノードIDは英小文字または数字の2桁で入力してください（例: 1f）',
    },
  },
}