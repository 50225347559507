import React, {
  useState,
  useEffect,
} from 'react'
import {
  Box,
} from '@mui/material';
import {
  useApp,
} from 'contexts'
import {
  Replay,
  QueryExecution,
} from 'models'
import {
  QueryExecutionPanel,
} from './'

interface ReplayDetailProps {
  data: Replay
}

export const ReplayDetail: React.FC<ReplayDetailProps> = ({ data }) => {
  const { getApi, defaultApiErrorHandler } = useApp()
  const [trackingQueryExecution, setTrackingQueryExecution] = useState<QueryExecution>(data.tracking_query_execution)
  const [weightQueryExecution, setWeightQueryExecution] = useState<QueryExecution>(data.weight_query_execution)

  useEffect(() => {
    getApi()
      .fetchReplay(data.replay_id, true)
      .then((result) => {
        console.log('fetchAsync done')
        setTrackingQueryExecution(result.tracking_query_execution)
        setWeightQueryExecution(result.weight_query_execution)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, data])

  return (
    <Box sx={{ marginLeft: 3, marginRight: 2, marginTop: 1, marginBottom: 1 }}>
      <QueryExecutionPanel data={trackingQueryExecution} caption={"追跡データ"} />
      <QueryExecutionPanel data={weightQueryExecution} caption={"重量データ"} />
    </Box>
  )
}