import React, {
  useCallback,
} from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import {
  useStoreLayout,
} from 'contexts'

type AreaAddFormData = {
  name: string
}

const AreaAddFormValidations = {
  name: {
    required: {
      value: true,
      message: 'エリア名を入力してください',
    },
    minLength: {
      value: 1,
      message: 'エリア名は1文字以上で入力してください',
    },
    maxLength: {
      value: 4,
      message: 'エリア名は4文字以内で入力してください',
    },
    pattern: {
      value: /^[A-Z0-9]{1,4}$/,
      message: 'エリア名は英大文字と数字で入力してください',
    },
  },
}

export interface AddAreaDialogProps {
  open: boolean
  onClose: () => void
  onAdd: (locationCode: string) => void
}

export const AddAreaDialog: React.FC<AddAreaDialogProps> = ({ open, onClose, onAdd }) => {
  const { locationAreas } = useStoreLayout()
  const { register, handleSubmit, formState, formState: { errors } } = useForm<AreaAddFormData>({
    mode: 'onBlur',
  })

  const onSubmit = useCallback((data: AreaAddFormData) => {
    onAdd(data.name)
  }, [onAdd])

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>エリアを追加</DialogTitle>
        <DialogContent>
          <TextField
            label="エリア名"
            size="small"
            margin="dense"
            inputProps={{
              minLength: 1,
              maxLength: 4,
            }}
            required
            {...register('name', {
              validate: (name: string) => {
                if (name === 'STORE') return `'STORE'は予約されています`
                if (locationAreas.find(a => a.name === name) !== undefined) return `'${name}'は既に定義されています`
                return true
              },
              ...AreaAddFormValidations.name,
            })}
            error={Boolean(errors?.name)}
            helperText={errors?.name?.message}  
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={onClose}>キャンセル</Button>
          <Button color="primary" variant="contained" type="submit" disabled={!formState.isValid} >追加</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
