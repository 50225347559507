import React, {
  useState,
  useCallback,
} from 'react'
import {
  ShelfLayout,
} from 'models'
import {
  ShelfTile,
} from 'components'

type ShelfEditorProps = {
  layout: ShelfLayout
  onUpdate: (updated: ShelfLayout) => void
}

export const ShelfEditor: React.FC<ShelfEditorProps> = ({ layout, onUpdate }) => {
  const shelfCode = layout.shelfCode
  const [shelf, setShelf] = useState<ShelfLayout>(layout)

  const handleAddRow = useCallback(() => {
    const newSegments = shelf.segments.slice()
    const length = newSegments.flat().length
    const newRow = [{ segmentCode: `${shelfCode}_${length + 1}`}, { segmentCode: `${shelfCode}_${length + 2}`}]
    newSegments.push(newRow)
    const newShelf = {
      shelfCode: shelfCode,
      segments: newSegments,
    };
    setShelf(newShelf)
    onUpdate(newShelf)
  }, [shelfCode, shelf, onUpdate])
  const handleRemoveRow = useCallback(() => {
    const newSegments = shelf.segments.slice(0, -1)
    const newShelf = {
      shelfCode: shelfCode,
      segments: newSegments,
    };
    setShelf(newShelf)
    onUpdate(newShelf)
  }, [shelfCode, shelf, onUpdate])
  const handleAddSegment = useCallback((row: number) => {
    const rowLengths = shelf.segments.map(row => row.length)
    rowLengths[row]++
    const flatSegments = shelf.segments.flat()
    const newSegments = rowLengths.map((rowLen, i) => {
      const idx = rowLengths.slice(0, i).reduce((p,c) => p + c, 0)
      return flatSegments.slice(idx,  idx + rowLen)
    })
    newSegments[newSegments.length - 1].push({segmentCode: `${shelfCode}_${flatSegments.length + 1}`})
    const newShelf = {
      shelfCode: shelfCode,
      segments: newSegments,
    };
    setShelf(newShelf)
    onUpdate(newShelf)
  }, [shelfCode, shelf, onUpdate])
  const handleRemoveSegment = useCallback((row: number) => {
    const rowLengths = shelf.segments.map(row => row.length)
    rowLengths[row]--
    const flatSegments = shelf.segments.flat()
    const newSegments = rowLengths.map((rowLen, i) => {
      const idx = rowLengths.slice(0, i).reduce((p,c) => p + c, 0)
      return flatSegments.slice(idx, idx + rowLen)
    })
    const newShelf = {
      shelfCode: shelfCode,
      segments: newSegments,
    };
    setShelf(newShelf)
    onUpdate(newShelf)
  }, [shelfCode, shelf, onUpdate])
    
  return (
    <ShelfTile
      model={shelf}
      width={600}
      variant="edit"
      onAddRow={handleAddRow}
      onRemoveRow={handleRemoveRow}
      onAddSegment={handleAddSegment}
      onRemoveSegment={handleRemoveSegment}
    />
  )
}