import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  useQueryParam,
  StringParam,
  DateParam,
  withDefault,
} from 'use-query-params'
import {
  Toolbar,
  Tooltip,
  Collapse,
  Grid,
  FormControlLabel,
  Checkbox,
  Box,
} from '@mui/material';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import {
  List as ListIcon,
  BubbleChart as ChartIcon,
} from '@mui/icons-material'
import MaterialTable from '@material-table/core'
import {
  useApp,
} from 'contexts'
import {
  Store,
  SegmentQuantities,
  SegmentQuantitiesFilter,
  Quantitiy,
  filterSegmentQuantities,
  sortSegmentQuantities,
} from 'models'
import {
  MainContents,
  StoresSelector,
  Loading,
  ReloadButton,
  DownloadButton,
  WeightLabel,
  TimestampLabel,
  MaterialTableOptions,
  SimpleMaterialTableOptions,
  MaterialTableComponents,
  MaterialTableLocalization,
  DatePicker,
  FilterButton,
  RateLabel,
} from 'components'
import {
  toSegment,
} from 'utils'
import {
  WeightBar,
  QuantitiesChart,
} from './components'
import { DateTime } from 'luxon'
import { saveAs } from 'file-saver'

const maxQty = (quantities: Quantitiy[], itemWeight: number | undefined): number | undefined => {
  if (quantities.length === 0 || itemWeight === undefined) return undefined
  const valueMax = Math.max(...quantities.map(q => Math.abs(q.value_diff)))
  return Math.ceil(valueMax / itemWeight)
}

const toCsvString = (quantities: SegmentQuantities[]): string => {
  return [
    'segment_code,timestamp,value_diff,item_code,item_name,item_weight,qty',
    quantities.map(sq => sq.quantities.map(q => `${sq.segment_code},${q.timestamp},${Math.floor(q.value_diff * 10) / 10},${sq.item_code || ''},${sq.item_name || ''},${sq.item_weight || ''},${q.qty || ''}`)).flat(),
  ].flat().join('\n')
}

export const AnalysisQuantities: React.FC = () => {
  const { getApi, defaultApiErrorHandler, getInitalStore } = useApp()
  const [storeCode, setStoreCode] = useQueryParam(
    's',
    withDefault(StringParam, getInitalStore().store_code),
  )
  const [date, setDate] = useQueryParam(
    'd',
    withDefault(DateParam, new Date()),
  )
  const [reload, setReload] = useState(false)
  const [quantities, setQuantities] = useState<SegmentQuantities[]>()
  const [filteredQuantities, setFilteredQuantities] = useState<SegmentQuantities[]>()
  const [showFilter, setShowFilter] = useState(false)
  const [filter, setFilter] = useState<SegmentQuantitiesFilter>({
    mode: 'all',
  })
  const [variant, setVariant] = useState<string | null>('table')

  const load = useCallback(() => {
    if (!storeCode) return
    getApi()
      .fetchQuantities(storeCode, DateTime.fromJSDate(date))
      .then((result) => {
        console.log('fetchAsync done')
        setQuantities(result.items)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, storeCode, date])
  useEffect(() => {
    load()
  }, [load])
  // reload
  useEffect(() => {
    if (!reload) return
    console.log('reloading...')
    load()
    setReload(false)
  }, [load, reload])
  // Effect Filter
  useEffect(() => {
    if (!quantities) {
      setFilteredQuantities(undefined)
      return
    }
    if (variant !== 'table') {
      setFilteredQuantities(quantities)
    } else {
      setFilteredQuantities(filterSegmentQuantities(quantities, filter))
    }
  }, [quantities, variant, filter])

  const onStoreChanged = useCallback((store: Store) => {
    setStoreCode(store?.store_code)
    setQuantities(undefined)
  }, [setStoreCode])
  const handleDateChange = useCallback((dt: DateTime) => {
    setDate(dt.toJSDate())
    setQuantities(undefined)
  }, [setDate])
  const handleFilter = useCallback(() => {
    setShowFilter(!showFilter)
  }, [showFilter])
  const handleHandleHasErrorQuantitiesClicked = useCallback(() => {
    setFilter({
      mode: (filter.mode === 'has_error_quantities' ? 'all' : 'has_error_quantities'),
    })
  }, [filter])
  const handleHandleHasQuantitiesClicked = useCallback(() => {
    setFilter({
      mode: (filter.mode === 'has_quantities' ? 'all' : 'has_quantities'),
    })
  }, [filter])
  const handleDownload = useCallback(() => {
    if (quantities === undefined) return
    const csv = toCsvString(quantities)
    saveAs(new Blob([csv], { type: 'text/csv;charset=utf-8' }), `quantities_${DateTime.fromJSDate(date).toFormat('yyyyMMdd')}_${storeCode}.csv`)
  }, [storeCode, date, quantities])
  const handleReload = useCallback(() => {
    setQuantities(undefined)
    setReload(true)
  }, [])
  const handleVariantChanged = useCallback((_evt: React.MouseEvent, newVariant: string | null) => {
    if (newVariant === null) return
    setVariant(newVariant)
    setShowFilter(false)
  }, [])

  return (
    <MainContents>
      <Toolbar variant="dense" disableGutters>
        <StoresSelector
          storeCode={storeCode}
          onSeleced={onStoreChanged}
        />
        <DatePicker
          defaultDate={DateTime.fromJSDate(date)}
          onChange={handleDateChange}
        />
        <FilterButton
          disabled={Boolean(variant !== 'table')}
          onClick={handleFilter}
        />
        <ToggleButtonGroup value={variant} exclusive onChange={handleVariantChanged} size="small" sx={{ marginLeft: 'auto' }}>
          <ToggleButton value="table">
            <Tooltip title="テーブル表示"><ListIcon /></Tooltip>
          </ToggleButton>
          <ToggleButton value="chart">
            <Tooltip title="チャート表示"><ChartIcon /></Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
        <DownloadButton onClick={handleDownload} disabled={Boolean(quantities === undefined)} />
        <ReloadButton onClick={handleReload} />
      </Toolbar>
      <Collapse in={showFilter}>
        <Grid container sx={{ marginLeft: 2, marginBottom: 0.5 }}>
          <Grid item xs={12}>
            <FormControlLabel
              label="エラー判定のあるセグメントのみを表示する"
              control={(
                <Checkbox
                  checked={filter.mode === 'has_error_quantities'}
                  onClick={handleHandleHasErrorQuantitiesClicked}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label="重量変化のあったセグメントのみを表示する（エラー判定を含む）"
              control={(
                <Checkbox
                  checked={filter.mode === 'has_quantities'}
                  onClick={handleHandleHasQuantitiesClicked}
                />
              )}
            />
          </Grid>
        </Grid>
      </Collapse>
      <Loading visible={quantities === undefined} />
      {filteredQuantities && (variant === 'table') && (
        <MaterialTable
          data={filteredQuantities}
          columns={[
            {
              title: '棚',
              field: 'segment_code',
              render: (d) => (<>{toSegment(d.segment_code)}</>),
              sorting: true,
              customSort: (d1, d2) => {
                const [, shelf1, seg1] = d1.segment_code.split('_')
                const [, shelf2, seg2] = d2.segment_code.split('_')
                if (shelf1 === shelf2) {
                  return Number(seg1) - Number(seg2)
                } else {
                  return shelf1.localeCompare(shelf2);
                }
              },
            },
            {
              title: '商品名',
              field: 'item_name',
              sorting: true,
            },
            {
              title: '商品重量',
              field: 'item_weight',
              render: (d) => (<WeightLabel value={d.item_weight} />),
              sorting: true,
            },
            {
              title: '判定数',
              field: 'counts',
              render: (d) => (<>{d.quantities.length}</>),
              sorting: true,
              customSort: (d1, d2) => (d1.quantities.length - d2.quantities.length),
            },
            {
              title: 'エラー数',
              field: 'errors',
              render: (d) => (<>{d.quantities.filter(q => q.qty === undefined).length}</>),
              sorting: true,
              customSort: (d1, d2) => (d1.quantities.filter(q => q.qty === undefined).length - d2.quantities.filter(q => q.qty === undefined).length),
            },
            {
              title: 'エラー率',
              field: 'error_rate',
              render: (d) => (<RateLabel count={d.quantities.filter(q => q.qty === undefined).length} total={d.quantities.length} errorRate />),
              sorting: true,
              customSort: sortSegmentQuantities,
            },
          ]}
          detailPanel={(({ rowData }) => (
            <Box sx={{ marginLeft: 3, marginRight: 2, marginTop: 1, marginBottom: 1 }}>
              <MaterialTable
                data={rowData.quantities}
                columns={[
                  {
                    title: '時刻',
                    field: 'timestamp',
                    render: (d) => (<TimestampLabel value={d.timestamp} />),
                  },
                  {
                    title: '変化重量',
                    field: 'value_diff',
                    render: (d) => (<WeightLabel value={d.value_diff} />),
                  },
                  {
                    title: '数量',
                    field: 'qty',
                    render: (d) => (<>{d.qty !== undefined ? d.qty : '?'}</>),
                  },
                  {
                    title: '',
                    field: 'bar',
                    render: (d) => (<WeightBar value={d.value_diff} itemWeight={rowData.item_weight} itemName={rowData.item_name} qty={d.qty} maxQty={maxQty(rowData.quantities, rowData.item_weight)} />),
                  }
                ]}
                options={SimpleMaterialTableOptions<Quantitiy>({
                  toolbar: false,
                })}
                components={{
                  ...MaterialTableComponents,
                }}
                localization={{
                  ...MaterialTableLocalization
                }}
              />
            </Box>
          ))}
          options={MaterialTableOptions<SegmentQuantities>({
            toolbar: false,
          })}
          components={{
            ...MaterialTableComponents,
          }}
          localization={{
            ...MaterialTableLocalization
          }}
        />
      )}
      {quantities && (variant === 'chart') && (
        <QuantitiesChart
          date={date}
          quantities={quantities}
        />
      )}
    </MainContents>
  )
}
