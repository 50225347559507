import React, {
} from 'react'
import {
  Box,
  SxProps,
  Theme,
} from '@mui/material';
import {
  common,
  green,
  grey,
  lightGreen,
  blue,
  amber,
  red,
} from '@mui/material/colors'
import {
  StoreDevicesStatus,
} from 'models'

const runningSx: SxProps<Theme> = {
  paddingTop: 0.5,
  paddingBottom: 0.5,
  paddingLeft: 3,
  paddingRight: 3,
  backgroundColor: green[600],
  color: common['white'],
  fontSize: 13,
}
const stopSx: SxProps<Theme> = {
  paddingTop: 0.5,
  paddingBottom: 0.5,
  paddingLeft: 4,
  paddingRight: 4,
  backgroundColor: grey[300],
  fontSize: 13,
}
const startingSx: SxProps<Theme> = {
  paddingTop: 0.5,
  paddingBottom: 0.5,
  paddingLeft: 3,
  paddingRight: 3,
  backgroundColor: lightGreen[100],
  fontSize: 13,
}
const restartingSx: SxProps<Theme> = {
  paddingTop: 0.5,
  paddingBottom: 0.5,
  paddingLeft: 3,
  paddingRight: 3,
  backgroundColor: blue[100],
  fontSize: 13,
}
const restartingServiceSx: SxProps<Theme> = {
  paddingTop: 0.5,
  paddingBottom: 0.5,
  paddingLeft: 3,
  paddingRight: 3,
  backgroundColor: blue[100],
  fontSize: 13,
}
const stoppingSx: SxProps<Theme> = {
  paddingTop: 0.5,
  paddingBottom: 0.5,
  paddingLeft: 3,
  paddingRight: 3,
  backgroundColor: lightGreen[100],
  fontSize: 13,
}
const serviceErrorSx: SxProps<Theme> = {
  paddingTop: 0.5,
  paddingBottom: 0.5,
  paddingLeft: 3,
  paddingRight: 3,
  backgroundColor: amber[300],
  fontSize: 13,
}
const errorSx: SxProps<Theme> = {
  paddingTop: 0.5,
  paddingBottom: 0.5,
  paddingLeft: 4,
  paddingRight: 4,
  backgroundColor: red[700],
  color: common['white'],
  fontSize: 13,
}

type StoreStatusProps = {
  status: StoreDevicesStatus
}

export const StoreStatus: React.FC<StoreStatusProps> = ({ status }) => {
  switch (status) {
    case 'running':
      return (<Box component="span" sx={runningSx} >稼動中</Box>)
    case 'stop':
      return (<Box component="span" sx={stopSx} >停止</Box>)
    case 'starting':
      return (<Box component="span" sx={startingSx} >起動処理中</Box>)
    case 'stopping':
      return (<Box component="span" sx={stoppingSx} >停止処理中</Box>)
    case 'service_error':
      return (<Box component="span" sx={serviceErrorSx} >サービスエラー</Box>)
    case 'error':
      return (<Box component="span" sx={errorSx} >障害</Box>)
    case 'restarting':
      return (<Box component="span" sx={restartingSx} >再起動処理中</Box>)
    case 'restarting_service':
      return (<Box component="span" sx={restartingServiceSx} >サービス再起動中</Box>)
    default:
      return (<Box component="span">{status}</Box>)
  }
}