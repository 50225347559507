import React from 'react';
import {
  Box,
  Alert,
  AlertTitle,
} from '@mui/material';

interface WarnigMessageProps {
  title: string
  message?: string
  hidden: boolean
  margin?: number 
}

export const WarnigMessage: React.FC<WarnigMessageProps> = ({
  title,
  message,
  hidden,
  margin = 4,
}) => (
  <Box sx={{ margin }} hidden={hidden}>
    <Alert severity="warning" variant="outlined" sx={{ paddingTop: 2 }}>
      <AlertTitle sx={{ display: 'flex', alignItems: 'center' }}>{title}</AlertTitle>
      {message || ''}
    </Alert>
  </Box>
)
