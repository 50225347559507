import React, {
  useState,
  useCallback, 
} from 'react'
import {
  Button,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import {
  ArrowDropDown,
} from '@mui/icons-material'
import {
  useApp,
} from 'contexts'
import {
  Store,
  NullStore,
} from 'models'

interface StoresSelectorProps {
  storeCode: string|undefined|null
  enableNotSelected?: boolean
  notSelectedLabel?: string
  onSeleced: (store: Store) => void
}

export const StoresSelector: React.FC<StoresSelectorProps>  = ({ storeCode, enableNotSelected, notSelectedLabel, onSeleced }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { getStores, getStore } = useApp()

  const handleClick = useCallback((event: React.MouseEvent<null|HTMLElement>) => {
    setAnchorEl(event?.currentTarget)
  }, [])
  const handleClose = useCallback(() => { setAnchorEl(null) }, [])
  const handleSelect = useCallback((store: Store) => {
    if (store?.store_code !== storeCode) onSeleced(store)
    setAnchorEl(null)
  }, [storeCode, onSeleced])

  return (
    <>
      <Button
        onClick={handleClick}
        endIcon={(<ArrowDropDown fontSize="large"/>)}
      >
        <Typography variant="h6">{storeCode ? getStore(storeCode).store_name : (notSelectedLabel || '全店舗')}</Typography>
      </Button>
      <Menu
        id="stores-selector"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {enableNotSelected &&
        <MenuItem key="all" onClick={() => handleSelect(NullStore)}>
          {notSelectedLabel || '全店舗'}
        </MenuItem>
        }
        {getStores().map(store => (
        <MenuItem key={store.store_code} onClick={() => handleSelect(store)}>
          {store.store_name || store.store_code}
        </MenuItem>
        ))}
      </Menu>
    </>
  )
}