
export const formatJSONString = (jsonStr?: string): string => {
  if (!jsonStr) return ''
  const obj = JSON.parse(jsonStr)
  return JSON.stringify(obj, null, 2)
}

// ascii code
// A:65, N:78, Z:78
// a:97, n:110, z:122
// 0:48, 5:53  9:57
export const rot13 = (str: string): string => (
  str.split('').map(str => {
    const c = str.charCodeAt(0)
    if (97 <= c && c < 110) {
      return String.fromCharCode(c + 13)
    } else if (110 <= c && c <= 122) {
      return String.fromCharCode(c - 13)
    } else if (48 <= c && c < 53) {
      return String.fromCharCode(c + 5)
    } else if (53 <= c && c <= 57) {
      return String.fromCharCode(c - 5)
    } else {
      return str
    }
  }).join('')
)