import React, {
} from 'react'
import {
  useNavigate,
  useLocation,
} from 'react-router-dom'
import {
  Tooltip,
} from '@mui/material'
import {
  CheckCircle,
  Warning,
} from '@mui/icons-material'
import {
  useApp,
} from 'contexts'
import {
  StoreEvent,
} from 'icons'
import MaterialTable from '@material-table/core'
import {
  Walkthrough,
} from 'models'
import {
  TimestampLabel,
  MaterialTableOptions,
  MaterialTableComponents,
  MaterialTableLocalization,
} from 'components'

const getWalkthrougNo = (walkthrough: Walkthrough, prefix: string) => `${prefix}#${Number(walkthrough.walkthrough_id.split('_')[2])}`

interface WStatusIconProps {
  data: Walkthrough
}
const StatusIcon: React.FC<WStatusIconProps> = ({ data }) => {
  if (data.timeout) return (
    <Tooltip title="確定タイムアウト">
      <Warning color="error"/>
    </Tooltip>
  )
  if (data.event_mode) return (
    <Tooltip title="イベントモード">
      <StoreEvent color="disabled"/>
    </Tooltip>
  )
  if (data.payment_requested_at) return (
    <Tooltip title="決済要求済み">
      <CheckCircle color="primary"/>
    </Tooltip>
  )
  return (<></>)
}

export interface WalkthroughsTableProps {
  data: Walkthrough[]
  storeNamePrefixEnabled?: boolean
}

export const WalkthroughsTable: React.FC<WalkthroughsTableProps> = ({ data, storeNamePrefixEnabled = true }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { getStore } = useApp()
  return (
    <MaterialTable
      data={data}
      columns={[
        {
          title: '#',
          field: 'no',
          render: (d) => getWalkthrougNo(d, storeNamePrefixEnabled ? getStore(d.store_code).store_name : ''),
        },
        {
          title: '商品数',
          field: 'items_length',
          render: (d) =>  ( d.items
            ? (<span>{d.items.reduce((acc, cur) => acc + cur.qty, 0)}</span>)
            : (<span>0</span>)
          )
        },
        {
          title: '入店',
          field: 'timestamp',
          render: (d) => (
            <TimestampLabel value={d.timestamp} />
          )
        },
        {
          title: '退店',
          field: 'exit_at',
          render: (d) =>  (
            <TimestampLabel
              value={d.exit_at || d.exit_timeout}
              color={d.exit_timeout ? 'orange' : undefined}
            />
          )
        },
        {
          title: '滞在時間',
          field: 'time',
          render: (d) => { 
            if (d.exit_at) return (<span>{d.exit_at - d.timestamp}</span>)
            if (d.exit_timeout) return (<span>Timeout</span>)
            return (<span>-</span>)
          }
        },
        {
          title: '',
          field: 'status',
          render: (d) =>  <StatusIcon data={d}/>,
        },
      ]}
      onRowClick={(_, rowData) => {
        navigate(`/walkthrough/${rowData?.walkthrough_id}${location.search}`)
      }}
      options={MaterialTableOptions<Walkthrough>({
        toolbar: false,
      })}
      components={{
        ...MaterialTableComponents,
      }}
      localization={{
        ...MaterialTableLocalization
      }}
    />
  )
}

