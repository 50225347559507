import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useForm } from 'react-hook-form'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Box,
  FormLabel,
  FormControl,
  FormControlLabel,
  TextField,
  Tooltip,
  IconButton,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Button,
  Switch,
} from '@mui/material'
import {
  AddBox as AddIcon,
  AutoAwesomeMotion as NodeIcon,
} from '@mui/icons-material'
import {
  useApp,
} from 'contexts'
import {
  WeightSensorDevice,
  WeightSensorDeviceUpdatePayload,
  AwslogsLevel,
  WeightSensorNode,
} from 'models'
import {
  ProgressScreen,
  WarnigMessage,
} from 'components'
import { StoresSelect } from './StoresSelect'
import { AwslogsLevelSelect } from './AwslogsLevelSelect'
import { WeightSensorNodeDialog } from './WeightSensorNodeDialog'
import { AddWeightSensorNodeDialog } from './AddWeightSensorNodeDialog'
import {
  WeightSensorFormData,
  WeightSensorFormValidations,
  toFormData,
  dataToPayload,
} from './forms_ws'
import { sx } from './sx'

interface WeightSensorSettingsDialogProps {
  open: boolean
  device: WeightSensorDevice
  onClose: () => void
  onUpdated: (device: WeightSensorDevice, restartService: boolean) => void
}

const toNodeDetails = (node: WeightSensorNode): string => {
  const filtered = node.segments.filter(seg => seg !== null && !seg.startsWith('_'));
  if (filtered.length === 0) return 'No segments enabled.'
  return filtered.map(seg => seg?.split('_').slice(1).join('_')).join(', ')
}

export const WeightSensorSettingsDialog: React.FC<WeightSensorSettingsDialogProps> = ({ open, device, onClose, onUpdated }) => {
  const { getApi, defaultApiErrorHandler } = useApp()
  const [storeCode, setStoreCode] = useState(device.store_code)
  const [agentAwslogsLevel, setAgentAwslogsLevel] = useState(device.agent_properties.awslogs_level)
  const [serviceAwslogsLevel, setServiceAwslogsLevel] = useState(device.service_properties.awslogs_level)
  const [serviceEnablePublishData, setServiceEnablePublishData] = useState(device.service_properties.enable_publish_data)
  const [serviceEnableWeightFile, setServiceEnableWeightFile] = useState(device.service_properties.enable_weight_file)
  const [serviceEnableRawdataFile, setServiceEnableRawdataFile] = useState(device.service_properties.enable_rawdata_file)
  const [nodes, setNodes] = useState<WeightSensorNode[]>(device.service_properties.nodes || {})
  const [uneditedNodes, setUneditedNodes] = useState<WeightSensorNode[]>(device.service_properties.nodes || {})
  const [selectedNodeId, setSelectedNodeId] = useState<string>()
  const [restartService, setRestartService] = useState(true)
  const [openAddWeightSensorNodeDialog, setOpenAddWeightSensorNodeDialog] = useState(false)
  const { register, handleSubmit, reset, formState, formState: { errors } } = useForm<WeightSensorFormData>({
    mode: 'onBlur',
  })
  const { isDirty, isValid } = formState;
  const [changed, setChanged] = useState(false)
  const [updating, setUpdating] = useState(false)

  // reset form
  useEffect(() => {
    reset(toFormData(device))
    setAgentAwslogsLevel(device.agent_properties.awslogs_level)
    setServiceAwslogsLevel(device.service_properties.awslogs_level)
    setServiceEnablePublishData(device.service_properties.enable_publish_data)
    setServiceEnableWeightFile(device.service_properties.enable_weight_file)
    setServiceEnableRawdataFile(device.service_properties.enable_rawdata_file)
    setNodes(device.service_properties.nodes || {})
    setUneditedNodes(device.service_properties.nodes || {})
  }, [device, reset])

  const handleServiceEnablePublishData = useCallback((_, checked) => {
    setServiceEnablePublishData(checked)
    setChanged(true)
  }, [])
  const handleServiceEnableWeightFile = useCallback((_, checked) => {
    setServiceEnableWeightFile(checked)
    setChanged(true)
  }, [])
  const handleServiceEnableRawdataFile = useCallback((_, checked) => {
    setServiceEnableRawdataFile(checked)
    setChanged(true)
  }, [])
  const handleCanceled = useCallback(() => {
    onClose()
    setNodes(uneditedNodes)
    setChanged(false)
  }, [onClose, uneditedNodes])
  const handleRestartService = useCallback((_, checked) => {
    setRestartService(checked)
  }, [])

  const storeCodeChanged = useCallback((newStoreCode: string) => {
    setStoreCode(newStoreCode)
    setChanged(true)
  }, [])
  const agentAwslogsLevelChanged = useCallback((newLevel: AwslogsLevel) => {
    setAgentAwslogsLevel(newLevel)
    setChanged(true)
  }, [])
  const serviceAwslogsLevelChanged = useCallback((newLevel: AwslogsLevel) => {
    setServiceAwslogsLevel(newLevel)
    setChanged(true)
  }, [])

  const handleAddNodeDialogClosed = useCallback(() => {
    setOpenAddWeightSensorNodeDialog(false)
  }, [])
  const handleNodeAdded = useCallback((nodeId: string) => {
    setOpenAddWeightSensorNodeDialog(false)
    if (nodes.find(node => node.node_id == nodeId) !== undefined) return;
    const newNode: WeightSensorNode = {
      node_id: nodeId,
      segments: [null, null, null, null, null, null, null, null, null, null],
    }
    const newNodes = [...nodes, newNode]
    setNodes(newNodes)
    setChanged(true)
  }, [nodes])
  const handleNodeDialogClosed = useCallback(() => {
    setSelectedNodeId(undefined)
  }, [])
  const handleNodeDeleted = useCallback((nodeId: string) => {
    const newNodes = nodes.filter(node => node.node_id != nodeId);
    setNodes(newNodes)
    setSelectedNodeId(undefined)
    setChanged(true)
  }, [nodes])
  const handleNodeUpdated = useCallback((node: WeightSensorNode) => {
    const newNodes = [...nodes]
    const idx = newNodes.findIndex(n => n.node_id == node.node_id);
    if (idx === -1) return;
    newNodes[idx] = node;
    setSelectedNodeId(undefined)
    setNodes(newNodes)
    setChanged(true)
  }, [nodes])

  // 更新
  const onSubmit = useCallback((data: WeightSensorFormData) => {
    setUpdating(true)
    const payload: WeightSensorDeviceUpdatePayload = dataToPayload(data, storeCode, agentAwslogsLevel, serviceAwslogsLevel, serviceEnablePublishData, serviceEnableWeightFile, serviceEnableRawdataFile, nodes)
    getApi().updateWeightSensorDevice(device.thing_name, payload)
      .then((device) => {
        onUpdated(device, restartService)
        setUpdating(false)
      })
      .catch(defaultApiErrorHandler)
  }, [
    getApi,
    defaultApiErrorHandler,
    onUpdated,
    device,
    restartService,
    storeCode,
    agentAwslogsLevel,
    serviceAwslogsLevel,
    serviceEnablePublishData,
    serviceEnableWeightFile,
    serviceEnableRawdataFile,
    nodes
  ])

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
    >
      <ProgressScreen colorVariant="light" open={open && updating} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{device.thing_name}</DialogTitle>
        <DialogContent>
          <Grid container spacing={0} sx={sx.forms}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={sx.category}>全般</Typography>
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel sx={sx.label}>店舗</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <StoresSelect storeCode={storeCode} onChange={storeCodeChanged} />
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>名称</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <FormControl sx={sx.formControl}>
                <TextField
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  margin="dense"
                  required
                  {...register(`name`, WeightSensorFormValidations.name)}
                  error={Boolean(errors?.name)}
                  helperText={errors?.name?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" sx={sx.category}>エージェント</Typography>
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>CloudWatch Logs</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <AwslogsLevelSelect awslogsLevel={agentAwslogsLevel} onChange={agentAwslogsLevelChanged} />
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>送信間隔</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <FormControl sx={sx.formControl}>
                <TextField
                  type="number"
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  margin="dense"
                  required
                  inputProps={{
                    max: 300,
                    min: 50,
                  }}
                  {...register(`agent_interval`, WeightSensorFormValidations.agent_interval)}
                  error={Boolean(errors?.agent_interval)}
                  helperText={errors?.agent_interval?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>サービス開始タイムアウト</FormLabel>
            </Grid>
            <Grid item xs={9}>
              <FormControl sx={sx.formControl}>
                <TextField
                  type="number"
                  size="small"
                  hiddenLabel
                  variant="outlined"
                  margin="dense"
                  required
                  inputProps={{
                    max: 600,
                    min: 60,
                  }}
                  {...register(`agent_service_start_timeout`, WeightSensorFormValidations.agent_service_start_timeout)}
                  error={Boolean(errors?.agent_interval)}
                  helperText={errors?.agent_service_start_timeout?.message}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" sx={sx.category}>サービス</Typography>
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>CloudWatch Logs</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <AwslogsLevelSelect awslogsLevel={serviceAwslogsLevel} onChange={serviceAwslogsLevelChanged} />
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>送信間隔</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <FormControl sx={sx.formControl}>
                <TextField
                  type="number"
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  margin="dense"
                  required
                  inputProps={{
                    max: 300,
                    min: 50,
                  }}
                  {...register(`service_interval`, WeightSensorFormValidations.service_interval)}
                  error={Boolean(errors?.service_interval)}
                  helperText={errors?.service_interval?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                label="MQTTデータ送信を有効にする"
                control={
                  <Switch
                    checked={serviceEnablePublishData}
                    onChange={handleServiceEnablePublishData}
                  />
                }
                sx={sx.action}
              />
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>重量データ送信間隔</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <FormControl sx={sx.formControl}>
                <TextField
                  type="number"
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  margin="dense"
                  required
                  inputProps={{
                    max: 600,
                    min: 1,
                  }}
                  {...register(`service_weight_data_interval`, WeightSensorFormValidations.service_weight_data_interval)}
                  error={Boolean(errors?.service_weight_data_interval)}
                  helperText={errors?.service_weight_data_interval?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                label="デバイスに重量情報ファイルを作成する"
                control={
                  <Switch
                    checked={serviceEnableWeightFile}
                    onChange={handleServiceEnableWeightFile}
                  />
                }
                sx={sx.action}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                label="デバイスに生データファイルを作成する"
                control={
                  <Switch
                    checked={serviceEnableRawdataFile}
                    onChange={handleServiceEnableRawdataFile}
                  />
                }
                sx={sx.action}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" sx={sx.subCategory}>
                <Box sx={{ display: 'flex' }}>
                  <Box>ノード</Box>
                  <Tooltip title="ノードの追加">
                    <IconButton
                      aria-label="add-node"
                      color="primary"
                      size="small"
                      sx={{ marginLeft: 'auto', marginRight: 1, }}
                      onClick={() => setOpenAddWeightSensorNodeDialog(true)}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sx={sx.nodeList}>
              <List dense>
                {nodes.map(node => (
                  <ListItemButton
                    key={`node_${node.node_id}`}
                    onClick={() => setSelectedNodeId(node.node_id)}
                  >
                    <ListItemAvatar>
                      <Avatar variant="rounded">
                        <NodeIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`Node ID: ${node.node_id}`}
                      secondary={toNodeDetails(node)}
                    />
                  </ListItemButton>
                ))}
              </List>
              <WarnigMessage
                hidden={0 < Object.keys(nodes).length}
                title="ノードは登録されていません。"
                margin={0.5}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            label="デバイスを再起動し、変更を反映する"
            control={
              <Switch
                checked={restartService}
                onChange={handleRestartService}
              />
            }
            sx={sx.action}
          />
          <Button
            color="inherit"
            variant="contained"
            sx={sx.action}
            onClick={handleCanceled}
          >
            キャンセル
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            sx={sx.action}
            disabled={!changed && (!isDirty || !isValid)}
          >
            保存
          </Button>
        </DialogActions>
      </form>
      <AddWeightSensorNodeDialog
        open={openAddWeightSensorNodeDialog}
        otherIds={nodes.map(node => node.node_id)}
        onAdded={handleNodeAdded}
        onClose={handleAddNodeDialogClosed}
      />
      {nodes.map((node) => (
        <WeightSensorNodeDialog
          key={`dialog_${node.node_id}`}
          open={selectedNodeId === node.node_id}
          nodeId={node.node_id}
          node={node}
          onUpdated={handleNodeUpdated}
          onClose={handleNodeDialogClosed}
          onDeleted={handleNodeDeleted}
        />
      ))}
    </Dialog>
  )
}