import React from 'react'
import {
  Routes,
  Route,
} from 'react-router-dom'
import { Login } from './pages/login/Login'
import {
  NoPermissions,
  Unauthorized,
  SystemError,
} from 'pages/errors'

export const UnauthenticatedAppRouter: React.FC = () => (
<Routes>
  <Route index element={<Login/>}/>
  <Route path="/unauthorized" element={<Unauthorized/>}/>
  <Route path="/system_error" element={<SystemError/>}/>
  <Route path="/no_permissions" element={<NoPermissions/>}/>
  <Route path="*" element={<Login/>}/>
</Routes>
)
