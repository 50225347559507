import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  Toolbar,
} from '@mui/material'
import MaterialTable from '@material-table/core'
import { DateTime } from 'luxon'
import {
  Store,
  Notification,
} from 'models'
import {
  useApp,
} from 'contexts'
import {
  useSelectedStoreCode,
} from 'hooks'
import {
  MainContents,
  StoresSelector,
  Loading,
  ReloadButton,
  TimestampLabel,
  MaterialTableOptions,
  MaterialTableComponents,
  MaterialTableLocalization,
  DatePicker,
} from 'components'

export const Logs: React.FC = () => {
  const {storeCode, setStoreCode} = useSelectedStoreCode()
  const [date, setDate] = useState(DateTime.now())
  const [reload, setReload] = useState(false)
  const [items, setItems] = useState<Notification[]>()
  const { getApi, defaultApiErrorHandler } = useApp()

  const load = useCallback(() => {
    getApi()
      .fetchNotifications(storeCode, date)
      .then((result) => {
        console.log('fetchAsync done')
        setItems(result.items)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, storeCode, date])
  useEffect(() => {
    load()
  }, [load])
  // reload
  useEffect(() => {
    if (!reload) return
    console.log('reloading...')
    load()
    setReload(false)
  }, [load, reload])

  const onStoreChanged = useCallback((store: Store) => {
    setStoreCode(store.store_code)
  }, [setStoreCode])
  const handleDateChange = useCallback((dt: DateTime) => {
    setDate(dt)
  }, [])
  const handleReload = useCallback(() => {
    setItems(undefined)
    setReload(true)
  }, [])

  return (
  <MainContents>
    <Toolbar variant="dense" disableGutters>
      <StoresSelector
        storeCode={storeCode}
        onSeleced={onStoreChanged}
      />
      <DatePicker
        onChange={handleDateChange}
      />
      <ReloadButton marginLeft="auto" onClick={handleReload} />
    </Toolbar>
    <Loading visible={items === undefined}/>
    {items &&
    <MaterialTable
      data={items}
      columns={[
        {
          title: '時刻',
          field: 'timestamp',
          width: '15%',
          defaultSort: 'asc',
          render: (d) => (<TimestampLabel value={d.timestamp} />),
        },
        {
          title: '優先度',
          field: 'priority',
          width: '15%',
        },
        {
          title: '種別',
          field: 'type',
          width: '15%',
        },
        {
          title: 'メッセージ',
          field: 'message',
        },
      ]}
      options={MaterialTableOptions<Notification>({
        toolbar: false,
      })}
      components={{
        ...MaterialTableComponents,
      }}
      localization={{
        ...MaterialTableLocalization
      }}
    />
    }
  </MainContents>
  )
}