import {
  Operator,
} from 'models'

const formatPhoneNo = (phoneNo: string): string => `+81${phoneNo.substr(1)}`

export type FormData = {
  phone_no: string
  name: string
}

export const dataToModel = (
  data: FormData,
): Operator => {
  return {
    operator_code: formatPhoneNo(data.phone_no),
    operator_name: data.name,
    active: false,
  }
}

export const FormValidations = {
  name: {
    required: {
      value: true,
      message: 'オペレータ名を入力してください',
    },
  },
  phone_no: {
    required: {
      value: true,
      message: '通知先番号を入力してください',
    },
    pattern: {
      value: /^0[0-9]{9,10}$/,
      message: '通知先番号はハイフン無しで入力してください',
    }
  },
}