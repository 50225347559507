import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useForm } from 'react-hook-form'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  FormLabel,
  FormControl,
  FormControlLabel,
  TextField,
  Button,
  Switch,
} from '@mui/material'
import {
  useApp,
} from 'contexts'
import {
  EdgeDevice,
  EdgeDeviceUpdatePayload,
  AwslogsLevel,
} from 'models'
import {
  ProgressScreen,
} from 'components'
import { StoresSelect } from './StoresSelect'
import { AwslogsLevelSelect } from './AwslogsLevelSelect'
import {
  EdgeFormData,
  EdgeFormValidations,
  toFormData,
  dataToPayload,
} from './forms_edge'
import { sx } from './sx'

interface EdgeSettingsDialogProps {
  open: boolean
  device: EdgeDevice
  onClose: () => void
  onUpdated: (device: EdgeDevice, restartService: boolean) => void
}

export const EdgeSettingsDialog: React.FC<EdgeSettingsDialogProps> = ({ open, device, onClose, onUpdated }) => {
  const { getApi, defaultApiErrorHandler } = useApp()
  const [storeCode, setStoreCode] = useState(device.store_code)
  const [agentAwslogsLevel, setAgentAwslogsLevel] = useState(device.agent_properties.awslogs_level)
  const [serviceAwslogsLevel, setServiceAwslogsLevel] = useState(device.service_properties.awslogs_level)
  const [serviceActionAwslogsLevel, setServiceActionAwslogsLevel] = useState(device.service_properties.action_awslogs_level)
  const [serviceActionPostApi, setServiceActionPostApi] = useState(device.service_properties.action_post_api)
  const [serviceActionDebug, setServiceActionDebug] = useState(device.service_properties.action_debug)
  const [servicePassageAwslogsLevel, setServicePassageAwslogsLevel] = useState(device.service_properties.passage_awslogs_level)
  const [servicePassagePostApi, setServicePassagePostApi] = useState(device.service_properties.passage_post_api)
  const [servicePassageDebug, setServicePassageDebug] = useState(device.service_properties.passage_post_api)
  const [serviceWeightAwslogsLevel, setServiceWeightAwslogsLevel] = useState(device.service_properties.weight_awslogs_level)
  const [serviceWeightEnableMqtt, setServiceWeightEnableMqtt] = useState(device.service_properties.weight_enable_mqtt)
  const [serviceWeightEnableFirehose, setServiceWeightEnableFirehose] = useState(device.service_properties.weight_enable_firehose)
  const [serviceTrackingAwslogsLevel, setServiceTrackingAwslogsLevel] = useState(device.service_properties.tracking_awslogs_level)
  const [serviceTrackingEnableMqtt, setServiceTrackingEnableMqtt] = useState(device.service_properties.tracking_enable_mqtt)
  const [serviceTrackingEnableFirehose, setServiceTrackingEnableFirehose] = useState(device.service_properties.tracking_enable_firehose)
  const [serviceMovieAwslogsLevel, setServiceMovieAwslogsLevel] = useState(device.service_properties.movie_awslogs_level)
  const [restartService, setRestartService] = useState(true)
  const { register, handleSubmit, reset, formState, formState: { errors } } = useForm<EdgeFormData>({
    mode: 'onBlur',
  })
  const { isDirty, isValid } = formState;
  const [changed, setChanged] = useState(false)
  const [updating, setUpdating] = useState(false)

  // reset form
  useEffect(() => {
    reset(toFormData(device))
    setAgentAwslogsLevel(device.agent_properties.awslogs_level)
    setServiceAwslogsLevel(device.service_properties.awslogs_level)
    setServiceActionAwslogsLevel(device.service_properties.action_awslogs_level)
    setServiceActionPostApi(device.service_properties.action_post_api)
    setServiceActionDebug(device.service_properties.action_debug)
    setServicePassageAwslogsLevel(device.service_properties.passage_awslogs_level)
    setServicePassagePostApi(device.service_properties.passage_post_api)
    setServicePassageDebug(device.service_properties.passage_debug)
    setServiceWeightAwslogsLevel(device.service_properties.weight_awslogs_level)
    setServiceWeightEnableMqtt(device.service_properties.weight_enable_mqtt)
    setServiceWeightEnableFirehose(device.service_properties.weight_enable_firehose)
    setServiceTrackingAwslogsLevel(device.service_properties.tracking_awslogs_level)
    setServiceTrackingEnableMqtt(device.service_properties.tracking_enable_mqtt)
    setServiceTrackingEnableFirehose(device.service_properties.tracking_enable_firehose)
    setServiceMovieAwslogsLevel(device.service_properties.movie_awslogs_level)
  }, [device, reset])

  const handleCanceled = useCallback(() => {
    onClose()
  }, [onClose])
  const handleRestartService = useCallback((_, checked) => {
    setRestartService(checked)
  }, [])

  const storeCodeChanged = useCallback((newStoreCode: string) => {
    setStoreCode(newStoreCode)
    setChanged(true)
  }, [])
  const agentAwslogsLevelChanged = useCallback((newLevel: AwslogsLevel) => {
    setAgentAwslogsLevel(newLevel)
    setChanged(true)
  }, [])
  const serviceAwslogsLevelChanged = useCallback((newLevel: AwslogsLevel) => {
    setServiceAwslogsLevel(newLevel)
    setChanged(true)
  }, [])
  const serviceActionAwslogsLevelChanged = useCallback((newLevel: AwslogsLevel) => {
    setServiceActionAwslogsLevel(newLevel)
    setChanged(true)
  }, [])
  const handleServiceActionPostApi = useCallback((_, checked) => {
    setServiceActionPostApi(checked)
    setChanged(true)
  }, [])
  const handleServiceActionDebug = useCallback((_, checked) => {
    setServiceActionDebug(checked)
    setChanged(true)
  }, [])
  const servicePassageAwslogsLevelChanged = useCallback((newLevel: AwslogsLevel) => {
    setServicePassageAwslogsLevel(newLevel)
    setChanged(true)
  }, [])
  const handleServicePassagePostApi = useCallback((_, checked) => {
    setServicePassagePostApi(checked)
    setChanged(true)
  }, [])
  const handleServicePassageDebug = useCallback((_, checked) => {
    setServicePassageDebug(checked)
    setChanged(true)
  }, [])
  const serviceTrackingAwslogsLevelChanged = useCallback((newLevel: AwslogsLevel) => {
    setServiceTrackingAwslogsLevel(newLevel)
    setChanged(true)
  }, [])
  const handleServiceTrackingEnableMqtt = useCallback((_, checked) => {
    setServiceTrackingEnableMqtt(checked)
    setChanged(true)
  }, [])
  const handleServiceTrackingEnableFirehose = useCallback((_, checked) => {
    setServiceTrackingEnableFirehose(checked)
    setChanged(true)
  }, [])
  const serviceWeightAwslogsLevelChanged = useCallback((newLevel: AwslogsLevel) => {
    setServiceWeightAwslogsLevel(newLevel)
    setChanged(true)
  }, [])
  const handleServiceWeightEnableMqtt = useCallback((_, checked) => {
    setServiceWeightEnableMqtt(checked)
    setChanged(true)
  }, [])
  const handleServiceWeightEnableFirehose = useCallback((_, checked) => {
    setServiceWeightEnableFirehose(checked)
    setChanged(true)
  }, [])
  const serviceMovieAwslogsLevelChanged = useCallback((newLevel: AwslogsLevel) => {
    setServiceMovieAwslogsLevel(newLevel)
    setChanged(true)
  }, [])

  // 更新
  const onSubmit = useCallback((data: EdgeFormData) => {
    setUpdating(true)
    const payload: EdgeDeviceUpdatePayload = dataToPayload(
      data,
      storeCode,
      agentAwslogsLevel,
      serviceAwslogsLevel,
      serviceActionAwslogsLevel,
      serviceActionPostApi,
      serviceActionDebug,
      servicePassageAwslogsLevel,
      servicePassagePostApi,
      servicePassageDebug,
      serviceWeightAwslogsLevel,
      serviceWeightEnableMqtt,
      serviceWeightEnableFirehose,
      serviceTrackingAwslogsLevel,
      serviceTrackingEnableMqtt,
      serviceTrackingEnableFirehose,
      serviceMovieAwslogsLevel,
    )
    getApi().updateBastionDevice(device.thing_name, payload)
      .then((device) => {
        onUpdated(device, restartService)
        setUpdating(false)
      })
      .catch(defaultApiErrorHandler)
  }, [
    getApi,
    defaultApiErrorHandler,
    onUpdated,
    device,
    restartService,
    storeCode,
    agentAwslogsLevel,
    serviceAwslogsLevel,
    serviceActionAwslogsLevel,
    serviceActionPostApi,
    serviceActionDebug,
    servicePassageAwslogsLevel,
    servicePassagePostApi,
    servicePassageDebug,
    serviceWeightAwslogsLevel,
    serviceWeightEnableMqtt,
    serviceWeightEnableFirehose,
    serviceTrackingAwslogsLevel,
    serviceTrackingEnableMqtt,
    serviceTrackingEnableFirehose,
    serviceMovieAwslogsLevel,
  ])

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
    >
      <ProgressScreen colorVariant="light" open={open && updating} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{device.thing_name}</DialogTitle>
        <DialogContent>
          <Grid container spacing={0} sx={sx.forms}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={sx.category}>全般</Typography>
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel sx={sx.label}>店舗</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <StoresSelect storeCode={storeCode} onChange={storeCodeChanged} />
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel sx={sx.label}>インスタンスID</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                {...register(`ssm_instance_id`, EdgeFormValidations.ssm_instance_id)}
                error={Boolean(errors?.ssm_instance_id)}
                helperText={errors?.ssm_instance_id?.message}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" sx={sx.category}>エージェント</Typography>
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>CloudWatch Logs</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <AwslogsLevelSelect awslogsLevel={agentAwslogsLevel} onChange={agentAwslogsLevelChanged} />
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>送信間隔</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <FormControl sx={sx.formControl}>
                <TextField
                  type="number"
                  size="small"
                  hiddenLabel
                  variant="outlined"
                  margin="dense"
                  required
                  inputProps={{
                    max: 300,
                    min: 50,
                  }}
                  {...register(`agent_interval`, EdgeFormValidations.agent_interval)}
                  error={Boolean(errors?.agent_interval)}
                  helperText={errors?.agent_interval?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>サービス開始タイムアウト</FormLabel>
            </Grid>
            <Grid item xs={9}>
              <FormControl sx={sx.formControl}>
                <TextField
                  type="number"
                  size="small"
                  hiddenLabel
                  variant="outlined"
                  margin="dense"
                  required
                  inputProps={{
                    max: 600,
                    min: 60,
                  }}
                  {...register(`agent_service_start_timeout`, EdgeFormValidations.agent_service_start_timeout)}
                  error={Boolean(errors?.agent_interval)}
                  helperText={errors?.agent_service_start_timeout?.message}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" sx={sx.category}>電源制御サービス</Typography>
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>CloudWatch Logs</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <AwslogsLevelSelect awslogsLevel={serviceAwslogsLevel} onChange={serviceAwslogsLevelChanged} />
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>送信間隔</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <FormControl sx={sx.formControl}>
                <TextField
                  type="number"
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  margin="dense"
                  required
                  inputProps={{
                    max: 300,
                    min: 50,
                  }}
                  {...register(`service_interval`, EdgeFormValidations.service_interval)}
                  error={Boolean(errors?.service_interval)}
                  helperText={errors?.service_interval?.message}
                />
              </FormControl>
            </Grid>

            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>ステータスチェック間隔</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <FormControl sx={sx.formControl}>
                <TextField
                  type="number"
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  margin="dense"
                  required
                  inputProps={{
                    max: 600,
                    min: 30,
                  }}
                  {...register(`check_stat_interval`, EdgeFormValidations.check_stat_interval)}
                  error={Boolean(errors?.check_stat_interval)}
                  helperText={errors?.check_stat_interval?.message}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" sx={sx.category}>Edge Action モジュール</Typography>
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>CloudWatch Logs</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <AwslogsLevelSelect awslogsLevel={serviceActionAwslogsLevel} onChange={serviceActionAwslogsLevelChanged} />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                label="API連携を有効にする"
                control={
                  <Switch
                    checked={serviceActionPostApi}
                    onChange={handleServiceActionPostApi}
                  />
                }
                sx={sx.action}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                label="Debug機能を有効にする"
                control={
                  <Switch
                    checked={serviceActionDebug}
                    onChange={handleServiceActionDebug}
                  />
                }
                sx={sx.action}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" sx={sx.category}>Edge Passage モジュール</Typography>
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>CloudWatch Logs</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <AwslogsLevelSelect awslogsLevel={servicePassageAwslogsLevel} onChange={servicePassageAwslogsLevelChanged} />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                label="API連携を有効にする"
                control={
                  <Switch
                    checked={servicePassagePostApi}
                    onChange={handleServicePassagePostApi}
                  />
                }
                sx={sx.action}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                label="Debug機能を有効にする"
                control={
                  <Switch
                    checked={servicePassageDebug}
                    onChange={handleServicePassageDebug}
                  />
                }
                sx={sx.action}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" sx={sx.category}>Edge Weight モジュール</Typography>
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>CloudWatch Logs</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <AwslogsLevelSelect awslogsLevel={serviceWeightAwslogsLevel} onChange={serviceWeightAwslogsLevelChanged} />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                label="AWS MQTT連携を有効にする"
                control={
                  <Switch
                    checked={serviceWeightEnableMqtt}
                    onChange={handleServiceWeightEnableMqtt}
                  />
                }
                sx={sx.action}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                label="AWS Firehose連携を有効にする"
                control={
                  <Switch
                    checked={serviceWeightEnableFirehose}
                    onChange={handleServiceWeightEnableFirehose}
                  />
                }
                sx={sx.action}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" sx={sx.category}>Edge Tracking モジュール</Typography>
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>CloudWatch Logs</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <AwslogsLevelSelect awslogsLevel={serviceTrackingAwslogsLevel} onChange={serviceTrackingAwslogsLevelChanged} />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                label="AWS MQTT連携を有効にする"
                control={
                  <Switch
                    checked={serviceTrackingEnableMqtt}
                    onChange={handleServiceTrackingEnableMqtt}
                  />
                }
                sx={sx.action}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                label="AWS Firehose連携を有効にする"
                control={
                  <Switch
                    checked={serviceTrackingEnableFirehose}
                    onChange={handleServiceTrackingEnableFirehose}
                  />
                }
                sx={sx.action}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" sx={sx.category}>Edge Movie モジュール</Typography>
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>CloudWatch Logs</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <AwslogsLevelSelect awslogsLevel={serviceMovieAwslogsLevel} onChange={serviceMovieAwslogsLevelChanged} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" sx={sx.category}>Edge Logs モジュール</Typography>
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel sx={sx.label}>通知Slackチャンネル</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <FormControl sx={sx.formControl}>
                <TextField
                  size="small"
                  hiddenLabel
                  variant="outlined"
                  margin="dense"
                  {...register(`service_logs_notify_slack_channel`, EdgeFormValidations.service_logs_notify_slack_channel)}
                  error={Boolean(errors?.service_logs_notify_slack_channel)}
                  helperText={errors?.service_logs_notify_slack_channel?.message}
                />
              </FormControl>
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            label="デバイスを再起動し、変更を反映する"
            control={
              <Switch
                checked={restartService}
                onChange={handleRestartService}
              />
            }
            sx={sx.action}
          />
          <Button
            color="inherit"
            variant="contained"
            sx={sx.action}
            onClick={handleCanceled}
          >
            キャンセル
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            sx={sx.action}
            disabled={!changed && (!isDirty || !isValid)}
          >
            保存
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}