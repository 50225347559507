import React, {
  useCallback,
} from 'react'
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
  IconButton,
  Button,
  Tooltip,
} from '@mui/material';
import {
  Delete as DeleteIcon,
} from '@mui/icons-material'
import {
  red,
  blue,
} from '@mui/material/colors'
import {
  BootHours,
} from 'models'

interface BootHoursTableProps {
  bootHours: BootHours[][]
  onChanged: (updated: BootHours[][]) => void
  handleAddClicked: () => void
  readOnly: boolean
}

export const BootHoursTable: React.FC<BootHoursTableProps> = ({ bootHours, onChanged, handleAddClicked, readOnly }) => {
  const handleDeleted = useCallback((dayOfWeek: number, idx: number) => {
    const updated = bootHours.map((bh, dof) => bh.filter((_, i) => !(dayOfWeek === dof && idx === i)))
    console.log(updated)
    onChanged(updated)
  }, [bootHours, onChanged])

  return (
  <TableContainer sx={{ width: 'auto', paddingLeft: 2, paddingRight: 2 }}>
    <Box sx={{ textAlign: 'right'}}>
      <Tooltip title="スケジュールを追加">
        <Box component="span">
          <Button size="small" variant="outlined" onClick={handleAddClicked} disabled={readOnly}>追加</Button>
        </Box>
      </Tooltip>
    </Box>
    <Table size="small">
      <TableBody>
        <BootHoursTableRows bootHours={bootHours} dayOfWeek={1} onDeleted={handleDeleted} readOnly={readOnly}/>
        <BootHoursTableRows bootHours={bootHours} dayOfWeek={2} onDeleted={handleDeleted} readOnly={readOnly}/>
        <BootHoursTableRows bootHours={bootHours} dayOfWeek={3} onDeleted={handleDeleted} readOnly={readOnly}/>
        <BootHoursTableRows bootHours={bootHours} dayOfWeek={4} onDeleted={handleDeleted} readOnly={readOnly}/>
        <BootHoursTableRows bootHours={bootHours} dayOfWeek={5} onDeleted={handleDeleted} readOnly={readOnly}/>
        <BootHoursTableRows bootHours={bootHours} dayOfWeek={6} onDeleted={handleDeleted} readOnly={readOnly}/>
        <BootHoursTableRows bootHours={bootHours} dayOfWeek={0} onDeleted={handleDeleted} readOnly={readOnly}/>
      </TableBody>
    </Table>
  </TableContainer>
  )
}


interface BootHoursTableRowsProps {
  bootHours: BootHours[][]
  dayOfWeek: number
  onDeleted: (dayOfWeek: number, idx: number) => void
  readOnly: boolean
}

const BootHoursTableRows: React.FC<BootHoursTableRowsProps> = ({ bootHours, dayOfWeek, onDeleted, readOnly }) => {
  if (bootHours[dayOfWeek].length === 0) {
    return (
      <TableRow>
        <TableCell padding="checkbox" sx={dayOfWeekSx(dayOfWeek)}>{dayOfWeekLabel(dayOfWeek)}</TableCell>
        <TableCell sx={{ minWidth: '150px' }}>ー</TableCell>
        <TableCell>
          <IconButton size="small" disabled>
            <DeleteIcon fontSize="inherit"/>
          </IconButton>
        </TableCell>
      </TableRow>
    )
  } else {
    return (
      <>
        {bootHours[dayOfWeek].map((bh, idx) => (
        <TableRow key={`bh_${dayOfWeek}_${idx}`}>
          {idx === 0 && (<TableCell padding="checkbox" rowSpan={bootHours[dayOfWeek].length} sx={dayOfWeekSx(dayOfWeek)}>{dayOfWeekLabel(dayOfWeek)}</TableCell>)}
          <TableCell sx={{ minWidth: '150px' }}>{bh.from}〜{bh.to}</TableCell>
          <TableCell>
            <Tooltip title="削除">
              <Box component="span">
                <IconButton size="small" disabled={readOnly} onClick={() => onDeleted(dayOfWeek, idx)}>
                  <DeleteIcon fontSize="inherit"/>
                </IconButton>
              </Box>
            </Tooltip>
          </TableCell>
        </TableRow>
        ))}
      </>
    )    
  }
}

const dayOfWeekLabel = (dayOfWeek: number) => {
  switch (dayOfWeek) {
    case 0:
      return '日'
    case 1:
      return '月'
    case 2:
      return '火'
    case 3:
      return '水'
    case 4:
      return '木'
    case 5:
      return '金'
    default:
      return '土'                              
  }
}

const dayOfWeekSx = (dayOfWeek: number) => {
  switch (dayOfWeek) {
    case 0:
      return { color: red[800] }
    case 6:
      return { color: blue[600] }
    default:
      return {}
  }
}
