import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  useParams,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Toolbar,
  Tooltip,
  Button,
} from '@mui/material';
import MaterialTable from '@material-table/core'
import {
  useApp,
} from 'contexts'
import {
  Walkthrough,
  WalkthroughMovie,
  Action,
  actionIdToSegment,
} from 'models'
import {
  MainContents,
  Loading,
  TimestampLabel,
  MovieCard,
  SimpleMaterialTableOptions,
  MaterialTableComponents,
  MaterialTableLocalization,
  WeightLabel,
  BackButton,
  ReloadButton,
  ConfirmDialog,
} from 'components'
import {
  sleep,
} from 'utils'
import {
  sxHeader,
  sxGrid,
  sxSectionTitle,
  sxNotice,
  sxTitle,
} from './WalkthroughsDetail.sx'

const getDesc = (action: Action): string => {
  if (action.detect_info) return action.detect_info.detect_method
  return ''
}

export const WalkthroughsDetail: React.FC = () => {
  const { walkthroughId } = useParams()
  if (walkthroughId === undefined) throw new Error('unhandled routing')
  const location = useLocation()
  const navigate = useNavigate()
  const { getApi, defaultApiErrorHandler } = useApp();
  const [walkthrough, setWalkthrough] = useState<Walkthrough>()
  const [movies, setMovies] = useState<WalkthroughMovie[]>([])
  const [actions, setActions] = useState<Action[]>([])
  const [checkoutConfirmDialogOpen, setCheckoutConfirmDialogOpen] = useState(false)
  const [reload, setReload] = useState(false)

  const load = useCallback(() => {
    Promise.all([
      getApi().fetchWalkthrough(walkthroughId),
      getApi().fetchWalkthroughMovies(walkthroughId),
      getApi().fetchActionsByWalkthroughId(walkthroughId),
    ]).then(([walkthrough, moviesResult, actionsResult]) => {
      console.log('fetchAsync done')
      setWalkthrough(walkthrough)
      setMovies(moviesResult.items)
      setActions(actionsResult.items.filter(act => act.person_status !== 'ignored' && act.item_status !== 'ignored'))
    })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, walkthroughId])
  useEffect(() => {
    load()
  }, [load])
  // reload
  useEffect(() => {
    if (!reload) return
    console.log('reloading...')
    load()
    setReload(false)
  }, [load, reload])

  const handleReload = useCallback(() => {
    setWalkthrough(undefined)
    setMovies([])
    setActions([])
    setReload(true)
  }, [])
  const handleBack = useCallback(() => {
    navigate(`/walkthrough/${location.search}`)
  }, [navigate, location])

  const handleCheckoutButtonClicked = useCallback(() => {
    setCheckoutConfirmDialogOpen(true)
  }, [])
  const handleCloseCheckoutConfirmDialogOpen = useCallback((isOk: boolean) => {
    if (isOk === false) {
      setReload(true)
      setCheckoutConfirmDialogOpen(false)
      return
    }
    setWalkthrough(undefined)
    setCheckoutConfirmDialogOpen(false)
    getApi()
      .chekoutWalkthrough(walkthroughId)
      .then(result => {
        sleep(5000).then(() => {
          console.log(result)
          setReload(true)
        })
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, walkthroughId])

  return (
    <MainContents>
      <Toolbar variant="dense" disableGutters>
        <BackButton onClick={handleBack} />
        <Typography variant="h5" sx={sxHeader} >{walkthroughId}</Typography>
        <ReloadButton marginLeft="auto" onClick={handleReload} />
      </Toolbar>
      <Loading visible={walkthrough === undefined} />
      {walkthrough && (
        <div>
          <Grid container spacing={1} sx={sxGrid}>
            <Grid item xs={4} sm={2} sx={sxTitle}>
              <Typography>入店日時</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>
                <TimestampLabel value={walkthrough.timestamp} format="yyyy/MM/dd HH:mm:ss" />
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2} sx={sxTitle}>
              <Typography>退店日時</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>
                {walkthrough.exit_at ? (
                  <TimestampLabel value={walkthrough.exit_at} format="yyyy/MM/dd HH:mm:ss" />
                ) : (
                  <Tooltip title="強制退店を行います">
                    <Button variant="outlined" color="primary" size="small" onClick={handleCheckoutButtonClicked}>チェックアウト</Button>
                  </Tooltip>
                )}
                <ConfirmDialog
                  open={checkoutConfirmDialogOpen}
                  handleClose={handleCloseCheckoutConfirmDialogOpen}
                  title="チェックアウト確認"
                  contentText="強制退店し、チェックアウトします。店内に残っていないか確認してください。"
                />
              </Typography>
            </Grid>

            <Grid item xs={4} sm={2} sx={sxTitle}>
              <Typography>ユーザID</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>{walkthrough.user_id}</Typography>
            </Grid>
            <Grid item xs={4} sm={2} sx={sxTitle}>
              <Typography>人物ID</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>{walkthrough.person_id}</Typography>
            </Grid>

            <Grid item xs={4} sm={2} sx={sxTitle}>
              <Typography>グループ入店</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>
                {Object.keys(walkthrough.friends).length}人
                {0 < Object.keys(walkthrough.friends).length ? ` (${Object.keys(walkthrough.friends).join(', ')})` : ''}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2} sx={sxTitle}>
              <Typography>イベントモード</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>{walkthrough.event_mode ? (<>YES</>) : (<>-</>)}</Typography>
            </Grid>

          </Grid>

          <Typography variant="h5" sx={sxSectionTitle}>購入商品</Typography>
          <List>
            {walkthrough.items?.map(item => (
              <ListItem key={item.item_code}>
                <ListItemAvatar>
                  <Avatar variant="rounded" src={item.image_url} alt={item.item_name} />
                </ListItemAvatar>
                <ListItemText>{item.item_name} x {item.qty}</ListItemText>
              </ListItem>
            ))}
          </List>
          {walkthrough.items && walkthrough.items.length === 0 && (
            <Typography sx={sxNotice}>購入した商品はありません</Typography>
          )}

          <Typography variant="h5" sx={sxSectionTitle}>映像</Typography>
          {movies.map(movie => (
            <MovieCard
              key={movie.cam_code}
              movieUrl={movie.url}
              title={movie.cam_code}
              maxWidth={600}
              imgHeight={400}
            />
          ))}
          {movies.length === 0 &&
            <Typography sx={sxNotice}>映像はありません。</Typography>
          }

          <Typography variant="h5" sx={sxSectionTitle}>アクション</Typography>
          <MaterialTable
            data={actions}
            columns={[
              {
                title: '時刻',
                field: 'timestamp',
                render: (d) => (
                  <TimestampLabel value={d.timestamp} />
                ),
              },
              {
                title: '棚',
                field: 'segment',
                render: (d) => (<span>{actionIdToSegment(d.action_id)}</span>),
              },
              {
                title: '重量変化',
                field: 'value_diff',
                render: (d) => (<WeightLabel value={d.value_diff} />),
              },
              {
                title: '商品',
                field: 'item_name',
              },
              {
                title: '数量',
                field: 'qty',
              },
              {
                title: '備考',
                field: 'desc',
                render: (d) => (<span>{getDesc(d)}</span>),
              },
            ]}
            onRowClick={(_, rowData) => {
              navigate(`/walkthrough/actions/${rowData?.action_id}`)
            }}
            options={{
              ...SimpleMaterialTableOptions<Action>({
                toolbar: false,
              }),
            }}
            components={{
              ...MaterialTableComponents,
            }}
            localization={{
              ...MaterialTableLocalization,
            }}
          />
        </div>
      )}
    </MainContents>
  )
}