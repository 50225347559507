import {
  NullStore,
} from './Store'

export type EventType = 'lambda' | 'sns';
export type EventName = 'checkin' | 'exit' | 'payment_request' | 'progress' | 'fallback' | 'shelf' | 'config' | 'status' | 'notice';
export const getEventNames = (): EventName[] => (['checkin', 'exit', 'payment_request', 'progress', 'fallback', 'shelf', 'config', 'status', 'notice'])

export interface LambdaEventOption {
  arn: string;
  dry_run: boolean;
}

export interface SnsEventOption {
  arn: string;
}

export interface EventHook {
  store_code: string;
  event_name: EventName;
  type: EventType;
  options: LambdaEventOption | SnsEventOption;
}

export const NullEventHook: EventHook = {
  store_code: NullStore.store_code,
  event_name: 'checkin',
  type: 'lambda',
  options: {
    arn: '',
    dry_run: true,
  },
}