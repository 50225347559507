import { useState, useRef, useEffect } from 'react'

export const useInterval = (
  callback: () => void,
  delay: number,
  initalDisabled?: boolean
) => {
  const savedCallback = useRef(() => {})
  // default Enabled
  const [enabled, setEnabled] = useState(!(initalDisabled || false))

  // save target function
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // set interval
  useEffect(() => {
    console.log('useInterval## set interval')
    if (enabled) {
      // execute interval
      const id = setInterval(() => savedCallback.current(), delay)
      return () => clearInterval(id)
    } else {
      return () => {}
    }
  }, [delay, enabled])

  return { setEnabled }
}

export default useInterval
