import React from 'react'
import {
  Container,
  Typography,
  Alert,
  AlertTitle,
} from '@mui/material';

export const Unauthorized: React.FC = () => {
  return (
    <Container sx={{ padding: 4 }}>
      <Alert severity="warning" variant="outlined">
        <AlertTitle>Unauthorized</AlertTitle>
        <Typography>認証に失敗しました。再ログインしてください。</Typography>
      </Alert>
    </Container>
  )
}
