import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  useQueryParam,
  StringParam,
} from 'use-query-params'
import {
  Box,
  Toolbar,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Collapse,
} from '@mui/material';
import {
  AddBox as Add,
  Settings as SettingsIcon,
  Link as ControlledIcon,
  LinkOff as ControlledOffIcon,
  RestartAlt as RestartServiceIcon,
  PowerSettingsNew as RebootIcon,
  KeyboardArrowDown as ArrowDownIcon,
  KeyboardArrowRight as ArrowRightIcon,
  PowerOff as FaultIsolationIcon,
} from '@mui/icons-material'
import {
  useApp,
} from 'contexts'
import {
  Store,
  NullStore,
  WeightSensorDevice,
  DevicePowerStatus,
  DeviceServiceStatus,
  DeviceServiceError,
} from 'models'
import {
  Iot,
  subscribe,
  DeviceStatusValue,
} from 'iot'
import {
  MainContents,
  StoresSelector,
  ReloadButton,
  Loading,
  ConfirmDialog,
  ProgressScreen,
  DeviceStatusMark,
} from 'components'
import { PassregiApi } from 'api'
import {
  DeviceDetailPanel,
  DeviceRegisterDialog,
  WeightSensorSettingsDialog,
} from './components'

export const DeviceWeightSensor: React.FC = () => {
  const [storeCode, setStoreCode] = useQueryParam(
    's',
    StringParam,
  )
  const [reload, setReload] = useState(false)
  const [devices, setDevices] = useState<WeightSensorDevice[]>()
  const [registerDialogOpen, setRegisterDialogOpen] = useState(false)
  const [updating, setUpdating] = useState(false)
  const { getApi, getIot, defaultApiErrorHandler } = useApp()

  const load = useCallback(() => {
    getApi()
      .fetchWeightSensorDevices(storeCode)
      .then(result => {
        setDevices(result.items)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, getIot, defaultApiErrorHandler, storeCode])
  useEffect(() => {
    load()
  }, [load])
  // reload
  useEffect(() => {
    if (!reload) return
    setDevices(undefined)
    load()
    setReload(false)
  }, [load, reload])

  const onStoreChanged = useCallback((store: Store) => {
    setDevices(undefined)
    setStoreCode((store.store_code !== NullStore.store_code ? store.store_code : undefined))
  }, [setStoreCode])
  const handleReload = useCallback(() => {
    setDevices(undefined)
    setReload(true)
  }, [])
  const onRegistered = useCallback(() => {
    handleReload()
    setRegisterDialogOpen(false)
  }, [handleReload])

  return (
    <MainContents>
      <Toolbar variant="dense" disableGutters>
        <StoresSelector
          storeCode={storeCode}
          onSeleced={onStoreChanged}
          enableNotSelected
          notSelectedLabel="全店舗"
        />
        <Tooltip title="重量センサーの登録">
          <IconButton
            aria-label="register"
            color="primary"
            sx={{ marginLeft: 'auto' }}
            onClick={() => setRegisterDialogOpen(true)}
            size="small"
          >
            <Add />
          </IconButton>
        </Tooltip>
        <DeviceRegisterDialog
          type="ws"
          open={registerDialogOpen}
          onClose={() => setRegisterDialogOpen(false)} 
          onRegistered={onRegistered}
        />
        <ReloadButton size="small" onClick={handleReload} />
      </Toolbar>
      <Loading visible={devices === undefined} marginBottom={10} />
      {devices && (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="none"></TableCell>
              <TableCell padding="checkbox"></TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>デバイス名</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>店舗コード</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>名称</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {devices.map(d => (
            <DeviceTableRow key={d.thing_name} device={d} getApi={getApi} getIot={getIot} setUpdating={setUpdating} setReload={setReload}/>
          ))}
          </TableBody>
        </Table>
        {devices.length === 0 && (
        <Box sx={{ margin: 1 }}>重量センサーがありません</Box>
        )}
      </TableContainer>
      )}
      <ProgressScreen open={updating} />
    </MainContents>
  )
}

interface DeviceTableRowProps {
  device: WeightSensorDevice,
  getApi: () => PassregiApi,
  getIot: () => Iot,
  setUpdating: (updating: boolean) => void,
  setReload: (updating: boolean) => void,
}

const DeviceTableRow: React.FC<DeviceTableRowProps> = ({ device, getApi, getIot, setUpdating, setReload }) => {
  const [powerStatus, setPowerStatus] = useState<DevicePowerStatus | undefined>(device.power_status)
  const [serviceStatus, setServiceStatus] = useState<DeviceServiceStatus | undefined>(device.service_status)
  const [serviceError, setServiceError] = useState<DeviceServiceError | undefined>(device.service_error)
  const [openDetail, setOpenDetail] = useState(false)
  const [openSettingDialog, setOpenSettingDialog] = useState(false)
  const [openControlledDialog, setOpenControlledDialog] = useState(false)
  const [openRestartServiceDialog, setOpenRestartServiceDialog] = useState(false)
  const [openRebootDialog, setOpenRebootDialog] = useState(false)
  const [openFaultIsolationIconDialog, setOpenFaultIsolationIconDialog] = useState(false)
  // subscribe device shadow update
  useEffect(() => {
    const subscriber = subscribe<DeviceStatusValue>(`$aws/things/${device.thing_name}/shadow/name/status/update/documents`, (d) => {
      const status = d.value.current.state.reported;
      console.log(status)
      setPowerStatus(status.power)
      setServiceStatus(status.service)
      setServiceError(status.service_error)
      device.power_status = status.power
      device.service_status = status.service
      device.service_error = status.service_error
    })
    return () => {
      subscriber.disconnect()
    }
  }, [device])

  const handleSettingButtonClicked = useCallback(() => {
    setOpenSettingDialog(true)
  }, [])
  const handleSettingDialogClosed = useCallback(() => {
    setOpenSettingDialog(false)
  }, [])

  const handleUpdated = useCallback((device: WeightSensorDevice, restartService: boolean) => {
    setOpenSettingDialog(false)
    if (restartService) {
      getApi()
        .rebootDevice(device.thing_name)
        .finally(() => {
          setReload(true)
        })
    } else {
      setReload(true)
    }
  }, [getApi, setReload])

  const handleControlledButtonClicked = useCallback(() => {
    setOpenControlledDialog(true)
  }, [])
  const handleControlled = useCallback((isOk: boolean) => {
    setOpenControlledDialog(false)
    if (!isOk) return
    setUpdating(true)
    const controlled = (device.controlled === false ? true : false)
    Promise.all([
      getApi().updateDeviceControlled(device.thing_name, controlled),
      getIot().updateThingShadow({ // 後方互換
        thingName: device.thing_name,
        shadowName: 'status',
        payload: JSON.stringify({
          state: {
            desired: {
              controlled: controlled,
            },
          },
        }),
      }),
    ]).finally(() => {
      setUpdating(false)
      setReload(true)
    })
  }, [device, getApi, getIot, setUpdating])

  const handleRestartServiceButtonClicked = useCallback(() => {
    setOpenRestartServiceDialog(true)
  }, [])
  const handleRestartService = useCallback((isOk: boolean) => {
    setOpenRestartServiceDialog(false)
    if (!isOk) return
    setUpdating(true)
    getApi()
      .restartServiceDevice(device.thing_name)
      .then(() => {
      })
      .finally(() => {
        setUpdating(false)
      })
  }, [device, getApi, setUpdating])

  const handleRebootButtonClicked = useCallback(() => {
    setOpenRebootDialog(true)
  }, [])
  const handleReboot = useCallback((isOk: boolean) => {
    setOpenRebootDialog(false)
    if (!isOk) return
    setUpdating(true)
    getApi()
      .rebootDevice(device.thing_name)
      .then(() => {
      })
      .finally(() => {
        setUpdating(false)
      })
  }, [device, getApi, setUpdating])

  const handleFaultIsolationIconButtonClicked = useCallback(() => {
    setOpenFaultIsolationIconDialog(true)
  }, [])
  const handleFaultIsolation = useCallback((isOk: boolean) => {
    setOpenFaultIsolationIconDialog(false)
    if (!isOk) return
    setUpdating(true)
    getApi()
      .faultIsolationDevice(device.thing_name)
      .then(() => {
      })
      .finally(() => {
        setUpdating(false)
      })
  }, [device, getApi, setUpdating])
  
  return (
  <React.Fragment>
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell padding="none" align="center" sx={{ width: 30 }}>
        <IconButton onClick={() => setOpenDetail(!openDetail)} sx={{ padding: 0 }}>
          {openDetail ? (<ArrowDownIcon/>) : (<ArrowRightIcon/>)}
        </IconButton>
      </TableCell>
      <TableCell padding="none" align="center" sx={{ width: 30 }}>
        <DeviceStatusMark
          powerStatus={powerStatus}
          serviceStatus={serviceStatus}
          serviceError={serviceError}
        />
      </TableCell>
      <TableCell>
        {device.thing_name}
      </TableCell>
      <TableCell>
        {device.store_code}
      </TableCell>
      <TableCell>
        {device.properties.name}
      </TableCell>
      <TableCell padding="none" align="right">
        <Tooltip title="設定変更">
          <IconButton size="small" color="primary" onClick={handleSettingButtonClicked}>
            <SettingsIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={device.controlled !== false ? '制御無効化' : '制御有効化'}>
          <IconButton size="small" color={device.controlled !== false ? 'primary' : 'secondary'} onClick={handleControlledButtonClicked}>
            {device.controlled !== false ? (<ControlledIcon/>) : (<ControlledOffIcon/>)}
          </IconButton>
        </Tooltip>
        <Tooltip title={powerStatus !== 'on' ? 'デバイス停止中' : 'サービス再起動'}>
          <Box component="span">
            <IconButton size="small" color={powerStatus !== 'on' ? 'default' : 'primary'} onClick={handleRestartServiceButtonClicked} disabled={powerStatus !== 'on'}>
              <RestartServiceIcon />
            </IconButton>
          </Box>
        </Tooltip>
        <Tooltip title={powerStatus !== 'on' ? 'デバイス停止中' : 'デバイス再起動'}>
          <Box component="span">
            <IconButton size="small" color={powerStatus !== 'on' ? 'default' : 'primary'} onClick={handleRebootButtonClicked} disabled={powerStatus!== 'on'}>
              <RebootIcon />
            </IconButton>
          </Box>
        </Tooltip>
        <Tooltip title="障害分離">
          <Box component="span">
            <IconButton size="small" color={powerStatus !== 'error' ? 'default' : 'primary'} onClick={handleFaultIsolationIconButtonClicked} disabled={powerStatus !== 'error'}>
              <FaultIsolationIcon/>
            </IconButton>
          </Box>
        </Tooltip>
      </TableCell>
      <WeightSensorSettingsDialog
        open={openSettingDialog}
        device={device}
        onClose={handleSettingDialogClosed}
        onUpdated={handleUpdated}
      />
      <ConfirmDialog
        title={(device.controlled !== false) ? '制御無効化' : '制御有効化'}
        open={openControlledDialog}
        handleClose={handleControlled}
      />
      <ConfirmDialog
        title="サービス再起動"
        open={openRestartServiceDialog}
        handleClose={handleRestartService}
      />
      <ConfirmDialog
        title="デバイス再起動"
        open={openRebootDialog}
        handleClose={handleReboot}
      />
      <ConfirmDialog
        title="障害分離"
        open={openFaultIsolationIconDialog}
        handleClose={handleFaultIsolation}
      />
    </TableRow>
    <TableRow>
      <TableCell colSpan={6} padding="none">
        <Collapse in={openDetail} unmountOnExit>
          <DeviceDetailPanel
            powerStatus={powerStatus}
            serviceStatus={serviceStatus}
            serviceError={serviceError}
          />
        </Collapse>
      </TableCell>
    </TableRow>
  </React.Fragment>
  )
}