import React from 'react'
import { Box, CircularProgress } from '@mui/material'

interface LoadingProps {
  visible?: boolean
  marginTop?: number
  marginBottom?: number
}

export const Loading: React.FC<LoadingProps> = ({ visible, marginTop = 15, marginBottom }) => {
  if (visible === undefined || visible === true) {
    return (
      <Box display="flex" marginTop={marginTop} marginBottom={marginBottom}>
        <CircularProgress
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      </Box>
    )
  } else {
    return <></>
  }
}
