import React from 'react'
import {
  Routes,
  Route,
} from 'react-router-dom'
import { AnalysisFlows } from './AnalysisFlows'
import { AnalysisFlowDetail } from './AnalysisFlowDetail'
import { AnalysisQuantities } from './AnalysisQuantities'
import { AnalysisReplay } from './AnalysisReplay'
import { AnalysisReplayDetail } from './AnalysisReplayDetail'

export const AnalysisRoutes: React.FC = ()=> (
<Routes>
  <Route path="flows">
    <Route index element={<AnalysisFlows/>}/>
    <Route path=":flowId" element={<AnalysisFlowDetail/>}/>
  </Route>
  <Route path="quantities" element={<AnalysisQuantities/>}/>
  <Route path="replay">
    <Route index element={<AnalysisReplay/>}/>
    <Route path=":replayId" element={<AnalysisReplayDetail/>}/>
  </Route>
</Routes>
)
