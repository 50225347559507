import React, {
  useEffect,
  useState,
  useCallback,
} from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
  Typography,
  SelectChangeEvent,
} from '@mui/material';
import {
  BootHours,
} from 'models'
import {
  sx,
} from '../sx'

export const checkConflict = (from: number, to: number, others: BootHours[]): boolean => {
  for (let i = 0, len = others.length; i < len; i++) {
    const other = others[i]
    const otherFrom = Number(other.from.replace(':', ''))
    const otherTo = Number(other.to.replace(':', ''))
    if (otherFrom - 5 <= to && to <= otherTo) return false
    if (otherFrom <= from && from <= otherTo + 5) return false
    if (from <= otherFrom && otherTo <= to) return false
  }
  return true
}

interface AddBootHoursDialogProps {
  open: boolean
  onClose: () => void
  onAdded: (dayOfWeek: number, bootHours: BootHours) => void
  bootHours: BootHours[][]
}

export const AddBootHoursDialog: React.FC<AddBootHoursDialogProps> = ({ open, onClose, onAdded, bootHours }) => {
  const [dayOfWeekValue, setDayOfWeekValue] = useState('1')
  const [fromValue, setFromValue] = useState('09:30')
  const [toValue, setToValue] = useState('17:00')
  const [error, setError] = useState<string>()

  const validate = useCallback((dayOfWeekValue: string, fromValue: string, toValue: string) => {
    if (fromValue === '' || toValue === '') {
      setError('起動時間と終了時間を入力してください')
      return
    }
    const dayOfWeek = Number(dayOfWeekValue)
    const from = Number(fromValue.replace(':', ''))
    const to = Number(toValue.replace(':', ''))
    const others = bootHours[dayOfWeek]
    console.log({ dayOfWeek, from, to, others })
    if (to < from) {
      setError('終了時間は起動時間より後にしてください')
    } else if (to - from < 5) {
      setError('終了時間は起動時間より5分以上後にしてください')
    } else if (!checkConflict(from, to, others)) {
      setError('他の起動時間と重複できません')
    } else {
      setError(undefined)
    }
  }, [bootHours])

  useEffect(() => {
    if (!open) return
    setDayOfWeekValue('1')
    setFromValue('09:30')
    setToValue('17:00')
    validate('1', '09:30', '17:00')
  }, [open])

  const dayOfWeekChanged = useCallback((evt: SelectChangeEvent) => {
    const newDayOfWeekValue = evt.target.value
    console.log({ newDayOfWeekValue })
    setDayOfWeekValue(newDayOfWeekValue)
    validate(newDayOfWeekValue, fromValue, toValue)
  }, [validate, fromValue, toValue])
  const fromChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newFromValue = event.target.value
    console.log({ newFromValue })
    setFromValue(newFromValue)
    validate(dayOfWeekValue, newFromValue, toValue)
  }, [validate, dayOfWeekValue, toValue])
  const toChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newToValue = event.target.value
    console.log({ newToValue })
    setToValue(newToValue)
    validate(dayOfWeekValue, fromValue, newToValue)
  }, [validate, dayOfWeekValue, fromValue])
  const onSubmit = useCallback(() => {
    onAdded(Number(dayOfWeekValue), {
      from: fromValue,
      to: toValue,
    })
  }, [onAdded, dayOfWeekValue, fromValue, toValue])
  const handleCanceled = useCallback(() => {
    console.log('handleCanceled')
    onClose()
  }, [onClose])

  return (
  <Dialog
    maxWidth="sm"
    fullWidth
    open={open}
    onClose={onClose}
    disableEscapeKeyDown
  >
    <DialogTitle>起動スケジュール追加</DialogTitle>
    <DialogContent>
      <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
        <FormControl variant="standard" size="small" sx={{ marginLeft: 1, marginRight: 1 }}>
          <Select
            variant="outlined"
            value={dayOfWeekValue}
            error={Boolean(error)}
            onChange={dayOfWeekChanged}
            sx={{ marginLeft: 1, marginRight: 1 }}
          >
            <MenuItem value={1}>月</MenuItem>
            <MenuItem value={2}>火</MenuItem>
            <MenuItem value={3}>水</MenuItem>
            <MenuItem value={4}>木</MenuItem>
            <MenuItem value={5}>金</MenuItem>
            <MenuItem value={6}>土</MenuItem>
            <MenuItem value={0}>日</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={sx.formControl}>
          <TextField
            hiddenLabel
            variant="outlined"
            type="time"
            size="small"
            margin="dense"
            value={fromValue}
            onChange={fromChanged}
            error={Boolean(error)}
            sx={{ marginLeft: 1, marginRight: 1 }}
          />
        </FormControl>
        <Box component="span" sx={{ marginLeft: 1, marginRight: 1 }}>〜</Box>
        <FormControl sx={sx.formControl}>
          <TextField
            hiddenLabel
            variant="outlined"
            type="time"
            size="small"
            margin="dense"
            value={toValue}
            onChange={toChanged}
            error={Boolean(error)}
            sx={{ marginLeft: 1, marginRight: 1 }}
          />
        </FormControl>
      </Box>
      <Box sx={{ marginTop: 1, marginLeft: 2 }}>
        <Typography variant="body1" color="error">{error}</Typography>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button
        color="inherit"
        variant="contained"
        onClick={handleCanceled}
        sx={sx.action}
      >
        キャンセル
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={onSubmit}
        disabled={Boolean(error)}
        sx={sx.action}
      >
        OK
      </Button>
    </DialogActions>
  </Dialog>
  )
}