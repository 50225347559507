import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  useParams,
} from 'react-router-dom'
import {
  Grid,
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  Theme,
  SxProps,
} from '@mui/material';
import {
  useApp,
} from 'contexts'
import {
  useInterval,
} from 'hooks'
import {
  Store,
  Configuration,
  StoreEnv,
  StoreShadowStatus,
  Device,
  isEnvActive,
  UnixTimestamp,
  PlugStatus,
  findEdgeDevice,
} from 'models'
import {
  getStateFromThingShadow,
} from 'utils'
import {
  Loading,
  ConfirmDialog,
  StoreStatus,
  StoreModeLabel,
} from 'components'
import {
  subscribe,
} from 'iot'
import {
  DevicesTableModel,
  DevicesTable,
  PlugsTableModel,
  PlugsTable,
  EdgeTable,
  EdgeTableModel,
  StoreStatusDurationLabel,
} from './dashboard'
import {
  currentUnixTimestamp,
} from 'utils'

const isEnableDeviceShutdown = (devices: Device[]): boolean => {
  return devices.length !== 0 && devices.filter(d => d.power_status === 'off').length === 0
}
const isEnableDeviceReboot = (devices: Device[]): boolean => {
  return devices.length !== 0
}
const isEnableDeviceRestartService = (devices: Device[]): boolean => {
  return devices.length !== 0 && devices.filter(d => d.power_status === 'off').length === 0
}
const isEnableDeviceStopService = (devices: Device[]): boolean => {
  return devices.length !== 0 && devices.filter(d => d.power_status === 'off').length === 0
}
const isEnableDeviceFaultIsolation = (devices: Device[]): boolean => {
  return devices.length !== 0 && devices.filter(d => d.power_status === 'error').length === devices.length
}
const getCheckedDevices = (model: DevicesTableModel) => {
  return model.devices.filter(d => d.checked)
}

const sxCategory: SxProps<Theme> = {
  display: 'flex',
  marginTop: 2,
  paddingBottom: 0.5,
  marginBottom: 1,
  borderBottom: '1px solid lightgray',
};

export const StoresDashboard: React.FC = () => {
  const { storeCode } = useParams()
  if (storeCode === undefined) throw new Error('unhandled routing')
  const [store, setStore] = useState<Store>()
  const [now, setNow] = useState<UnixTimestamp>(currentUnixTimestamp())
  const [configuration, setConfiguration] = useState<Configuration>()
  const [storeEnv, setStoreEnv] = useState<StoreEnv>()
  const [storeStatus, setStoreStatus] = useState<StoreShadowStatus>({
    devices: 'stop',
  });
  const [envActive, setEnvActive] = useState(false)
  const [edgeTableModel, setEdgeTableModel] = useState<EdgeTableModel>()
  const [devicesTableModel, setDevicesTableModel] = useState<DevicesTableModel>()
  const [plugsTableModel, setPlugsTableModel] = useState<PlugsTableModel>()
  const [operationMenuAnchorEl, setOperationMenuAnchorEl] = useState(null)
  const [enableBoot, setEnableBoot] = useState(false)
  const [enableShutdown, setEnableShutdown] = useState(false)
  const [enableReboot, setEnableReboot] = useState(false)
  const [enableRestartService, setEnableRestartService] = useState(false)
  const [openConfirmBoot, setOpenConfirmBoot] = useState(false)
  const [openConfirmShutdown, setOpenConfirmShutdown] = useState(false)
  const [openConfirmReboot, setOpenConfirmReboot] = useState(false)
  const [openConfirmRestartService, setOpenConfirmRestartService] = useState(false)
  const [edgeOperationMenuAnchorEl, setEdgeOperationMenuAnchorEl] = useState(null)
  const [enableEdgeRestart, setEdgeRestart] = useState(false)
  const [openConfirmEdgeRestart, setOpenConfirmEdgeRestart] = useState(false)
  const [deviceOperationMenuAnchorEl, setDeviceOperationMenuAnchorEl] = useState(null)
  const [enableDeviceShutdown, setEnableDeviceShutdown] = useState(false)
  const [enableDeviceReboot, setEnableDeviceReboot] = useState(false)
  const [enableDeviceRestartService, setEnableDeviceRestartService] = useState(false)
  const [enableDeviceStopService, setEnableDeviceStopService] = useState(false)
  const [enableDeviceFaultIsolation, setEnableDeviceFaultIsolation] = useState(false)
  const [openConfirmDeviceShutdown, setOpenConfirmDeviceShutdown] = useState(false)
  const [openConfirmDeviceReboot, setOpenConfirmDeviceReboot] = useState(false)
  const [openConfirmDeviceRestartService, setOpenConfirmDeviceRestartService] = useState(false)
  const [openConfirmDeviceStopService, setOpenConfirmDeviceStopService] = useState(false)
  const [openConfirmDeviceFaultIsolation, setOpenConfirmDeviceFaultIsolation] = useState(false)
  const [plugOperationMenuAnchorEl, setPlugOperationMenuAnchorEl] = useState(null)
  const [enablePlugOn, setEnablePlugOn] = useState(false)
  const [enablePlugOff, setEnablePlugOff] = useState(false)
  const [openConfirmPlugOn, setOpenConfirmPlugOn] = useState(false)
  const [openConfirmPlugOff, setOpenConfirmPlugOff] = useState(false)
  const { getApi, getIot, defaultApiErrorHandler } = useApp()

  useEffect(() => {
    setNow(currentUnixTimestamp())
    Promise.all([
      getApi().fetchStore(storeCode),
      getApi().fetchConfiguration(storeCode),
      getApi().fetchBootConfiguration(storeCode),
      getApi().fetchEnv(storeCode),
      getApi().fetchDevices(storeCode),
    ])
      .then(([store, config, bootConfig, env, devices]) => {
        setStore(store)
        setConfiguration(config)
        setStoreEnv(env)
        setEnvActive(isEnvActive(env, true))
        setDevicesTableModel({
          devices: devices.items.map(d => ({
            checked: false,
            ...d,
          })),
        })
        const edgeDevice = findEdgeDevice(devices.items);
        if (edgeDevice === undefined) {
          setPlugsTableModel({
            plugs: [],
          })
          setEdgeTableModel({
          });
          return;
        }
        getIot().getThingShadow({
          thingName: edgeDevice.thing_name,
          shadowName: 'status',
        }).then(result => {
          const status = getStateFromThingShadow(result)
          const plugStatuses: { [plugName: string]: PlugStatus } = {}
          for (let plugName in status.plugs) {
            plugStatuses[plugName] = status.plugs[plugName]
          }
          console.log(status)
          setEdgeTableModel({
            thing_name: edgeDevice.thing_name,
            action_status: status['edge_action'],
            passage_status: status['edge_passage'],
            logs_status: status['edge_logs'],
            weight_status: status['edge_weight'],
            tracking_status: status['edge_tracking'],
          });
          setPlugsTableModel({
            plugs: bootConfig.plugs.map(p => ({
              checked: false,
              status: plugStatuses[p.name] || 'unknown',
              ...p,
            })),
            thing_name: edgeDevice.thing_name,
          });
        })
      })
      .catch(defaultApiErrorHandler)
    getIot().getThingShadow({
      thingName: `store_${storeCode}`,
      shadowName: 'status',
    })
      .then(result => {
        const state = getStateFromThingShadow(result)
        console.log(state)
        setStoreStatus(state);
      })
      .catch(_e => {
        console.log(`Shadow not found: ${storeCode}`)
        setStoreStatus({
          devices: 'shadow_not_found',
        })
      })
  }, [storeCode, getApi, getIot, defaultApiErrorHandler])
  // set current time (1sec)
  useInterval(() => {
    setNow(currentUnixTimestamp())
  }, 1000)
  // subscribe store shadow update
  useEffect(() => {
    console.log(`subscribe $aws/things/store_${storeCode}/shadow/name/status/update/documents`) // Topic
    const subscriber = subscribe<any>(`$aws/things/store_${storeCode}/shadow/name/status/update/documents`, (d) => {
      const shadow = d.value?.current?.state?.reported;
      setStoreStatus(shadow);
    })
    return () => {
      console.log('store out')
      subscriber.disconnect()
    }
  }, [storeCode])
  useEffect(() => {
    const status = storeStatus.devices;
    setEnableBoot(status === 'stop')
    setEnableShutdown(status === 'running' || status === 'service_error' || status === 'error')
    setEnableReboot(status === 'running' || status === 'service_error' || status === 'error')
    setEnableRestartService(status === 'running' || status === 'service_error')
  }, [storeStatus])
  useEffect(() => {
    const enabled = edgeTableModel?.thing_name !== undefined
    setEdgeRestart(enabled)
  }, [edgeTableModel])

  // Store Operation
  const handleOpenOperationMenuClicked = useCallback((event) => {
    setOperationMenuAnchorEl(event.currentTarget)
  }, [])
  const handleOpenOperationMenuClosed = useCallback(() => {
    setOperationMenuAnchorEl(null)
  }, [])
  const handleBootClicked = useCallback(() => {
    setOperationMenuAnchorEl(null)
    setOpenConfirmBoot(true)
  }, [])
  const handleBoot = useCallback((isOk: boolean) => {
    setOpenConfirmBoot(false)
    if (!isOk) return
    getApi()
      .bootStore(storeCode)
      .then(() => {
        console.log('OK')
      })
      .catch(defaultApiErrorHandler)
  }, [storeCode, getApi, defaultApiErrorHandler])
  const handleShutdownClicked = useCallback(() => {
    setOperationMenuAnchorEl(null)
    setOpenConfirmShutdown(true)
  }, [])
  const handleShutdown = useCallback((isOk: boolean) => {
    setOpenConfirmShutdown(false)
    if (!isOk) return
    getApi()
      .shutdownStore(storeCode)
      .then(() => {
        console.log('OK')
      })
      .catch(defaultApiErrorHandler)
  }, [storeCode, getApi, defaultApiErrorHandler])
  const handleRebootClicked = useCallback(() => {
    setOperationMenuAnchorEl(null)
    setOpenConfirmReboot(true)
  }, [])
  const handleReboot = useCallback((isOk: boolean) => {
    setOpenConfirmReboot(false)
    if (!isOk) return
    getApi()
      .rebootStore(storeCode)
      .then(() => {
        console.log('OK')
      })
      .catch(defaultApiErrorHandler)
  }, [storeCode, getApi, defaultApiErrorHandler])
  const handleRestartServiceClicked = useCallback(() => {
    setOperationMenuAnchorEl(null)
    setOpenConfirmRestartService(true)
  }, [])
  const handleRestartService = useCallback((isOk: boolean) => {
    setOpenConfirmRestartService(false)
    if (!isOk) return
    getApi()
      .restartServiceStore(storeCode)
      .then(() => {
        console.log('OK')
      })
      .catch(defaultApiErrorHandler)
  }, [storeCode, getApi, defaultApiErrorHandler])
  // Edge Operation
  const handleOpenEdgeOperationMenuClicked = useCallback((event) => {
    setEdgeOperationMenuAnchorEl(event.currentTarget)
  }, [])
  const handleOpenEdgeOperationMenuClosed = useCallback(() => {
    setEdgeOperationMenuAnchorEl(null)
  }, [])
  const handleEdgeRestartClicked = useCallback(() => {
    setEdgeOperationMenuAnchorEl(null)
    setOpenConfirmEdgeRestart(true)
  }, [])
  const handleEdgeRestart = useCallback((isOk: boolean) => {
    setOpenConfirmEdgeRestart(false)
    if (!isOk) return
    const thingName = edgeTableModel?.thing_name
    if (thingName === undefined) return
    getApi()
      .restartDeviceEdge(thingName)
      .then(() => {
        console.log('OK')
      })
      .catch(defaultApiErrorHandler)
  }, [edgeTableModel, getApi, defaultApiErrorHandler])
  // Device Operation
  const handleOpenDeviceOperationMenuClicked = useCallback((event) => {
    setDeviceOperationMenuAnchorEl(event.currentTarget)
  }, [])
  const handleOpenDeviceOperationMenuClosed = useCallback(() => {
    setDeviceOperationMenuAnchorEl(null)
  }, [])
  const handleDeviceShutdownClicked = useCallback(() => {
    setDeviceOperationMenuAnchorEl(null)
    setOpenConfirmDeviceShutdown(true)
  }, [])
  const handleDeviceShutdown = useCallback((isOk: boolean) => {
    setOpenConfirmDeviceShutdown(false)
    if (!isOk || !devicesTableModel) return
    Promise.all(
      getCheckedDevices(devicesTableModel).map(d => getApi().shutdownDevice(d.thing_name)),
    )
      .then(() => {
        console.log('OK')
      })
      .catch(defaultApiErrorHandler)
  }, [devicesTableModel, getApi, defaultApiErrorHandler])
  const handleDeviceRebootClicked = useCallback(() => {
    setDeviceOperationMenuAnchorEl(null)
    setOpenConfirmDeviceReboot(true)
  }, [])
  const handleDeviceReboot = useCallback((isOk: boolean) => {
    setOpenConfirmDeviceReboot(false)
    if (!isOk || !devicesTableModel) return
    Promise.all(
      getCheckedDevices(devicesTableModel).map(d => getApi().rebootDevice(d.thing_name)),
    )
      .then(() => {
        console.log('OK')
      })
      .catch(defaultApiErrorHandler)
  }, [devicesTableModel, getApi, defaultApiErrorHandler])
  const handleDeviceRestartServiceClicked = useCallback(() => {
    setDeviceOperationMenuAnchorEl(null)
    setOpenConfirmDeviceRestartService(true)
  }, [])
  const handleDeviceRestartService = useCallback((isOk: boolean) => {
    setOpenConfirmDeviceRestartService(false)
    if (!isOk || !devicesTableModel) return
    Promise.all(
      getCheckedDevices(devicesTableModel).map(d => getApi().restartServiceDevice(d.thing_name)),
    )
      .then(() => {
        console.log('OK')
      })
      .catch(defaultApiErrorHandler)
  }, [devicesTableModel, getApi, defaultApiErrorHandler])
  const handleDeviceStopServiceClicked = useCallback(() => {
    setDeviceOperationMenuAnchorEl(null)
    setOpenConfirmDeviceStopService(true)
  }, [])
  const handleDeviceStopService = useCallback((isOk: boolean) => {
    setOpenConfirmDeviceStopService(false)
    if (!isOk || !devicesTableModel) return
    Promise.all(
      getCheckedDevices(devicesTableModel).map(d => getApi().stopServiceDevice(d.thing_name)),
    )
      .then(() => {
        console.log('OK')
      })
      .catch(defaultApiErrorHandler)
  }, [devicesTableModel, getApi, defaultApiErrorHandler])
  const handleDeviceFaultIsolationClicked = useCallback(() => {
    setDeviceOperationMenuAnchorEl(null)
    setOpenConfirmDeviceFaultIsolation(true)
  }, [])
  const handleDeviceFaultIsolation = useCallback((isOk: boolean) => {
    setOpenConfirmDeviceFaultIsolation(false)
    if (!isOk || !devicesTableModel) return
    Promise.all(
      getCheckedDevices(devicesTableModel).map(d => getApi().faultIsolationDevice(d.thing_name)),
    )
      .then(() => {
        console.log('OK')
      })
      .catch(defaultApiErrorHandler)
  }, [devicesTableModel, getApi, defaultApiErrorHandler])
  const handleDevicesChanged = useCallback(() => {
    if (!devicesTableModel) return
    const checkedDevices = getCheckedDevices(devicesTableModel)
    setEnableDeviceShutdown(isEnableDeviceShutdown(checkedDevices))
    setEnableDeviceReboot(isEnableDeviceReboot(checkedDevices))
    setEnableDeviceRestartService(isEnableDeviceRestartService(checkedDevices))
    setEnableDeviceStopService(isEnableDeviceStopService(checkedDevices))
    setEnableDeviceFaultIsolation(isEnableDeviceFaultIsolation(checkedDevices))
  }, [devicesTableModel])

  // Plug Operation
  const handleOpenPlugOperationMenuClicked = useCallback((event) => {
    setPlugOperationMenuAnchorEl(event.currentTarget)
  }, [])
  const handleOpenPlugOperationMenuClosed = useCallback(() => {
    setPlugOperationMenuAnchorEl(null)
  }, [])
  const handlePlugOnClicked = useCallback(() => {
    setPlugOperationMenuAnchorEl(null)
    setOpenConfirmPlugOn(true)
  }, [])
  const handlePlugOffClicked = useCallback(() => {
    setPlugOperationMenuAnchorEl(null)
    setOpenConfirmPlugOff(true)
  }, [])
  const handlePlugOn = useCallback((isOk: boolean) => {
    setOpenConfirmPlugOn(false)
    if (!isOk || !plugsTableModel) return
    Promise.all(
      plugsTableModel.plugs.filter(p => p.checked).map(p => getApi().onPlug(storeCode, p.name))
    )
      .then(() => {
        console.log('OK')
      })
      .catch(defaultApiErrorHandler)
  }, [storeCode, plugsTableModel, getApi, defaultApiErrorHandler])
  const handlePlugOff = useCallback((isOk: boolean) => {
    setOpenConfirmPlugOff(false)
    if (!isOk || !plugsTableModel) return
    Promise.all(
      plugsTableModel.plugs.filter(p => p.checked).map(p => getApi().offPlug(storeCode, p.name))
    )
      .then(() => {
        console.log('OK')
      })
      .catch(defaultApiErrorHandler)
  }, [storeCode, plugsTableModel, getApi, defaultApiErrorHandler])
  const handlePlugsChanged = useCallback(() => {
    if (!plugsTableModel) return
    const checkedPlugs = plugsTableModel.plugs.filter(p => p.checked)
    console.log(checkedPlugs)
    setEnablePlugOn(0 < checkedPlugs.length)
    setEnablePlugOff(0 < checkedPlugs.length)
  }, [plugsTableModel])

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box sx={sxCategory}>
            <Typography component="div">店舗</Typography>
            <Button variant="outlined" size="small" sx={{ marginLeft: 'auto', marginRight: 1 }} onClick={handleOpenOperationMenuClicked}>操作</Button>
            <Menu
              open={Boolean(operationMenuAnchorEl)}
              anchorEl={operationMenuAnchorEl}
              onClose={handleOpenOperationMenuClosed}
            >
              <MenuItem disabled={!(envActive && enableBoot)} onClick={handleBootClicked}>起動</MenuItem>
              <MenuItem disabled={!(envActive && enableShutdown)} onClick={handleShutdownClicked}>停止</MenuItem>
              <MenuItem disabled={!(envActive && enableReboot)} onClick={handleRebootClicked}>再起動</MenuItem>
              <MenuItem disabled={!(envActive && enableRestartService)} onClick={handleRestartServiceClicked}>サービス再起動</MenuItem>
            </Menu>
            <ConfirmDialog
              title="起動確認"
              open={openConfirmBoot}
              handleClose={handleBoot}
            />
            <ConfirmDialog
              title="停止確認"
              open={openConfirmShutdown}
              handleClose={handleShutdown}
            />
            <ConfirmDialog
              title="再起動確認"
              open={openConfirmReboot}
              handleClose={handleReboot}
            />
            <ConfirmDialog
              title="サービス再起動確認"
              open={openConfirmRestartService}
              handleClose={handleRestartService}
            />
          </Box>
        </Grid>
        {(store && configuration && storeEnv) ? (
          <>
            <Grid item xs={3}>
              <Typography variant="caption">ステータス</Typography>
              <Typography><StoreStatus status={storeStatus.devices} /></Typography>
            </Grid>
            <Grid item xs={3}>
              <StoreStatusDurationLabel now={now} storeStatus={storeStatus} />
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption">モード</Typography>
              <Typography><StoreModeLabel mode={configuration.store_mode} /></Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption">環境</Typography>
              <Typography>{storeEnv.env}{store.is_mock && (<> (Mock)</>)}</Typography>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Loading marginTop={4} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Box sx={sxCategory}>
            <Typography component="div">Edge モジュール</Typography>
            <Button variant="outlined" size="small" sx={{ marginLeft: 'auto', marginRight: 1 }} onClick={handleOpenEdgeOperationMenuClicked}>操作</Button>
            <Menu
              open={Boolean(edgeOperationMenuAnchorEl)}
              anchorEl={edgeOperationMenuAnchorEl}
              onClose={handleOpenEdgeOperationMenuClosed}
            >
              <MenuItem disabled={!(envActive && enableEdgeRestart)} onClick={handleEdgeRestartClicked}>Edge再起動</MenuItem>
            </Menu>
            <ConfirmDialog
              title="Edge再起動確認"
              open={openConfirmEdgeRestart}
              handleClose={handleEdgeRestart}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          {edgeTableModel ? (
            <EdgeTable
              model={edgeTableModel}
            />
          ) : (
            <Loading marginTop={4} />
          )}
        </Grid>
        <Grid item xs={12}>
          <Box sx={sxCategory}>
            <Typography component="div">デバイス</Typography>
            <Button variant="outlined" size="small" sx={{ marginLeft: 'auto', marginRight: 1 }} onClick={handleOpenDeviceOperationMenuClicked}>操作</Button>
            <Menu
              open={Boolean(deviceOperationMenuAnchorEl)}
              anchorEl={deviceOperationMenuAnchorEl}
              onClose={handleOpenDeviceOperationMenuClosed}
            >
              <MenuItem disabled={!(envActive && enableDeviceRestartService)} onClick={handleDeviceRestartServiceClicked}>サービス再起動</MenuItem>
              <MenuItem disabled={!(envActive && enableDeviceReboot)} onClick={handleDeviceRebootClicked}>デバイス再起動</MenuItem>
              <MenuItem disabled={!(envActive && enableDeviceShutdown)} onClick={handleDeviceShutdownClicked}>デバイス停止</MenuItem>
              <MenuItem disabled={!(envActive && enableDeviceStopService)} onClick={handleDeviceStopServiceClicked}>サービス停止</MenuItem>
              <MenuItem disabled={!(envActive && enableDeviceFaultIsolation)} onClick={handleDeviceFaultIsolationClicked}>障害分離</MenuItem>
            </Menu>
            <ConfirmDialog
              title="デバイス停止確認"
              open={openConfirmDeviceShutdown}
              handleClose={handleDeviceShutdown}
            />
            <ConfirmDialog
              title="デバイス再起動確認"
              open={openConfirmDeviceReboot}
              handleClose={handleDeviceReboot}
            />
            <ConfirmDialog
              title="サービス再起動確認"
              open={openConfirmDeviceRestartService}
              handleClose={handleDeviceRestartService}
            />
            <ConfirmDialog
              title="サービス停止確認"
              open={openConfirmDeviceStopService}
              handleClose={handleDeviceStopService}
            />
            <ConfirmDialog
              title="障害から分離の確認"
              contentText="エラーコードをクリアし、off状態とします"
              open={openConfirmDeviceFaultIsolation}
              handleClose={handleDeviceFaultIsolation}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          {devicesTableModel ? (
            <DevicesTable
              model={devicesTableModel}
              onChanged={handleDevicesChanged}
            />
          ) : (
            <Loading marginTop={4} />
          )}
        </Grid>

        <Grid item xs={12}>
          <Box sx={sxCategory}>
            <Typography component="div">プラグ</Typography>
            <Button variant="outlined" size="small" sx={{ marginLeft: 'auto', marginRight: 1 }} onClick={handleOpenPlugOperationMenuClicked}>操作</Button>
            <Menu
              open={Boolean(plugOperationMenuAnchorEl)}
              anchorEl={plugOperationMenuAnchorEl}
              onClose={handleOpenPlugOperationMenuClosed}
            >
              <MenuItem disabled={!(envActive && enablePlugOn)} onClick={handlePlugOnClicked}>ON</MenuItem>
              <MenuItem disabled={!(envActive && enablePlugOff)} onClick={handlePlugOffClicked}>OFF</MenuItem>
            </Menu>
            <ConfirmDialog
              title="プラグON確認"
              contentText="接続されたデバイスに電源が供給され、起動します。"
              open={openConfirmPlugOn}
              handleClose={handlePlugOn}
            />
            <ConfirmDialog
              title="プラグOFF確認"
              contentText="接続されたデバイスに電源がカットされ、停止します。予めシャットダウンしていることを確認してください。"
              open={openConfirmPlugOff}
              handleClose={handlePlugOff}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          {plugsTableModel ? (
            <PlugsTable
              model={plugsTableModel}
              onChanged={handlePlugsChanged}
            />
          ) : (
            <Loading marginTop={4} />
          )}
        </Grid>
      </Grid>
    </>
  )
}