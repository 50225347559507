import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { App } from './App'
import * as serviceWorker from './serviceWorker'
import {
  BrowserRouter,
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import Amplify from 'aws-amplify'
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers'

const ENV_ERROR = (name: string): string => { throw new Error(`${name} is not defined.`) }
const LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL || ENV_ERROR('REACT_APP_LOG_LEVEL')
const REGION: string = process.env.REACT_APP_AWS_REGION || ENV_ERROR('REACT_APP_AWS_REGION')
const IDENTITY_POOL_ID: string = process.env.REACT_APP_IDENTITY_POOL_ID || ENV_ERROR('REACT_APP_IDENTITY_POOL_ID')
const IOT_PUBSUB_ENDPOINT: string = process.env.REACT_APP_IOT_PUBSUB_ENDPOINT || ENV_ERROR('REACT_APP_IOT_PUBSUB_ENDPOINT')


const NO_OP = () => {}
if (LOG_LEVEL !== 'debug') console.log = NO_OP
if (LOG_LEVEL !== 'debug' && LOG_LEVEL !== 'info') console.info = NO_OP

// ### AWS-SDK利用のための処理 cognito+amplify
Amplify.configure({
  Auth: {
    identityPoolId: IDENTITY_POOL_ID,
    region: REGION,
  },
})
Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: REGION,
    aws_pubsub_endpoint: IOT_PUBSUB_ENDPOINT,
  })
)

// https://github.com/pbeshai/use-query-params/issues/196
const RouteAdapter: React.FC = ({ children }) => {
  const navigate: NavigateFunction = useNavigate()
  const location: Location = useLocation()

  const adaptedHistory = React.useMemo(
    () => ({
      replace(loc: Location) {
        navigate(loc, { replace: true, state: loc.state })
      },
      push(loc: Location) {
        navigate(loc, { replace: false, state: loc.state })
      },
    }),
    [navigate]
  )
  // @ts-ignore
  return children({ history: adaptedHistory, location })
}

ReactDOM.render(
  <BrowserRouter>
    <QueryParamProvider ReactRouterRoute={RouteAdapter}>
      <App/>
    </QueryParamProvider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
