import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react'
import {
  Slider,
  Box,
} from '@mui/material';
import {
  SegmentQuantities,
} from 'models'
import {
  toSegment,
} from 'utils'
import { DateTime } from 'luxon'
import * as d3 from 'd3'

const width = 760
const unitHeight = 15
const marginLeft = 60
const marginRight = 20
const marginTop = 10
const marginBottom = 5

const getTimeRange = (quantities: SegmentQuantities[], date: Date): [Date, Date] => {
  const timestamps = quantities.map(q => q.quantities.map(q2 => q2.timestamp)).flat()
  const minTs = d3.min(timestamps)
  const maxTs = d3.max(timestamps)
  return [
    minTs ? DateTime.fromSeconds(minTs).startOf('hour').toJSDate() : DateTime.fromJSDate(date).startOf('day').toJSDate(),
    maxTs ? DateTime.fromSeconds(maxTs).endOf('hour').toJSDate() : DateTime.fromJSDate(date).endOf('day').toJSDate(),
  ]
}

const makeMarks = (begin: number, end: number): Mark[] => {
  const marks: Mark[] = []
  for (let value = begin; value <= end; value += 6) {
    marks.push({
      value,
      label: DateTime.fromSeconds(value * 10 * 60).toFormat('HH:mm'),
    })
  }
  return marks;
}

interface Mark {
  value: number
  label: string
}

interface QuantitiesChartProps {
  date: Date
  quantities: SegmentQuantities[]
}

interface Data {
  date: number
  segment: string
  value_diff: number
  qty: number | undefined
}

export const QuantitiesChart: React.FC<QuantitiesChartProps> = ({
  date,
  quantities,
}) => {
  const ref = useRef<SVGSVGElement | null>(null)
  const updateChartRef = useRef<(from: number, to: number) => void>(() => { })
  const [height, setHeight] = useState(600)
  const [sliderRange, setSliderRange] = useState<number[]>([0, 100])
  const [sliderValues, setSliderValues] = useState<number[]>([0, 100])
  const [sliderMarks, setSliderMarks] = useState<Mark[]>([])

  useEffect(() => {
    console.log('Initalize Chart')
    const svgElm = ref.current
    if (svgElm === null) return

    const data: Data[] = quantities.map(q => q.quantities.map(q2 => ({
      date: q2.timestamp * 1000,
      segment: toSegment(q.segment_code),
      value_diff: q2.value_diff,
      qty: q2.qty,
    }))).flat()
    const segments = Array.from(new Set(quantities.map(q => toSegment(q.segment_code))))
    console.log(segments)
    const height = segments.length * unitHeight
    setHeight(height + marginTop + marginBottom)
    const scaleSegment = d3
      .scaleBand()
      .domain(segments)
      .range([0, height])

    const range = getTimeRange(quantities, date)
    console.log(range)
    const newSliderRange: [number, number] = [range[0].valueOf() / 1000 / 60 / 10, range[1].valueOf() / 1000 / 60 / 10]
    setSliderRange(newSliderRange)
    setSliderValues(newSliderRange)
    setSliderMarks(makeMarks(newSliderRange[0], newSliderRange[1]))
    const scaleTime = d3.scaleTime().domain(range).range([0, width])
    console.log(scaleTime)

    const xAxis = d3
      .axisBottom(scaleTime)
      .tickSizeInner(-height)
    const yAxis = d3
      .axisLeft(scaleSegment)
    // .tickSizeInner(-width)

    const svg = d3.select(svgElm)
    const xAxisG = svg
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(${marginLeft}, ${marginTop + height})`)
    xAxisG.call(xAxis)
    const yAxisG = svg
      .append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(${marginLeft}, ${marginTop})`)
    yAxisG.call(yAxis)
    const plotArea = svg
      .append('g')
      .attr('class', 'd')
      .attr('transform', `translate(${marginLeft}, ${marginTop})`)

    console.log(data)
    const updatePlotArea = (data: Data[]) => {
      plotArea
        .selectAll('g.d circle')
        .data(data)
        .join(
          enter => enter
            .append('circle')
            .attr('cx', d => scaleTime(d.date))
            .attr('cy', d => scaleSegment(d.segment) || 0)
            .attr('r', 2)
            .attr('fill', d => d.qty !== undefined ? 'darkgreen' : 'crimson')
          ,
          update => {
            update
              .attr('cx', d => scaleTime(d.date))
              .attr('cy', d => scaleSegment(d.segment) || 0)
            update
              .transition()
              .duration(1000)
            // .transition()
            // .call(g => g.transition().duration(600))
            return update
          },
          exit => exit
            .remove()
          ,
        )
    }
    updatePlotArea(data)

    const updateChart = (from: number, to: number) => {
      console.log(`updateChart: ${from}, ${to}`)
      scaleTime.domain([new Date(from), new Date(to)])
      xAxisG.call(xAxis)
      updatePlotArea(data)
    }
    updateChartRef.current = updateChart

  }, [ref, date, quantities])

  useEffect(() => {
    // console.log(sliderValues)
    updateChartRef.current(
      sliderValues[0] * 10 * 60 * 1000,
      sliderValues[1] * 10 * 60 * 1000,
    )
  }, [sliderValues])

  const handleRangeChanged = useCallback((_evt: any, newValues: any) => {
    setSliderValues(newValues as number[])
  }, [])

  return (
    <Box>
      <svg
        ref={ref}
        width={width + marginLeft + marginRight}
        height={height + marginTop + marginBottom}
      >
      </svg>
      <Box sx={{ paddingTop: 4, paddingLeft: 2, paddingRight: 3 }}>
        <Slider
          value={sliderValues}
          min={sliderRange[0]}
          max={sliderRange[1]}
          // valueLabelDisplay="on"
          marks={sliderMarks}
          onChange={handleRangeChanged}
        />
      </Box>
    </Box>
  )
}