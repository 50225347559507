export type CredentialFormData = {
  clientName: string
}

export const CredentialFormValidations = {
  clientName: {
    required: {
      value: true,
      message: 'クライアント名を入力してください',
    },
  },
}
