import React from 'react'

type DataSizeLabelProps = {
  value?: number
}

const getValueAndUnit = (value: number): [number, string] => {
  if (value <= 0) return [0, 'MB']
  if (value < 1024) return [value, 'B']
  if (value < 1024 * 1024) {
    return [Math.floor(100 * value / 1024) / 100, 'KB']
  }
  if (value < 1024 * 1024 * 1024) {
    return [Math.floor(100 * value / 1024 / 1024) / 100, 'MB']
  }
  return [Math.floor(100 * value / 1024 / 1024 / 1024) / 100, 'GB']
}

export const DataSizeLabel: React.FC<DataSizeLabelProps> = ({ value = 0 }) => {
  const [value2, unit] = getValueAndUnit(value)
  return (<>{value2} {unit}</>)
}

