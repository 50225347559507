export interface Store {
  store_code: string
  store_name: string
  merchant_code?: string
  status: StoreStatus
  is_mock?: boolean
}

export const NullStore: Store = {
  store_code: '_',
  store_name: 'NULL',
  status: 'closed',
}

export type StoreStatus = 'open' | 'closed' | 'event'

export interface Configuration {
  store_code: string
  store_mode: StoreMode
  action_timeout_min: number
  action_wait_sec: number
  checkout_wait_sec: number
  weight_threshold: number
  dist_threshold: number
  hand_tracking_range: number
  hand_dist_threshold: number
  snapshot_timeout_min: number
  tracking_range_sec: number
  checkin_timeout_sec: number
  exit_timeout_min: number
  hand_tracking_window_interval_ms: number
  hand_tracking_action_diff_ms: number
  notify_slack_url?: string
  notify_sns_topic?: string
  disable_event_hook: boolean
  flow_velocity_move_threshold?: number
  flow_stay_ms_threshold?: number
  flow_dist_threshold?: number
  flow_hand_tracking_range?: number
  tracking_max_min?: number
  disable_sensor?: boolean
}

export type StoreMode = 'normal' | 'maintenance' | 'event'

export interface StoreEnv {
  store_code: string
  env: 'dev1' | 'itg' | 'stg' | 'prd' | 'unknown';
  disabled: boolean;
}

export const isEnvActive = (env: StoreEnv | undefined, forceLocalTrue?: boolean): boolean => {
  if (env === undefined) return false
  const appEnv = process.env.REACT_APP_ENV
  if (appEnv === 'local' && forceLocalTrue) return true
  if (appEnv !== 'local') {
    return (env.env === appEnv?.toLocaleLowerCase())
  } else {
    return (env.env === process.env.REACT_APP_IOT_ENV)
  }
}

export type StoreDevicesStatus = 'stop' | 'starting' | 'running' | 'stopping' | 'restarting' | 'restarting_service' | 'error' | 'service_error' | 'shadow_not_found'
export interface StoreShadowStatus {
  devices: StoreDevicesStatus
  starting_at?: number;
  boot_at?: number;
  stopping_at?: number;
  stop_at?: number;
  restarting_at?: number;
  restart_at?: number;
  restarting_service_at?: number;
  restart_service_at?: number;
  error_at?: number;
  service_error_at?: number;
}

export interface BootConfiguration {
  store_code: string;
  plugs: Plug[];
  boot_timeout_sec: number;
  shutdown_timeout_sec: number;
  reboot_timeout_sec: number;
  restart_service_timeout_sec: number;
  slack_notify_channel: string;
  slack_alert_channel: string;
  slack_path: string;
}
export interface Plug {
  name: string;
  desc: string;
}
export type PlugStatus = 'on' | 'off' | 'unknown'

export interface BootSchedule {
  store_code: string;
  disabled: boolean;
  dry_run: boolean;
  boot_hours: BootHours[][]; // 曜日（7） x 起動時間（複数）
  exclude_dates: string[]
}
export interface BootHours {
  from: string;
  to: string;
}
