import React, {
  useState,
  useCallback,
} from 'react'
import {
  Toolbar,
  Tooltip,
  Paper,
  Box,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  Switch,
  IconButton,
  Button,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  Close,
  Edit,
  ShoppingCart as Submit,
} from '@mui/icons-material'
import {
  grey,
} from '@mui/material/colors'
import { Alert } from '@mui/material';
import MaterialTable from '@material-table/core'
import {
  useApp,
} from 'contexts'
import {
  Candidate,
  Obscure,
  Item,
  ConfirmActionInput,
  ConfirmWalkthroughActionInput,
  Walkthrough,
} from 'models'
import {
  MaterialTableComponents,
  MaterialTableLocalization,
  SimpleMaterialTableOptions,
  ItemsSelector,
  ConfirmDialog,
} from 'components'
import {
  formatWalkthroughId,
  formatPersonId,
  toSegment,
} from 'utils'
import {
  WeightLabel,
} from 'components'
import { ConfirmPersonDetail } from './ConfirmPersonDetail'

interface ConfirmItem {
  item_code: string
  item_name: string
  item_image_url: string
  item_weight: number
  qty: number
}

const toCandidates = (obscure: Obscure): Candidate[] => {
  if (obscure.person_id !== undefined && obscure.walkthrough_id !== undefined) {
    return [{
      person_id: obscure.person_id,
      walkthrough_id: obscure.walkthrough_id,
      item_code: obscure.item_code,
      item_name: obscure.item_name,
      item_image_url: obscure.item_image_url,
      item_weight: obscure.item_weight,
      qty: obscure.qty || 0,
    }]
  } else if (obscure.candidates) {
    return obscure.candidates.map(c => ({
      person_id: c.person_id,
      walkthrough_id: c.walkthrough_id,
      item_code: obscure.item_code,
      item_name: obscure.item_name,
      item_image_url: obscure.item_image_url,
      item_weight: obscure.item_weight,
      qty: 0,
    }))
  } else {
    return []
  }
}

const updatedCandidatesQty = (candidates: Candidate[], target: Candidate, num: number): Candidate[] => {
  const newCandidates = [...candidates]
  const targetCand = newCandidates.find(can => can.person_id === target.person_id)
  if (targetCand) {
    if (targetCand.qty === undefined) targetCand.qty = 0
    targetCand.qty += num
  }
  return newCandidates
}

const updatedCandidatesItem = (candidates: Candidate[], target: Candidate, item: Item): Candidate[] => {
  const newCandidates = [...candidates]
  target.item_code = item.item_code
  target.item_name = item.item_name.ja
  target.item_image_url = item.image_url
  target.item_weight = item.weight
  return newCandidates
}

const mergeCandidatesAndWalkthrougs = (candidates: Candidate[], walkthroughs: Walkthrough[], items: ConfirmItem[]): Candidate[] => {
  const newCandidates = [...candidates]
  const item = 0 < items.length ? items[0] : undefined
  const candidatePersonIds = candidates.map(c => c.person_id)
  for (let i = 0, len = walkthroughs.length; i < len; i++) {
    const wt = walkthroughs[i]
    const personIds = [wt.person_id, ...Object.keys(wt.friends).map(p => Number(p))]
    for (let j = 0, len2 = personIds.length; j < len2; j++) {
      if (candidatePersonIds.indexOf(wt.person_id) !== -1) continue
      newCandidates.push({
        person_id: personIds[j],
        walkthrough_id: wt.walkthrough_id,
        item_code: item?.item_code,
        item_name: item?.item_name,
        item_image_url: item?.item_image_url,
        item_weight: item?.item_weight,
        qty: 0,
        aggregated: wt.items_aggregated,
      })
    }
  }
  return newCandidates
}

const onlyObscureCandidates = (candidates: Candidate[], obscure: Obscure): Candidate[] => {
  const newCandidates = [...candidates]
  const personIds = obscure.candidates?.map(c => c.person_id) || []
  return newCandidates.filter(c => personIds.indexOf(c.person_id) !== -1)
}

const toConfirmItems = (obscure: Obscure): ConfirmItem[] => {
  if (obscure.item_code === undefined || obscure.item_name === undefined || obscure.item_image_url === undefined || obscure.item_weight === undefined) {
    return []
  } else {
    return [{
      item_code: obscure.item_code,
      item_name: obscure.item_name,
      item_image_url: obscure.item_image_url,
      item_weight: obscure.item_weight,
      qty: (obscure.qty !== undefined) ? obscure.qty : 0,
    }]
  }
}

const updatedConfirmItemsQty = (items: ConfirmItem[], itemCode: string, num: number): ConfirmItem[] => {
  const newItems = [...items]
  const item = newItems.find(i => i.item_code === itemCode)
  if (item !== undefined) item.qty += num
  return newItems
}

const candidatesToConfirmItems = (candidates: Candidate[]): ConfirmItem[] => {
  const items: { [key: string]: ConfirmItem } = {}
  for (let i = 0, len = candidates.length; i < len; i++) {
    const c = candidates[i]
    if (c.item_code === undefined || c.item_name === undefined || c.item_image_url === undefined || c.item_weight === undefined) continue
    if (items[c.item_code] === undefined) {
      items[c.item_code] = {
        item_code: c.item_code,
        item_name: c.item_name,
        item_image_url: c.item_image_url,
        item_weight: c.item_weight,
        qty: 0,
      }
    }
    items[c.item_code].qty += c.qty || 0
  }
  return Object.values(items)
}

const isValid = (candidates: Candidate[], qty: number): boolean => {
  return (candidates.map(c => c.qty || 0).reduce((p, c) => p + c) === qty)
}

export interface ConfirmPanelProps {
  obscure: Obscure
  onConfirmed: () => void
}

export const ConfirmPanel: React.FC<ConfirmPanelProps> = ({
  obscure,
  onConfirmed,
}) => {
  const [candidates, setCandidates] = useState<Candidate[]>(toCandidates(obscure))
  const [items, setItems] = useState<ConfirmItem[]>(toConfirmItems(obscure))
  const [confirmItems, setConfirmItems] = useState(Boolean(obscure.qty === undefined))
  const [validated, setValidated] = useState(Boolean(obscure.qty === undefined))
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [showAllCandidates, setShowAllCandidates] = useState(false)
  const [selected, setSelected] = useState<Candidate>()
  const { getApi, defaultApiErrorHandler } = useApp()

  const handleConfirmItemsChanged = useCallback((_, checked: boolean) => {
    setConfirmItems(checked)
    if (checked) {
      const items: { [key: string]: ConfirmItem } = {}
      candidates.forEach(c => {
        if (c.item_code === undefined || c.item_name === undefined || c.item_weight === undefined || c.item_image_url === undefined) return
        if (!items[c.item_code]) {
          items[c.item_code] = {
            item_code: c.item_code,
            item_name: c.item_name,
            item_weight: c.item_weight,
            item_image_url: c.item_image_url,
            qty: 0,
          }
        }
        items[c.item_code].qty += c.qty || 0
      });
      setItems(Object.values(items))
      setValidated(true)
    } else { // reset
      setItems(toConfirmItems(obscure))
    }
  }, [obscure, candidates])

  const handleShowAllCandidatesChanged = useCallback((_, checked: boolean) => {
    setShowAllCandidates(checked)
    if (checked) {
      getApi().fetchWalkthroughs(obscure.store_code, obscure.timestamp)
        .then((result) => {
          console.log(result)
          if (result.items.length === 0) return
          setCandidates(mergeCandidatesAndWalkthrougs(candidates, result.items, items))
        })
        .catch(defaultApiErrorHandler)
    } else {
      setCandidates(onlyObscureCandidates(candidates, obscure))
      return
    }
  }, [getApi, defaultApiErrorHandler, obscure, candidates, items])

  const handleItemDialogClose = useCallback(() => {
    setSelected(undefined)
  }, [])
  const handleItemChanged = useCallback((item?: Item) => {
    if (item && selected) {
      const newCandidates = updatedCandidatesItem(candidates, selected, item)
      setCandidates(newCandidates)
      setItems(candidatesToConfirmItems(newCandidates))
    }
    setSelected(undefined)
  }, [candidates, selected])

  const handleSubmit = useCallback(() => {
    setConfirmOpen(true)
  }, [])
  const handleConfirm = useCallback((isOk: boolean) => {
    if (isOk === false) {
      setConfirmOpen(false)
      return
    }
    const actions: ConfirmActionInput[] = candidates
      .filter(c => c.qty !== 0 && c.aggregated === undefined)
      .map(c => ({
        person_id: c.person_id,
        item_code: c.item_code,
        qty: c.qty,
      }))
      .filter((c): c is ConfirmActionInput => c.qty !== undefined)
    const walkthroughs: ConfirmWalkthroughActionInput[] = candidates
      .filter(c => c.qty !== 0 && c.aggregated !== undefined)
      .map(c => ({
        person_id: c.person_id,
        item_code: c.item_code,
        qty: c.qty,
        walkthrough_id: c.walkthrough_id,
      }))
      .filter((c): c is ConfirmWalkthroughActionInput => c.qty !== undefined && c.walkthrough_id !== undefined)
    console.log(actions)
    getApi()
      .confirmAction({
        store_code: obscure.store_code,
        segment_code: obscure.segment_code,
        timestamp: obscure.timestamp,
        actions,
        walkthroughs,
      })
      .then(() => {
        onConfirmed()
      })
      .catch(defaultApiErrorHandler)
      .finally(() => {
        setConfirmOpen(false)
      })
  }, [getApi, defaultApiErrorHandler, obscure, onConfirmed, candidates])

  return (
    <>
      <Paper sx={{ margin: 1, padding: 1 }}>
        <Typography variant="h3" sx={{ marginBottom: 1 }}>
          {toSegment(obscure.segment_code)}
        </Typography>
        {0 < items.length && (
          <Table size="small" sx={{ marginBottom: 0.5 }}>
            <TableBody>
              {items.map(item => (
                <TableRow key={item.item_code}>
                  <TableCell >
                    <Box display="flex">
                      <Avatar src={item.item_image_url} alt={item.item_name} variant="rounded" />
                      <Box marginLeft={1}>
                        <Typography variant="h4">{item.item_name}</Typography>
                        <Typography variant="body2" align="right" sx={{ paddingRight: 1, color: grey[600] }}>{item.item_weight}g</Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    x {item.qty}
                  </TableCell>
                  <TableCell align="right">
                    <WeightLabel value={item.item_weight * item.qty * -1} markable />
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell />
                <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                  <WeightLabel value={obscure.value_diff} markable />
                </TableCell>
                <TableCell align="right">
                  <WeightLabel value={items.map(i => i.item_weight * i.qty).reduce((p, c) => p + c, 0) * -1} markable />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        {items.length === 0 && (
          <Alert severity="info">商品を設定してください。</Alert>
        )}
        {obscure.qty !== undefined && (
          <FormControlLabel
            label="商品と数量を変更する"
            control={(
              <Switch
                checked={confirmItems}
                onChange={handleConfirmItemsChanged}
                color="secondary"
              />
            )}
          />
        )}
      </Paper>
      <Paper sx={{ margin: 1, padding: 1 }}>
        <MaterialTable
          data={candidates.sort((c1, c2) => (c1.person_id - c2.person_id))}
          columns={[
            {
              title: '#',
              field: 'walkthrough_id',
              width: '10%',
              render: (d) => (<>{formatWalkthroughId(d.walkthrough_id)}</>),
            },
            {
              title: '人物',
              field: 'person_id',
              width: '10%',
              render: (d) => (<>{formatPersonId(d.person_id)}</>),
            },
            {
              title: confirmItems ? '商品 x 数量' : '数量',
              field: 'item',
              render: (d) => (
                <>
                  {confirmItems && (
                    <>
                      <>{d.item_name || '-'}</>
                      <Tooltip title="商品の変更">
                        <IconButton
                          size="small"
                          disabled={d.aggregated}
                          onClick={() => setSelected(d)}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  <Box component="span" sx={{ marginLeft: 1 }}>x {d.qty}</Box>
                </>
              ),
            },
          ]}
          actions={[
            (d) => ({
              icon: 'add',
              tooltip: d.aggregated ? '確定済' : '取得',
              disabled: d.aggregated,
              onClick: () => {
                if (confirmItems && d.item_code) setItems(updatedConfirmItemsQty(items, d.item_code, 1))
                const newCandidates = updatedCandidatesQty(candidates, d, 1);
                setCandidates(newCandidates)
                if (confirmItems) {
                  setValidated(true)
                } else if (obscure.qty !== undefined) {
                  const validated = isValid(newCandidates, obscure.qty)
                  setValidated(validated)
                }
              },
            }),
            (d) => ({
              icon: 'remove',
              tooltip: d.aggregated ? '確定済' : '返却',
              disabled: d.aggregated,
              onClick: () => {
                if (confirmItems && d.item_code) setItems(updatedConfirmItemsQty(items, d.item_code, -1))
                const newCandidates = updatedCandidatesQty(candidates, d, -1);
                setCandidates(newCandidates)
                if (confirmItems) {
                  setValidated(true)
                } else if (obscure.qty !== undefined) {
                  setValidated(isValid(newCandidates, obscure.qty))
                }
              },
            }),
          ]}
          detailPanel={({ rowData }) => (rowData.walkthrough_id ? (<ConfirmPersonDetail walkthroughId={rowData.walkthrough_id} />) : (<></>))}
          components={{
            Toolbar: () => (<></>),
            ...MaterialTableComponents,
          }}
          options={SimpleMaterialTableOptions<Candidate>()}
          localization={MaterialTableLocalization}
        />
        <FormControlLabel
          label="候補となる全てのウォークスルーから人物を取得"
          control={(
            <Switch
              checked={showAllCandidates}
              onChange={handleShowAllCandidatesChanged}
              color="secondary"
            />
          )}
        />
        {obscure.qty !== undefined && !validated && !confirmItems && (
          <Alert severity="warning" sx={{ marginTop: 1 }}>
            合計商品数が <strong>{obscure.qty}</strong> になるように、数量を人物に配分してください。
          </Alert>
        )}
        {confirmItems && (
          <Alert severity="warning" sx={{ marginTop: 1 }}>商品と数量を確定してください。</Alert>
        )}
      </Paper>
      <Box textAlign="right" paddingRight={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ paddingLeft: 4, paddingRight: 4 }}
          startIcon={(<Submit />)}
          disabled={!validated}
        >
          確定
        </Button>
      </Box>
      <Dialog
        open={Boolean(selected)}
        onClose={handleItemDialogClose}
      >
        <Toolbar variant="dense">
          <Typography variant="h4">商品選択</Typography>
          <Tooltip title="キャンセル">
            <IconButton style={{ marginLeft: 'auto' }} onClick={handleItemDialogClose} size="large">
              <Close />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <DialogContent>
          <ItemsSelector
            storeCode={obscure.store_code}
            onSeleced={handleItemChanged}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleItemDialogClose}>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={confirmOpen}
        contentText={`確定します。よろしいですか？`}
        handleClose={handleConfirm}
      />
    </>
  )
}