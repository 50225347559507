import {
  EdgeDevice,
  EdgeDeviceUpdatePayload,
  AwslogsLevel,
} from 'models'

export interface EdgeFormData {
  agent_interval: number
  agent_service_start_timeout: number
  service_interval: number
  service_logs_notify_slack_channel: string
  check_stat_interval: number
  ssm_instance_id: string
}

export const toFormData = (device: EdgeDevice): EdgeFormData => {
  return {
    agent_interval: device.agent_properties.interval,
    agent_service_start_timeout: device.agent_properties.service_start_timeout || 120,
    service_interval: device.service_properties.interval || 60,
    service_logs_notify_slack_channel: device.service_properties.logs_notify_slack_channel || '',
    check_stat_interval: device.service_properties.check_stat_interval || 180,
    ssm_instance_id: device.properties.ssm_instance_id || '',
  }
}

export const dataToPayload = (
  data: EdgeFormData,
  storeCode: string,
  agentAwslogsLevel: AwslogsLevel,
  serviceAwslogsLevel: AwslogsLevel,
  serviceActionAwslogsLevel: AwslogsLevel,
  serviceActionPostApi: boolean,
  serviceActionDebug: boolean,
  servicePassageAwslogsLevel: AwslogsLevel,
  servicePassagePostApi: boolean,
  servicePassageDebug: boolean,
  serviceWeightAwslogsLevel: AwslogsLevel,
  serviceWeightEnableMqtt: boolean,
  serviceWeightEnableFirehose: boolean,
  serviceTrackingAwslogsLevel: AwslogsLevel,
  serviceTrackingEnableMqtt: boolean,
  serviceTrackingEnableFirehose: boolean,
  serviceMovieAwslogsLevel: AwslogsLevel,
): EdgeDeviceUpdatePayload => {
  return {
    store_code: storeCode !== '_' ? storeCode : '',
    properties: {
      ssm_instance_id: data.ssm_instance_id,
    },
    agent_properties: {
      awslogs_level: agentAwslogsLevel,
      interval: Number(data.agent_interval),
      service_start_timeout: Number(data.agent_service_start_timeout),
    },
    service_properties: {
      awslogs_level: serviceAwslogsLevel,
      interval: Number(data.service_interval),
      action_awslogs_level: serviceActionAwslogsLevel,
      action_debug: serviceActionDebug,
      action_post_api: serviceActionPostApi,
      passage_awslogs_level: servicePassageAwslogsLevel,
      passage_debug: servicePassageDebug,
      passage_post_api: servicePassagePostApi,
      weight_awslogs_level: serviceWeightAwslogsLevel,
      weight_enable_mqtt: serviceWeightEnableMqtt,
      weight_enable_firehose: serviceWeightEnableFirehose,
      tracking_awslogs_level: serviceTrackingAwslogsLevel,
      tracking_enable_mqtt: serviceTrackingEnableMqtt,
      tracking_enable_firehose: serviceTrackingEnableFirehose,
      movie_awslogs_level: serviceMovieAwslogsLevel,
      logs_notify_slack_channel: data.service_logs_notify_slack_channel,
      check_stat_interval: Number(data.check_stat_interval),
    }
  }
}

export const EdgeFormValidations = {
  agent_interval: {
    required: {
      value: true,
      message: '送信間隔を入力してください',
    },
    min: {
      value: 50,
      message: '送信間隔は50〜300で入力してください',
    },
    max: {
      value: 300,
      message: '送信間隔は50〜300で入力してください',
    },
  },
  agent_service_start_timeout: {
    required: {
      value: true,
      message: 'サービス起動タイムアウトを入力してください',
    },
    min: {
      value: 60,
      message: 'サービス起動タイムアウトは60〜600で入力してください',
    },
    max: {
      value: 600,
      message: 'サービス起動タイムアウトは60〜600で入力してください',
    },
  },
  service_interval: {
    required: {
      value: true,
      message: '送信間隔を入力してください',
    },
    min: {
      value: 50,
      message: '送信間隔は50〜300で入力してください',
    },
    max: {
      value: 300,
      message: '送信間隔は50〜300で入力してください',
    },
  },
  service_logs_notify_slack_channel: {
    pattern: {
      value: /^(#.+)?$/,
      message: 'チャンネル名は#で開始してください',
    }
  },
  check_stat_interval: {
    required: {
      value: true,
      message: 'ステータスチェック間隔を入力してください',
    },
    min: {
      value: 30,
      message: 'ステータスチェック間隔は30〜600で入力してください',
    },
    max: {
      value: 600,
      message: 'ステータスチェック間隔は30〜600で入力してください',
    },
  },
  ssm_instance_id: {
    pattern: {
      value: /^mi-[a-z0-9-]+$/,
      message: 'インスタンスIDは「mi-xxxxxxx」のフォーマットで入力してください',
    }
  },
}