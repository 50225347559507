import React, {
  useEffect,
  useRef,
} from 'react'
import {
  Paper
} from '@mui/material';
import {
  ObscureValueDetail,
} from 'models'
import {
  WeightLabel,
} from 'components'
import {
  formatPersonId,
} from 'utils'
import * as d3 from 'd3'

const width = 360
const baseHeight = 25
const padding = {
  left: 70,
}

export interface ValueDetailPanelProps {
  valueDiff: number
  valueDetails: ObscureValueDetail[]
}

export const ValueDetailPanel: React.FC<ValueDetailPanelProps> = ({ valueDiff, valueDetails }) => {
  const ref = useRef<SVGSVGElement | null>(null)
  useEffect(() => {
    const data = valueDetails.map(d => ({
      name: d.person_ids.map(formatPersonId).join(','),
      person_ids: d.person_ids.map(formatPersonId),
      value: d.value,
    }))
    const color = d3.schemeAccent[0]
    const xScale = d3.scaleLinear()
      .domain([0, d3.max(valueDetails.map(d => Math.abs(d.value))) || 100])
      .range([padding.left, width - padding.left])
    const yScalle = d3.scaleBand()
      .domain(data.map(d => d.name))
      .range([0, baseHeight * valueDetails.length])
    const axisLeft = d3.axisLeft(yScalle)
      .tickSize(0)
      .ticks(0)
    // .tickPadding(padding.left)
    const svgElm = ref.current
    if (svgElm === null) return
    const svg = d3.select(svgElm)
    svg
      .append('g')
      .attr('transform', `translate(${padding.left - 2}, 0)`)
      .attr('stroke-opacity', 0)
      .call(axisLeft)
    const bars = svg.selectAll('.bar').data(data)
    bars
      .join(
        enter => enter
          .append('rect')
          .attr('x', padding.left)
          .attr('y', d => yScalle(d.name) || 0)
          .attr('height', baseHeight * 0.9)
          .attr('width', d => xScale(Math.abs(d.value)))
          .attr('fill', () => color)
        ,
        update => update
          .attr('width', d => xScale(Math.abs(d.value)))
        ,
        exit => exit
          .remove()
        ,
      )
    bars
      .join(
        enter => enter
          .append('text')
          .text(d => `${d.value}g`)
          .attr('x', padding.left + 8)
          .attr('y', d => baseHeight - 7 + (yScalle(d.name) || 0))
          .attr('fill', 'black')
          .attr('font-size', '0.8em')
          .attr('font-family', 'sans-serif')
          .style('dominant-baseline', 'text-after-edge')
        ,
        exit => exit
          .remove()
        ,
      )
  }, [ref, valueDetails])

  return (
    <Paper sx={{ marginBottom: 1, padding: 1, }}>
      <WeightLabel value={valueDiff} />
      <div>
        <svg
          ref={ref}
          width={width}
          height={baseHeight * valueDetails.length}
        />
      </div>
    </Paper>
  )
}

