import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  useNavigate,
  useLocation,
} from 'react-router-dom'
import {
  useQueryParam,
  DateParam,
  withDefault,
} from 'use-query-params'
import {
  Toolbar,
} from '@mui/material'
import MaterialTable from '@material-table/core'
import { DateTime } from 'luxon'
import {
  useApp,
} from 'contexts'
import {
  useSelectedStoreCode,
} from 'hooks'
import {
  Store,
  NullStore,
  Flow,
} from 'models'
import {
  MainContents,
  StoresSelector,
  Loading,
  ReloadButton,
  DatePicker,
  TimestampLabel,
  MaterialTableOptions,
  MaterialTableComponents,
  MaterialTableLocalization,
} from 'components'


const getWalkthrougNo = (walkthroughId?: string) => `#${walkthroughId ? Number(walkthroughId.split('_')[2]) : ''}`

export const AnalysisFlows: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {storeCode, setStoreCode} = useSelectedStoreCode()
  // const [storeCode, setStoreCode] = useQueryParam(
  //   's',
  //   StringParam,
  // )
  const [date, setDate] = useQueryParam(
    'd',
    withDefault(DateParam, new Date()),
  )
  const [reload, setReload] = useState(false)
  const [items, setItems] = useState<Flow[]>()
  const { getApi, defaultApiErrorHandler } = useApp()

  const load = useCallback(() => {
    getApi()
    .fetchFlows(storeCode, DateTime.fromJSDate(date))
    .then((result) => {
      console.log('fetchAsync done')
      setItems(result.items)
    })
    .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, storeCode, date])
  useEffect(() => {
    load()
  }, [load])
  useEffect(() => {
    load()
  }, [load])
  // reload
  useEffect(() => {
    if (!reload) return
    console.log('reloading...')
    load()
    setReload(false)
  }, [load, reload])

  const onStoreChanged = useCallback((store: Store) => {
    setItems(undefined)
    setStoreCode(store !== NullStore ? store.store_code : undefined)
    if (store === NullStore) setDate(undefined)
  }, [setStoreCode, setDate])
  const handleDateChange = useCallback((dt: DateTime) => {
    setItems(undefined)
    setDate(dt.toJSDate())
  }, [setDate])
  const handleReload = useCallback(() => {
    setItems(undefined)
    setReload(true)
  }, [])

  return (
  <MainContents>
    <Toolbar variant="dense" disableGutters>
      <StoresSelector
        storeCode={storeCode}
        onSeleced={onStoreChanged}
      />
      <DatePicker
        defaultDate={DateTime.fromJSDate(date)}
        onChange={handleDateChange}
      />
      <ReloadButton marginLeft="auto" onClick={handleReload} />
    </Toolbar>
    <Loading visible={items === null} />
    {items &&
    <MaterialTable
      data={items}
      columns={[
        {
          title: '人物ID',
          field: 'person_id',
        },
        {
          title: '入店',
          field: 'from',
          render: (d) => (<TimestampLabel value={d.from} />),
        },
        {
          title: '退店',
          field: 'to',
          render: (d) => (<TimestampLabel value={d.to} />),
        },
        {
          title: 'ウォークスルー',
          field: 'walkthrough_id',
          render: (d) => (<>{getWalkthrougNo(d.walkthrough_id)}</>),
        },
      ]}
      onRowClick={(_, rowData) => {
        navigate(`/analysis/flows/${rowData?.flow_id}${location.search}`)
      }}
      options={MaterialTableOptions<Flow>({
        toolbar: false,
      })}
      components={{
        ...MaterialTableComponents,
      }}
      localization={{
        ...MaterialTableLocalization
      }}
    />
    }
  </MainContents>
  )
}