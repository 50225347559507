import React from 'react'
import {
  Routes,
  Route,
} from 'react-router-dom'
import { Operators } from './Operators'

export const OperatorsRoutes: React.FC = () => (
<Routes>
  <Route index element={<Operators/>}/>
</Routes>
)
