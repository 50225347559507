import React from 'react'
import {
  Chip,
  Box,
} from '@mui/material'
import {
  ShoppingCart as ShoppingCartIcon,
} from '@mui/icons-material'
import {
  WeightLabel,
} from 'components'

export interface ConfirmItemLabelProps {
  itemName?: string
  qty?: number
  valueDiff: number
}

export const ConfirmItemLabel: React.FC<ConfirmItemLabelProps> = ({
  itemName,
  qty,
  valueDiff,
}) => {
  if (itemName && qty) {
    return (
      <Box>
        {itemName} x{qty}
      </Box>
    )  
  } else {
    return (
      <Box>
        <Chip
          variant="outlined"
          size="small"
          icon={<ShoppingCartIcon/>}
          label="未確定"
          color="secondary"
        />
        {itemName}
        (<WeightLabel value={valueDiff} />)
      </Box>
    )
  }
}
