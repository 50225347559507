import React, {
} from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import {
  SystemStatisticsData,
} from 'models'
import {
  StatisticsNumberLabel,
} from 'components'
import { DateTime } from 'luxon'

interface StatisticsSystemDetailPanelProps {
  data: SystemStatisticsData
}

export const StatisticsSystemDetailPanel: React.FC<StatisticsSystemDetailPanelProps> = ({ data }) => (
  <TableContainer sx={{ margin: 1, paddingLeft: 2, paddingRight: 2, width: '90%' }}>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{DateTime.fromFormat(data.date, 'yyyyMMdd').setLocale('ja').toFormat('yyyy-MM-dd')}</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }} align="center">障害</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }} align="center">エラー</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }} align="center">警告</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }} align="center">通知</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell component="th">WeightSensor</TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.fatal.ws} /></TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.error.ws} /></TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.warn.ws} /></TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.info.ws} /></TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th">GateKeeper</TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.fatal.gk} /></TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.error.gk} /></TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.warn.gk} /></TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.info.gk} /></TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th">ToF</TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.fatal.tof} /></TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.error.tof} /></TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.warn.tof} /></TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.info.tof} /></TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th">SkeletonTracking</TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.fatal.st} /></TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.error.st} /></TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.warn.st} /></TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.info.st} /></TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th">Camera</TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.fatal.cam} /></TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.error.cam} /></TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.warn.cam} /></TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.info.cam} /></TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th">Bastion</TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.fatal.bst} /></TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.error.bst} /></TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.warn.bst} /></TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.info.bst} /></TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th">System</TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.fatal.sys} /></TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.error.sys} /></TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.warn.sys} /></TableCell>
          <TableCell align="center"><StatisticsNumberLabel value={data.info.sys} /></TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
)
