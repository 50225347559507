import {
  Theme,
  SxProps,
} from '@mui/material';

export const sxCategory: SxProps<Theme> = {
  marginTop: 2,
  paddingBottom: 0.5,
  marginBottom: 1,
  borderBottom: '1px solid lightgray',
};

export const sxField: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
};
export const sxReadOnlyInput: SxProps<Theme> = {
  marginTop: 1,
  paddingLeft: 1,
};

export const sx = {
  forms: {
    margin: 0,
  } as SxProps<Theme>,
  label: {
    color: '#333333',
  } as SxProps<Theme>,
  labelItem: {
    marginTop: 2,
    paddingRight: 2,
    textAlign: 'right',
    width: '100%',
    fontWeight: 'bold',
  } as SxProps<Theme>,
  labelSelect: {
    marginTop: 1,
    paddingRight: 2,
    textAlign: 'right',
    width: '100%',
    fontWeight: 'bold',
  } as SxProps<Theme>,
  formControl: {
  } as SxProps<Theme>,
  action: {
    marginLeft: 2,
  } as SxProps<Theme>,
}