import React, {
  useState,
  useEffect, 
} from 'react'
import MaterialTable from '@material-table/core'
import {
  useApp,
} from 'contexts'
import { Item } from 'models'
import { Loading } from './Loading'
import { ItemCell } from './ItemCell'
import {
  MaterialTableOptions,
  MaterialTableComponents,
  MaterialTableLocalization,
} from './MaterialTables'

interface ItemsSelectorProps {
  storeCode: string
  onSeleced: (item?: Item) => void
}

export const ItemsSelector: React.FC<ItemsSelectorProps>  = ({ storeCode, onSeleced }) => {
  const [items, setItems] = useState<Item[]|null>(null)
  const { getApi } = useApp()

  useEffect(() => {
    console.log(`Items#useEffect ${storeCode}`)
    console.log(storeCode)
    getApi()
      .fetchItems(storeCode)
      .then((result) => {
        console.log('fetchAsync done')
        setItems(result.items)
      })
      .catch((err) => console.log(err))
  }, [getApi, storeCode])

  return (
   <>
    <Loading visible={items === null} />
    {items &&
      <MaterialTable
        data={items}
        columns={[
          {
            title: '商品',
            field: 'item_name',
            render: d => (<ItemCell itemName={d.item_name.ja} imageUrl={d.image_url} subText={`${d.weight}g`}/>),
            customFilterAndSearch: (filter: string, d: Item) => (d.item_name.ja.indexOf(filter) !== -1),
          },
        ]}
        options={MaterialTableOptions<Item>({
          sorting: false,
          pageSize: 10,
        })}
        components={MaterialTableComponents}
        localization={MaterialTableLocalization}
        onRowClick={(_, d) => onSeleced(d)}
      />
    }
   </>
  )
}
