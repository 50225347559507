import React, { useState } from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { useAuth0 } from '@auth0/auth0-react'

export const UserMenu: React.FC = () => {
  // TODO: findDOMNodeの警告の対処
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [open, setOpen] = useState(false)
  const { user, logout } = useAuth0()

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    console.log(`handleMenu: ${open}`)
    setAnchorEl(event.currentTarget)
    setOpen(!open)
  }
  const handleClose = () => {
    console.log(`handleMenu: ${open}`)
    setAnchorEl(null)
    setOpen(false)
  }
  const handleLogout = () => {
    logout({ returnTo: window.location.origin })
  }

  return <>
    <IconButton
      aria-label="user menu"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={handleMenu}
      color="inherit"
      size="large">
      <AccountCircleIcon />
    </IconButton>
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
    >
      <MenuItem>{user?.name}</MenuItem>
      <MenuItem onClick={handleLogout} >Logout</MenuItem>
    </Menu>
  </>;
}
