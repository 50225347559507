import React from 'react'
import {
  Routes,
  Route,
} from 'react-router-dom'
import { Credentials } from './Credentials'
import { CredentialsCredential } from './CredentialsCredential'

export const CredentialsRoutes: React.FC = () => (
<Routes>
  <Route index element={<Credentials/>}/>
  <Route path=":clientId" element={<CredentialsCredential/>}/>
</Routes>
)
