import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  useParams,
  useNavigate,
  useLocation,
  Link as RouterLink,
} from 'react-router-dom'
import {
  Typography,
  Link,
  Toolbar,
  Grid,
  SxProps,
  Theme,
} from '@mui/material';
import {
  useApp,
} from 'contexts'
import {
  Action,
  ActionMovie,
  actionIdToSegment,
} from 'models'
import {
  MainContents,
  Loading,
  TimestampLabel,
  WeightLabel,
  MovieCard,
  BackButton,
  ReloadButton,
} from 'components'

const sxTitle: SxProps<Theme> = {
  color: t => t.palette.text.secondary,
}

export const Detail: React.FC = () => {
  const { actionId } = useParams()
  if (actionId === undefined) throw new Error('unhandled routing')
  const navigate = useNavigate()
  const location = useLocation()
  const { getApi } = useApp()
  const [action, setAction] = useState<Action>()
  const [movies, setMovies] = useState<ActionMovie[]>([])
  const [reload, setReload] = useState(false)

  const load = useCallback(() => {
    Promise.all([
      getApi().fetchAction(actionId),
      getApi().fetchActionMovies(actionId),
    ]).then(([action, moviesResult]) => {
      console.log('fetchAsync done')
      setAction(action)
      setMovies(moviesResult.items)
    })
  }, [actionId, getApi])
  useEffect(() => {
    load()
  }, [load])
  // reload
  useEffect(() => {
    if (!reload) return
    console.log('reloading...')
    load()
    setReload(false)
  }, [load, reload])

  const handleReload = useCallback(() => {
    setAction(undefined)
    setMovies([])
    setReload(true)
  }, [])
  const handleBack = useCallback(() => {
    navigate(`/walkthrough/actions${location.search}`)
  }, [navigate, location])

  return (
    <MainContents>
      <Toolbar variant="dense" disableGutters>
        <BackButton onClick={handleBack} />
        <Typography variant="h5" sx={{ marginLeft: 1 }} >{actionId}</Typography>
        <ReloadButton marginLeft="auto" onClick={handleReload} />
      </Toolbar>
      <Loading visible={action === undefined} />
      {action &&
        <div>
          <Grid container spacing={1} sx={{ margin: 0.5 }}>
            <Grid item xs={4} sm={2} sx={sxTitle}>
              <Typography>日時</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>
                <TimestampLabel value={action.timestamp} format="yyyy/MM/dd HH:mm:ss" />
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2} sx={sxTitle}>
              <Typography>棚</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>
                <Typography>{actionIdToSegment(action.action_id)}</Typography>
              </Typography>
            </Grid>

            <Grid item xs={4} sm={2} sx={sxTitle}>
              <Typography>重量変化</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>
                <Typography><WeightLabel value={action.value_diff} /></Typography>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2} sx={sxTitle}>
              <Typography>商品</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>
                <Typography>{action.item_name}(<WeightLabel value={action.item_weight} />/個)</Typography>
              </Typography>
            </Grid>

            <Grid item xs={4} sm={2} sx={sxTitle}>
              <Typography>商品ステータス</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>
                <Typography>{action.item_status}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2} sx={sxTitle}>
              <Typography>数量</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>
                <Typography>{action.qty}</Typography>
              </Typography>
            </Grid>

            <Grid item xs={4} sm={2} sx={sxTitle}>
              <Typography>人物ステータス</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>
                <Typography>{action.person_status}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2} sx={sxTitle}>
              <Typography>人物ID</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>
                <Typography>{action.person_id}</Typography>
              </Typography>
            </Grid>

            <Grid item xs={4} sm={2} sx={sxTitle}>
              <Typography>Walkthrough</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>
                <Typography>
                  {action.walkthrough_id && (
                    <Link component={RouterLink} to={`/walkthrough/${action.walkthrough_id}`}>{action.walkthrough_id}</Link>
                  )}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2} sx={sxTitle}>
              <Typography>人物ID候補</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>
                <Typography>{action.detect_info?.candidates.join(',')}</Typography>
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="h5" sx={{ marginTop: 2, paddingBottom: 1, borderBottom: '1px lightgray solid' }}>映像</Typography>
          {movies.map(movie => (
            <MovieCard
              key={movie.cam_code}
              movieUrl={movie.url}
              title={movie.cam_code}
              maxWidth={600}
              imgHeight={400}
            />
          ))}
          {movies.length === 0 &&
            <Typography sx={{ marginTop: 1, marginBottom: 2 }}> 映像はありません。</Typography>
          }
        </div>
      }
    </MainContents >
  )
}