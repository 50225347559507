import React, {
  useEffect,
  useState,
  useCallback,
} from 'react'
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
} from '@mui/material';
import {
  grey,
} from '@mui/material/colors'
import {
  Plug,
  PlugStatus,
} from 'models'
import {
  subscribe,
} from 'iot'
import {
  PlugStatusMark,
} from 'components'

const isChecked = (devices: PlugsModel[]) => {
  return Boolean(devices.map(d => d.checked).indexOf(false) === -1)
}
const isIndeterminate = (devices: PlugsModel[]) => {
  const checked = devices.map(d => d.checked);
  return Boolean(checked.indexOf(false) !== -1 && checked.indexOf(true) !== -1)
}

export interface PlugsTableModel {
  plugs: PlugsModel[];
  thing_name?: string;
}

interface PlugsModel extends Plug {
  checked: boolean;
  status: PlugStatus;
}

interface PlugsTableProps {
  model: PlugsTableModel,
  onChanged: () => void,
}

export const PlugsTable: React.FC<PlugsTableProps> = ({ model, onChanged }) => {
  const [plugs, setPlugs] = useState<PlugsModel[]>(model.plugs)
  const [checked, setChecked] = useState<boolean>(isChecked(model.plugs))
  const [indeterminate, setIndeterminate] = useState<boolean>(isIndeterminate(model.plugs))

  // subscribe device shadow update
  useEffect(() => {
    console.log(model)
    if (model.thing_name === undefined) return;
    const subscriber: any = subscribe<any>(`$aws/things/${model.thing_name}/shadow/name/status/update/documents`, (data) => {
      const stat = data.value?.current?.state?.reported?.plugs || {}
      for (let plugName in stat) {
        const plug = model.plugs.find(p => p.name == plugName)
        if (plug) plug.status = stat[plugName]
      }
      console.log(model.plugs)
      setPlugs([...model.plugs])
    })
    return () => {
      if (subscriber) subscriber.disconnect()
    }
  }, [model])

  const handleAllCheckClicked = useCallback((_, checked: boolean) => {
    const newPlugs = plugs.map(d => ({
      ...d,
      checked,
    }))
    console.log(newPlugs)
    model.plugs = newPlugs
    setPlugs(newPlugs)
    setChecked(isChecked(newPlugs))
    setIndeterminate(isIndeterminate(newPlugs))
    onChanged()
  }, [model, plugs, onChanged])
  const handleChanged = useCallback(() => {
    const newPlugs = [...plugs]
    console.log(newPlugs)
    model.plugs = newPlugs
    setPlugs(newPlugs)
    setChecked(isChecked(newPlugs))
    setIndeterminate(isIndeterminate(newPlugs))
    onChanged()
  }, [model, plugs, onChanged])

  return (
    <TableContainer sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <Table size="small">
        <TableHead>
          <TableRow sx={{ backgroundColor: grey[50] }}>
            <TableCell padding="checkbox">
              <Checkbox
                size="small"
                checked={checked}
                indeterminate={indeterminate}
                onChange={handleAllCheckClicked}
              />
            </TableCell>
            <TableCell padding="checkbox" />
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {plugs.map((p, i) => (
            <PlugsTableRow
              key={`plugs_row_${i}`}
              model={p}
              onChanged={handleChanged}
            />
          ))}
        </TableBody>
      </Table>
      {plugs.length === 0 && (
        <Box sx={{ margin: 1 }}>プラグはありません</Box>
      )}
    </TableContainer>
  )
}

interface PlugsTableRowProps {
  model: PlugsModel,
  onChanged: () => void,
}

const PlugsTableRow: React.FC<PlugsTableRowProps> = ({
  model,
  onChanged,
}) => {
  const handleCheckChanged = useCallback((_, checked: boolean) => {
    model.checked = checked
    onChanged()
  }, [model, onChanged])
  return (
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          size="small"
          checked={model.checked}
          onChange={handleCheckChanged}
        />
      </TableCell>
      <TableCell padding="checkbox">
        <PlugStatusMark status={model.status} />
      </TableCell>
      <TableCell padding="none">
        {model.name}
      </TableCell>
      <TableCell>
        {model.desc}
      </TableCell>
    </TableRow>
  )
}