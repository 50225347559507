import React, {
  useCallback,
} from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import {
  useStoreLayout,
} from 'contexts'
import {
  AreaModel,
} from './Area'
import {
  DiagonalAreaModel,
  isAreaModel,
} from './DiagonalArea'

type FormData = {
  heights: string[]
}

export interface ShelfSettingsDialogProps {
  open: boolean
  onClose: () => void
  shelf: AreaModel | DiagonalAreaModel
}

export const ShelfSettingsDialog: React.FC<ShelfSettingsDialogProps> = ({ open, onClose, shelf }) => {
  const { shelfAreas, diagonalShelfAreas, setShelfAreas, setDiagonalShelflAreas } = useStoreLayout()
  const { register, handleSubmit, getValues, formState, formState: { errors } } = useForm<FormData>({
    mode: 'onBlur',
  })

  const onSubmit = useCallback((data: FormData) => {
    console.log(data)
    const heights = data.heights.map(h => Number(h))
    if (isAreaModel(shelf)) {
      const idx = shelfAreas.findIndex(a => a.name === shelf.name)
      const newShelfAreas = [...shelfAreas]
      newShelfAreas[idx] = {
        ...shelf,
        heights: heights,
      }
      setShelfAreas(newShelfAreas)  
    } else {
      const idx = diagonalShelfAreas.findIndex(a => a.name === shelf.name)
      const newDiagonalShelfAreas = [...diagonalShelfAreas]
      newDiagonalShelfAreas[idx] = {
        ...shelf,
        heights: heights,
      }
      setDiagonalShelflAreas(newDiagonalShelfAreas)
    }
    onClose()
  }, [shelf, shelfAreas, diagonalShelfAreas, setShelfAreas, setDiagonalShelflAreas, onClose])

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>棚設定</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {shelf.heights?.map((h, idx) => (
              <Grid item xs={12} key={`heights-${idx}`}>
                <TextField
                  label={`${idx + 1}段目の高さ`}
                  size="small"
                  margin="dense"
                  type="number"
                  required
                  defaultValue={h}
                  inputProps={{ step: '10' }}
                  {...register(`heights.${idx}`, {
                    required: {
                      value: true,
                      message: `${idx + 1}段目の高さを入力してください`,
                    },
                    max: {
                      value: 2000,
                      message: '300〜2000で入力してください',
                    },
                    min: {
                      value: 300,
                      message: '300〜2000で入力してください',
                    },
                    validate: () => {
                      // if (errors.heights) return undefined // 他にエラーがある
                      const heights = getValues().heights.map(h => Number(h))
                      console.log(idx, heights)
                      if (0 < idx && heights[idx - 1] <= heights[idx]) return '上の段より高くできません。'
                      if (idx < heights.length - 1 && heights[idx] <= heights[idx + 1]) return '下の段より低くできません。'
                      return undefined
                    },
                  })}
                  error={Boolean(errors?.heights && idx < errors.heights.length && errors.heights[idx])}
                  helperText={errors?.heights && idx < errors.heights.length && errors.heights[idx]?.message}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={onClose}>キャンセル</Button>
          <Button color="primary" variant="contained" type="submit" disabled={!formState.isValid}>OK</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
