import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useParams } from 'react-router-dom'
import { Toolbar, Tooltip, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import {
  List as ListIcon,
  ViewComfy as GridIcon,
} from '@mui/icons-material'
import MaterialTable from '@material-table/core'
import {
  useApp,
} from 'contexts'
import { Item } from 'models'
import {
  WeightLabel,
  Loading,
  WarnigMessage,
  MaterialTableOptions,
  MaterialTableComponents,
  MaterialTableLocalization,
} from 'components'


export const StoresItems: React.FC = () => {
  const { getApi } = useApp()
  const { storeCode } = useParams()
  if (storeCode === undefined) throw new Error('unhandled routing')
  const [items, setItems] = useState<Item[] | null>(null)
  const [variant, setVariant] = useState<string | null>('grid')
  console.log(`storeCode: ${storeCode}`)

  useEffect(() => {
    console.log(`Items#useEffect ${storeCode}`)
    console.log(storeCode)
    getApi()
      .fetchItems(storeCode)
      .then((result) => {
        console.log('fetchAsync done')
        setItems(result.items)
      })
      .catch((err) => console.log(err))
  }, [getApi, storeCode])

  const handleVariantChanged = useCallback((_evt: React.MouseEvent, newVariant: string | null) => {
    if (newVariant === null) return
    setVariant(newVariant)
  }, [])

  return (
    <>
      <Toolbar variant="dense" disableGutters>
        <ToggleButtonGroup value={variant} exclusive onChange={handleVariantChanged} size="small" sx={{ marginLeft: 'auto' }}>
          <ToggleButton value="grid">
            <Tooltip title="グリッド表示"><GridIcon /></Tooltip>
          </ToggleButton>
          <ToggleButton value="list">
            <Tooltip title="リスト表示"><ListIcon /></Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Toolbar>
      <Loading visible={items === null} marginBottom={15} />
      {items && (variant === 'grid') && (
        <ImageList rowHeight={180} cols={4}>
          {items.map(item => (
            <ImageListItem key={item.item_code}>
              <img src={item.image_url} alt={item.item_name.ja} />
              <ImageListItemBar
                title={item.item_name.ja}
                subtitle={<span>{item.weight}g</span>}
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
      {items && (variant === 'list') && (
        <MaterialTable
          data={items}
          columns={[
            {
              title: '商品コード',
              field: 'item_code',
              render: (d) => (<>{d.item_code.split('_')[1]}</>),
              sorting: true,
            },
            {
              title: '商品名',
              field: 'name',
              render: (d) => (<>{d.item_name.ja}</>),
              sorting: false,
            },
            {
              title: '重量',
              field: 'weight',
              render: (d) => (<WeightLabel value={d.weight} />),
              sorting: true,
            },
          ]}
          options={MaterialTableOptions<Item>({
            search: false,
            toolbar: false,
            pageSize: 20,
            pageSizeOptions: [20, 50, 100],
          })}
          components={{
            ...MaterialTableComponents,
          }}
          localization={{
            ...MaterialTableLocalization
          }}
        />
      )}
      <WarnigMessage
        hidden={items === null || 0 < items.length}
        title="商品が見つかりませんでした"
      />
    </>
  )
}
