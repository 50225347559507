import React, {
  useEffect,
  useState,
  useCallback,
} from 'react'
import {
  useParams,
  useNavigate,
} from 'react-router-dom'
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Toolbar,
  Tooltip,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import {
  Add,
  List as ListIcon,
  ViewComfy as GridIcon,
  Block as DiasbledIcon,
} from '@mui/icons-material'
import MaterialTable from '@material-table/core'
import {
  useShelvesLayout,
} from 'hooks'
import {
  ShelvesLayout,
  getMinItemWeight,
} from 'models'
import {
  Loading,
  WarnigMessage,
  ShelfTile,
  WeightLabel,
  CheckLabel,
  MaterialTableOptions,
  MaterialTableComponents,
  MaterialTableLocalization,
} from 'components'
import { AddDialog } from './shelves/components/AddDialog'

interface ListModel {
  shelfCode: string
  rowCount: number
  count: number
  minItemWeight: number | undefined
  weightThreshold: number | undefined
  weightFluctuation: boolean
  disabled: boolean
}

const layoutToModels = (layout: ShelvesLayout | null): ListModel[] | undefined => {
  if (layout === null) return undefined
  return layout.shelves.map(shelf => ({
    shelfCode: shelf.shelfCode,
    rowCount: shelf.segments.length,
    count: shelf.segments.map(seg => seg.length).reduce((pre, acc) => pre + acc, 0),
    minItemWeight: getMinItemWeight(shelf),
    weightThreshold: shelf.weightThreshold,
    weightFluctuation: Boolean(shelf.weightFluctuation),
    disabled: Boolean(shelf.disabled),
  }))
}


interface ShelfNameProps {
  value: string
  disabled?: boolean
}
const ShelfName: React.FC<ShelfNameProps>  = ({ value, disabled }) => (
  <div
    style={{
      textAlign: 'center',
    }}
  >
    {value.split('_')[1]}{disabled && (<Tooltip title="無効"><DiasbledIcon fontSize="inherit"/></Tooltip>)}
  </div>
)


export const StoresShelves: React.FC = () => {
  const { storeCode } = useParams()
  if (storeCode === undefined) throw new Error('unhandled routing')
  const navigate = useNavigate()
  const { layout, setReload } = useShelvesLayout(storeCode)
  const [items, setItems] = useState<ListModel[]>()
  const [variant, setVariant] = useState<string | null>('grid')
  const [addDialogOpen, setAddDialogOpen] = useState(false)
  console.log(`StoresShelves##`)

  useEffect(() => {
    setItems(layoutToModels(layout))
  }, [layout])  

  const handleVariantChanged = useCallback((_evt: React.MouseEvent, newVariant: string | null) => {
    if (newVariant === null) return
    setVariant(newVariant)
  }, [])
  const handleAdded = useCallback(() => {
    setAddDialogOpen(false)
    setReload(true)
  }, [setReload])

  return (
    <>
      <Toolbar 
        variant="dense"
      >
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Add />}
          onClick={() => setAddDialogOpen(true)}
          sx={{ marginLeft: 'auto' }}
        >
        棚を作成
        </Button>
        <AddDialog
          open={addDialogOpen}
          onClose={() => setAddDialogOpen(false)} 
          onCreate={handleAdded}
        />
        <ToggleButtonGroup value={variant} exclusive onChange={handleVariantChanged} size="small" sx={{ marginLeft: 1 }}>
          <ToggleButton value="grid">
            <Tooltip title="グリッド表示"><GridIcon/></Tooltip>
          </ToggleButton>
          <ToggleButton value="list">
            <Tooltip title="リスト表示"><ListIcon/></Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Toolbar>
      <Loading visible={layout === null} />
      {layout && (variant === 'grid') && (
      <ImageList
        cols={3}
        rowHeight="auto"
        sx={{ alignItems: 'flex-end' }}
      >
        {layout.shelves.map(shelf => (
        <ImageListItem
          key={shelf.shelfCode}
          sx={{ cursor: 'pointer' }}
        >
          <ShelfTile
            model={shelf}
            showItems
            onClick={() => navigate(`/stores/${storeCode}/shelves/${shelf.shelfCode.split('_')[1]}`)}
          />
          <ImageListItemBar
            title={<ShelfName value={shelf.shelfCode} disabled={shelf.disabled}/>}
          />
        </ImageListItem>
        ))}
      </ImageList>
      )}
      {items && (variant === 'list') &&(
      <MaterialTable
        data={items}
        columns={[
          {
            title: '棚',
            field: 'shelfCode',
            render: (d) => (<>{d.shelfCode.split('_')[1]}{d.disabled && (<Tooltip title="無効"><DiasbledIcon fontSize="inherit"/></Tooltip>)}</>),
            sorting: true,
          },
          {
            title: '段数',
            field: 'rowCount',
            sorting: true,
          },
          {
            title: 'カゴ数',
            field: 'count',
            sorting: true,
          },
          {
            title: '最小重量',
            field: 'minItemWeight',
            render: (d) => (<WeightLabel value={d.minItemWeight}/>),
            sorting: true,
          },
          {
            title: '重量閾値',
            field: 'weightThreshold',
            render: (d) => (<WeightLabel value={d.weightThreshold}/>),
            sorting: true,
          },
          {
            title: '誤差許容',
            field: 'weightFluctuation',
            render: (d) => (<CheckLabel value={d.weightFluctuation}/>),
          },
        ]}
        onRowClick={(_, row) => row && navigate(`/stores/${storeCode}/shelves/${row.shelfCode.split('_')[1]}`)}
        options={MaterialTableOptions<ListModel>({
          search: false,
          toolbar: false,
        })}
        components={{
          ...MaterialTableComponents,
        }}
        localization={{
          ...MaterialTableLocalization
        }}
      />
      )}
      <WarnigMessage
        hidden={layout === null || 0 < layout.shelves.length} 
        title="登録された棚はありません"
      />
    </>
  )
}
