import React, {
  useState,
  useCallback,
} from 'react'
import { useForm } from 'react-hook-form'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  TextField,
  Button,
  Box,
} from '@mui/material';
import {
  useApp,
} from 'contexts'
import {
  Operator,
} from 'models'
import {
  ProgressScreen,
} from 'components'
import {
  FormData,
  FormValidations,
  dataToModel,
} from './forms'

type OperatorAddDialogProps = {
  open: boolean
  onClose: () => void
  onCreate: () => void
}

export const OperatorAddDialog: React.FC<OperatorAddDialogProps> = ({ open, onClose, onCreate }) => {
  const { getApi } = useApp()
  const [isUpdating, setIsUpdating] = useState(false)
  const { register, handleSubmit, formState, formState: { errors } } = useForm<FormData>({
    mode: 'onBlur',
  })

  // 追加
  const onSubmit = useCallback((form: FormData) => {
    setIsUpdating(true)
    const operator: Operator = dataToModel(form)
    getApi().updateOperator(operator)
      .then(() => {
        onCreate()
      })
      .finally(() => {
        setIsUpdating(false)
      })
  }, [getApi, onCreate])

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>オペレータを追加</DialogTitle>
        <DialogContent>
          <Box sx={{ margin: 0 }}>
            <FormControl sx={{ margin: 1 }}>
              <TextField
                label="オペレータ名"
                required
                {...register(`name`, FormValidations.name)}
                error={Boolean(errors?.name)}
                helperText={errors?.name?.message}
              />
            </FormControl>
            <FormControl sx={{ margin: 1 }}>
              <TextField
                label="通知先番号"
                required
                {...register(`phone_no`, FormValidations.phone_no)}
                error={Boolean(errors?.phone_no)}
                helperText={errors?.phone_no?.message}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={onClose}>キャンセル</Button>
          <Button color="primary" variant="contained" type="submit" disabled={!formState.isValid} >追加</Button>
        </DialogActions>
      </form>
      <ProgressScreen open={isUpdating} />
    </Dialog>
  )
}