import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  Toolbar,
  Grid,
} from '@mui/material'
import { DateTime } from 'luxon'
import {
  useApp,
} from 'contexts'
import {
  useSelectedStoreCode,
} from 'hooks'
import {
  Store,
  MonthlyStatistics,
} from 'models'
import {
  MainContents,
  StoresSelector,
  Loading,
  ReloadButton,
  MonthPicker,
} from 'components'

export const StatisticsMonthly: React.FC = () => {
  const {storeCode, setStoreCode} = useSelectedStoreCode()
  const [date, setDate] = useState(DateTime.now())
  const [reload, setReload] = useState(false)
  const [statisticsData, setStatisticsData] = useState<MonthlyStatistics>()
  const { getApi, defaultApiErrorHandler } = useApp()

  const load = useCallback(() => {
    if (storeCode === undefined) return
    getApi().fetchMonthlyStatistics(storeCode, date)
      .then((data) => {
        setStatisticsData(data)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, storeCode, date])
  useEffect(() => {
    load()
  }, [load])
  // reload
  useEffect(() => {
    if (!reload) return
    console.log('reloading...')
    load()
    setReload(false)
  }, [load, reload])

  const onStoreChanged = useCallback((store: Store) => {
    setStoreCode(store.store_code)
  }, [setStoreCode])
  const handleDateChange = useCallback((dt: DateTime) => {
    setDate(dt)
  }, [])
  const handleReload = useCallback(() => {
    setStatisticsData(undefined)
    setReload(true)
  }, [])

  return (
    <MainContents>
      <Toolbar variant="dense" disableGutters>
        <StoresSelector
          storeCode={storeCode}
          onSeleced={onStoreChanged}
        />
        <MonthPicker
          onChange={handleDateChange}
        />
        <ReloadButton marginLeft="auto" onClick={handleReload} />
      </Toolbar>
      <Loading visible={statisticsData === undefined} />
      {statisticsData && (
      <Grid container>
        <Grid xs={6}>
        </Grid>
        <Grid xs={6}>
        </Grid>
      </Grid>
      )}
    </MainContents>
  )
}
