import React, {
  useEffect,
  useState,
  useCallback,
} from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormLabel,
  Box,
  Switch,
  TextField,
  Select,
  MenuItem,
  Button,
  Theme,
  SxProps,
} from '@mui/material';
import {
  useForm,
} from 'react-hook-form'
import {
  EventName,
  EventType,
  EventHook,
} from 'models'
import {
  useApp,
} from 'contexts'
import {
  EventHookFormData,
  EventHookFormValidations,
  toEventHook,
} from './forms'

const sxLabelItem: SxProps<Theme> = {
  marginTop: 2,
  paddingRight: 2,
  textAlign: 'right',
  width: '100%',
  fontWeight: 'bold',
};
const sxLabelSelect: SxProps<Theme> = {
  marginTop: 1,
  paddingRight: 2,
  textAlign: 'right',
  width: '100%',
  fontWeight: 'bold',
};
const sxSelectItem: SxProps<Theme> = {
  marginTop: 1,
  marginBottom: 1,
};
const sxLabel: SxProps<Theme> = {
  color: '#333333',
};
const sxField: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
};

type EventHookAddDialogProps = {
  storeCode: string
  eventNames: EventName[]
  open: boolean
  onClose: () => void
  onAdded: (updated: EventHook) => void
}

export const EventHookAddDialog: React.FC<EventHookAddDialogProps> = ({
  storeCode,
  eventNames,
  open,
  onClose,
  onAdded,
}) => {
  const [eventName, setEventName] = useState<EventName>(0 < eventNames.length ? eventNames[0] : 'notice')
  const [type, setType] = useState<EventType>('lambda')
  const [dryRun, setDryRun] = useState<boolean>(false)
  const { register, handleSubmit, reset, formState, formState: { errors } } = useForm<EventHookFormData>({
    mode: 'onBlur',
  })
  const { getApi, defaultApiErrorHandler } = useApp()

  useEffect(() => {
    setEventName(0 < eventNames.length ? eventNames[0] : 'notice')
    reset()
  }, [eventNames])
  
  const handleEventNameChanged = useCallback((evt: any) => {
    setEventName(evt.target.value)
  }, [])
  const handleTypeChanged = useCallback((evt: any) => {
    setType(evt.target.value)
  }, [])
  const handleDryRunSwitchChanged = useCallback((evt: any) => {
    setDryRun(evt.target.checked)
  }, [])
  
  const onSubmit = useCallback((data: EventHookFormData) => {
    const hook = toEventHook(storeCode, eventName, type, dryRun, data)
    getApi()
      .updateEventHook(hook)
      .then(result => {
        onAdded(result)
      })
      .catch(defaultApiErrorHandler)
  }, [onAdded, storeCode, eventName, type, dryRun, getApi, defaultApiErrorHandler])

  return (
  <Dialog
    maxWidth="md"
    open={open}
    onClose={onClose}
    disableEscapeKeyDown
  >
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>イベントフック追加</DialogTitle>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item xs={2} sx={sxLabelItem}>
            <FormLabel sx={sxLabel}>イベント名{eventNames.length}</FormLabel>
          </Grid>
          <Grid item xs={10} sx={sxSelectItem}>
            <Select
              size="small"
              margin="dense"
              value={eventName}
              onChange={handleEventNameChanged}
            >
              {eventNames.map(name => (
              <MenuItem value={name}>{name}</MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={2} sx={sxLabelItem}>            
            <FormLabel sx={sxLabel}>タイプ</FormLabel>
          </Grid>
          <Grid item xs={10} sx={sxSelectItem}>
            <Select
              size="small"
              margin="dense"
              value={type}
              onChange={handleTypeChanged}
            >
              <MenuItem value="lambda">Lambda</MenuItem>
              <MenuItem value="sns">SNS</MenuItem>
            </Select>
          </Grid>

          <Grid item xs={2} sx={sxLabelItem}>
            <FormLabel sx={sxLabel}>ARN</FormLabel>
          </Grid>
          <Grid item xs={10}>
            <Box sx={sxField}>
              <TextField
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                fullWidth
                required
                {...register(`options_arn`, EventHookFormValidations.options_arn)}
                error={Boolean(errors?.options_arn)}
                helperText={errors?.options_arn?.message}
              />
            </Box>
          </Grid>

          <Grid item xs={2} sx={sxLabelSelect}>
            <FormLabel sx={sxLabel}>dry run</FormLabel>
          </Grid>
          <Grid item xs={10}>
            <Switch
              checked={dryRun}
              disabled={type !== 'lambda'}
              onChange={handleDryRunSwitchChanged}
              color="primary"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>キャンセル</Button>
        <Button color="primary" variant="contained" type="submit" disabled={!formState.isValid}>追加</Button>
      </DialogActions>
    </form>
  </Dialog>
  )
}