import React from 'react'
import {
  Routes,
  Route,
} from 'react-router-dom'
import { MonitorTracking } from './MonitorTracking'
import { MonitorWeight } from './MonitorWeight'
import { MonitorDevice } from './MonitorDevice'

export const MonitorRoutes: React.FC = () => (
  <Routes>
    <Route path="tracking" element={<MonitorTracking />} />
    <Route path="weight" element={<MonitorWeight />} />
    <Route path="device" element={<MonitorDevice />} />
  </Routes>
)
