import React from 'react'
import {
  Typography,
} from '@mui/material'


interface ItemImgProps {
  imageUrl: string
  itemName: string
  subText?: string
  height?: string|number
}

export const ItemCell: React.FC<ItemImgProps>  = ({ itemName, imageUrl, subText, height }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={imageUrl}
        alt={itemName}
        style={{
          height: height || 32,
          marginLeft: 5,
          marginRight: 5,
        }} />
      <div style={{ flexGrow: 1 }}>
        <Typography variant="subtitle1" >{itemName}</Typography>
        {subText &&
        <Typography variant="body2" style={{ textAlign: 'right' }} >{subText}</Typography>
        }
      </div>
    </div>
  )
}
