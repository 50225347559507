export interface SystemStatisticsData {
  store_code: string;
  date: string;
  fatal: SystemStatisticsDetails;
  error: SystemStatisticsDetails;
  warn: SystemStatisticsDetails;
  info: SystemStatisticsDetails;
}

export interface SystemStatisticsDetails {
  cam: number;
  ws: number;
  gk: number;
  st: number;
  tof: number;
  bst: number;
  sys: number;
}

export interface MonthlySystemStatistics {
  month: string;
  data: SystemStatisticsData[];
}

export const totalSystemStatisticsDetailsCount = (d: SystemStatisticsDetails) => (d.cam + d.ws + d.gk + d.st + d.tof + d.bst + d.sys)