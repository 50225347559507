import React from 'react'
import {
  Routes,
  Route,
} from 'react-router-dom'
import { Statistics } from './Statistics'
import { StatisticsMonthly } from './StatisticsMonthly'
import { StatisticsSystem } from './StatisticsSystem'

export const StatisticsRoutes: React.FC = () => (
<Routes>
  <Route index element={<Statistics/>}/>
  <Route path="monthly" element={<StatisticsMonthly/>}/>
  <Route path="system" element={<StatisticsSystem/>}/>
</Routes>
)

