import React from 'react'
import {
  Card,
  CardMedia,
} from '@mui/material'

interface MovieCardProps {
  movieUrl: string
  title?: string
  maxWidth?: number
  imgHeight?: number
}

export const MovieCard: React.FC<MovieCardProps>  = ({ title, movieUrl, maxWidth, imgHeight }) => {
  return (
    <Card style={{ maxWidth: maxWidth || 640, margin: 10 }}>
      <CardMedia
        component="video"
        title={title}
        src={movieUrl}
        style={{ height: imgHeight || 480 }}
        autoPlay
        controls
      />
    </Card>
  )
}
