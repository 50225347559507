import { Auth } from 'aws-amplify'
import * as AWS from 'aws-sdk'

export class Iot {

  constructor(
    private region: string,
    private endpoint: string,
  ) {}

  private async newAwsIot() {
    const credentials = await Auth.currentCredentials()
    return new AWS.Iot({
      region: this.region,
      credentials,
    })
  }

  private async newAwsIotData() {
    const credentials = await Auth.currentCredentials()
    return new AWS.IotData({
      endpoint: this.endpoint,
      region: this.region,
      credentials,
    })
  }

  async attachPolicy(identityId: string, policyName: string) {
    const iot = await this.newAwsIot()
    const targets = await iot.listTargetsForPolicy({
      policyName,
    }).promise()
    if (targets.targets && targets.targets.find(t => t.endsWith(identityId)) === undefined) {
      console.log('attach_policy', identityId)
      await iot.attachPolicy({
        policyName,
        target: identityId,
      }).promise()  
    }
  }

  async listThings(params: AWS.Iot.Types.ListThingsRequest) {
    const iot = await this.newAwsIot()
    return iot.listThings(params).promise()
  }

  async getThingShadow(params: AWS.IotData.Types.GetThingShadowRequest) {
    const iotData = await this.newAwsIotData()
    return iotData.getThingShadow(params).promise()
  }

  async updateThingShadow(params: AWS.IotData.Types.UpdateThingShadowRequest) {
    const iotData = await this.newAwsIotData()
    return iotData.updateThingShadow(params).promise()
  }

}