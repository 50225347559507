import React, {
} from 'react'
import {
  Snackbar,
} from '@mui/material'
import { Alert } from '@mui/material';

export interface SnackbarAlertProps {
  open?: boolean
  onClose?: () => void
  severity?: 'success'|'info'|'warning'|'error'
}

export const SnackbarAlert: React.FC<SnackbarAlertProps> = ({ open, onClose, severity, children }) => {

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={onClose} severity={severity}>{children}</Alert>
    </Snackbar>
  )
}