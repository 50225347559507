import React, {
  useEffect,
  useState,
  useMemo,
} from 'react'
import {
  Outlet,
  Link as RouterLink,
  Location,
  useLocation,
  useParams,
} from 'react-router-dom'
import {
  Breadcrumbs,
  Link,
  Typography,
  Tabs,
  Tab,
} from '@mui/material';
import {
  Store as StoreIcon,
} from '@mui/icons-material'
import {
  useApp,
} from 'contexts'
import {
  MainContents,
} from 'components'

const getTabValue = (location: Location) => {
  switch(location.pathname.split('/').splice(-1)[0]) {
    case '':
      return 0
    case 'items':
      return 1
    case 'layout':
      return 2
    case 'shelves':
      return 3
    case 'config':
      return 4
    case 'boot_config':
      return 5
    case 'hooks':
      return 6
    default:
      return 0    
  }
}

export const StoresStore: React.FC = () => {
  const location = useLocation()
  const { storeCode } = useParams()
  if (storeCode === undefined) throw new Error('unhandled routing')
  const { getStore, getApi, defaultApiErrorHandler } = useApp()
  const store = useMemo(() => getStore(storeCode), [storeCode, getStore])
  const [disableSensor, setDisableSensor] = useState<boolean>(true)
  const tabValue = getTabValue(location)

  useEffect(() => {
    getApi()
      .fetchConfiguration(storeCode)
      .then(result => {
        setDisableSensor(Boolean(result.disable_sensor))
      })
      .catch(defaultApiErrorHandler)
  }, [storeCode, getApi, defaultApiErrorHandler])
  
  return (
    <MainContents>
      <Breadcrumbs>
        <Link component={RouterLink} to="/stores" sx={{ display: 'flex' }}>
          <StoreIcon sx={{ marginRight: 0.5 }}/>
          店舗
        </Link>
        <Typography color="textPrimary">{store.store_name}</Typography>
      </Breadcrumbs>
      <Tabs
        value={tabValue}
      >
        <Tab
          component={RouterLink}
          label="ダッシュボード"
          to={`/stores/${storeCode}/`}
        />
        <Tab
          component={RouterLink}
          label="商品"
          to={`/stores/${storeCode}/items`}
        />
        <Tab
          component={RouterLink}
          label="レイアウト"
          to={`/stores/${storeCode}/layout`}
          disabled={disableSensor}
        />
        <Tab
          component={RouterLink}
          label="棚"
          to={`/stores/${storeCode}/shelves`}
          disabled={disableSensor}
        />
        <Tab
          component={RouterLink}
          label="設定"
          to={`/stores/${storeCode}/config`}
        />
        <Tab
          component={RouterLink}
          label="起動設定"
          to={`/stores/${storeCode}/boot_config`}
        />
        <Tab
          component={RouterLink}
          label="連携"
          to={`/stores/${storeCode}/hooks`}
        />
      </Tabs>
      <Outlet/>
    </MainContents>
  )
}
