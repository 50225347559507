import React from 'react'
import {
  Routes,
  Route,
} from 'react-router-dom'
import { Stores } from './Stores'
import { StoresStore } from './StoresStore'
import { StoresDashboard } from './StoresDashboard'
import { StoresItems } from './StoresItems'
import { StoresLayout } from './StoresLayout'
import { StoresShelves } from './StoresShelves'
import { StoresShelf } from './StoresShelf'
import { StoresConfg } from './StoresConfg'
import { StoresBootConfig } from './StoresBootConfig'
import { StoresHooks } from './StoresHooks'

export const StoresRoutes: React.FC = () => (
<Routes>
  <Route index element={<Stores/>}/>
  <Route path=":storeCode" element={<StoresStore/>}>
    <Route index element={<StoresDashboard/>}/>
    <Route path="items" element={<StoresItems/>}/>
    <Route path="layout" element={<StoresLayout/>}/>
    <Route path="shelves">
      <Route index element={<StoresShelves/>}/>
      <Route path=":shelfCode" element={<StoresShelf/>} />
    </Route>
    <Route path="config" element={<StoresConfg/>}/>
    <Route path="boot_config" element={<StoresBootConfig/>}/>
    <Route path="hooks" element={<StoresHooks/>}/>
  </Route>
</Routes>
)
