import {
  Store,
  Configuration,
  BootConfiguration,
  BootSchedule,
  StoreEnv,
  Location,
  Shelf,
  ShelfAreaUpdatePart,
  Segment,
  Walkthrough,
  Item,
  Action,
  ActionMovie,
  Obscure,
  Device,
  WeightSensorDevice,
  EdgeDevice,
  DeviceType,
  DeviceImage,
  FloorItems,
  FloorItem,
  Notification,
  StatisticsData,
  MonthlyStatistics,
  MonthlySystemStatistics,
  UnixTimestamp,
  ConfirmInput,
  WalkthroughsOutput,
  WalkthroughMoviesOutput,
  ActionsOutput,
  DevicesOutput,
  WeightSensorDevicesOutput,
  GateKeeperDevicesOutput,
  EdgeDevicesOutput,
  DeviceUpdatePayload,
  WeightSensorDeviceUpdatePayload,
  GateKeeperDeviceUpdatePayload,
  EdgeDeviceUpdatePayload,
  Operator,
  OperatorsOutput,
  GateKeeperDevice,
  Credential,
  CredentialsOutput,
  ScopesOutput,
  FlowsOutput,
  Flow,
  SegmentQuantities,
  ShelfConfigUpdatePart,
  Replay,
  ReplayData,
  ReplayRequestPayload,
  EventHook,
  BootLog,
} from 'models'
import {
  isUnixTimestamp,
} from 'utils'
import { DateTime } from 'luxon'

type ApiReturnType<T> = {
  items: T
}

export class Unauthorized extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'Unauthorized';
  }
}

export class Forbidden extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'Forbidden';
  }
}

export class ApiError extends Error {
  constructor(message?: string, name?: string) {
    super(message);
    this.name = name || 'ApiError';
  }
}

export class SystemError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'SystemError';
  }
}

class PassregiApi {
  constructor(
    private readonly baseUrl: string,
    private readonly token: string
  ) { }

  async fetchItems(storeCode: string): Promise<ApiReturnType<Item[]>> {
    return await fetch(`${this.baseUrl}/items?store_code=${storeCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  // ====== STORE =======
  async fetchStores(): Promise<ApiReturnType<Store[]>> {
    return await fetch(`${this.baseUrl}/stores`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchStore(storeCode: string): Promise<Store> {
    return await fetch(`${this.baseUrl}/stores/${storeCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async setupStore(storeCode: string, storeName: string, merchantCode: string | undefined, disableSensor: boolean): Promise<Store> {
    return await fetch(`${this.baseUrl}/stores/${storeCode}/setup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify({
        store_name: storeName,
        merchant_code: merchantCode,
        disable_sensor: disableSensor,
      }),
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async updateStoreName(storeCode: string, storeName: string): Promise<Store> {
    return await fetch(`${this.baseUrl}/stores/${storeCode}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify({
        store_name: storeName,
      }),
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async updateStoreMock(storeCode: string, isMock: boolean): Promise<Store> {
    return await fetch(`${this.baseUrl}/stores/${storeCode}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify({
        is_mock: isMock,
      }),
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchLocations(storeCode: string): Promise<ApiReturnType<Location[]>> {
    return await fetch(`${this.baseUrl}/locations?store_code=${storeCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async updateLocations(locations: Location[], deleteLocationCodes?: string[]): Promise<Location[]> {
    return await fetch(`${this.baseUrl}/locations`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify({
        items: locations,
        delete_location_codes: deleteLocationCodes,
      }),
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchConfiguration(storeCode: string): Promise<Configuration> {
    return await fetch(`${this.baseUrl}/configurations/${storeCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchBootConfiguration(storeCode: string): Promise<BootConfiguration> {
    return await fetch(`${this.baseUrl}/boot_configurations/${storeCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchBootSchedule(storeCode: string): Promise<BootSchedule> {
    return await fetch(`${this.baseUrl}/boot_schedules/${storeCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async bootStore(storeCode: string): Promise<Store> {
    return await fetch(`${this.baseUrl}/stores/${storeCode}/boot`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async shutdownStore(storeCode: string): Promise<Store> {
    return await fetch(`${this.baseUrl}/stores/${storeCode}/shutdown`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async rebootStore(storeCode: string): Promise<Store> {
    return await fetch(`${this.baseUrl}/stores/${storeCode}/reboot`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async restartServiceStore(storeCode: string): Promise<Store> {
    return await fetch(`${this.baseUrl}/stores/${storeCode}/restart_service`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async updateConfiguration(config: Configuration): Promise<Configuration> {
    return await fetch(`${this.baseUrl}/configurations/${config.store_code}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(config),
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async updateBootConfiguration(bootConfig: BootConfiguration): Promise<BootConfiguration> {
    return await fetch(`${this.baseUrl}/boot_configurations/${bootConfig.store_code}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(bootConfig),
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async updateBootSchedule(bootSchedules: BootSchedule): Promise<BootSchedule> {
    return await fetch(`${this.baseUrl}/boot_schedules/${bootSchedules.store_code}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(bootSchedules),
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchEnv(storeCode: string): Promise<StoreEnv> {
    return await fetch(`${this.baseUrl}/env/${storeCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async activeEnv(storeCode: string): Promise<StoreEnv> {
    return await fetch(`${this.baseUrl}/env/${storeCode}/active`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async deactiveEnv(storeCode: string): Promise<StoreEnv> {
    return await fetch(`${this.baseUrl}/env/${storeCode}/deactive`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  // Shelf
  async fetchShelves(storeCode: string): Promise<ApiReturnType<Shelf[]>> {
    return await fetch(`${this.baseUrl}/shelves?store_code=${storeCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchShelf(shelfCode: string): Promise<Shelf> {
    return await fetch(`${this.baseUrl}/shelves/${shelfCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchSegments(storeCode: string): Promise<ApiReturnType<Segment[]>> {
    return await fetch(`${this.baseUrl}/segments?store_code=${storeCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async addShelf(shelfCode: string): Promise<Shelf> {
    return await fetch(`${this.baseUrl}/shelves/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify({
        shelf_code: shelfCode,
      }),
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async updateShelfSegments(
    shelfCode: string,
    segmentCodes: string[][]
  ): Promise<Shelf> {
    return await fetch(`${this.baseUrl}/shelves/${shelfCode}/segments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify({
        segment_codes: segmentCodes,
      }),
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async updateShelfArea(
    shelfCode: string,
    payload: ShelfAreaUpdatePart,
  ): Promise<Shelf> {
    return await fetch(`${this.baseUrl}/shelves/${shelfCode}/area`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async updateShelfConfig(
    shelfCode: string,
    payload: ShelfConfigUpdatePart,
  ): Promise<Shelf> {
    return await fetch(`${this.baseUrl}/shelves/${shelfCode}/config`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchSegmentWithItem(segmentCode: string): Promise<Segment> {
    return await fetch(`${this.baseUrl}/segments/${segmentCode}/item`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchFloorItems(storeCode: string): Promise<FloorItems> {
    return await fetch(`${this.baseUrl}/floor_items/${storeCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async updateFloorItems(storeCode: string, items: FloorItem[]): Promise<FloorItems> {
    return await fetch(`${this.baseUrl}/floor_items/${storeCode}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify({
        items: items,
      }),
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async deleteShelf(shelfCode: string): Promise<Shelf> {
    return await fetch(`${this.baseUrl}/shelves/${shelfCode}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  // Weight Sensor Devices
  async fetchWeightSensorDevices(storeCode: string | undefined | null): Promise<WeightSensorDevicesOutput> {
    return this.fetchDevices<WeightSensorDevicesOutput>(storeCode, 'ws')
  }
  // GateKeeper Devices
  async fetchGateKeeperDevices(storeCode: string | undefined | null): Promise<GateKeeperDevicesOutput> {
    return this.fetchDevices<GateKeeperDevicesOutput>(storeCode, 'gk')
  }
  // Edge Devices
  async fetchEdgeDevices(storeCode: string | undefined | null): Promise<EdgeDevicesOutput> {
    return this.fetchDevices<EdgeDevicesOutput>(storeCode, 'edge')
  }
  async fetchDevices<T extends DevicesOutput>(storeCode: string | undefined | null, type?: DeviceType): Promise<T> {
    const params = new URLSearchParams()
    if (storeCode) params.append('store_code', storeCode)
    if (type) params.append('type', type)
    if (process.env.REACT_APP_ENV !== 'PRD' && process.env.REACT_APP_ENV !== 'STG') params.append('include_mock', 'true')
    const url = `${this.baseUrl}/devices?${params.toString()}`
    return await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchDevice(deviceCode: string): Promise<Device> {
    const url = `${this.baseUrl}/devices/${deviceCode}`
    return await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchDeviceImage(deviceCode: string): Promise<DeviceImage> {
    const url = `${this.baseUrl}/devices/${deviceCode}/image`
    return await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async registerDevice(thingName: string): Promise<Device> {
    const url = `${this.baseUrl}/devices/${thingName}`
    return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async updateWeightSensorDevice(thingName: string, payload: WeightSensorDeviceUpdatePayload): Promise<WeightSensorDevice> {
    return this.updateDevice<WeightSensorDevice>(thingName, payload)
  }
  async updateGateKeeperDevice(thingName: string, payload: GateKeeperDeviceUpdatePayload): Promise<GateKeeperDevice> {
    return this.updateDevice<GateKeeperDevice>(thingName, payload)
  }
  async updateBastionDevice(thingName: string, payload: EdgeDeviceUpdatePayload): Promise<EdgeDevice> {
    return this.updateDevice<EdgeDevice>(thingName, payload)
  }
  private async updateDevice<T extends Device>(thingName: string, payload: DeviceUpdatePayload): Promise<T> {
    const url = `${this.baseUrl}/devices/${thingName}`
    return await fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async updateDeviceControlled(thingName: string, controlled: boolean) {
    const url = `${this.baseUrl}/devices/${thingName}/${controlled ? 'controlled' : 'uncontrolled'}`
    return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async rebootDevice(thingName: string): Promise<Device> {
    const url = `${this.baseUrl}/devices/${thingName}/reboot`
    return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async restartServiceDevice(thingName: string): Promise<Device> {
    const url = `${this.baseUrl}/devices/${thingName}/restart_service`
    return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async stopServiceDevice(thingName: string): Promise<Device> {
    const url = `${this.baseUrl}/devices/${thingName}/stop_service`
    return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async faultIsolationDevice(thingName: string): Promise<Device> {
    const url = `${this.baseUrl}/devices/${thingName}/fault_isolation`
    return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async shutdownDevice(thingName: string): Promise<Device> {
    const url = `${this.baseUrl}/devices/${thingName}/shutdown`
    return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async restartDeviceEdge(thingName: string): Promise<Store> {
    return await fetch(`${this.baseUrl}/devices/${thingName}/restart_edge`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async onPlug(storeCode: string, plugName: string): Promise<Device> {
    const url = `${this.baseUrl}/stores/${storeCode}/plugs/${plugName}/on`
    return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async offPlug(storeCode: string, plugName: string): Promise<Device> {
    const url = `${this.baseUrl}/stores/${storeCode}/plugs/${plugName}/off`
    return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  // ====== walkthrough ======
  async fetchLatestWalkthroughs(): Promise<ApiReturnType<Walkthrough[]>> {
    return await fetch(`${this.baseUrl}/walkthroughs`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchWalkthroughs(storeCode: string, date: DateTime): Promise<WalkthroughsOutput>
  async fetchWalkthroughs(storeCode: string, checkinAt: UnixTimestamp): Promise<WalkthroughsOutput>
  async fetchWalkthroughs(storeCode: string, param: DateTime | UnixTimestamp): Promise<WalkthroughsOutput> {
    const queryParams = (() => {
      if (isUnixTimestamp(param)) {
        return `store_code=${storeCode}&checkin_at=${param}`
      } else {
        return `store_code=${storeCode}&date=${param.toFormat('yyyy-MM-dd')}`
      }
    })()
    return await fetch(`${this.baseUrl}/walkthroughs?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchWalkthrough(walkthroughId: string): Promise<Walkthrough> {
    return await fetch(`${this.baseUrl}/walkthroughs/${walkthroughId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async chekoutWalkthrough(walkthroughId: string): Promise<Walkthrough> {
    return await fetch(`${this.baseUrl}/walkthroughs/${walkthroughId}/checkout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchWalkthroughMovies(
    walkthroughId: string
  ): Promise<WalkthroughMoviesOutput> {
    return await fetch(`${this.baseUrl}/walkthroughs/${walkthroughId}/movies`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchActions(storeCode: string, date: DateTime): Promise<ActionsOutput> {
    return await fetch(`${this.baseUrl}/actions?store_code=${storeCode}&date=${date.toFormat('yyyy-MM-dd')}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchActionsByWalkthroughId(walkthroughId: string): Promise<ActionsOutput> {
    return await fetch(`${this.baseUrl}/actions?walkthrough_id=${walkthroughId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchAction(actionId: string): Promise<Action> {
    return await fetch(`${this.baseUrl}/actions/${actionId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchActionMovies(
    actionId: string
  ): Promise<ApiReturnType<ActionMovie[]>> {
    return await fetch(`${this.baseUrl}/actions/${actionId}/movies`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchObscures(): Promise<ApiReturnType<Obscure[]>> {
    return await fetch(`${this.baseUrl}/obscures`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async confirmAction(payload: ConfirmInput): Promise<ActionsOutput> {
    return await fetch(`${this.baseUrl}/confirm`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  // ignore
  async ignoreAction(actionId: string): Promise<ActionsOutput> {
    return await fetch(`${this.baseUrl}/actions/${actionId}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchNotifications(storeCode: string, date: DateTime): Promise<ApiReturnType<Notification[]>> {
    return await fetch(`${this.baseUrl}/notifications?store_code=${storeCode}&date=${date.toFormat('yyyy-MM-dd')}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchBootLogs(storeCode: string, month: DateTime): Promise<ApiReturnType<BootLog[]>> {
    return await fetch(`${this.baseUrl}/boot_logs?store_code=${storeCode}&month=${month.toFormat('yyyy-MM')}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchStatistics(storeCode: string, date: DateTime): Promise<StatisticsData> {
    return await fetch(`${this.baseUrl}/statistics/${storeCode}/${date.toFormat('yyyy-MM-dd')}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchMonthlyStatistics(storeCode: string, month: DateTime): Promise<MonthlyStatistics> {
    return await fetch(`${this.baseUrl}/statistics/${storeCode}?month=${month.toFormat('yyyy-MM')}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchMonthlySystemStatistics(storeCode: string, month: DateTime): Promise<MonthlySystemStatistics> {
    return await fetch(`${this.baseUrl}/system_statistics/${storeCode}?month=${month.toFormat('yyyy-MM')}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchOperators(): Promise<OperatorsOutput> {
    return await fetch(`${this.baseUrl}/operators`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async updateOperator(operator: Operator): Promise<Operator> {
    return await fetch(`${this.baseUrl}/operators/${operator.operator_code}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(operator),
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async deleteOperator(operatorCode: string): Promise<Operator> {
    return await fetch(`${this.baseUrl}/operators/${operatorCode}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async testNotifyOperator(operatorCode: string): Promise<Operator> {
    return await fetch(`${this.baseUrl}/operators/${operatorCode}/test`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchCredentials(): Promise<CredentialsOutput> {
    return await fetch(`${this.baseUrl}/credentials`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchCredential(clientId: string): Promise<Credential> {
    return await fetch(`${this.baseUrl}/credentials/${clientId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async addCredential(clientName: string): Promise<Credential> {
    const payload = {
      client_name: clientName,
    }
    return await fetch(`${this.baseUrl}/credentials`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async updateCredential(clientId: string, permissions: string[] | undefined, scopes: string[] | undefined): Promise<Credential> {
    if (!permissions && !scopes) throw new Error()
    const payload = {
      permissions,
      scopes,
    }
    return await fetch(`${this.baseUrl}/credentials/${clientId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchScopes(): Promise<ScopesOutput> {
    return await fetch(`${this.baseUrl}/scopes`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchFlows(storeCode: string, date: DateTime): Promise<FlowsOutput> {
    return await fetch(`${this.baseUrl}/flows?store_code=${storeCode}&date=${date.toFormat('yyyy-MM-dd')}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchFlow(flowId: string): Promise<Flow> {
    return await fetch(`${this.baseUrl}/flows/${flowId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchQuantities(storeCode: string, date: DateTime): Promise<ApiReturnType<SegmentQuantities[]>> {
    return await fetch(`${this.baseUrl}/quantities?store_code=${storeCode}&date=${date.toFormat('yyyy-MM-dd')}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchReplayList(storeCode: string, date?: DateTime): Promise<ApiReturnType<Replay[]>> {
    const url = `${this.baseUrl}/replay?store_code=${storeCode}` + (date ? `&date=${date.toFormat('yyyy-MM-dd')}` : '')
    return await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchReplay(replayId: string, withDownloadLink: boolean = false): Promise<Replay> {
    return await fetch(`${this.baseUrl}/replay/${replayId}?with_download_link=${withDownloadLink}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchReplayData(replayId: string): Promise<ReplayData> {
    return await fetch(`${this.baseUrl}/replay_data/${replayId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async requestReplay(payload: ReplayRequestPayload): Promise<Replay> {
    return await fetch(`${this.baseUrl}/replay`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async fetchEventHooks(storeCode: string): Promise<ApiReturnType<EventHook[]>> {
    return await fetch(`${this.baseUrl}/event_hooks?store_code=${storeCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

  async updateEventHook(eventHook: EventHook): Promise<EventHook> {
    return fetch(`${this.baseUrl}/event_hooks`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `Bearer ${this.token}`,
      },
      body: JSON.stringify(eventHook),
    })
      .then(handleErrors)
      .then((res) => res.json())
  }

}


// レスポンスに対して共通で行う処理
// 4xx系, 5xx系のエラーをさばくハンドラ
const handleErrors = async (response: any) => {
  // 4xx系, 5xx系エラーのときには response.ok = false になる
  if (!response.ok) {
    if (response.status === 401) throw new Unauthorized(response.statusText)
    if (response.status === 403) throw new Forbidden(response.statusText)
    const body = await response.json()
    console.log(body)
    if (body.error_code) throw new ApiError(body.message, body.error_code)
    throw new SystemError(response.statusText)
  }
  return response
}

export { PassregiApi }
