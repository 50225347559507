import React, {
} from 'react'
import {
  Box,
  Typography, 
} from '@mui/material';
import {
  DevicePowerStatus,
  DeviceServiceStatus,
  DeviceServiceError,
} from 'models'

interface DeviceDetailPanelProps {
  powerStatus?: DevicePowerStatus
  serviceStatus?: DeviceServiceStatus
  serviceError?: DeviceServiceError
}

export const DeviceDetailPanel: React.FC<DeviceDetailPanelProps> = ({ powerStatus, serviceStatus, serviceError }) => {
  return (
    <Box sx={{ margin: 1, paddingLeft: 2, paddingRight: 2 }}>
      <Typography variant="body2">
        <Box component="span" sx={{ fontWeight: 'bold', paddingRight: 1 }}>power:</Box>{powerStatus}
      </Typography>
      <Typography variant="body2">
        <Box component="span" sx={{ fontWeight: 'bold', paddingRight: 1 }}>service:</Box>{serviceStatus}
      </Typography>
      {serviceError && (
      <Typography variant="body2">
        <Box component="span" sx={{ fontWeight: 'bold', paddingRight: 1 }}>error_code:</Box>{serviceError.error_code}
      </Typography>
      )}
    </Box>
  )  
}