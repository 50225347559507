import React from 'react';
import {
  Link,
  Outlet,
} from 'react-router-dom';
import {
  Toolbar,
  List,
  Divider,
  Typography,
  Box,
} from '@mui/material';
import { NotificationsProvider } from 'contexts';
import { UserMenu } from './UserMenu';
import { NavItem } from './NavItem';
import { getEnvPrimaryColor } from 'utils';
import BuildInfo from 'BuildInfo.json';
import logo_s from 'logo_s.png';

const navList = [
  {
    label: 'Dashboard',
    path: '/',
    pattern: '/',
    icon: 'dashboard',
  },
  {
    label: 'Stores',
    path: '/stores',
    icon: 'store',
  },
  {
    label: 'Walkthrough',
    path: `/walkthrough`,
    icon: 'directions_walk',
    children: [
      {
        label: 'Confirm',
        path: `/walkthrough/confirm`,
      },
      {
        label: 'Walkthroughs',
        path: `/walkthrough`,
        excludes: ['/walkthrough/confirm', '/walkthrough/actions'],
      },
      {
        label: 'Actions',
        path: `/walkthrough/actions`,
      },
    ]
  },
  {
    label: 'Analysis',
    path: `/analysis`,
    icon: 'show_chart',
    children: [
      {
        label: 'Flow',
        path: `/analysis/flows`,
      },
      {
        label: 'Quantities',
        path: `/analysis/quantities`,
      },
      {
        label: 'Replay',
        path: `/analysis/replay`,
      },
    ],
  },
  {
    label: 'Device',
    path: `/device`,
    icon: 'router',
    children: [
      {
        label: 'Edge',
        path: `/device/edge`,
      },
      {
        label: 'WeightSensor',
        path: `/device/weightsensor`,
      },
      {
        label: 'GateKeeper',
        path: `/device/gatekeeper`,
      },
    ]
  },
  {
    label: 'Monitor',
    path: `/monitor`,
    icon: 'computer',
    children: [
      {
        label: 'Tracking',
        path: `/monitor/tracking`,
      },
      {
        label: 'Weight',
        path: `/monitor/weight`,
      },
      {
        label: 'Device',
        path: `/monitor/device`,
      },
    ]
  },
  {
    label: 'Statistics',
    path: `/statistics`,
    icon: 'bar_chart',
    children: [
      {
        label: 'Daily',
        path: `/statistics`,
        pattern: '/statistics',
      },
      // {
      //   label: 'Monthly',
      //   path: `/statistics/monthly`,
      // },
      {
        label: 'System',
        path: `/statistics/system`,
      },
    ]
  },
  {
    label: 'Logs',
    path: `/logs`,
    icon: 'event_note',
    children: [
      {
        label: 'Search',
        path: `/logs`,
        pattern: '/logs',
      },
      {
        label: 'Streams',
        path: `/logs/streams`,
      },
      {
        label: 'Boot',
        path: `/logs/boot`,
      },
    ]
  },
  {
    label: 'Operators',
    path: '/operators',
    icon: 'people',
  },
  {
    label: 'Credentials',
    path: '/credentials',
    icon: 'vpn_key',
  },
]

export const Main: React.FC = () => (
  <>
    <Box
      hidden={Boolean(process.env.REACT_APP_ENV === 'PRD')}
      sx={{
        height: 3,
        backgroundColor: getEnvPrimaryColor(),
      }}
    />
    <Box sx={{ borderBottom: '1px solid lightgray' }}>
      <Toolbar variant="dense" disableGutters
        sx={{
          maxWidth: '1034px',
          margin: '0 auto',
        }}
      >
        <Link to='/'>
          <Box
            component="img"
            src={logo_s}
            alt="Passレジ"
            sx={{ marginLeft: (theme) => theme.spacing(0.5) }}
          />
        </Link>
        <Box sx={{ marginLeft: 'auto' }}>
          <UserMenu />
        </Box>
      </Toolbar>
    </Box>
    <Box sx={{
      display: 'flex',
      maxWidth: '1034px',
      margin: '0 auto',
    }}>
      <Box sx={{
        marginTop: (theme) => theme.spacing(1.5),
        wordBreak: 'keep-all',
      }}>
        <List component="div">
          {navList.map((nav) => (
            <NavItem key={nav.path} nav={nav} />
          ))}
        </List>
        <Divider />
        <Typography sx={{
          marginTop: (theme) => theme.spacing(2),
          fontSize: '0.9em',
          textAlign: 'right',
        }}>
          ver: {BuildInfo.version}
        </Typography>
      </Box>
      <Box sx={{
        minWidth: '900px',
        marginTop: (theme) => theme.spacing(1.5),
        marginLeft: (theme) => theme.spacing(5),
      }}>
        <NotificationsProvider>
          <Outlet />
        </NotificationsProvider>
      </Box>
    </Box>
  </>
)
