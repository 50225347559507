import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  Paper,
  Toolbar,
} from '@mui/material';
import {
  useApp,
} from 'contexts'
import {
  ActionMovie,
} from 'models'
import {
  MovieCard,
  Loading,
  ReloadButton,
  WarnigMessage,
} from 'components'

export interface ActionMoviesProps {
  actionId: string
}

export const ActionMovies: React.FC<ActionMoviesProps> = ({ actionId }) => {
  const [movies, setMovies] = useState<ActionMovie[]>()
  const { getApi, defaultApiErrorHandler } = useApp()
  const [reload, setReload] = useState(false)

  const loadMovies = useCallback(() => {
    getApi().fetchActionMovies(actionId)
      .then(result => {
        setMovies(result.items)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, actionId])
  useEffect(() => {
    loadMovies()
  }, [loadMovies])
  // reload
  useEffect(() => {
    if (!reload) return
    loadMovies()
    setReload(false)
  }, [loadMovies, reload])

  const handleReload = useCallback(() => {
    setMovies(undefined)
    setReload(true)
  }, [])

  return (
    <Paper sx={{ paddingBottom: 1, marginBottom: 1 }}>
      <Toolbar variant="dense" disableGutters>
        <ReloadButton
          marginLeft="auto"
          marginRight={1}
          onClick={handleReload}
        />
      </Toolbar>
      {movies && movies.map(movie => (
        <MovieCard
          key={movie.url}
          movieUrl={movie.url}
          title={movie.cam_code}
          maxWidth={600}
          imgHeight={400}
        />
      ))}
      <Loading visible={movies === undefined} />
      <WarnigMessage title="動画はありません" hidden={Boolean(!movies || 0 < movies.length)} />
    </Paper>
  )
}