import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  useQueryParam,
  StringParam,
  DateParam,
  withDefault,
} from 'use-query-params'
import {
  Toolbar,
} from '@mui/material'
import { DateTime } from 'luxon'
import {
  useApp,
} from 'contexts'
import {
  Store,
  NullStore,
  Walkthrough,
} from 'models'
import {
  MainContents,
  StoresSelector,
  Loading,
  ReloadButton,
  DatePicker,
} from 'components'
import {
  WalkthroughsTable,
} from './components'

export const Walkthroughs: React.FC = () => {
  const [storeCode, setStoreCode] = useQueryParam(
    's',
    StringParam,
  )
  const [date, setDate] = useQueryParam(
    'd',
    withDefault(DateParam, new Date()),
  )
  const [reload, setReload] = useState(false)
  const [items, setItems] = useState<Walkthrough[]>()
  const { getApi, defaultApiErrorHandler } = useApp()

  const load = useCallback(() => {
    if (storeCode) {
      getApi()
        .fetchWalkthroughs(storeCode, DateTime.fromJSDate(date))
        .then((result) => {
          console.log('fetchAsync done')
          setItems(result.items)
        })
        .catch(defaultApiErrorHandler)
    } else {
      getApi()
        .fetchLatestWalkthroughs()
        .then((result) => {
          console.log('fetchAsync done')
          setItems(result.items)
        })
        .catch(defaultApiErrorHandler)
    }
  }, [getApi, defaultApiErrorHandler, storeCode, date])
  useEffect(() => {
    load()
  }, [load])
  // reload
  useEffect(() => {
    if (!reload) return
    console.log('reloading...')
    load()
    setReload(false)
  }, [load, reload])

  const onStoreChanged = useCallback((store: Store) => {
    setStoreCode(store !== NullStore ? store.store_code : undefined)
    if (store === NullStore) setDate(undefined)
  }, [setStoreCode, setDate])
  const handleDateChange = useCallback((dt: DateTime) => {
    setDate(dt.toJSDate())
  }, [setDate])
  const handleReload = useCallback(() => {
    setItems(undefined)
    setReload(true)
  }, [])

  return (
    <MainContents>
      <Toolbar variant="dense" disableGutters>
        <StoresSelector
          storeCode={storeCode}
          onSeleced={onStoreChanged}
          enableNotSelected
          notSelectedLabel="全店舗/直近"
        />
        <DatePicker
          onChange={handleDateChange}
          defaultDate={DateTime.fromJSDate(date)}
          disabled={Boolean(storeCode === undefined)}
        />
        <ReloadButton marginLeft="auto" onClick={handleReload} />
      </Toolbar>
      <Loading visible={items === undefined} />
      {items &&
      <WalkthroughsTable
        data={items}
        storeNamePrefixEnabled={Boolean(storeCode === undefined)}
      />
      }
    </MainContents>
  )
}

