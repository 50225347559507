import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import { Link } from 'react-router-dom'
import { Toolbar, Button, List, ListItem } from '@mui/material';
import {
  Add,
  Store as StoreIcon,
} from '@mui/icons-material'
import {
  useApp,
} from 'contexts'
import {
  Store,
} from 'models'
import {
  MainContents,
  Loading,
  WarnigMessage,
} from 'components'
import { AddDialog } from './AddDialog'

export const Stores: React.FC = () => {
  const [stores, setStores] = useState<Store[]|null>(null)
  const [addDialogOpen, setAddDialogOpen] = useState(false)
  const [reload, setReload] = useState(false)
  const { getApi } = useApp()

  useEffect(() => {
    getApi()
      .fetchStores()
      .then((result) => {
        setStores(result.items)
      })
      .catch((err) => console.log(err))
  }, [getApi])
  // Reload
  useEffect(() => {
    getApi()
      .fetchStores()
      .then((result) => {
        setStores(result.items)
      })
      .catch((err) => console.log(err))
  }, [getApi, reload])

  const handleAddDialogClosed = useCallback((needToUpdate: boolean) => {
    setAddDialogOpen(false)
    setReload(needToUpdate)
  }, [])

  return (
    <MainContents>
      <Toolbar variant="dense" disableGutters>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Add />}
          onClick={() => setAddDialogOpen(true)}
          sx={{ marginLeft: 'auto' }}
        >
        店舗を追加
        </Button>
        <AddDialog
          open={addDialogOpen}
          onClose={handleAddDialogClosed} 
        />
      </Toolbar>
      <Loading visible={stores === null} />
      <List>
      {stores && stores.map(store => (
        <ListItem 
          key={store.store_code} 
          button component={Link}
          to={`/stores/${store.store_code}/`}
          dense
        >
          <StoreIcon fontSize="small" sx={{ marginRight: (theme) => theme.spacing(0.5) }} />
          {store.store_name || store.store_code}
        </ListItem>
      ))}
      </List>
      <WarnigMessage
        title="店舗を追加してください"
        hidden={!stores || 0 < stores.length}
      />
    </MainContents>
  )
}