import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  Toolbar,
  Grid,
  Typography,
  SxProps,
  Theme,
} from '@mui/material';
import { DateTime } from 'luxon'
import {
  useApp,
} from 'contexts'
import {
  useSelectedStoreCode,
} from 'hooks'
import {
  Store,
  StatisticsData,
} from 'models'
import {
  MainContents,
  StoresSelector,
  Loading,
  ReloadButton,
  DatePicker,
} from 'components'
import {
  StatusPanel,
} from './components'

const sxCtegoryLabel: SxProps<Theme> = {
  marginBottom: 0.5,
}
const sxCategories: SxProps<Theme> = {
  marginBottom: 2,
}

export const Statistics: React.FC = () => {
  const { storeCode, setStoreCode } = useSelectedStoreCode()
  const [date, setDate] = useState(DateTime.now())
  const [reload, setReload] = useState(false)
  const [data, setData] = useState<StatisticsData>()
  const { getApi, defaultApiErrorHandler } = useApp()

  const load = useCallback(() => {
    if (storeCode === undefined) return
    getApi().fetchStatistics(storeCode, date)
      .then((data) => {
        setData(data)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, storeCode, date])
  useEffect(() => {
    load()
  }, [load])
  // reload
  useEffect(() => {
    if (!reload) return
    console.log('reloading...')
    load()
    setReload(false)
  }, [load, reload])

  const onStoreChanged = useCallback((store: Store) => {
    setStoreCode(store.store_code)
  }, [setStoreCode])
  const handleDateChange = useCallback((dt: DateTime) => {
    setDate(dt)
  }, [])
  const handleReload = useCallback(() => {
    setData(undefined)
    setReload(true)
  }, [])

  return (
    <MainContents>
      <Toolbar variant="dense" disableGutters>
        <StoresSelector
          storeCode={storeCode}
          onSeleced={onStoreChanged}
        />
        <DatePicker
          onChange={handleDateChange}
        />
        <ReloadButton marginLeft="auto" onClick={handleReload} />
      </Toolbar>
      <Typography variant="h5" sx={sxCtegoryLabel}>チェックイン</Typography>
      <Loading visible={data === undefined} />
      {data && (
        <Grid container spacing={1} sx={sxCategories}>
          <Grid item xs={6}>
            <StatusPanel
              variant="error"
              title={`${data.checkouts_explicit_no_items + data.checkouts_explicit} / ${data.checkins_total}`}
              subTitle="購入判定"
              desc={`${formatRate(data.checkouts_explicit_no_items + data.checkouts_explicit, data.checkins_total)}（未購入${data.checkouts_explicit_no_items}件含む）`}
              counts={[data.checkouts_explicit_no_items, data.checkouts_explicit]}
              totalCount={data.checkins_total}
              helpText="すべての購入判定が確定できた件数"
            />
          </Grid>
          <Grid item xs={6}>
            <StatusPanel
              variant="error"
              title={`${data.checkins_total - data.checkins_corrected} / ${data.checkins_total}`}
              subTitle="購入結果"
              desc={`${formatRate(data.checkins_total - data.checkins_corrected, data.checkins_total)}`}
              counts={[(data.checkins_total - data.checkins_corrected)]}
              totalCount={data.checkins_total}
              helpText="購入判定の結果が正しく認識された件数"
            />
          </Grid>
          <Grid item xs={6}>
            <StatusPanel
              variant="standard"
              title={`${data.checkins_group} / ${data.checkins_total}`}
              subTitle="グループ入店"
              desc={`${formatRate(data.checkins_group, data.checkins_total)}`}
              counts={[data.checkins_group]}
              totalCount={data.checkins_total}
              helpText="グループ入店数"
            />
          </Grid>
          <Grid item xs={6}>
            <StatusPanel
              variant="standard"
              title={`${data.checkins_event_mode} / ${data.checkins_total}`}
              subTitle="イベントモード"
              desc={`${formatRate(data.checkins_event_mode, data.checkins_total)}`}
              counts={[data.checkins_event_mode]}
              totalCount={data.checkins_total}
              helpText="イベントモードでのチェックイン数"
            />
          </Grid>
        </Grid>
      )}
      <Typography variant="h5" sx={sxCtegoryLabel}>アクション</Typography>
      <Loading visible={data === undefined} />
      {data && (
        <Grid container spacing={1} sx={sxCategories}>
          <Grid item xs={6}>
            <StatusPanel
              variant="error"
              title={`${data.actions_explicit} / ${data.actions_total}`}
              subTitle="取得判定"
              desc={`${formatRate(data.actions_explicit, data.actions_total)}`}
              counts={[data.actions_explicit]}
              totalCount={data.actions_total}
              helpText="人物IDと商品の両方が確定した件数"
            />
          </Grid>
          <Grid item xs={6}>
            <StatusPanel
              variant="warning"
              title={`${data.actions_confirm} / ${data.actions_total}`}
              subTitle="オペレータ判断数"
              desc={`${formatRate(data.actions_confirm, data.actions_total)}`}
              counts={[data.actions_confirm]}
              totalCount={data.actions_total}
              helpText="オペレータによる判断が行われた件数"
            />
          </Grid>
          <Grid item xs={6}>
            <StatusPanel
              variant="error"
              title={`${data.actions_person_explicit} / ${data.actions_total}`}
              subTitle="人物ID確定"
              desc={`${formatRate(data.actions_person_explicit, data.actions_total)}`}
              counts={[data.actions_person_explicit]}
              totalCount={data.actions_total}
              helpText="人物ID判定が確定した件数"
            />
          </Grid>
          <Grid item xs={6}>
            <StatusPanel
              variant="error"
              title={`${data.actions_item_explicit} / ${data.actions_total}`}
              subTitle="商品確定"
              desc={`${formatRate(data.actions_item_explicit, data.actions_total)}`}
              counts={[data.actions_item_explicit]}
              totalCount={data.actions_total}
              helpText="商品判定が確定した件数"
            />
          </Grid>
          <Grid item xs={6}>
            <StatusPanel
              variant="warning"
              title={`${data.actions_no_persons} / ${data.actions_total}`}
              subTitle="人物ID未検知"
              desc={`${formatRate(data.actions_no_persons, data.actions_total)}`}
              counts={[data.actions_no_persons]}
              totalCount={data.actions_total}
              helpText="人物IDが未検出であった件数"
            />
          </Grid>
          <Grid item xs={6}>
            <StatusPanel
              variant="warning"
              title={`${data.actions_timeout} / ${data.actions_total}`}
              subTitle="確定タイムアウト"
              desc={`${formatRate(data.actions_timeout, data.actions_total)}`}
              counts={[data.actions_timeout]}
              totalCount={data.actions_total}
              helpText="確定タイムアウトが発生した件数"
            />
          </Grid>

          <Grid item xs={6}>
            <StatusPanel
              variant="standard"
              title={`${data.actions_detect_by_dist} / ${data.actions_filter_by_hand_tracking} / ${data.actions_detect_by_hand_tracking}`}
              subTitle="人物ID識別方法"
              desc="距離 / 手の有無 / 手の距離"
              counts={[data.actions_detect_by_dist, data.actions_filter_by_hand_tracking, data.actions_detect_by_hand_tracking]}
              totalCount={data.actions_detect_by_dist + data.actions_filter_by_hand_tracking + data.actions_detect_by_hand_tracking}
              helpText="人物IDの判定で使われたアルゴリズムの割合を表示します"
            />
          </Grid>
        </Grid>
      )}
    </MainContents>
  )
}

const formatRate = (value: number, sum: number): string => (sum !== 0 ? `${Math.floor(value / sum * 100)}%` : '')