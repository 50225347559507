import React from 'react'
import {
  Tooltip,
} from '@mui/material'
import {
  ErrorOutline as Error,
  Done as Ok,
} from '@mui/icons-material'
import {
  Action,
} from 'models'

type ItemStatusLabelProps = {
  action: Action
}

export const ItemStatusLabel: React.FC<ItemStatusLabelProps> = ({ action }) => {
  if (action.item_code === undefined || action.qty === undefined) {
    return (<Tooltip title="不明"><Error fontSize="small" color="error"/></Tooltip>)
  }
  return (<Tooltip title="確定"><Ok fontSize="small" color="primary"/></Tooltip>)
}

