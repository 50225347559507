import React, {
  useEffect,
  useRef,
} from 'react'
import {
  ReplayData,
  ReplayDataWeightFrame,
  UnixTimestamp,
  UnixTimestampMs,
} from 'models'
import * as d3 from 'd3'
import { DateTime } from 'luxon'

interface WeightData {
  segment: string
  ms: UnixTimestampMs
  value: number
}

interface WeightChart {
  groupData: d3.InternMap<string, WeightData[]> | undefined
  color: d3.ScaleOrdinal<string, string, never> | undefined
}

const toWeightData = (timestamp: UnixTimestamp, segmentsFrames: ReplayDataWeightFrame[]): WeightData[] => {
  return segmentsFrames.map((segmentFrames) => {
    const segment = segmentFrames.segment_code
    const dataList: WeightData[] = segmentFrames.frames.map((values, idx) => {
      const ms = (timestamp + idx) * 1000
      const framesLen = values.length
      return values.map((value, valueIdx) => ({
        segment,
        ms: ms + Math.floor(valueIdx * 1000 / framesLen),
        value,
      }))
    }).flat()
    return [{
      segment,
      ms: timestamp * 1000,
      value: dataList.length !== 0 ? dataList[0].value : 0,
    }, ...dataList]
  }).flat()
}

const tickValues = (timestamp: UnixTimestamp, durationMin: number): number[] => {
  return [...new Array(durationMin)].map((_, idx) => timestamp * 1000 + idx * 1000 * 60)
}

type WeightsChartProps = {
  replayData: ReplayData
  segments: string[]
  timestamp?: UnixTimestamp
  width?: number
  height?: number
  marginTop?: number
  marginBottom?: number
  marginLeft?: number
  marginRight?: number
}

export const WeightsChart: React.FC<WeightsChartProps> = ({
  replayData,
  segments,
  timestamp,
  width = 850,
  height = 500,
  marginTop = 10,
  marginBottom = 20,
  marginLeft = 50,
  marginRight = 10,
}) => {
  const ref = useRef<SVGSVGElement | null>(null)
  const legendRef = useRef<HTMLDivElement | null>(null)
  const updateChartRef = useRef<(segments: string[]) => void>(() => {})
  const updateChartTimestampRef = useRef<(timestamp?: UnixTimestamp) => void>(() => {})
  useEffect(() => {
    const timestamp = replayData.timestamp
    const durationMin = replayData.duration_min
    console.log({ timestamp, durationMin })
    const svgElm = ref.current
    const legendElm = legendRef.current
    if (svgElm === null || legendElm === null) return

    const svg = d3.select(svgElm)
    const legend = d3.select(legendElm)

    const xScale = d3.scaleLinear()
      .domain([timestamp, timestamp + 60 * 1000 * durationMin])
      .range([0, width - marginLeft - marginRight])
    const yScale = d3.scaleLinear()
      .domain([1000, -100])
      .range([0, height - marginTop - marginBottom])
      .nice()

    const xAxis = d3
      .axisBottom(xScale)
      .tickValues(tickValues(timestamp, durationMin))
      .tickFormat(ms => `${DateTime.fromMillis(ms.valueOf()).toFormat('HH:mm')}`)
    const yAxis = d3
      .axisLeft(yScale)

    const xAxisG = svg
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(${marginLeft}, ${height - marginBottom})`)
    xAxisG.call(xAxis)

    const yAxisG = svg
      .append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(${marginLeft}, ${marginTop})`)
    yAxisG.call(yAxis)

    const vLine = svg
      .append('g')
      .attr('class', 'v-line')
      .attr('transform', `translate(${marginLeft}, ${marginTop})`)
      .append('line')
      .attr('fill', 'none')
      .attr('stroke-width', 1)
      .attr('stroke', 'gray')
      .attr('x1', xScale(timestamp))
      .attr('y1', 0)
      .attr('x2', xScale(timestamp))
      .attr('y2', height - marginTop - marginBottom)
      .style('stroke-dasharray', '5,2')

    const dataArea = svg
      .append('g')
      .attr('class', 'd')
      .attr('transform', `translate(${marginLeft}, ${marginTop})`)

    const chart: WeightChart = {
      groupData: undefined,
      color: undefined,
    }
    const updatePlot = (msFrom: UnixTimestampMs) => {
      const { groupData, color } = chart
      if (groupData === undefined || color === undefined) return
      console.log('#updatePlot', groupData)
      const msMin = msFrom - 30 * 1000
      console.log(vLine)
      vLine
        .attr('x1', xScale(msFrom + 40 * 1000))
        .attr('x2', xScale(msFrom + 40 * 1000))
      dataArea
        .selectAll('.line')
        .data(groupData, (d: any) => d[0])
        .join(
          enter => enter
            .append('path')
            .attr('class', 'line')
            .attr('fill', 'none')
            .attr('stroke-width', 1.5)
            .attr('stroke', ([segment, _]) => color(segment))
            .attr('d', ([_, d]) => d3.line<WeightData>().defined(d2 => msMin <= d2.ms).curve(d3.curveBasis).x(d2 => Math.max(0, xScale(d2.ms))).y(d2 => yScale(d2.value))(d))
          ,
          update => update
            .attr('d', ([_, d]) => d3.line<WeightData>().defined(d2 => msMin <= d2.ms).curve(d3.curveBasis).x(d2 => Math.max(0, xScale(d2.ms))).y(d2 => yScale(d2.value))(d))
          ,
          exit => exit.remove(),
      )
    }
    const updateChartArea = (segments: string[]) => {
      console.log(`#updateChartArea: ${segments.join(',')}`)
      const frames = replayData.frames_weight.filter(f => segments.indexOf(f.segment_code) !== -1)
      const data = toWeightData(timestamp, frames)
      const minWeight = d3.min(data, d => d.value) || 0
      const maxWeight = d3.max(data, d => d.value) || 1000
      yScale.domain([maxWeight, minWeight]).nice()
      yAxisG.call(yAxis)
      chart.groupData = d3.group(data, d => d.segment)
      const color = d3.scaleOrdinal(d3.schemeCategory10).domain(segments)
      chart.color = color
      legend
        .selectAll('.legend')
        .data(segments)
        .join(
          enter => {
            const span = enter.append('span').attr('class', 'legend').style('margin-right', '10px')
            const svg = span.append('svg').attr('width', 10).attr('height', 12).style('margin-right', '3px')
            svg.append('rect').attr('width', 10).attr('height', 12).attr('fill', d => color(d))
            span.append('span').text(d => d)
            return span
          },
          update => update,
          exit => exit.remove(),
        )
      updatePlot(timestamp * 1000)
    }
    updateChartRef.current = updateChartArea
    const updateChartTimestamp = (medianTimestamp?: UnixTimestamp) => {
      console.log(`#updateChartTimestamp: ${medianTimestamp ? DateTime.fromSeconds(medianTimestamp).toFormat('HH:mm:ss') : '-'}`)
      const fromMs = timestamp * 1000
      const toMs = fromMs + 60 * durationMin * 1000
      if (medianTimestamp !== undefined) {
        const fromMs2 = Math.min(Math.max(fromMs - 40 * 1000, (medianTimestamp - 40) * 1000), toMs - 60 * 1000)
        const tickBase = medianTimestamp * 1000
        const tickValues: number[] = [tickBase - 40000, tickBase - 30000, tickBase - 20000, tickBase - 10000, tickBase, tickBase + 10000]
        console.log([fromMs2, fromMs2 + 60 * 1000])
        xScale.domain([fromMs2, fromMs2 + 60 * 1000])
        xAxis
          .tickValues(tickValues.sort())
          .tickFormat(ms => `${DateTime.fromMillis(ms.valueOf()).toFormat('HH:mm:ss')}`)
        xAxisG.call(xAxis)
        updatePlot(fromMs2)
      } else {
        xScale.domain([fromMs, toMs])
        xAxis
          .tickValues(tickValues(timestamp, durationMin))
          .tickFormat(ms => `${DateTime.fromMillis(ms.valueOf()).toFormat('HH:mm')}`)
        xAxisG.call(xAxis)
        updatePlot(fromMs)
      }
    }
    updateChartTimestampRef.current = updateChartTimestamp
  }, [ref, replayData, width, height, marginTop, marginBottom, marginLeft, marginRight])

  useEffect(() => {
    if (updateChartRef.current === null) return
    updateChartRef.current(segments)
  }, [segments])
  useEffect(() => {
    if (updateChartTimestampRef.current === null) return
    updateChartTimestampRef.current(timestamp)
  }, [timestamp])

  return (
  <div>
    <svg
      ref={ref}
      width={width}
      height={height}
    />
    <div ref={legendRef} style={{ textAlign: 'right', marginTop: '5px' }}></div>
  </div>
  )
}

