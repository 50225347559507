import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  useQueryParam,
  StringParam,
  DateParam,
  withDefault,
} from 'use-query-params'
import {
  useLocation,
  Link,
} from 'react-router-dom'
import {
  Toolbar,
  Grid,
  Typography,
  Box,
  SxProps,
  Theme,
} from '@mui/material';
import {
  Link as LinkIcon,
} from '@mui/icons-material'
import MaterialTable from '@material-table/core'
import { DateTime } from 'luxon'
import {
  useApp,
} from 'contexts'
import {
  Store,
  Action,
  actionIdToSegment,
} from 'models'
import {
  MainContents,
  StoresSelector,
  Loading,
  ReloadButton,
  TimestampLabel,
  WeightLabel,
  ActionStatusLabel,
  MaterialTableOptions,
  MaterialTableComponents,
  MaterialTableLocalization,
  DatePicker,
  WeightDiffInfo,
} from 'components'
import {
  formatPersonId,
} from 'utils'
import {
  PersonStatusLabel,
  ItemStatusLabel,
} from '../components'

const sxTitle: SxProps<Theme> = {
  paddingLeft: 1,
  color: t => t.palette.text.secondary,
}
const sxDetailGrid: SxProps<Theme> = {
  paddingTop: 0.5,
  paddingLeft: 1,
}

export const WalkthroughActions: React.FC = () => {
  const { getApi, defaultApiErrorHandler, getInitalStore } = useApp()
  const [storeCode, setStoreCode] = useQueryParam(
    's',
    withDefault(StringParam, getInitalStore().store_code),
  )
  const [date, setDate] = useQueryParam(
    'd',
    withDefault(DateParam, new Date()),
  )
  const location = useLocation()
  const [reload, setReload] = useState(false)
  const [actions, setActions] = useState<Action[] | null>(null)

  const load = useCallback(() => {
    if (!storeCode) return
    getApi()
      .fetchActions(storeCode, DateTime.fromJSDate(date))
      .then((result) => {
        console.log('fetchAsync done')
        setActions(result.items)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, storeCode, date])
  useEffect(() => {
    load()
  }, [load])
  // reload
  useEffect(() => {
    if (!reload) return
    console.log('reloading...')
    load()
    setReload(false)
  }, [load, reload])

  const onStoreChanged = useCallback((store: Store) => {
    setStoreCode(store?.store_code)
  }, [setStoreCode])
  const handleDateChange = useCallback((dt: DateTime) => {
    setDate(dt.toJSDate())
  }, [setDate])
  const handleReload = useCallback(() => {
    setActions(null)
    setReload(true)
  }, [])

  return (
    <MainContents>
      <Toolbar variant="dense" disableGutters>
        <StoresSelector
          storeCode={storeCode}
          onSeleced={onStoreChanged}
        />
        <DatePicker
          defaultDate={DateTime.fromJSDate(date)}
          onChange={handleDateChange}
        />
        <ReloadButton marginLeft="auto" onClick={handleReload} />
      </Toolbar>
      <Loading visible={actions === null} />
      {actions &&
        <MaterialTable
          data={actions}
          columns={[
            {
              title: '時刻',
              field: 'timestamp',
              render: (d) => (<TimestampLabel value={d.timestamp} />),
              sorting: true,
              searchable: false,
            },
            {
              title: '状態',
              field: 'status',
              render: (d) => (<ActionStatusLabel personStatus={d.person_status} itemStatus={d.item_status} />),
              sorting: false,
              searchable: false,
            },
            {
              title: '棚',
              field: 'action_id',
              render: (d) => (<span>{actionIdToSegment(d.action_id)}</span>),
              sorting: true,
              searchable: true,
              customFilterAndSearch: (term, d) => (term.indexOf('_') === -1 ? actionIdToSegment(d.action_id).startsWith(term) : actionIdToSegment(d.action_id) === term),
            },
            {
              title: '重量変化',
              field: 'value_diff',
              render: (d) => (<WeightLabel value={d.value_diff} />),
              sorting: false,
              searchable: false,
            },
            {
              title: '商品重量',
              field: 'item_weight',
              render: (d) => (<WeightLabel value={d.item_weight} />),
              sorting: false,
              searchable: false,
            },
            {
              title: '数量',
              field: 'qty',
              render: (d) => (<>{d.qty || '不明'}</>),
              sorting: false,
              searchable: false,
            },
            {
              title: '商品判定',
              field: 'item',
              render: (d) => (<ItemStatusLabel action={d} />),
              sorting: false,
              searchable: false,
            },
            {
              title: '人物候補',
              field: 'candidates',
              render: (d) => (<>{d.detect_info?.candidates.map(c => (
                <Box key={`${d.action_id}_${c}`} sx={(d.person_id !== c) ? { color: t => t.palette.text.secondary } : undefined}>
                  {formatPersonId(c)}
                </Box>
              ))}
              </>),
              sorting: false,
              searchable: false,
            },
            {
              title: '人物判定',
              field: 'person',
              render: (d) => (<PersonStatusLabel action={d} />),
              sorting: false,
              searchable: false,
            },
          ]}
          detailPanel={(({ rowData }) => (
            <Grid container sx={{ margin: 1 }}>
              <Grid item xs={6} sx={sxDetailGrid}>
                <Typography variant="h5">商品判定</Typography>
                <Grid container>
                  <Grid item xs={3} sx={sxTitle}>重量変化</Grid>
                  <Grid item xs={9}><WeightLabel value={rowData.value_diff} /></Grid>
                  <Grid item xs={3} sx={sxTitle}>商品</Grid>
                  <Grid item xs={9}>{rowData.item_name}{rowData.item_weight && (<>{rowData.item_weight}g</>)}</Grid>
                  <Grid item xs={3} sx={sxTitle}>数量</Grid>
                  <Grid item xs={9}>{rowData.qty ? rowData.qty : '不明'}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sx={sxDetailGrid}>
                <Typography variant="h5">人物判定</Typography>
                <Grid container>
                  <Grid item xs={3} sx={sxTitle}>人物ID</Grid>
                  <Grid item xs={9}>{rowData.person_id || '-'}</Grid>
                  <Grid item xs={3} sx={sxTitle}>人物ID候補</Grid>
                  <Grid item xs={9}>
                    {rowData.detect_info?.candidates.map(c => (
                      <Box key={`${rowData.action_id}_${c}_d`} sx={(rowData.person_id !== c) ? { color: t => t.palette.text.secondary } : undefined}>{c}</Box>
                    ))}
                  </Grid>
                  <Grid item xs={3} sx={sxTitle}>判定</Grid>
                  <Grid item xs={9}>{rowData.detect_info?.detect_method || '-'}</Grid>
                </Grid>
              </Grid>
              {rowData.past_values && (
                <Grid item xs={12} sx={sxDetailGrid}>
                  <Typography variant="h5">重量変化詳細</Typography>
                  <WeightDiffInfo value={rowData.value_diff} pastValues={rowData.past_values} color={rowData.value_diff < 0 ? 'green' : 'blue'} />
                </Grid>
              )}
              <Grid item xs={12} sx={{ paddingRight: 1, textAlign: 'right' }}>
                <Link to={`/walkthrough/actions/${rowData.action_id}${location.search}`}><LinkIcon fontSize="small" />{rowData.action_id}</Link>
              </Grid>
            </Grid>
          ))}
          // onRowClick={() => { togglePanel() }}
          options={MaterialTableOptions<Action>({
            search: true,
          })}
          components={{
            ...MaterialTableComponents,
          }}
          localization={{
            ...MaterialTableLocalization
          }}
        />
      }
    </MainContents>
  )
}

