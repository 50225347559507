import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react'
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  SxProps,
  Theme,
} from '@mui/material';
import {
  LibraryAdd as Add,
  Delete,
  Settings,
  FormatLineSpacing as HeightsSettings,
  Category as AddItem,
  TransferWithinAStation as StaffGate,
  CloudDownload as Download,
  CloudUpload as Upload,
  Room as LocationIcon,
} from '@mui/icons-material'
import {
  Stage,
  Layer,
  Group,
  Rect,
} from 'react-konva'
import {
  Rect as RectType,
} from 'konva/types/shapes/Rect'
import * as d3 from 'd3';
import {
  Location,
  FloorItem,
  Shelf,
} from 'models'
import {
  useStoreLayout,
} from 'contexts'
import {
  Grid,
} from './Grid'
import {
  Area,
  AreaModel,
} from './Area'
import {
  DiagonalArea,
  DiagonalAreaModel,
} from './DiagonalArea'
import {
  Person,
  PersonModel,
} from './Person'
import {
  FlowLine,
  FlowLineModel,
} from './FlowLine'
import {
  AddAreaDialog,
} from './StoreLayout.AddAreaDialog'
import {
  ShelfLocationDialog,
} from './StoreLayout.ShelfLocationDialog'
import {
  ShelfSettingsDialog,
} from './StoreLayout.ShelfSettingsDialog'
import {
  SettingsDialog,
} from './StoreLayout.SettingsDialog'
import {
  AddItemDialog,
} from './StoreLayout.AddItemDialog'
import {
  UploadDialog,
  toJsonString,
} from './StoreLayout.UploadDialog'
import {
  toLocationName,
  toShelfName,
} from 'utils'
import { saveAs } from 'file-saver'

const itemName = (item: FloorItem): string => item.name

export const createColors = (locations: Location[], shelves: Shelf[]): d3.ScaleOrdinal<string, string> => {
  const allNames = Array.from(new Set([
    ...locations.map(loc => toLocationName(loc)),
    ...shelves.map(sh => toShelfName(sh)),
  ]))
  const entranceNames = allNames.filter(name => name.startsWith('ENTRANCE')).sort()
  const exitNames = allNames.filter(name => name.startsWith('EXIT')).sort()
  const gateNames = allNames.filter(name => name.startsWith('GATE')).sort()
  const otherNames = allNames.filter(name => name !== 'STORE' && !name.startsWith('ENTRANCE') && !name.startsWith('EXIT') && !name.startsWith('GATE')).sort()
  const names = [...entranceNames, ...exitNames, ...gateNames, ...otherNames]
  console.log(names)
  return d3.scaleOrdinal(d3.schemeSet1).domain(names)
}

export const getStoresAndArea = (storeCode: string, locations: Location[], colors: d3.ScaleOrdinal<string, string>): [AreaModel, AreaModel[]] => {
  const store = locations.find(loc => loc.location_code === `${storeCode}_STORE`)
  if (store === undefined) throw new Error() // TODO
  const areas = locations.filter(loc => loc.location_code !== `${storeCode}_STORE`)
  return [
    {
      name: 'STORE',
      variant: 'store',
      x: store.area[0] / 10,
      y: store.area[1] / 10,
      width: store.area[2] / 10,
      height: store.area[3] / 10,
      color: 'gray',
    },
    areas.map(loc => ({
      name: toLocationName(loc),
      variant: 'area',
      x: loc.area[0] / 10,
      y: loc.area[1] / 10,
      width: loc.area[2] / 10,
      height: loc.area[3] / 10,
      color: colors(toLocationName(loc)),
    }))
  ]
}

export const getAreasFromShelves = (shelves: Shelf[], colors: d3.ScaleOrdinal<string, string>): AreaModel[] => {
  return shelves.filter(shelf => shelf.direction !== 'Diagonal').map(shelf => ({
    name: toShelfName(shelf),
    variant: 'shelf',
    x: shelf.area[0] / 10,
    y: shelf.area[1] / 10,
    width: shelf.area[2] / 10,
    height: shelf.area[3] / 10,
    color: colors(toShelfName(shelf)),
    direction: shelf.direction,
    heights: shelf.heights,
  }))
}

export const getAreasLowFromShelves = (shelves: Shelf[], colors: d3.ScaleOrdinal<string, string>): AreaModel[] => {
  return shelves.filter(shelf => shelf.direction !== 'Diagonal').map(shelf => ({
    name: toShelfName(shelf),
    variant: 'shelf_low',
    x: shelf.area_low[0] / 10,
    y: shelf.area_low[1] / 10,
    width: shelf.area_low[2] / 10,
    height: shelf.area_low[3] / 10,
    color: colors(toShelfName(shelf)),
    direction: shelf.direction,
    heights: shelf.heights,
  }))
}

export const getDiagonalAreasFromShelves = (shelves: Shelf[], colors: d3.ScaleOrdinal<string, string>): DiagonalAreaModel[] => {
  // Diagonal の場合 area は[x1, y1, x2, y2]
  return shelves.filter(shelf => shelf.direction === 'Diagonal').map(shelf => ({
    name: toShelfName(shelf),
    variant: 'shelf',
    x1: shelf.area[0] / 10,
    y1: shelf.area[1] / 10,
    x2: shelf.area[2] / 10,
    y2: shelf.area[3] / 10,
    color: colors(toShelfName(shelf)),
    heights: shelf.heights,
  }))
}

export const getDiagonalAreasLowFromShelves = (shelves: Shelf[], colors: d3.ScaleOrdinal<string, string>): DiagonalAreaModel[] => {
  // Diagonal の場合 area は[x1, y1, x2, y2]
  return shelves.filter(shelf => shelf.direction === 'Diagonal').map(shelf => ({
    name: toShelfName(shelf),
    variant: 'shelf_low',
    x1: shelf.area_low[0] / 10,
    y1: shelf.area_low[1] / 10,
    x2: shelf.area_low[2] / 10,
    y2: shelf.area_low[3] / 10,
    color: colors(toShelfName(shelf)),
    heights: shelf.heights,
  }))
}

export const getAreasFromFloorItems = (items: FloorItem[]): AreaModel[] => {
  return items.map(item => ({
    name: itemName(item),
    variant: 'object',
    x: item.x / 10,
    y: item.y / 10,
    width: item.width / 10,
    height: item.height / 10,
    color: 'black',
  }))
}

interface StoreStageProps {
  persons?: PersonModel[]
  editable?: boolean
  width: number
  height: number
  marginTop?: number
  marginLeft?: number
  marginBottom?: number
  marginRight?: number
  hiddenAreas?: boolean
  hiddenItems?: boolean
  showShelvesLow?: boolean
  lines?: FlowLineModel[]
}

interface Scale {
  x: number
  y: number
}

const sxButton: SxProps<Theme> = {
  marginLeft: 'auto',
}
const sxToolsMenuSwitch: SxProps<Theme> = {
  marginTop: 2,
  paddingLeft: 2,
}
const sxToolsMenu: SxProps<Theme> = {
  marginTop: 1,
}

const PADDING = 2
const BAR_SIZE = 100

export const StoreLayout: React.FC<StoreStageProps> = ({
  persons,
  editable,
  width,
  height,
  hiddenAreas,
  hiddenItems,
  showShelvesLow,
  marginTop = 0,
  marginLeft = 0,
  marginBottom = 0,
  marginRight = 0,
  lines,
}) => {
  if (persons && 0 < persons.length) { // for debug
    console.log(persons.map(p => `${p.person_id} [${p.x}, ${p.y}, ${p.rhx}, ${p.rhy}, ${p.lhx}, ${p.lhy}]`).join(', '))
  }
  const {
    store, locationAreas, itemAreas, shelfAreas, shelfLowAreas, diagonalShelfAreas, diagonalShelfLowAreas,
    setStore, setLocationAreas, setItemAreas, setShelfAreas, setShelfLowAreas, setDiagonalShelflAreas, setDiagonalShelfLowlAreas,
  } = useStoreLayout()
  const [scale, setScale] = useState<Scale>({ x: 1, y: 1 })
  const [selected, setSelected] = useState<AreaModel | DiagonalAreaModel | null>(null)
  const [addAreaDialogOpen, setAddAreaDialogOpen] = useState(false)
  const [addItemDialogOpen, setAddItemDialogOpen] = useState(false)
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false)
  const [shelfLocationDialogOpen, setShelfLocationDialogOpen] = useState(false)
  const [shelfSettingsDialogOpen, setShelfSettingsDialogOpen] = useState(false)
  const [editShelvesLow, setEditShelvesLow] = useState(false)
  const [editItems, setEditItems] = useState(false)
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false)
  const [horizontalBarEnable, setHorizontalBarEnable] = useState(false)
  const [verticalBarEnable, setVerticalBarEnable] = useState(false)
  const [horizontalDelta, setHorizontalDelta] = useState(0)
  const [verticalDelta, setVerticalDelta] = useState(0)
  const horizontalBarRef = useRef<RectType>(null)
  const verticalBarRef = useRef<RectType>(null)

  const offsetX = store.x
  const offsetY = store.y

  // scaling
  useEffect(() => {
    console.log('Scaling....', editable, width, height, store)
    if (editable || (store.width < width && store.height < height)) {
      setScale({
        x: 1,
        y: 1,
      })
      const hBar = width - marginLeft - marginRight < store.width
      const vBar = height - marginTop - marginBottom < store.height
      setHorizontalBarEnable(hBar)
      setVerticalBarEnable(vBar)
      console.log(1, hBar, vBar)
      if (horizontalBarEnable && hBar === false) {
        setHorizontalDelta(0)
        if (horizontalBarRef.current) horizontalBarRef.current.x(PADDING)
      }
      if (verticalBarEnable && vBar === false) {
        setVerticalDelta(0)
        if (verticalBarRef.current) verticalBarRef.current.y(PADDING)
      }
    } else {
      const scale = Math.floor(100 * Math.min((width / store.width), (height / store.height))) / 100
      setScale({
        x: scale,
        y: scale,
      })
      setHorizontalBarEnable(false)
      setVerticalBarEnable(false)
      console.log(scale, false, false)
    }
  }, [editable, store, width, height, horizontalBarEnable, verticalBarEnable, marginLeft, marginRight, marginTop, marginBottom])

  const handleAddArea = useCallback((name: string) => {
    const newLocationAreas: AreaModel[] = [{
      name: name,
      variant: 'area',
      x: offsetX + 100,
      y: offsetY + 100,
      width: 300,
      height: 300,
      color: 'green',
    }, ...locationAreas]
    setLocationAreas(newLocationAreas)
    setAddAreaDialogOpen(false)
  }, [locationAreas, offsetX, offsetY, setLocationAreas])
  const handleStaffGate = useCallback(() => {
    const newGateNo = (() => {
      if (locationAreas.find(a => a.name === 'GATE-IN') === undefined) return ''
      for (let no = 2; ; no++) {
        if (locationAreas.find(a => a.name === `GATE${no}-IN`) === undefined) return no
      }
    })();
    const newLocationAreas: AreaModel[] = [{
      name: `GATE${newGateNo}-OUT`,
      variant: 'area',
      x: offsetX + 50,
      y: offsetY,
      width: 100,
      height: 30,
      color: 'blue',
    }, {
      name: `GATE${newGateNo}-IN`,
      variant: 'area',
      x: offsetX + 50,
      y: offsetY + 30,
      width: 100,
      height: 30,
      color: 'red',
    }, ...locationAreas]
    setLocationAreas(newLocationAreas)
  }, [locationAreas, offsetX, offsetY, setLocationAreas])
  const handleRemoveArea = useCallback(() => {
    const idx = locationAreas.findIndex(a => a.name === selected?.name)
    const newLocationAreas = [...locationAreas]
    newLocationAreas.splice(idx, 1)
    setLocationAreas(newLocationAreas)
    setSelected(null)
  }, [selected, locationAreas, setLocationAreas])
  const handleChangeEditShelvesLow = useCallback(() => {
    setEditShelvesLow(!editShelvesLow)
    setSelected(null)
  }, [editShelvesLow])
  const handleChangeEditItems = useCallback(() => {
    setEditItems(!editItems)
    setSelected(null)
  }, [editItems])
  const handleAddItem = useCallback((name: string) => {
    const newItemAreas: AreaModel[] = [{
      name: name,
      variant: 'object',
      x: offsetX + 50,
      y: offsetY + 50,
      width: 180,
      height: 40,
      color: 'gray',
    }, ...itemAreas]
    setItemAreas(newItemAreas)
    setAddItemDialogOpen(false)
  }, [itemAreas, offsetX, offsetY, setItemAreas])
  const handleRemoveItem = useCallback(() => {
    const idx = itemAreas.findIndex(a => a.name === selected?.name)
    const newItemAreas = [...itemAreas]
    newItemAreas.splice(idx, 1)
    setItemAreas(newItemAreas)
    setSelected(null)
  }, [selected, itemAreas, setItemAreas])
  const handleDownload = useCallback(() => {
    const json = toJsonString(store, locationAreas, shelfAreas, shelfLowAreas, itemAreas)
    console.log(json)
    saveAs(new Blob([json], { type: 'application/json;charset=utf-8' }), 'layout.json')
  }, [store, locationAreas, shelfAreas, shelfLowAreas, itemAreas])
  const handleUpload = useCallback(() => {
    console.log('Uploaded')
    setUploadDialogOpen(false)
  }, [])
  const handleWheel = useCallback((e) => {
    // https://konvajs.org/docs/sandbox/Canvas_Scrolling.html
    // prevent parent scrolling
    e.evt.preventDefault()
    if (horizontalBarRef.current) {
      const dx = e.evt.deltaX
      const minX = -(store.width + marginLeft + marginRight - width)
      const maxX = 0
      const newHorizontalDelta = Math.max(minX, Math.min(horizontalDelta - dx, maxX))
      setHorizontalDelta(newHorizontalDelta)
      const availableWidth = width - PADDING * 2 - BAR_SIZE
      const hx = newHorizontalDelta / (width - store.width - marginRight - marginLeft - PADDING * 2) * availableWidth + PADDING
      horizontalBarRef.current.x(hx)
    }
    if (verticalBarRef.current) {
      const dy = e.evt.deltaY
      const minY = -(store.height + marginTop + marginBottom - height)
      const maxY = 0
      const newVerticalDelta = Math.max(minY, Math.min(verticalDelta - dy, maxY))
      setVerticalDelta(newVerticalDelta)
      const availableHeight = height - PADDING * 2 - BAR_SIZE
      const hy = newVerticalDelta / (-store.height + height - marginTop - marginBottom - PADDING * 2) * availableHeight + PADDING
      verticalBarRef.current.y(hy)
    }
  }, [horizontalDelta, verticalDelta, store, width, height, marginLeft, marginRight, marginTop, marginBottom])
  const handleHorizontalDragMoved = useCallback((e) => {
    // delta in %
    const availableWidth = width - PADDING * 2 - BAR_SIZE
    const delta = (e.target.attrs.x - PADDING) / availableWidth
    setHorizontalDelta(-(store.width + marginLeft + marginRight - width) * delta)
  }, [width, store, marginLeft, marginRight])
  const handleVerticalDragMoved = useCallback((e) => {
    // delta in %
    const availableHeight = height - PADDING * 2 - BAR_SIZE
    const delta = (e.target.attrs.y - PADDING) / availableHeight
    setVerticalDelta(-(store.height + marginTop + marginBottom - height) * delta)
  }, [height, store, marginTop, marginBottom])

  return (
    <Box sx={{ display: 'flex' }}>
      <Stage
        width={width}
        height={height}
        scale={scale}
        onWheel={editable ? handleWheel : undefined}
      >
        <Layer
          x={horizontalDelta}
          y={verticalDelta}
        >
          <Rect
            x={1}
            y={1}
            width={Math.max(width, store.width + marginLeft + marginRight) - 2}
            height={Math.max(height, store.height + marginTop + marginBottom) - 2}
            stroke="gray"
            strokeWidth={1}
            onClick={() => setSelected(null)}
          />
          <Grid
            offsetX={marginLeft}
            offsetY={marginTop}
            width={Math.max(width, store.width + marginLeft + marginRight)}
            height={Math.max(height, store.height + marginTop + marginBottom)}
            majorTicks={100}
          />
        </Layer>
        <Layer
          x={marginLeft + horizontalDelta}
          y={marginTop + verticalDelta}
        >
          <Area
            model={store}
            offsetX={offsetX}
            offsetY={offsetY}
            isSelected={selected?.name === 'STORE' && selected?.variant === 'store'}
            snap
            onSelect={() => {
              if (!editable) return
              console.log(store)
              if (selected) {
                setSelected(null)
              } else if (!editItems) {
                setSelected(store)
              }
            }}
            onChange={(newArea) => {
              console.log(newArea)
              setStore({
                ...newArea,
                x: newArea.x + offsetX,
                y: newArea.y + offsetY,
              })
            }}
          />
          <Group visible={!Boolean(hiddenItems)}>
            {itemAreas.map(item =>
              <Area
                key={item.name}
                model={item}
                offsetX={offsetX}
                offsetY={offsetY}
                minSize={10}
                opacities={[0.5, 0.8]}
                isSelected={selected?.name === item.name && selected?.variant === 'object'}
                draggable={editable && editItems}
                snap
                onSelect={() => {
                  if (!editable || !editItems) return
                  if (selected?.name === item.name && selected?.variant === 'object') {
                    setSelected(null)
                    return
                  }
                  console.log(item)
                  const newItemAreas = [...itemAreas]
                  newItemAreas.splice(newItemAreas.findIndex(a => a.name === item.name), 1)
                  newItemAreas.push(item)
                  setItemAreas(newItemAreas)
                  setSelected(item)
                }}
                onChange={(newItem) => {
                  console.log(newItem)
                  const idx = itemAreas.findIndex(a => a.name === newItem.name)
                  const newItemAreas = [...itemAreas]
                  newItemAreas[idx] = {
                    ...newItem,
                    x: newItem.x + offsetX,
                    y: newItem.y + offsetY,
                  }
                  setItemAreas(newItemAreas)
                }}
              />
            )}
          </Group>
          <Group visible={!editItems && !Boolean(hiddenAreas)}>
            {locationAreas.map(area =>
              <Area
                key={area.name}
                model={area}
                offsetX={offsetX}
                offsetY={offsetY}
                showLable
                isSelected={selected?.name === area.name && selected?.variant === 'area'}
                draggable={editable}
                snap
                onSelect={() => {
                  if (!editable) return
                  if (selected?.name === area.name && selected?.variant === 'area') {
                    setSelected(null)
                    return
                  }
                  console.log(area)
                  const newLocationAreas = [...locationAreas]
                  newLocationAreas.splice(newLocationAreas.findIndex(a => a.name === area.name), 1)
                  newLocationAreas.push(area)
                  setLocationAreas(newLocationAreas)
                  setSelected(area)
                }}
                onChange={(newArea) => {
                  console.log(newArea)
                  const idx = locationAreas.findIndex(a => a.name === newArea.name)
                  const newLocationAreas = [...locationAreas]
                  newLocationAreas[idx] = {
                    ...newArea,
                    x: newArea.x + offsetX,
                    y: newArea.y + offsetY,
                  }
                  setLocationAreas(newLocationAreas)
                }}
              />
            )}
          </Group>
          <Group visible={(!showShelvesLow && !editShelvesLow) && !Boolean(hiddenItems)}>
            {shelfAreas.map(shelf =>
              <Area
                key={shelf.name}
                model={shelf}
                offsetX={offsetX}
                offsetY={offsetY}
                minSize={10}
                opacities={[0.5, 0.8]}
                showLable
                isSelected={selected?.name === shelf.name && selected?.variant === 'shelf'}
                draggable={editable}
                rotateEnabled={editable}
                snap={false}
                onSelect={() => {
                  if (!editable || editItems) return
                  if (selected?.name === shelf.name && selected?.variant === 'shelf') {
                    setSelected(null)
                    return
                  }
                  console.log(shelf)
                  const newShelfAreas = [...shelfAreas]
                  newShelfAreas.splice(newShelfAreas.findIndex(a => a.name === shelf.name), 1)
                  newShelfAreas.push(shelf)
                  setShelfAreas(newShelfAreas)
                  setSelected(shelf)
                }}
                onChange={(newShelfArea) => {
                  console.log(newShelfArea)
                  const idx = shelfAreas.findIndex(a => a.name === shelf.name)
                  const newShelfAreas = [...shelfAreas]
                  newShelfAreas[idx] = {
                    ...newShelfArea,
                    x: newShelfArea.x + offsetX,
                    y: newShelfArea.y + offsetY,
                  }
                  setShelfAreas(newShelfAreas)
                }}
              />
            )}
          </Group>
          <Group visible={(!showShelvesLow && !editShelvesLow) && !Boolean(hiddenItems)}>
            {diagonalShelfAreas.map(shelf => (
              <DiagonalArea
                key={shelf.name}
                model={shelf}
                offsetX={offsetX}
                offsetY={offsetY}
                isSelected={selected?.name === shelf.name && selected?.variant === 'shelf'}
                onSelect={() => {
                  if (!editable || editItems) return
                  if (selected?.name === shelf.name && selected?.variant === 'shelf') {
                    setSelected(null)
                    return
                  }
                  console.log(shelf)
                  const newDiagonalShelfAreas = [...diagonalShelfAreas]
                  newDiagonalShelfAreas.splice(diagonalShelfAreas.findIndex(a => a.name === shelf.name), 1)
                  newDiagonalShelfAreas.push(shelf)
                  setDiagonalShelflAreas(newDiagonalShelfAreas)
                  setSelected(shelf)
                }} />
            ))}
          </Group>
          <Group visible={(showShelvesLow || editShelvesLow) && !Boolean(hiddenItems)}>
            {shelfLowAreas.map(shelf =>
              <Area
                key={shelf.name}
                model={shelf}
                offsetX={offsetX}
                offsetY={offsetY}
                minSize={10}
                opacities={[0.5, 0.8]}
                showLable
                isSelected={selected?.name === shelf.name && selected?.variant === 'shelf_low'}
                draggable={editable}
                rotateEnabled={false}
                snap={false}
                onSelect={() => {
                  if (!editable || editItems) return
                  if (selected?.name === shelf.name && selected?.variant === 'shelf_low') {
                    setSelected(null)
                    return
                  }
                  console.log(shelf)
                  const newShelfLowAreas = [...shelfLowAreas]
                  newShelfLowAreas.splice(newShelfLowAreas.findIndex(a => a.name === shelf.name), 1)
                  newShelfLowAreas.push(shelf)
                  setShelfLowAreas(newShelfLowAreas)
                  setSelected(shelf)
                }}
                onChange={(newShelfArea) => {
                  console.log(newShelfArea)
                  const idx = shelfLowAreas.findIndex(a => a.name === shelf.name)
                  const newShelfLowAreas = [...shelfLowAreas]
                  newShelfLowAreas[idx] = {
                    ...newShelfArea,
                    x: newShelfArea.x + offsetX,
                    y: newShelfArea.y + offsetY,
                  }
                  setShelfLowAreas(newShelfLowAreas)
                }}
              />
            )}
          </Group>
          <Group visible={(showShelvesLow || editShelvesLow) && !Boolean(hiddenItems)}>
            {diagonalShelfLowAreas.map(shelf => (
              <DiagonalArea
                key={shelf.name}
                model={shelf}
                offsetX={offsetX}
                offsetY={offsetY}
                isSelected={selected?.name === shelf.name && selected?.variant === 'shelf_low'}
                onSelect={() => {
                  if (!editable || editItems) return
                  if (selected?.name === shelf.name && selected?.variant === 'shelf_low') {
                    setSelected(null)
                    return
                  }
                  console.log(shelf)
                  const newDiagonalShelfLowAreas = [...diagonalShelfLowAreas]
                  newDiagonalShelfLowAreas.splice(diagonalShelfLowAreas.findIndex(a => a.name === shelf.name), 1)
                  newDiagonalShelfLowAreas.push(shelf)
                  setDiagonalShelfLowlAreas(newDiagonalShelfLowAreas)
                  setSelected(shelf)
                }} />
            ))}
          </Group>
        </Layer>
        <Layer
          x={marginLeft + horizontalDelta}
          y={marginTop + verticalDelta}
        >
          <Group x={-offsetX} y={-offsetY}>
            {lines && lines.map(line => (
              <FlowLine
                key={`line_${line.personId}`}
                model={line}
              />
            ))}
            {persons && persons.map(person => (
              <Person
                key={`person_${person.person_id}`}
                model={person}
              />
            ))}
          </Group>
        </Layer>
        <Layer>
          {horizontalBarEnable && (
            <Rect
              ref={horizontalBarRef}
              width={BAR_SIZE}
              height={10}
              fill='grey'
              opacity={0.8}
              x={PADDING}
              y={height - PADDING - 10}
              draggable={true}
              dragBoundFunc={(pos) => {
                pos.x = Math.max(
                  Math.min(pos.x, width - BAR_SIZE - PADDING),
                  PADDING,
                )
                pos.y = height - PADDING - 10
                return pos
              }}
              onDragMove={handleHorizontalDragMoved}
            />
          )}
          {verticalBarEnable && (
            <Rect
              ref={verticalBarRef}
              width={10}
              height={BAR_SIZE}
              fill='grey'
              opacity={0.8}
              x={width - PADDING - 10}
              y={PADDING}
              draggable={true}
              dragBoundFunc={(pos) => {
                pos.x = width - PADDING - 10
                pos.y = Math.max(
                  Math.min(pos.y, height - BAR_SIZE - PADDING),
                  PADDING,
                )
                return pos
              }}
              onDragMove={handleVerticalDragMoved}
            />
          )}
        </Layer>
      </Stage>
      {editable &&
        <Box sx={{
          maxWidth: 240,
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 1,
        }}>
          <Box sx={sxToolsMenu}>
            <Button
              variant="contained"
              startIcon={<Add />}
              sx={sxButton}
              disabled={editItems}
              onClick={() => setAddAreaDialogOpen(true)}
            >
              エリア追加
            </Button>
            <AddAreaDialog
              open={addAreaDialogOpen}
              onClose={() => { setAddAreaDialogOpen(false) }}
              onAdd={handleAddArea}
            />
          </Box>
          <Box sx={sxToolsMenu}>
            <Button
              variant="contained"
              startIcon={<StaffGate />}
              sx={sxButton}
              disabled={editItems}
              onClick={() => handleStaffGate()}
            >
              ゲート追加
            </Button>
          </Box>
          <Box sx={sxToolsMenu}>
            <Button
              variant="contained"
              startIcon={<Delete />}
              sx={sxButton}
              disabled={
                editItems
                || selected === null
                || selected.variant === 'store'
                || selected.variant === 'shelf'
                || selected.name.startsWith('ENTRANCE')
                || selected.name.startsWith('EXIT')
              }
              onClick={handleRemoveArea}
            >
              削除
            </Button>
          </Box>
          <Box sx={sxToolsMenu}>
            <Button
              variant="contained"
              startIcon={<LocationIcon />}
              sx={sxButton}
              disabled={editItems || (selected?.variant !== 'shelf' && selected?.variant !== 'shelf_low')}
              onClick={() => setShelfLocationDialogOpen(true)}
            >
              棚座標
            </Button>
            {selected &&
              <ShelfLocationDialog
                open={shelfLocationDialogOpen}
                shelf={selected}
                onClose={() => {
                  setSelected(null)
                  setShelfLocationDialogOpen(false)
                }}
              />
            }
          </Box>
          <Box sx={sxToolsMenu}>
            <Button
              variant="contained"
              startIcon={<HeightsSettings />}
              sx={sxButton}
              disabled={editItems || selected?.variant !== 'shelf'}
              onClick={() => setShelfSettingsDialogOpen(true)}
            >
              棚設定
            </Button>
            {selected &&
              <ShelfSettingsDialog
                open={shelfSettingsDialogOpen}
                shelf={selected}
                onClose={() => {
                  setSelected(null)
                  setShelfSettingsDialogOpen(false)
                }}
              />
            }
          </Box>
          <Box sx={sxToolsMenu}>
            <Button
              variant="contained"
              startIcon={<Settings />}
              sx={sxButton}
              disabled={editItems}
              onClick={() => setSettingsDialogOpen(true)}
            >
              店舗設定
            </Button>
            <SettingsDialog
              open={settingsDialogOpen}
              onClose={() => { setSettingsDialogOpen(false) }}
            />
          </Box>
          <FormControlLabel
            control={(
              <Switch
                color="primary"
                size="small"
                checked={!editShelvesLow}
                onChange={handleChangeEditShelvesLow}
              />
            )}
            label="棚エリア上段編集"
            sx={sxToolsMenuSwitch}
          />
          <FormControlLabel
            control={(
              <Switch
                color="primary"
                size="small"
                checked={editItems}
                onChange={handleChangeEditItems}
              />
            )}
            label="フロアオブジェクト編集"
            sx={sxToolsMenuSwitch}
          />
          <Box sx={sxToolsMenu}>
            <Button
              variant="contained"
              startIcon={<AddItem />}
              sx={sxButton}
              disabled={!editItems}
              onClick={() => setAddItemDialogOpen(true)}
            >
              フロアオブジェクト追加
            </Button>
            <AddItemDialog
              open={addItemDialogOpen}
              onClose={() => { setAddItemDialogOpen(false) }}
              onAdd={handleAddItem}
            />
          </Box>
          <Box sx={sxToolsMenu}>
            <Button
              variant="contained"
              startIcon={<Delete />}
              sx={sxButton}
              disabled={!editItems || selected === null}
              onClick={handleRemoveItem}
            >
              フロアオブジェクト削除
            </Button>
          </Box>
          <Box sx={{ margin: 1 }} />
          <Box sx={sxToolsMenu}>
            <Button
              variant="contained"
              startIcon={<Download />}
              sx={sxButton}
              onClick={handleDownload}
            >
              ダウンロード
            </Button>
          </Box>
          <Box sx={sxToolsMenu}>
            <Button
              variant="contained"
              startIcon={<Upload />}
              sx={sxButton}
              onClick={() => setUploadDialogOpen(true)}
            >
              アップロード
            </Button>
            <UploadDialog
              open={uploadDialogOpen}
              onClose={() => { setUploadDialogOpen(false) }}
              onUpload={handleUpload}
            />
          </Box>
        </Box>
      }
    </Box>
  )
}