import React from 'react'
import {
  Container,
  Typography,
  Alert,
  AlertTitle,
} from '@mui/material';

export const NoPermissions: React.FC = () => {
  return (
    <Container sx={{ padding: 4 }}>
      <Alert severity="warning" variant="outlined">
        <AlertTitle>Acess Denied</AlertTitle>
        <Typography>権限がありません。管理者に連絡ください。</Typography>
      </Alert>
    </Container>
  )
}
