import React, {
  useState,
  useCallback,
} from 'react'
import { useForm } from 'react-hook-form'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  TextField,
  Button,
  Box,
} from '@mui/material';
import {
  useApp,
} from 'contexts'
import {
  ProgressScreen,
} from 'components'
import {
  CredentialFormValidations,
  CredentialFormData,
} from '../forms'


type CredentialAddDialogProps = {
  open: boolean
  onClose: () => void
}

export const CredentialAddDialog: React.FC<CredentialAddDialogProps> = ({ open, onClose }) => {
  const { getApi, defaultApiErrorHandler } = useApp()
  const [isUpdating, setIsUpdating] = useState(false)
  const { register, handleSubmit, formState, formState: { errors } } = useForm<CredentialFormData>({
    mode: 'onBlur',
  })

  const onSubmit = useCallback((form: CredentialFormData) => {
    setIsUpdating(true)
    getApi()
      .addCredential(form.clientName)
      .then(() => {
        setIsUpdating(false)
        onClose()
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, onClose])

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>クライアントを追加</DialogTitle>
        <DialogContent>
          <Box sx={{ margin: 0 }}>
            <FormControl sx={{ margin: 1 }}>
              <TextField
                label="クライアント名"
                required
                {...register(`clientName`, CredentialFormValidations.clientName)}
                error={Boolean(errors?.clientName)}
                helperText={errors?.clientName?.message}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={onClose}>キャンセル</Button>
          <Button color="primary" variant="contained" type="submit" disabled={!formState.isValid} >追加</Button>
        </DialogActions>
      </form>
      <ProgressScreen open={isUpdating} />
    </Dialog>
  )
}