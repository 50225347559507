import React, {
  useCallback,
} from 'react'
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  IconButton,
} from '@mui/material';
import {
  AddBox as AddIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material'

interface ExcludeDateListProps {
  excludeDates: string[]
  onChanged: (updated: string[]) => void
  handleAddClicked: () => void
  readOnly: boolean
}

export const ExcludeDateList: React.FC<ExcludeDateListProps> = ({ excludeDates, onChanged, handleAddClicked, readOnly }) => {

  const handleDeleteButtonClicked = useCallback((date: string) => {
    const newExcludeDates = excludeDates.filter(d => d !== date)
    onChanged(newExcludeDates)
  }, [excludeDates, onChanged])

  return (
  <Box sx={{ marginLeft: 1, marginTop: 4 }}>  
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="body1">除外日</Typography>
      <Tooltip title="追加">
        <Box component="span">
          <IconButton
            size="small"
            onClick={handleAddClicked}
            disabled={readOnly}
          >
            <AddIcon/>
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
    <List dense>
    {excludeDates.map(date => (
      <ListItem key={date}>
        <ListItemText primary={date}/>
        <ListItemSecondaryAction>
          <Tooltip title="削除">
            <Box component="span">
              <IconButton
                size="small"
                edge="end"
                disabled={readOnly}
                onClick={() => handleDeleteButtonClicked(date)}
              >
                <DeleteIcon fontSize="inherit"/>
              </IconButton>
            </Box>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    ))}
    </List>
    {excludeDates.length === 0 && (
    <Typography variant="body1" sx={{ marginLeft: 1 }}>除外日はありません</Typography>
    )}
  </Box>
  )
}