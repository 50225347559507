import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import {
  Typography,
  Toolbar,
  Grid,
  Box,
  Tabs,
  Tab,
  Collapse,
  Tooltip,
  IconButton,
  Button,
  FormControlLabel,
  Checkbox,
  SxProps,
  Theme,
} from '@mui/material';
import {
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material'
import {
  useApp,
} from 'contexts'
import {
  useShelvesLayout,
} from 'hooks'
import {
  UnixTimestamp,
  ReplayData,
  TrackingPlayerControlModel,
  toTrackingPlayerFrames,
} from 'models'
import {
  MainContents,
  Loading,
  BackButton,
  ReloadButton,
  TimestampLabel,
  TrackingPlayer,
} from 'components'
import {
  getStoreCodeIn,
} from 'utils'
import {
  WeightsChart,
} from './components'

const toLabel = (code: string) => code.split('_').splice(1).join('_')

const sxTitle: SxProps<Theme> = {
  color: t => t.palette.text.secondary,
}

export const AnalysisReplayDetail: React.FC = () => {
  const { replayId } = useParams()
  if (replayId === undefined) throw new Error('unhandled routing')
  const storeCode = getStoreCodeIn(replayId)
  const navigate = useNavigate()
  const location = useLocation()
  const { getApi, defaultApiErrorHandler, getStore } = useApp()
  const { layout } = useShelvesLayout(storeCode)
  const [data, setData] = useState<ReplayData>()
  const [trackingModel, setTrackingModel] = useState<TrackingPlayerControlModel>()
  const [reload, setReload] = useState(false)
  const [shelfCodes, setShelfCodes] = useState<string[]>([])
  const [shelfCodesValue, setShefCodesValue] = useState<string>()
  const [timestamp, setTimestamp] = useState<UnixTimestamp>()
  const [segments, setSegments] = useState<string[]>([])
  const [showSegmentsFilters, setShowSegmentsFilters] = useState(true)

  const load = useCallback(() => {
    getApi().fetchReplayData(replayId)
      .then(data => {
        setData(data)
        setTrackingModel({
          timestamp: data.timestamp,
          frames: toTrackingPlayerFrames(data),
        })
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, replayId])
  useEffect(() => {
    load()
  }, [load])
  // reload
  useEffect(() => {
    if (!reload) return
    console.log('reloading...')
    load()
    setReload(false)
  }, [load, reload])

  useEffect(() => {
    if (layout === null) return
    // const shelves = 
    setShelfCodes(layout.shelves.map(shelf => shelf.shelfCode))
    if (layout.shelves.length !== 0) {
      const shelf = layout.shelves[0]
      setShefCodesValue(shelf.shelfCode)
      setSegments(shelf.segments.flat().map(s => toLabel(s.segmentCode)))
    }
  }, [layout])

  const handleReload = useCallback(() => {
    setData(undefined)
    setReload(true)
  }, [])
  const handleBack = useCallback(() => {
    navigate(`/analysis/replay${location.search}`)
  }, [navigate, location])
  const handleTimestampChanged = useCallback((timestamp: UnixTimestamp) => {
    setTimestamp(timestamp)
  }, [])
  const handleShelvesValueChanged = useCallback((_: React.ChangeEvent<{}>, value: any) => {
    setShefCodesValue(value)
  }, [])
  const handleFilterButtonClicked = useCallback(() => {
    setShowSegmentsFilters(!showSegmentsFilters)
  }, [showSegmentsFilters])
  const handleAllChecked = useCallback((shelfCode: string) => {
    if (layout === null) return
    const shelf = layout.shelves.find(s => toLabel(s.shelfCode) === shelfCode)
    if (shelf === undefined) return
    const newSegments = Array.from(new Set([...segments, ...shelf.segments.flat().map(s => toLabel(s.segmentCode))])).sort()
    console.log(newSegments)
    setSegments(newSegments)
  }, [layout, segments])
  const handleAllUnchecked = useCallback((shelfCode: string) => {
    const newSegments = segments.filter(s => !s.startsWith(`${shelfCode}_`))
    setSegments(newSegments)
  }, [segments])
  const handleSegmentCheckChanged = useCallback((segmentCode: string) => {
    if (segments.indexOf(segmentCode) === -1) {
      const newSegments = [...segments, segmentCode].sort()
      setSegments(newSegments)
    } else {
      const newSegments = segments.filter(s => s !== segmentCode)
      setSegments(newSegments)
    }
  }, [segments])

  return (
    <MainContents>
      <Toolbar variant="dense" disableGutters>
        <BackButton onClick={handleBack} />
        <Typography variant="h5" sx={{ marginLeft: 1 }} >{replayId}</Typography>
        <ReloadButton marginLeft="auto" onClick={handleReload} />
      </Toolbar>
      <Grid container spacing={1} sx={{ marginTop: 0.5, marginBottom: 2 }}>
        <Grid item xs={4} sm={2} sx={sxTitle}>
          <Typography>店舗</Typography>
        </Grid>
        <Grid item xs={8} sm={4}>
          <Typography>{getStore(storeCode).store_name}</Typography>
        </Grid>
        <Grid item xs={4} sm={2} sx={sxTitle}>
          <Typography>期間</Typography>
        </Grid>
        <Grid item xs={8} sm={4}>
          <Typography>
            {data && (<TimestampLabel value={data.timestamp} format="yyyy/MM/dd HH:mm" />)}
            <Box component="span" sx={{ marginLeft: 0.5, marginRight: 0.5 }}>〜</Box>
            {data && (<TimestampLabel value={data.timestamp + data.duration_min * 60} format="HH:mm" />)}
          </Typography>
        </Grid>
      </Grid>
      <TrackingPlayer
        storeCode={storeCode}
        model={trackingModel}
        onTimestampChanged={handleTimestampChanged}
      />
      {data && (
        <WeightsChart
          replayData={data}
          timestamp={timestamp}
          segments={segments}
          height={250}
        />
      )}
      <Loading visible={data === undefined} />
      <Box display="flex">
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          value={shelfCodesValue}
          onChange={handleShelvesValueChanged}
        >
          {shelfCodes.map(shelfCode => (
            <Tab key={shelfCode} label={toLabel(shelfCode)} value={shelfCode} sx={{ minWidth: '80px' }} />
          ))}
        </Tabs>
        <Box sx={{ paddingLeft: 1, textAlign: 'right' }}>
          <IconButton
            disableRipple
            disabled={Boolean(layout === null)}
            onClick={handleFilterButtonClicked}
            size="large">
            {showSegmentsFilters ? (<ExpandLess />) : (<ExpandMore />)}
          </IconButton>
        </Box>
      </Box>
      {layout && layout.shelves.map(shelf => (
        <Collapse key={`tab_${shelf.shelfCode}`} in={showSegmentsFilters && Boolean(shelfCodesValue === shelf.shelfCode)} sx={{ marginLeft: 1, marginRight: 1 }}>
          {shelf.segments.map((row, rowIdx) => (
            <div key={`${shelf.shelfCode}-${rowIdx}`}>
              {row.map(s => (
                <Tooltip key={s.segmentCode} title={s.item?.item_name.ja || '商品未設定'}>
                  <FormControlLabel
                    label={toLabel(s.segmentCode)}
                    control={(
                      <Checkbox
                        size="small"
                        color="primary"
                        name={s.segmentCode}
                        checked={segments.indexOf(toLabel(s.segmentCode)) !== -1}
                        onChange={() => handleSegmentCheckChanged(toLabel(s.segmentCode))}
                      />
                    )}
                  />
                </Tooltip>
              ))}
            </div>
          ))}
          <Toolbar variant="dense" disableGutters>
            <Button variant="outlined" size="small" sx={{ marginRight: 1 }} onClick={() => handleAllChecked(toLabel(shelf.shelfCode))}>すべて選択</Button>
            <Button variant="outlined" size="small" sx={{ marginRight: 1 }} onClick={() => handleAllUnchecked(toLabel(shelf.shelfCode))}>すべて解除</Button>
          </Toolbar>
        </Collapse>
      ))}
    </MainContents>
  );
}
