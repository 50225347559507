
import React, {
  useEffect,
  useCallback,
} from 'react'
import { useForm } from 'react-hook-form'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  FormLabel,
  FormControl,
  TextField,
} from '@mui/material'
import {
  Plug,
} from 'models'
import {
  PlugFormData,
  PlugFormValidations,
} from './plug_forms'
import {
  sx,
} from '../sx'

interface AddPlugDialogProps {
  open: boolean
  onClose: () => void
  onAdded: (plug: Plug) => void
}

export const AddPlugDialog: React.FC<AddPlugDialogProps> = ({
  open,
  onClose,
  onAdded,
}) => {
  const { register, handleSubmit, reset, formState, formState: { errors } } = useForm<PlugFormData>({
    mode: 'onBlur',
  })
  const { isDirty, isValid } = formState;

  // reset form
  useEffect(() => {
    reset({
      name: '',
    })
  }, [reset, open])

  // 更新
  const onSubmit = useCallback((data: PlugFormData) => {
    onAdded({
      name: data.name,
      desc: data.desc,
    })
  }, [onAdded])
  const handleCanceled = useCallback(() => {
    console.log('handleCanceled')
    onClose()
  }, [onClose])
  
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>プラグ追加</DialogTitle>
        <DialogContent>
          <Grid container spacing={0} sx={sx.forms}>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>プラグ名</FormLabel>
            </Grid>
            <Grid item xs={9}>
              <FormControl sx={sx.formControl} fullWidth>
                <TextField
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  margin="dense"
                  required
                  {...register(`name`, PlugFormValidations.name)}
                  error={Boolean(errors?.name)}
                  helperText={errors?.name?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel sx={sx.label}>説明</FormLabel>
            </Grid>
            <Grid item xs={9}>
              <FormControl sx={sx.formControl} fullWidth>
                <TextField
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  margin="dense"
                  {...register(`desc`, PlugFormValidations.desc)}
                  error={Boolean(errors?.desc)}
                  helperText={errors?.desc?.message}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button 
            color="inherit"
            variant="contained"
            sx={sx.action}
            onClick={handleCanceled}
          >
            キャンセル
          </Button>
          <Button 
            type="submit"
            color="primary"
            variant="contained"
            sx={sx.action}
            disabled={!isDirty || !isValid}
          >
            OK
          </Button>
      </DialogActions>
      </form>
    </Dialog>
  )
}