import React, {
} from 'react'
import {
  Tooltip,
} from '@mui/material'
import {
  green,
} from '@mui/material/colors'
import {
  FiberManualRecord as Circle,
} from '@mui/icons-material'
import {
  EdgeStatus,
} from 'models'


type EdgeModuleStatusMarkProps = {
  status: EdgeStatus | undefined
}

export const EdgeModuleStatusMark: React.FC<EdgeModuleStatusMarkProps> = ({ status }) => {
  if (status === 'running') {
    return (
      <Tooltip title="running">
        <Circle style={{ color: green[500] }} />
      </Tooltip>
    )
  } else if (status === 'error') {
    return (
      <Tooltip title="error">
        <Circle color="error" />
      </Tooltip>
    )
  } else {
    return (
      <Tooltip title={status || 'undefined'}>
        <Circle color="disabled" />
      </Tooltip>
    )
  }
}
