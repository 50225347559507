import React from 'react'
import {
  Routes,
  Route,
} from 'react-router-dom'
import { Walkthroughs } from './Walkthroughs'
import { WalkthroughConfirm } from './confirm/WalkthroughConfirm'
import { WalkthroughActions } from './actions/WalkthroughActions'
import { Detail } from './actions/Detail'
import { WalkthroughsDetail } from './WalkthroughsDetail'

export const WalkthroughRoutes: React.FC = () => (
<Routes>
  <Route index element={<Walkthroughs/>}/>
  <Route path=":walkthroughId" element={<WalkthroughsDetail/>}/>
  <Route path="confirm" element={<WalkthroughConfirm/>}/>
  <Route path="actions">
    <Route index element={<WalkthroughActions/>}/>
    <Route path=":actionId" element={<Detail/>}/>
  </Route>
</Routes>
)
