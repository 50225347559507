import React, {
  useCallback,
} from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  FormControl,
  TextField,
  Button,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import {
  useStoreLayout,
} from 'contexts'
import {
  AreaModel,
} from './Area'
import {
  DiagonalAreaModel,
  isAreaModel,
  isDiagonalAreaModel,
} from './DiagonalArea'

type FormData = {
  x: string
  y: string
  width: string
  height: string
}

type DiagonalFormData = {
  x1: string
  y1: string
  x2: string
  y2: string
}

const isFormData = (data: FormData | DiagonalFormData): data is FormData => {
  return (data as any).width !== undefined
}

const isDiagonalFormData = (data: FormData | DiagonalFormData): data is DiagonalFormData => {
  return (data as any).x1 !== undefined
}

const FormValidations = {
  x: {
    required: {
      value: true,
      message: 'X座標を入力してください',
    },
    min: {
      value: 0,
      message: 'X座標は0以上で入力してください',
    },
  },
  y: {
    required: {
      value: true,
      message: 'Y座標を入力してください',
    },
    min: {
      value: 0,
      message: 'Y座標は0以上で入力してください',
    },
  },
  width: {
    required: {
      value: true,
      message: '幅を入力してください',
    },
    min: {
      value: 0,
      message: '幅は10〜1000で入力してください',
    },
    max: {
      value: 1000,
      message: '幅は10〜1000で入力してください',
    },
  },
  height: {
    required: {
      value: true,
      message: '高さを入力してください',
    },
    min: {
      value: 0,
      message: '高さは10〜100で入力してください',
    },
    max: {
      value: 1000,
      message: '高さは10〜1000で入力してください',
    },
  },
  x1: {
    required: {
      value: true,
      message: 'x1座標を入力してください',
    },
    min: {
      value: 0,
      message: 'x1座標は0以上で入力してください',
    },
  },
  y1: {
    required: {
      value: true,
      message: 'y1座標を入力してください',
    },
    min: {
      value: 0,
      message: 'y1座標は0以上で入力してください',
    },
  },
  x2: {
    required: {
      value: true,
      message: 'x2座標を入力してください',
    },
    min: {
      value: 0,
      message: 'x2座標は0以上で入力してください',
    },
  },
  y2: {
    required: {
      value: true,
      message: 'y2座標を入力してください',
    },
    min: {
      value: 0,
      message: 'y2座標は0以上で入力してください',
    },
  },
}

export interface ShelfLocationDialogProps {
  open: boolean
  onClose: () => void
  shelf: DiagonalAreaModel | AreaModel
}

export const ShelfLocationDialog: React.FC<ShelfLocationDialogProps> = ({ open, onClose, shelf }) => {
  const {
    shelfAreas, shelfLowAreas, diagonalShelfAreas, diagonalShelfLowAreas,
    setShelfAreas, setShelfLowAreas, setDiagonalShelflAreas, setDiagonalShelfLowlAreas,
  } = useStoreLayout()
  const form = useForm<FormData>({
    mode: 'onBlur',
  })
  const diagonalForm = useForm<DiagonalFormData>({
    mode: 'onBlur',
  })

  const onSubmit = useCallback((data: FormData | DiagonalFormData) => {
    console.log(shelf, data)
    if (isAreaModel(shelf) && isFormData(data)) {
      if (shelf.variant === 'shelf') {
        const idx = shelfAreas.findIndex(a => a.name === shelf.name)
        const newShelfAreas = [...shelfAreas]
        newShelfAreas[idx] = {
          ...shelf,
          x: Number(data.x),
          y: Number(data.y),
          width: Number(data.width),
          height: Number(data.height),
        }
        setShelfAreas(newShelfAreas)  
      } else {
        const idx = shelfLowAreas.findIndex(a => a.name === shelf.name)
        const newShelfLowAreas = [...shelfLowAreas]
        newShelfLowAreas[idx] = {
          ...shelf,
          x: Number(data.x),
          y: Number(data.y),
          width: Number(data.width),
          height: Number(data.height),
        }
        setShelfLowAreas(newShelfLowAreas)
      }
    } else if (isDiagonalAreaModel(shelf) && isDiagonalFormData(data)) {
      if (shelf.variant === 'shelf') {
        const idx = diagonalShelfAreas.findIndex(a => a.name === shelf.name)
        const newDiagonalShelfAreas = [...diagonalShelfAreas]
        newDiagonalShelfAreas[idx] = {
          ...shelf,
          x1: Number(data.x1),
          y1: Number(data.y1),
          x2: Number(data.x2),
          y2: Number(data.y2),
        }
        setDiagonalShelflAreas(newDiagonalShelfAreas)
      } else {
        const idx = diagonalShelfLowAreas.findIndex(a => a.name === shelf.name)
        const newDiagonalShelfLowAreas = [...diagonalShelfLowAreas]
        newDiagonalShelfLowAreas[idx] = {
          ...shelf,
          x1: Number(data.x1),
          y1: Number(data.y1),
          x2: Number(data.x2),
          y2: Number(data.y2),
        }
        setDiagonalShelfLowlAreas(newDiagonalShelfLowAreas)
      }
    } else {
      throw new Error()
    }
    onClose()
  }, [shelf, shelfAreas, shelfLowAreas, diagonalShelfAreas, diagonalShelfLowAreas, setShelfAreas, setShelfLowAreas, setDiagonalShelflAreas, setDiagonalShelfLowlAreas, onClose])

  return (
  <Dialog
    maxWidth="sm"
    fullWidth
    open={open}
    onClose={onClose}
    disableEscapeKeyDown
  >
    <DialogTitle>棚座標設定</DialogTitle>
    {isAreaModel(shelf) && (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl>
              <TextField
                label="X座標"
                size="small"
                margin="dense"
                variant="outlined"
                required
                type="number"
                defaultValue={shelf.x}
                inputProps={{ step: '1' }}
                {...form.register(`x`, FormValidations.x)}
                error={Boolean(form.formState.errors?.x)}
                helperText={form.formState.errors?.x?.message}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <TextField
                label="Y座標"
                size="small"
                margin="dense"
                variant="outlined"
                required
                type="number"
                defaultValue={shelf.y}
                inputProps={{ step: '1' }}
                {...form.register(`y`, FormValidations.y)}
                error={Boolean(form.formState.errors?.y)}
                helperText={form.formState.errors?.y?.message}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <TextField
                label="幅"
                size="small"
                margin="dense"
                variant="outlined"
                required
                type="number"
                defaultValue={shelf.width}
                inputProps={{ step: '1' }}
                {...form.register(`width`, FormValidations.width)}
                error={Boolean(form.formState.errors?.width)}
                helperText={form.formState.errors?.width?.message}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <TextField
                label="奥行"
                size="small"
                margin="dense"
                variant="outlined"
                required
                type="number"
                defaultValue={shelf.height}
                inputProps={{ step: '1' }}
                {...form.register(`height`, FormValidations.height)}
                error={Boolean(form.formState.errors?.height)}
                helperText={form.formState.errors?.height?.message}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>キャンセル</Button>
        <Button color="primary" variant="contained" type="submit" disabled={!form.formState.isValid}>OK</Button>
      </DialogActions>
    </form>
    )}
    {isDiagonalAreaModel(shelf) && (
    <form onSubmit={diagonalForm.handleSubmit(onSubmit)}>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl>
              <TextField
                label="左端X座標"
                size="small"
                margin="dense"
                variant="outlined"
                required
                type="number"
                defaultValue={shelf.x1}
                inputProps={{ step: '1' }}
                {...diagonalForm.register(`x1`, FormValidations.x1)}
                error={Boolean(diagonalForm.formState.errors?.x1)}
                helperText={diagonalForm.formState.errors?.x1?.message}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <TextField
                label="左端Y座標"
                size="small"
                margin="dense"
                variant="outlined"
                required
                type="number"
                defaultValue={shelf.y1}
                inputProps={{ step: '1' }}
                {...diagonalForm.register(`y1`, FormValidations.y1)}
                error={Boolean(diagonalForm.formState.errors?.y1)}
                helperText={diagonalForm.formState.errors?.y1?.message}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <TextField
                label="右端X座標"
                size="small"
                margin="dense"
                variant="outlined"
                required
                type="number"
                defaultValue={shelf.x2}
                inputProps={{ step: '1' }}
                {...diagonalForm.register(`x2`, FormValidations.x2)}
                error={Boolean(diagonalForm.formState.errors?.x2)}
                helperText={diagonalForm.formState.errors?.x2?.message}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <TextField
                label="右端Y座標"
                size="small"
                margin="dense"
                variant="outlined"
                required
                type="number"
                defaultValue={shelf.y2}
                inputProps={{ step: '1' }}
                {...diagonalForm.register(`y2`, FormValidations.y2)}
                error={Boolean(diagonalForm.formState.errors?.y2)}
                helperText={diagonalForm.formState.errors?.y2?.message}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Typography>※左右の座標を反転すると棚の向きが変わります</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>キャンセル</Button>
        <Button color="primary" variant="contained" type="submit" disabled={!diagonalForm.formState.isValid}>OK</Button>
      </DialogActions>
    </form>
    )}
  </Dialog>
  )
}

