import React, {
  useState,
  useCallback,
} from 'react'
import {
  Button,
  IconButton,
  Typography,
  TextFieldProps,
} from '@mui/material'
import {
  NavigateBefore,
  NavigateNext,
} from '@mui/icons-material'
import {
  DesktopDatePicker,
  LocalizationProvider,
} from '@mui/lab'
import { DateTime } from 'luxon'
import { isSameOrAfterDays } from 'utils'
import LuxonUtils from '@date-io/luxon'
import { DateIOFormats } from "@date-io/core/IUtils";

// CalendarPickerのヘッダを自然にするためにフォーマットをカスタマイズする
class AdapterLuxon extends LuxonUtils {

  constructor({
    locale,
    formats,
  }: { formats?: Partial<DateIOFormats>; locale?: string } = {}) {
    super({ formats, locale });
  }

  public format = (date: DateTime, formatKey: keyof DateIOFormats) => {
    if (formatKey === 'month') return ''
    if (formatKey === 'year') return this.formatByString(date, 'yyyy年MMM月');
    return this.formatByString(date, this.formats[formatKey]);
  };
}

type DatePickerProps = {
  disabled?: boolean
  defaultDate?: DateTime
  onChange: (dateTime: DateTime) => void
  inputFormat?: string
  disableNavigate?: boolean
  showUnderline?: boolean
  disablePast?: boolean
  disableFuture?: boolean
}

const getColor = (dateTime: DateTime): string => {
  switch (dateTime.weekday) {
    case 6: // Sat.
      return 'blue'
    case 7: // Sun.
      return 'red'
    default:
      return 'inherit'
  }
}

export const DatePicker: React.FC<DatePickerProps> = ({
  disabled,
  defaultDate,
  onChange,
  inputFormat = 'M/d',
  disableNavigate = false,
  showUnderline = false,
  disablePast = false,
  disableFuture = true,
}) =>  {
  const [date, setDate] = useState(defaultDate || DateTime.now())
  const [color, setColor] = useState('inherit')
  const [isOpen, setIsOpen] = useState(false)

  const handleDateChange = useCallback((date: unknown) => {
    console.log(date)
    setIsOpen(false)
    setDate(date as DateTime)
    onChange(date as DateTime)
    setColor(getColor(date as DateTime))
  }, [onChange])
  //
  const handleNavigateBefore = useCallback(() => {
    const newDate = date.minus({ days: 1 })
    setDate(newDate)
    onChange(newDate)
    setColor(getColor(newDate))
  }, [date, onChange])
  //
  const handleNavigateNext = useCallback(() => {
    const newDate = date.plus({ days: 1 })
    setDate(newDate)
    onChange(newDate)
    setColor(getColor(newDate))
  }, [date, onChange])

  const DateField: React.FC<TextFieldProps> = useCallback((props) =>  {
    const handle = () => {
      console.log('handle', isOpen)
      setIsOpen(true)
    }
    return (
      <Button ref={props.inputRef} onClick={handle} disabled={disabled} style={{
        borderStyle: 'outset',
        borderRadius: 0,
        borderBottomWidth: showUnderline ? '1px' : undefined,
        paddingBottom: showUnderline ? '2px' : undefined,
      }}>
        <Typography 
          variant="subtitle1" 
          style={{
            color: disabled ? 'inherit' : color,
          }}>
          {`${props.inputProps?.value}`}
        </Typography>
      </Button>
    )
  }, [isOpen, color, disabled, showUnderline])

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} locale="ja-JP">
      {!disableNavigate && (
      <IconButton
        aria-label="昨日"
        disabled={disabled}
        onClick={handleNavigateBefore}
        size="large">
        <NavigateBefore />
      </IconButton>
      )}
      <DesktopDatePicker
        views={['day']}
        value={date}
        inputFormat={inputFormat}
        renderInput={(props) => <DateField {...props}/>}
        disabled={disabled}
        disablePast={disablePast}
        disableFuture={disableFuture}
        onChange={handleDateChange}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        rightArrowButtonText="翌月"
        leftArrowButtonText="前月"
      />
      {!disableNavigate && (
      <IconButton
        aria-label="翌日"
        disabled={disabled || isSameOrAfterDays(date)}
        onClick={handleNavigateNext}
        size="large">
        <NavigateNext />
      </IconButton>
      )}
    </LocalizationProvider>
  )
}