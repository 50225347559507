import React, {
  useCallback,
} from 'react'
import {
  Select,
  SelectChangeEvent,
  MenuItem,
} from '@mui/material'
import {
  AwslogsLevel,
} from 'models'

interface AwslogsLevelSelectProps {
  awslogsLevel: AwslogsLevel
  onChange: (awslogsLevel: AwslogsLevel) => void
}

export const AwslogsLevelSelect: React.FC<AwslogsLevelSelectProps> = ({ awslogsLevel, onChange }) => {
  const onChanged = useCallback((evt: SelectChangeEvent<AwslogsLevel>) => {
    const newValue = evt.target.value as AwslogsLevel
    onChange(newValue)
  }, [onChange])
  return (
    <Select size="small" value={awslogsLevel} onChange={onChanged}>
      <MenuItem value="NONE">無効</MenuItem>
      <MenuItem value="INFO">INFO</MenuItem>
      <MenuItem value="WARN">WARN</MenuItem>
      <MenuItem value="ERROR">ERROR</MenuItem>
    </Select>
  )
}
