
// deviocafe-test_20200701_0001 => #1
export const formatWalkthroughId = (walkthroughId: string|undefined): string => (
  (walkthroughId === undefined) ? '-' : `#${Number(walkthroughId.split('_').pop())}`
)

// 12345678901 => #901
export const formatPersonId = (personId: number|undefined): string => (
  (personId === undefined || personId === 0) ? '-' : `#${('000'+(personId % 1000)).slice(-3)}`
)
