import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  useParams,
  useNavigate,
  Link as RouterLink,
} from 'react-router-dom'
import {
  Grid,
  Box,
  Link,
  Typography,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  ArrowBack,
  Edit,
  Settings,
  Delete as DeleteIcon,
} from '@mui/icons-material'
import {
  useShelfLayout,
} from 'hooks'
import {
  getMinItemWeight,
} from 'models'
import {
  useApp,
} from 'contexts'
import {
  Loading,
  ShelfTile,
  WeightLabel,
  ConfirmDialog,
  ProgressScreen,
} from 'components'
import { EditDialog } from './shelves/components/EditDialog'
import { ConfigDialog } from './shelves/components/ConfigDialog'

export const StoresShelf: React.FC = () => {
  const { storeCode, shelfCode } = useParams()
  const navigate = useNavigate()
  if (storeCode === undefined || shelfCode === undefined) throw new Error('unhandled routing')
  const { getApi, defaultApiErrorHandler } = useApp()
  const { layout, disabled, weightThreshold, weightFluctuation, disableAutoModifyWeightThreshold, shelfType, setReload } = useShelfLayout(`${storeCode}_${shelfCode}`)
  const [ minWeight, setMinWeight] = useState<number>()
  const [ defaultWeightThreshold, setDefaultWeightThreshold] = useState<number>()
  const [ editDialogOpen, setEditDialogOpen ] = useState(false)
  const [ configDialogOpen, setConfigDialogOpen ] = useState(false)
  const [ openDeleteConfirmDialog, setOpenDeleteConfirmDialog ] = useState(false)
  const [ updating, setUpdating ] = useState(false)
  console.log(`StoresShelf##`, layout)

  useEffect(() => {
    getApi()
      .fetchConfiguration(storeCode)
      .then(result => {
        setDefaultWeightThreshold(result.weight_threshold)
      })
      .catch(defaultApiErrorHandler)
  }, [storeCode, getApi, defaultApiErrorHandler])
  useEffect(() => {
    setMinWeight(layout ? getMinItemWeight(layout) : undefined)
  }, [layout])

  const handleUpdated = useCallback(() => {
    console.log(`StoresShelf##handleUpdated`)
    setEditDialogOpen(false)
    setReload(true)
  }, [setReload])
  const handleConfigUpdated = useCallback(() => {
    console.log(`StoresShelf##handleConfigUpdated`)
    setConfigDialogOpen(false)
    setReload(true)
  }, [setReload])
  const handleDelete = useCallback((isOk: boolean) => {
    setOpenDeleteConfirmDialog(false)
    if (!isOk) return
    setUpdating(true)
    getApi()
      .deleteShelf(`${storeCode}_${shelfCode}`)
      .then(() => {
        navigate(`/stores/${storeCode}/shelves`)
      })
      .catch(defaultApiErrorHandler)
      .finally(() => {
        setUpdating(false)
      })
  }, [storeCode, shelfCode, getApi, navigate])

  return (
    <>
      <Box sx={{ display: 'flex', margin: 0.5 }}>
        <Link
          component={RouterLink}
          to={`/stores/${storeCode}/shelves`}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <ArrowBack sx={{ marginRight: 0.5 }}/>戻る
        </Link>
        <Tooltip title="棚レイアウトの編集">
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Edit/>}
            onClick={() => setEditDialogOpen(true)}
            disabled={layout === null}
            sx={{ marginLeft: 'auto' }}
          >
          編集
          </Button>
        </Tooltip>
        {layout &&
        <EditDialog
          layout={layout}
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)} 
          onUpdate={handleUpdated}
        />
        }
      </Box>
      <Loading visible={layout === null} />
      {layout && (
      <>
        <Typography variant="h5" sx={{ textAlign: 'center' }}>
          {layout.shelfCode.split('_')[1]}
          {disabled && (<>（無効）</>)}
        </Typography>
        <ShelfTile
          model={layout}
          minHeight={160}
          showItems={true}
          showItemsWeight={true}
        />
        {weightFluctuation && (
        <Typography variant="body2" sx={{ marginTop: 1, textAlign: 'right' }}>※重量の個体差が大きい商品を含む</Typography>
        )}
        {disableAutoModifyWeightThreshold && (
        <Typography variant="body2" sx={{ marginTop: 1, textAlign: 'right' }}>※最小重量の自動設定は無効</Typography>
        )}
        <Grid container>
          <Grid xs={4}>
            {minWeight && (
            <Typography sx={{ marginLeft: 1, marginTop: 1 }}>最小重量: <WeightLabel value={minWeight}/></Typography>
            )}
          </Grid>
          <Grid xs={8}>
            <Typography sx={{ marginTop: 1, paddingLeft: 2, textAlign: 'right' }}>
              重量変化しきい値: {weightThreshold || defaultWeightThreshold}g{weightThreshold === undefined && (<>（デフォルト値）</>)}
              <Tooltip title="設定">
                <IconButton size="small" color="primary" onClick={() => setConfigDialogOpen(true)} sx={{ marginLeft: 1 }}>
                  <Settings/>
                </IconButton>
              </Tooltip>
              <Tooltip title="削除">
                <IconButton size="small" color="secondary" onClick={() => setOpenDeleteConfirmDialog(true)} sx={{ marginLeft: 1 }}>
                  <DeleteIcon/>
                </IconButton>
              </Tooltip>
            </Typography>  
          </Grid>
        </Grid>
        {defaultWeightThreshold && shelfType && (
        <ConfigDialog
          defaultWeightThreshold={defaultWeightThreshold}
          shelfType={shelfType}
          disabled={disabled}
          weightThreshold={weightThreshold}
          weightFluctuation={weightFluctuation}
          disableAutoModifyWeightThreshold={disableAutoModifyWeightThreshold}
          open={configDialogOpen}
          onClose={() => setConfigDialogOpen(false)} 
          onUpdated={handleConfigUpdated}
        />
        )}
        <ConfirmDialog
          title="棚の削除"
          contentText="棚、棚エリア設定、紐付く商品の情報を全て削除します"
          open={openDeleteConfirmDialog}
          color="error"
          handleClose={handleDelete}
        />
        <ProgressScreen colorVariant="light" open={updating}/>
      </>
      )}
    </>
  )
}
