import React, {
} from 'react'
import {
  red,
  orange,
} from '@mui/material/colors'

interface StatisticsNumberLabelProps {
  value: number
  displayZero?: boolean
  disableWarnAndError?: boolean
  warnThreshold?: number
  errorThreshold?: number
}

const getStyle = (value: number, warnThreshold: number,  errorThreshold: number) => {
  if (errorThreshold <= value) return { color: red[800] }
  if (warnThreshold <= value) return { color: orange[800] }
  return undefined
}

const getLabel = (value: number, displayZero: boolean): string => ((!displayZero && value === 0) ? '-' : `${value}`)

export const StatisticsNumberLabel: React.FC<StatisticsNumberLabelProps> = ({
  value,
  displayZero = false,
  disableWarnAndError = false,
  warnThreshold = 1,
  errorThreshold = 4,
}) => {
  return (<span style={disableWarnAndError ? undefined : getStyle(value, warnThreshold, errorThreshold)}>{getLabel(value, displayZero)}</span>)
}
