import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import {
  Toolbar,
  Box,
  SxProps,
  Theme,
} from '@mui/material';
import {
  StoreLayoutProvider,
} from 'contexts'
import {
  useSelectedStoreCode,
  useInterval,
} from 'hooks'
import {
  Store,
} from 'models'
import {
  MainContents,
  StoresSelector,
  StoreLayout,
  PersonModel,
  toPersonModel,
} from 'components'
import {
  subscribe,
} from 'iot'
import { DateTime } from 'luxon'

const sxDateTime: SxProps<Theme> = {
  marginRight: 1,
  marginTop: 0.5,
  textAlign: 'right',
}

export const MonitorTracking: React.FC = () => {
  const { storeCode, setStoreCode } = useSelectedStoreCode()
  const [persons, setPersons] = useState<PersonModel[]>([])
  const [check, setCheck] = useState(false)
  const [dateTime, setDateTime] = useState<DateTime>()
  const subscribeRef = useRef<number>()

  useEffect(() => {
    console.log(`tracking/${storeCode}`)
    const subscriber = subscribe<any>(`tracking/${storeCode}`, (d) => {
      for (let i = 0, len = d.value.frames.length; i < len; i++) {
        setTimeout(() => {
          setPersons(d.value.frames[i].persons.map((p: any) => toPersonModel(p)))
        }, 1000 * i / len)
      }
      const dt = d.value.timestamp ? DateTime.fromSeconds(d.value.timestamp) : undefined
      setDateTime(dt)
      if (dt !== undefined) console.log(dt.toFormat('HH:mm:ss'))
      subscribeRef.current = DateTime.now().toSeconds()
    })
    return () => {
      console.log('out')
      subscriber.disconnect()
    }
  }, [storeCode])

  useInterval(() => {
    setCheck(true)
  }, 5000) // 5 sec
  useEffect(() => {
    if (!check) return
    if (subscribeRef.current === undefined || subscribeRef.current + 5 < DateTime.now().toSeconds()) {
      setPersons([])
      setDateTime(undefined)
    }
    setCheck(false)
  }, [check])

  const onStoreChanged = useCallback((store: Store | undefined) => {
    if (store) setStoreCode(store.store_code)
  }, [setStoreCode])

  return (
    <MainContents>
      <Toolbar variant="dense" disableGutters>
        <StoresSelector
          storeCode={storeCode}
          onSeleced={onStoreChanged}
        />
      </Toolbar>
      <StoreLayoutProvider storeCode={storeCode}>
        <StoreLayout
          width={870}
          height={600}
          persons={persons}
        />
      </StoreLayoutProvider>
      <Box sx={sxDateTime}>{dateTime ? dateTime.toFormat('HH:mm:ss') : 'No Data'}</Box>
    </MainContents>
  )
}
