import React, {
  useEffect,
  useState,
  useCallback,
} from 'react'
import {
  Toolbar,
} from '@mui/material'
import MaterialTable from '@material-table/core'
import {
  useApp,
} from 'contexts'
import {
  useInterval,
} from 'hooks'
import {
  Obscure,
} from 'models'
import {
  MainContents,
  Loading,
  ReloadButton,
  TimestampLabel,
  SimpleMaterialTableOptions,
  MaterialTableComponents,
  MaterialTableLocalization,
} from 'components'
import {
  WalkthroughConfirmDialog,
  ConfirmPersonLabel,
  ConfirmItemLabel,
} from './components'

export const WalkthroughConfirm: React.FC = () => {
  const [obscures, setObscures] = useState<Obscure[]>()
  const [selected, setSelected] = useState<Obscure>()
  const [openConfirm, setOpenConfim] = useState(false)
  const { getApi, getStore, defaultApiErrorHandler } = useApp()
  const [reload, setReload] = useState(false)

  const load = useCallback(() => {
    getApi()
      .fetchObscures()
      .then((result) => {
        console.log('fetchAsync done')
        setObscures(result.items)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler])

  useEffect(() => {
    load()
  }, [load])

  // reload
  useEffect(() => {
    if (!reload || openConfirm) return
    console.log('reloading...')
    load()
    setReload(false)
  }, [load, reload, openConfirm])

  // auto reload
  useInterval(() => {
    setReload(true)
  }, 20000)

  const handleReload = useCallback(() => {
    setObscures(undefined)
    setReload(true)
  }, [])
  const handleConfirmClose = useCallback(() => {
    setReload(true)
    setOpenConfim(false)
  }, [])

  return (
    <MainContents>
      <Loading visible={obscures === undefined} />
      {obscures &&
      <MaterialTable
        columns={[
          {
            title: '時刻',
            field: 'timestamp',
            editable: 'never',
            render: (d) => (<TimestampLabel value={d.timestamp} />),
            width: '10%',
          },
          {
            title: '期限',
            field: 'confirm_timeout',
            editable: 'never',
            render: (d) => (<TimestampLabel value={d.confirm_timeout} />),
            width: '10%',
          },
          {
            title: '店舗',
            field: 'store_code',
            render: (d) => (<>{getStore(d.store_code).store_name}</>),
            width: '25%',
          },
          {
            title: '人物',
            field: 'person_id',
            width: '15%',
            render: (d) => (<ConfirmPersonLabel personId={d.person_id} candidates={d.candidates} />),
          },
          {
            title: '商品',
            field: 'item',
            render: (d) => (<ConfirmItemLabel itemName={d.item_name} qty={d.qty} valueDiff={d.value_diff} />),
          },
        ]}
        data={obscures}
        onRowClick={(_, rowData) => {
          console.log(rowData)
          setSelected(rowData)
          setOpenConfim(true)
        }}
        options={SimpleMaterialTableOptions<Obscure>()}
        components={{
          ...MaterialTableComponents,
          Toolbar: () => (
            <Toolbar variant="dense" disableGutters>
              <ReloadButton marginLeft="auto" onClick={handleReload} />
            </Toolbar>
          )
        }}
        localization={{
          ...MaterialTableLocalization
        }}
      />
    }
    {selected &&
    <WalkthroughConfirmDialog
      open={openConfirm}
      obscure={selected}
      onClose={handleConfirmClose}
    />
    }
    </MainContents>
  )
}
