import { PersonModel } from 'components'
import { UnixTimestamp, UnixTimestampMs } from './types'
import { Flow, FlowBehavior } from './Flow'
import { ReplayData, ReplayDataPersonCordinate } from './Replay'
import * as d3 from 'd3'

const colors = d3.scaleOrdinal(d3.schemeCategory10).range()
const getScale = (behavior: FlowBehavior): number => {
  const duration = (behavior.toMs - behavior.fromMs) / 1000
  if (duration < 1) return 1
  return Math.min(duration / 3, 8)
}

export const flowToTrackingPlayerControlModel = (flow: Flow): TrackingPlayerControlModel => {
  const personId = flow.person_id
  const color = colors[personId % 10]
  const models = flow.behaviors.map((b, idx) => ({
    person_id: flow.person_id + idx + 1,
    color,
    x: b.x / 10,
    y: b.y / 10,
    rhx: 0,
    rhy: 0,
    lhx: 0,
    lhy: 0,
    scale: getScale(b),
    opacity: 0.4,
    text: `${Math.floor((b.toMs - b.fromMs) / 100) / 10}s`,
  }))
  const framesLen = flow.to - flow.from
  const trackings = flow.trackings
  const frames: TrackingPlayerControlFrame[] = Array.from(new Array(framesLen)).map(() => [])
  for (let i = 0, len = trackings.length; i < len; i++) {
    const [ms, x, y] = trackings[i]
    const sec = Math.floor((ms - flow.from * 1000) / 1000)
    frames[sec].push([{
      person_id: personId,
      color,
      x: x / 10,
      y: y / 10,
      rhx: 0,
      rhy: 0,
      lhx: 0,
      lhy: 0,
    }, ...models])
  }
  return {
    timestamp: flow.from,
    frames,
  }
}

export const toTrackingPlayerFrames = (data: ReplayData): TrackingPlayerControlFrame[] => {
  const framesLen = data.duration_min * 60
  const framesTracking = data.frames_tracking
  const frameRate = d3.max(framesTracking, ft => d3.max(ft.frames, f => f.length))
  const frames: TrackingPlayerControlFrame[] = Array.from(new Array(framesLen)).map(
    () => Array.from(new Array(frameRate)).map(() => [])
  )
  console.log(`frameRate: ${frameRate}`)
  for (let idx = 0; idx < framesLen; idx++) {
    for (let p = 0, len = framesTracking.length; p < len; p++) {
      const trackingFrame = framesTracking[p]
      const cordinates: ReplayDataPersonCordinate[] = trackingFrame.frames[idx]
      if (cordinates.length === 0) continue
      const color = colors[trackingFrame.person_id % 10]
      for (let c = 0, len = cordinates.length; c < len; c++) {
        frames[idx][c].push({
          person_id: trackingFrame.person_id,
          color,
          x: cordinates[c][0] / 10,
          y: cordinates[c][1] / 10,
          rhx: cordinates[c][2] / 10,
          rhy: cordinates[c][3] / 10,
          lhx: cordinates[c][5] / 10,
          lhy: cordinates[c][6] / 10,
        })
      }
    }
  }
  return frames
}

export interface TrackingPlayerControlModel {
  timestamp: UnixTimestamp
  frames: TrackingPlayerControlFrame[]
}

export type TrackingPlayerControlFrame = PersonModel[][]
type TrackingPlayerControlStatus = 'pause' | 'playing' | 'end' | 'tear_down'

export class TrackingPlayerControl {
  
  private timestampMs = 0
  private speedRate = 1
  private frames: TrackingPlayerControlFrame[] = []
  private status: TrackingPlayerControlStatus = 'pause'
  private seconds: number = 0

  constructor(
    private handle: (persons: PersonModel[], ms: UnixTimestampMs) => void,
    private onStopped: () => void,
    private onTimestampChanged: (ts: UnixTimestamp) => void = () => {},
  ) {
    console.log('##TrackingPlayerControl init')
  }

  preset(
    frames: TrackingPlayerControlFrame[],
    timestampMs: UnixTimestampMs,
    speedRate: number = 1,
  ) {
    if (speedRate < 0.5 || 30 < speedRate) throw new Error(`speedRate is wrong: ${speedRate}`)
    this.timestampMs = timestampMs
    this.speedRate = speedRate
    this.frames = frames
    console.log(`preset: timestampMs:${this.timestampMs}, speedRate: ${this.speedRate}, frames: ${frames.length}`)
    if (frames.length !== 0) {
      const frame = this.frames[this.seconds]
      this.handle(frame[0], this.timestampMs + this.seconds * 1000)
      this.fireTimestampEvent()
    }
    this.startMainLoop().then(() => {
      console.log('startMainLoop done.')
    }) // async not await
  }

  tearDown() {
    console.log(`tearDown: ${this.status} => tear_down`, this)
    this.status = 'tear_down'
  }

  private async startMainLoop() {
    while(this.status !== 'tear_down') {
      if (this.status === 'pause' || this.status === 'end' || this.frames.length === 0 || this.seconds === this.frames.length) {
        await sleep(1000)
        continue
      }
      const frameTimeMs = 1000 / this.speedRate // 1フレーム辺りの時間（ms）
      const frame = this.frames[this.seconds]
      const frameRate = frame.length
      const interval = frameTimeMs / frameRate
      for (let i = 0; i < frameRate; i++) {
        this.handle(frame[i], this.timestampMs + this.seconds * 1000 + i * interval)
        await sleep(interval)
      }
      if (this.seconds < this.frames.length) {
        this.fireTimestampEvent()
        this.seconds++
      }
      if (this.seconds === this.frames.length) {
        this.status = 'end'
        this.onStopped()
      }
    }
  }

  start(speedRate: number = 1) {
    this.setSpeedRate(speedRate)
    this.status = 'playing'
  }

  pause() {
    this.status = 'pause'
  }

  restart(speedRate: number = 1) {
    this.setSpeedRate(speedRate)
    this.seconds = 0
    this.status = 'playing'
  }

  setSeconds(seconds: number) {
    if (this.frames.length <= seconds) return
    this.seconds = seconds
    const frame = this.frames[this.seconds]
    this.handle(frame[0], this.timestampMs + this.seconds * 1000)
    this.fireTimestampEvent()
  }

  setSpeedRate(speedRate: number) {
    if (speedRate < 0.5 || 30 < speedRate) throw new Error(`speedRate is wrong: ${speedRate}`)
    this.speedRate = speedRate
  }

  private fireTimestampEvent() {
    this.onTimestampChanged(Math.floor(this.timestampMs / 1000) + this.seconds)    
  }

}

const sleep = async (msec: number) => new Promise((resolve, _) => {
  setTimeout(() => {
    resolve(undefined);
  }, msec);
})
