import {
  UnixTimestamp,
  UnixTimestampUs,
} from './types'

export interface Walkthrough {
  store_code: string
  walkthrough_id: string
  user_id: string
  timestamp: UnixTimestamp
  person_id: number
  friends: WalkthroughFriends
  exit_at?: UnixTimestamp
  last_exit_at?: UnixTimestamp
  exit_timeout?: UnixTimestamp
  items_aggregated: boolean
  items?: WalkthroughItem[]
  payment_requested_at?: UnixTimestamp
  timeout?: UnixTimestamp
  event_mode?: boolean
}

export interface WalkthroughFriends {
  [person_id: string]: {
    person_id: number;
    timestamp: UnixTimestamp;
    exit_at?: UnixTimestamp;
  }
}
export interface WalkthroughItem {
  item_code: string;
  item_name: string;
  qty: number;
  image_url: string;
}

export interface WalkthroughMovie {
  store_code: string
  walkthrough_id: string
  url: string
  cam_code: string
  type: string
  fps: number
}

export interface Action {
  action_id: string
  item_code: string
  item_name: string
  item_status: ActionItemStatus
  item_weight: number
  person_id: number
  person_status: ActionPersonStatus
  qty: number
  store_code: string
  timestamp: number
  transaction_id: string
  value_diff: number
  walkthrough_id?: string
  confirm_timeout: number
  detect_info?: ActionDetectInfo
  past_values?: PastValues
}

export type ActionPersonStatus = 'explicit' | 'obscure' | 'ignored' | 'confirmed' | 'organized' | 'staff' | 'no_persons'
export type ActionItemStatus = 'explicit' | 'obscure' | 'ignored' | 'confirmed'
export type ActionDetectMethod = 'undetected' | 'detect_by_area' | 'detect_by_dist' | 'detect_by_reaching' | 'detect_by_none_staff' | 'detect_by_all_staff' | 'detect_by_skeleton';

export interface ActionDetectInfo {
  detect_method: ActionDetectMethod
  candidates: number[]
}

export interface ActionMovie {
  store_code: string
  action_id: string
  url: string
  cam_code: string
  type: string
  fps: number
}

export interface Obscure {
  store_code: string
  timestamp: number
  segment_code: string
  confirm_timeout: number
  action_id?: string
  person_id?: number
  walkthrough_id?: string
  item_code?: string
  item_name?: string
  item_weight?: number
  item_image_url?: string
  qty?: number
  value_diff: number
  candidates?: ObscureCandidate[]
  value_details?: ObscureValueDetail[]
}

export interface ObscureCandidate {
  action_id: string
  person_id: number
  walkthrough_id: string
}

export interface ObscureValueDetail {
  person_ids: number[]
  value: number
}

export interface Candidate {
  person_id: number
  walkthrough_id: string
  item_code?: string
  item_name?: string
  item_image_url?: string
  item_weight?: number
  qty?: number
  aggregated?: boolean
}

export interface PastValues {
  us: UnixTimestampUs
  interval: UnixTimestampUs
  values: number[]
}

// deviocafe-akb_J_1_1598855555_117335380710
export const actionIdToSegment = (actionId: string) => {
  const tokens = actionId.split('_')
  return `${tokens[1]}_${tokens[2]}`
}