import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Tooltip,
} from '@mui/material';
import {
  Link as ControlledIcon,
  LinkOff as ControlledOffIcon,
} from '@mui/icons-material'
import {
  grey,
} from '@mui/material/colors'
import {
  Device,
} from 'models'
import {
  subscribe,
} from 'iot'
import {
  DeviceStatusMark,
} from 'components'

export interface DevicesTableModel {
  devices: DeviceModel[];
}

const isChecked = (devices: DeviceModel[]) => {
  return Boolean(devices.map(d => d.checked).indexOf(false) === -1)
}
const isIndeterminate = (devices: DeviceModel[]) => {
  const checked = devices.map(d => d.checked);
  return Boolean(checked.indexOf(false) !== -1 && checked.indexOf(true) !== -1)
}

interface DeviceModel extends Device {
  checked: boolean;
}

interface DevicesTableProps {
  model: DevicesTableModel,
  onChanged: () => void,
}

export const DevicesTable: React.FC<DevicesTableProps> = ({ model, onChanged }) => {
  const [devices, setDevices] = useState<DeviceModel[]>(model.devices)
  const [checked, setChecked] = useState<boolean>(isChecked(model.devices))
  const [indeterminate, setIndeterminate] = useState<boolean>(isIndeterminate(model.devices))
  // subscribe device shadow update
  useEffect(() => {
    const subscribers = model.devices.map(d => {
      const subscriber: any = subscribe<any>(`$aws/things/${d.thing_name}/shadow/name/status/update/documents`, (data) => {
        const status = data.value?.current?.state?.reported;
        d.power_status = status.power
        d.service_status = status.service
        d.service_error = status.service_error
        console.log(d)
        setDevices([...model.devices])
      })
      return subscriber
    })
    return () => {
      subscribers.map(s => s.disconnect())
    }
  }, [model])

  const handleAllCheckClicked = useCallback((_, checked: boolean) => {
    const newDevices = devices.map(d => ({
      ...d,
      checked,
    }))
    console.log(newDevices)
    model.devices = newDevices
    setDevices(newDevices)
    setChecked(isChecked(newDevices))
    setIndeterminate(isIndeterminate(newDevices))
    onChanged()
  }, [model, devices, onChanged])
  const handleChanged = useCallback(() => {
    const newDevices = [...devices]
    console.log(newDevices)
    model.devices = newDevices
    setDevices(newDevices)
    setChecked(isChecked(newDevices))
    setIndeterminate(isIndeterminate(newDevices))
    onChanged()
  }, [model, devices, onChanged])
  return (
    <TableContainer sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <Table size="small">
        <TableHead>
          <TableRow sx={{ backgroundColor: grey[50] }}>
            <TableCell padding="checkbox">
              <Checkbox
                size="small"
                checked={checked}
                indeterminate={indeterminate}
                onChange={handleAllCheckClicked}
              />
            </TableCell>
            <TableCell padding="checkbox" />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {devices.map(d => (
            <DevicesTableRow
              key={d.thing_name}
              model={d}
              onChanged={handleChanged}
            />
          ))}
        </TableBody>
      </Table>
      {devices.length === 0 && (
        <Box sx={{ margin: 1 }}>デバイスはありません</Box>
      )}
    </TableContainer>
  )
}

interface DevicesTableRowProps {
  model: DeviceModel,
  onChanged: () => void,
}

const DevicesTableRow: React.FC<DevicesTableRowProps> = ({
  model,
  onChanged,
}) => {
  const handleCheckChanged = useCallback((_, checked: boolean) => {
    model.checked = checked
    onChanged()
  }, [model, onChanged])
  return (
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          size="small"
          checked={model.checked}
          onChange={handleCheckChanged}
        />
      </TableCell>
      <TableCell padding="checkbox">
        <DeviceStatusMark
          powerStatus={model.power_status}
          serviceStatus={model.service_status}
          serviceError={model.service_error}
        />
      </TableCell>
      <TableCell padding="none">
        {model.thing_name}
      </TableCell>
      <TableCell>
        {model.power_status}
      </TableCell>
      <TableCell>
        {model.service_status}
      </TableCell>
      <TableCell>
        {model.service_error?.error_code}
      </TableCell>
      <TableCell padding="none">
        {model.controlled ? (
          <Tooltip title="制御有効">
            <ControlledIcon color="primary" />
          </Tooltip>
        ) : (
          <Tooltip title="制御無効">
            <ControlledOffIcon />
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        {model.ip_address || 'unknown'}
      </TableCell>
    </TableRow>
  )
}