import React, {
  useEffect,
  useCallback,
} from 'react'
import { useForm } from 'react-hook-form'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  FormLabel,
  FormControl,
  TextField,
} from '@mui/material'
import {
  WeightSensorNodeAddFormData,
  WeightSensorNodeFormValidations,
} from './forms_ws'
import { sx } from './sx'

interface AddWeightSensorNodeDialogProps {
  open: boolean
  otherIds: string[]
  onClose: () => void
  onAdded: (nodeId: string) => void
}

export const AddWeightSensorNodeDialog: React.FC<AddWeightSensorNodeDialogProps> = ({
  open,
  otherIds,
  onClose,
  onAdded,
}) => {
  const { register, handleSubmit, reset, formState, formState: { errors } } = useForm<WeightSensorNodeAddFormData>({
    mode: 'onBlur',
  })
  const { isDirty, isValid } = formState;

  // reset form
  useEffect(() => {
    reset({
      node_id: '',
    })
  }, [reset, open])

  // 更新
  const onSubmit = useCallback((data: WeightSensorNodeAddFormData) => {
    onAdded(data.node_id)
  }, [onAdded])
  const handleCanceled = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>ノード追加</DialogTitle>
        <DialogContent>
          <Grid container spacing={0} sx={sx.forms}>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>ノードID</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <FormControl sx={sx.formControl}>
                <TextField
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  margin="dense"
                  inputProps={{
                    maxLength: 2
                  }}
                  required
                  {...register(`node_id`, {
                    ...WeightSensorNodeFormValidations.node_id,
                    validate: (value: string) => {
                      if (otherIds.indexOf(value) != -1) return 'ノードIDが重複しています'
                      return true
                    },
                  })}
                  error={Boolean(errors?.node_id)}
                  helperText={errors?.node_id?.message}
                />
              </FormControl>
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            variant="contained"
            sx={sx.action}
            onClick={handleCanceled}
          >
            キャンセル
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            sx={sx.action}
            disabled={!isDirty || !isValid}
          >
            OK
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}