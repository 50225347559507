import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  useParams,
} from 'react-router-dom'
import {
  Toolbar,
  Tooltip,
  IconButton,
} from '@mui/material'
import {
  AddBox as Add,
} from '@mui/icons-material'
import MaterialTable from '@material-table/core'
import {
  useApp,
} from 'contexts'
import {
  EventHook,
  EventName,
  getEventNames,
  NullEventHook,
} from 'models'
import {
  ReloadButton,
  Loading,
  MaterialTableOptions,
  MaterialTableComponents,
  MaterialTableLocalization,
} from 'components'
import {
  EventHookAddDialog,
  EventHookEditDialog,
} from './hooks'

export const StoresHooks: React.FC = () => {
  const { storeCode } = useParams()
  if (storeCode === undefined) throw new Error('unhandled routing')
  const [reload, setReload] = useState(false)
  const [items, setItems] = useState<EventHook[] | null>(null)
  const [eventNames, setEventNames] = useState<EventName[]>([])
  const [addDialogOpen, setAddDialogOpen] = useState(false)
  const [selected, setSelected] = useState<EventHook>(NullEventHook)
  const { getApi, defaultApiErrorHandler } = useApp()

  const load = useCallback(() => {
    getApi().fetchEventHooks(storeCode)
      .then((result) => {
        setItems(result.items)
        const names = result.items.map(i => i.event_name)
        setEventNames(getEventNames().filter(name => names.indexOf(name) === -1))
      })
      .catch(defaultApiErrorHandler)
  }, [storeCode, getApi, defaultApiErrorHandler])

  useEffect(() => {
    load()
  }, [load])
  // reload
  useEffect(() => {
    if (!reload) return
    load()
    setReload(false)
  }, [load, reload])

  const handleReload = useCallback(() => {
    setItems(null)
    setReload(true)
  }, [])
  const handleAddDialogClosed = useCallback(() => {
    setAddDialogOpen(false)
  }, [])
  const handleAdded = useCallback(() => {
    setAddDialogOpen(false)
    setItems(null)
    setReload(true)
  }, [])
  const handleUpdated = useCallback(() => {
    setSelected(NullEventHook)
    setItems(null)
    setReload(true)
  }, [])
  const handleEditDialogClosed = useCallback(() => {
    setSelected(NullEventHook)
  }, [])

  return (
  <>
    <Toolbar variant="dense" disableGutters>
      <Tooltip title="イベントフックの追加">
        <IconButton
          aria-label="add"
          color="primary"
          onClick={() => setAddDialogOpen(true)}
          size="small"
          disabled={eventNames.length === 0}
          sx={{
            marginLeft: 'auto',
          }}
        >
          <Add />
        </IconButton>
      </Tooltip>
      <ReloadButton size="small" onClick={handleReload}/>
    </Toolbar>
    <Loading visible={items === null} />
    {items && (
    <MaterialTable
      data={items}
      columns={[
        {
          title: 'イベント名',
          field: 'event_name',
          sorting: false,
        },
        {
          title: 'タイプ',
          field: 'type',
          sorting: false,
        },
        {
          title: 'ARN',
          field: 'type',
          sorting: false,
          render: (d) => (<>{d.options.arn}</>),
        },
      ]}
      options={MaterialTableOptions<EventHook>({
        search: false,
        toolbar: false,
      })}
      components={{
        ...MaterialTableComponents,
      }}
      localization={{
        ...MaterialTableLocalization
      }}
      actions={[
        (d) => ({
          icon: 'settings',
          iconProps: {
            color: 'primary'
          },
          tooltip: '設定変更',
          onClick: () => {
            setSelected(d)
          },
        }),
      ]}
    />
    )}
    <EventHookAddDialog
      storeCode={storeCode}
      eventNames={eventNames}
      open={addDialogOpen}
      onClose={handleAddDialogClosed}
      onAdded={handleAdded}
    />
    <EventHookEditDialog
      open={selected.store_code !== NullEventHook.store_code}
      eventHook={selected}
      onClose={handleEditDialogClosed}
      onUpdated={handleUpdated}
    />
  </>
  )
}