import React from 'react'
import { Box, Typography, Toolbar } from '@mui/material';

interface MainContentsHeaderProps {
  title: string
  description?: React.ReactNode
}

export const MainContentsHeader: React.FC<MainContentsHeaderProps> = ({ title, description, children }) => (
  <Box sx={{ marginBottom: 3 }}>
    <Toolbar
      variant="dense"
      disableGutters
      sx={{ marginBottom: 1 }}
    >
      <Typography variant="h1">{title}</Typography>
      {children}
    </Toolbar>
    {description && (<Typography>{description}</Typography>)}
  </Box>
)

