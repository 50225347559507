import {
  useEffect,
} from 'react'
import {
  useQueryParam,
  StringParam,
  withDefault,
} from 'use-query-params'
import {
  useApp,
} from 'contexts'
import {
  Store,
  NullStore,
} from 'models'
import {
  loadStoreCode,
  saveStoreCode,
} from 'utils'

const getInitalStoreCode = (stores: Store[]): string => {
  if (stores.length === 0) return NullStore.store_code;
  return stores[0].store_code
}

export const useSelectedStoreCode = () => {
  const { getStores } = useApp()
  const [storeCode, setStoreCode] = useQueryParam(
    'store_code',
    withDefault(StringParam, loadStoreCode() || getInitalStoreCode(getStores()))
  )
  useEffect(() => {
    console.log('saved', storeCode)
    saveStoreCode(storeCode)
  }, [storeCode])
  return { storeCode, setStoreCode }
}

export default useSelectedStoreCode
