import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  createContext,
  useContext,
} from 'react'
import {
  useLocation,
} from 'react-router-dom'
import {
  Box,
  Alert,
} from '@mui/material';


export interface Notifications {
  addWarning: (message: Node | string) => void
}

const initalNotifications: Notifications = {
  addWarning: (_) => { },
}

// create the context
export const NotificationsContext = createContext<Notifications>(initalNotifications)
export const useNotifications = () => useContext(NotificationsContext)

// NotificationsProvider
export const NotificationsProvider: React.FC = ({ children }) => {
  const location = useLocation()
  const [notifications, setNotifications] = useState<Notifications>(initalNotifications)
  const [warnings, setWarnings] = useState<(Node | string)[]>([])
  const warningsRef = useRef<(Node | string)[]>([])

  useEffect(() => {
    setNotifications({
      addWarning: (message: Node | string) => {
        warningsRef.current.push(message)
        setWarnings([...warningsRef.current])
      },
    })
  }, [])
  // location changed
  useEffect(() => {
    warningsRef.current.length = 0
    setWarnings([])
  }, [location])

  const closeWarning = useCallback((idx: number) => {
    warningsRef.current.splice(idx, 1)
    setWarnings([...warningsRef.current])
  }, [warningsRef])

  return (
    <NotificationsContext.Provider value={notifications}>
      <Box>
        {warnings.map((warn, idx) => (
          <Alert
            key={`warn-${idx}`}
            severity="warning"
            onClose={() => { closeWarning(idx) }}
            sx={{
              marginLeft: 0.5,
              marginRight: 0.5,
              marginBottom: 0.5,
            }}
          >
            {warn}
          </Alert>
        ))}
      </Box>
      {children}
    </NotificationsContext.Provider>
  )
}