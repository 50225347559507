import React from 'react'
import {
  Routes,
  Route,
} from 'react-router-dom'
import { DeviceWeightSensor } from './DeviceWeightSensor'
import { DeviceGateKeeper } from './DeviceGateKeeper'
import { DeviceEdge } from './DeviceEdge'

export const DeviceRoutes: React.FC = () => (
  <Routes>
    <Route path="weightsensor" element={<DeviceWeightSensor />} />
    <Route path="gatekeeper" element={<DeviceGateKeeper />} />
    <Route path="edge" element={<DeviceEdge />} />
  </Routes>
)

