import React, {
} from 'react'
import {
  Group,
  Line,
} from 'react-konva'
import {
  Flow,
} from 'models'
import * as d3 from 'd3'

const colors = d3.scaleOrdinal(d3.schemeCategory10).range()

export interface FlowLineModel {
  personId: number
  color: string
  points: number[]
}

export const toFlowLineModel = (flow: Flow): FlowLineModel => {
  return {
    personId: flow.person_id,
    color: colors[flow.person_id % 10],
    points: flow.trackings.map(trk => ([trk[1] / 10, trk[2] / 10])).flat(),
  }
}

type FlowLineProps = {
  model: FlowLineModel
}

export const FlowLine: React.FC<FlowLineProps> = ({ model }) => (
  <Group>
    <Line
      points={model.points}
      stroke={model.color}
      tension={0.3}
      closed={false}
      opacity={0.9}
    />
  </Group>
)
