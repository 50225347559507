import {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  useApp,
} from 'contexts'
import {
  Item,
  Segment,
  ShelvesLayout,
  ShelfLayout,
  SegmentLayout,
  ShelfType,
} from 'models'

const toSegmentLayouts = (segments: Segment[], items: Item[]): SegmentLayout[] => (
  segments.map(seg => ({
    segmentCode: seg.segment_code,
    item: items.find(i => i.item_code === seg.item_code),
  }))
)

const findSegment = (segments: SegmentLayout[], segmentCode: string): SegmentLayout => {
  const seg = segments.find(seg => seg.segmentCode === segmentCode)
  if (seg === undefined) throw new Error()
  return seg
}

export const useShelvesLayout = (
  storeCode: string
) => {
  const { getApi } = useApp()
  const [reload, setReload] = useState(false)
  const [layout, setLayout] = useState<ShelvesLayout|null>(null)
  const [totalSegmentsCount, setTotalSegmentsCount] = useState(0)

  const load = useCallback(() => {
    Promise.all([
      getApi().fetchShelves(storeCode),
      getApi().fetchSegments(storeCode),
      getApi().fetchItems(storeCode),
      getApi().fetchConfiguration(storeCode)
    ]).then(([shelvesResult, segmentsResult, itemsResult, configResult]) => {
      const segments = toSegmentLayouts(segmentsResult.items, itemsResult.items)
      setLayout({
        storeCode: storeCode,
        shelves: shelvesResult.items.map(slf => ({
          shelfCode: slf.shelf_code,
          weightThreshold: slf.weight_threshold || configResult.weight_threshold,
          weightFluctuation: Boolean(slf.weight_fluctuation),
          disabled: Boolean(slf.disabled),
          segments: slf.segment_codes.map(row => row.map(sc => findSegment(segments, sc))),
        }))
      })
      setTotalSegmentsCount(shelvesResult.items.reduce((acc, cur) => acc + cur.segment_codes.flat().length, 0))
    })
    setReload(false)
  }, [storeCode, getApi])
  useEffect(() => {
    load()
  }, [load])
  useEffect(() => {
    if (!reload) return
    load()
  }, [reload, load])
  const getShelfLayout = useCallback((shelfCode: string) => {
    return layout?.shelves.find(s => s.shelfCode === shelfCode)
  }, [layout])
  return { layout, totalSegmentsCount, setReload, getShelfLayout }
}

export const useShelfLayout = (
  shelfCode: string
) => {
  const { getApi } = useApp()
  const [reload, setReload] = useState(false)
  const [layout, setLayout] = useState<ShelfLayout|null>(null)
  const [shelfType, setShelfType] = useState<ShelfType>('standard')
  const [disabled, setDisabled] = useState(false)
  const [weightThreshold, setWeightThreshold] = useState<number>()
  const [weightFluctuation, setWeightFluctuation] = useState(false)
  const [disableAutoModifyWeightThreshold, setDisableAutoModifyWeightThreshold] = useState(false)

  const load = useCallback(() => {
    const storeCode = shelfCode.split('_')[0]
    Promise.all([
      getApi().fetchShelf(shelfCode),
      getApi().fetchSegments(storeCode),
      getApi().fetchItems(storeCode),
      getApi().fetchConfiguration(storeCode),
    ]).then(([shelf, segmentsResult, itemsResult, configResult]) => {
      const segments = toSegmentLayouts(segmentsResult.items, itemsResult.items)
      setLayout({
          shelfCode: shelf.shelf_code,
          weightThreshold: shelf.weight_threshold || configResult.weight_threshold,
          weightFluctuation: Boolean(shelf.weight_fluctuation),
          segments: shelf.segment_codes.map(row => row.map(sc => findSegment(segments, sc)))
      })
      setShelfType(shelf.type || 'standard')
      setWeightThreshold(shelf.weight_threshold)
      setWeightFluctuation(Boolean(shelf.weight_fluctuation))
      setDisableAutoModifyWeightThreshold(Boolean(shelf.disable_auto_modify_weight_threshold))
      setDisabled(Boolean(shelf.disabled))
      setReload(false)
    })
  }, [shelfCode, getApi])
  useEffect(() => {
    load()
  }, [load])
  useEffect(() => {
    if (!reload) return
    load()
  }, [reload, load])

  return {
    layout,
    shelfType,
    disabled,    
    weightThreshold,
    weightFluctuation,
    disableAutoModifyWeightThreshold,
    setReload,
  }
}