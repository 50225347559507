import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  useParams,
} from 'react-router-dom'
import {
  Grid,
  Box,
  Typography,
  FormLabel,
  TextField,
  Input,
  InputAdornment,
  Button,
  Select,
  MenuItem,
  Switch,
  Tooltip,
  Theme,
  SxProps,
} from '@mui/material';
import {
  useForm,
} from 'react-hook-form'
import {
  useApp,
} from 'contexts'
import {
  Help,
  ProgressScreen,
  SnackbarAlert,
  AlertDialog,
  ConfirmDialog,
  WarnigMessage,
} from 'components'
import {
  StoreMode,
  isEnvActive,
} from 'models'
import {
  StoreConfigFormData,
  StoreConfigFormValidations,
  toFormData,
  toConfiguration,
} from './config/forms'

const env = process.env.REACT_APP_ENV
const sxCategory: SxProps<Theme> = {
  marginTop: 2,
  paddingBottom: 0.5,
  marginBottom: 1,
  borderBottom: '1px solid lightgray',
};
const sxLabelItem: SxProps<Theme> = {
  marginTop: 2,
  paddingRight: 2,
  textAlign: 'right',
  width: '100%',
  fontWeight: 'bold',
};
const sxLabelSelect: SxProps<Theme> = {
  marginTop: 1,
  paddingRight: 2,
  textAlign: 'right',
  width: '100%',
  fontWeight: 'bold',
};
const sxLabel: SxProps<Theme> = {
  color: '#333333',
};
const sxField: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
};
const sxReadOnlyInput: SxProps<Theme> = {
  marginTop: 1,
  paddingLeft: 1,
};

export const StoresConfg: React.FC = () => {
  const { storeCode } = useParams()
  if (storeCode === undefined) throw new Error('unhandled routing')
  const [loading, setLoading] = useState(true)
  const [notifyOpen, setNotifyOpen] = useState(false)
  const [storeMode, setStoreMode] = useState<StoreMode>('normal')
  const [active, setActive] = useState(false)
  const [activeConfirmDialogOpen, setActiveConfirmDialogOpen] = useState(false)
  const [envUndefined, setEnvUndefined] = useState(true)
  const [merchantCode, setMerchantCode] = useState<string>('')
  const [disableEventHook, setDisableEventHook] = useState<boolean>(true)
  const [disableSensor, setDisableSensor] = useState<boolean>(true)
  const [isMock, setIsMock] = useState<boolean>(false)
  const [mockConfirmDialogOpen, setMockConfirmDialogOpen] = useState(false)
  
  const { getApi, apiErrorHandler, defaultApiErrorHandler, reloadStores } = useApp()
  const { register, handleSubmit, reset, formState, formState: { errors } } = useForm<StoreConfigFormData>({
    mode: 'onBlur',
  })
  const { isDirty, isValid } = formState;
  const [changed, setChanged] = useState(false)
  const [alertMessage, setAlertMessage] = useState<string>()
  useEffect(() => {
    Promise.all([
      getApi().fetchStore(storeCode),
      getApi().fetchConfiguration(storeCode),
      getApi().fetchEnv(storeCode),
    ])
      .then(([store, configuration, env]) => {
        setMerchantCode(store.merchant_code || '')
        setStoreMode(configuration.store_mode)
        setDisableEventHook(configuration.disable_event_hook)
        setDisableSensor(Boolean(configuration.disable_sensor))
        setIsMock(Boolean(store.is_mock))
        setActive(isEnvActive(env))
        setEnvUndefined(env.env === 'unknown')
        reset(toFormData(store, configuration))
        setLoading(false)
      })
      .catch(defaultApiErrorHandler)
  }, [storeCode, getApi, defaultApiErrorHandler, reset])

  const onSubmit = useCallback((data: StoreConfigFormData) => {
    setLoading(true)
    Promise.all([
      getApi().updateStoreName(storeCode, data.store_name),
      getApi().updateConfiguration(toConfiguration(storeCode, storeMode, disableEventHook, disableSensor, data)),
    ])
      .then(([store, configuration]) => {
        setStoreMode(configuration.store_mode)
        reset(toFormData(store, configuration))
        reloadStores().then(() => {
          setLoading(false)
          setNotifyOpen(true)
          setChanged(false)  
        })
      })
      .catch(apiErrorHandler({
        'CannotChangeStoreMode': () => {
          setLoading(false)
          setAlertMessage('未処理のウォークスルーがあるため、メンテナンスモードに変更できません。')
        },
      }))
  }, [storeCode, storeMode, disableEventHook, disableSensor, getApi, reset, apiErrorHandler, reloadStores])
  const handleStoreModeChanged = useCallback((evt: any) => {
    setStoreMode(evt.target.value)
    setChanged(true)
  }, [])
  const handleNotifyClosed = useCallback(() => {
    setNotifyOpen(false)
  }, [])
  const onCloseAlertDialog = useCallback(() => {
    setAlertMessage(undefined)
  }, [])
  const handleActiveSwitchChanged = useCallback(() => {
    setActiveConfirmDialogOpen(true)
  }, [])
  const handleCloseActiveConfirmDialogOpen = useCallback((isOk: boolean) => {
    if (isOk === false) {
      setActiveConfirmDialogOpen(false)
      return
    }
    setActiveConfirmDialogOpen(false)
    setLoading(true)
    if (active) {
      getApi().deactiveEnv(storeCode)
        .then((env) => {
          setActive(isEnvActive(env))
          setLoading(false)
        })
        .catch(apiErrorHandler({
          'UpdateInProgress': () => {
            setLoading(false)
            setAlertMessage('設定の反映中です。しばらくお待ちください。')
          },
        }))
    } else {
      getApi().activeEnv(storeCode)
        .then((env) => {
          setActive(isEnvActive(env))
          setLoading(false)
        })
        .catch(apiErrorHandler({
          'UpdateInProgress': () => {
            setLoading(false)
            setAlertMessage('設定の反映中です。しばらくお待ちください。')
          },
        }))
    }
  }, [storeCode, getApi, apiErrorHandler, active])
  const handleMockSwitchChanged = useCallback(() => {
    setMockConfirmDialogOpen(true)
  }, [])
  const handleCloseMockConfirmDialogOpen = useCallback((isOk: boolean) => {
    if (isOk === false) {
      setMockConfirmDialogOpen(false)
      return
    }
    setMockConfirmDialogOpen(false)
    setLoading(true)
    getApi().updateStoreMock(storeCode, !isMock)
      .then((store) => {
        setIsMock(Boolean(store.is_mock))
        reloadStores().then(() => {
          setLoading(false)
          setNotifyOpen(true)
          setChanged(false)  
        })
      })
      .catch(apiErrorHandler)
  }, [storeCode, isMock])
  return (
    <>
      <ProgressScreen colorVariant="light" open={loading}/>
      <WarnigMessage
        title="センサー連携がセットアップされていません"
        message="IoT環境にスタックを作成してください"
        hidden={loading || !envUndefined || disableSensor}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={sxCategory}>基本設定</Typography>
          </Grid>
          <Grid item xs={4} sx={sxLabelItem}>
            <FormLabel sx={sxLabel}>店舗コード</FormLabel>
          </Grid>
          <Grid item xs={6}>
            <Input
              fullWidth
              value={storeCode}
              readOnly
              style={{
                backgroundColor: 'whitesmoke',
              }}
              sx={sxReadOnlyInput}
            />
          </Grid>
          <Grid item xs={2}></Grid>

          <Grid item xs={4} sx={sxLabelItem}>
            <FormLabel required sx={sxLabel}>店舗名</FormLabel>
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="string"
              size="small"
              margin="dense"
              fullWidth
              hiddenLabel
              variant="outlined"
              required
              {...register(`store_name`, StoreConfigFormValidations.store_name)}
              error={Boolean(errors?.store_name)}
              helperText={errors?.store_name?.message}
            />
          </Grid>
          <Grid item xs={2}></Grid>
          
          <Grid item xs={4} sx={sxLabelSelect}>
            <FormLabel required sx={sxLabel}>モード</FormLabel>
          </Grid>
          <Grid item xs={6}>
            <Select
              size="small"
              margin="dense"
              value={storeMode}
              onChange={handleStoreModeChanged}
            >
              <MenuItem value="normal">通常</MenuItem>
              <MenuItem value="maintenance">メンテナンス</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={2}></Grid>

          <Grid item xs={4} sx={sxLabelItem}>
            <FormLabel sx={sxLabel}>マーチャントコード</FormLabel>
          </Grid>
          <Grid item xs={6}>
            <Input
              fullWidth
              value={merchantCode}
              readOnly
              style={{
                backgroundColor: 'whitesmoke',
              }}
              sx={sxReadOnlyInput}
            />
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={sxCategory}>システム</Typography>
          </Grid>
          <Grid item xs={4} sx={sxLabelSelect}>
            <FormLabel sx={sxLabel}>ToF/重量センサー連携</FormLabel>
          </Grid>
          <Grid item xs={6}>
            <Tooltip title={active ? '有効' : '無効'}>
              <Switch
                checked={active}
                disabled={loading || envUndefined || disableSensor}
                onChange={handleActiveSwitchChanged}
                color="primary"
              />
            </Tooltip>
            {disableSensor && (<Typography>※センサー連携は無効です</Typography>)}
            <ConfirmDialog
              open={activeConfirmDialogOpen}
              handleClose={handleCloseActiveConfirmDialogOpen}
              title="システム変更"
              contentText={active ? 'センサー連携を無効化します。' : 'この環境にセンサー連携を行います。'}
            />
          </Grid>
          <Grid item xs={2}></Grid>
          {(env === 'ITG' || env === 'local') && (
          <>
            <Grid item xs={4} sx={sxLabelSelect}>
              <FormLabel sx={sxLabel}>モック設定</FormLabel>
            </Grid>
            <Grid item xs={6}>
              <Tooltip title={isMock ? '有効' : '無効'}>
                <Switch
                  checked={isMock}
                  disabled={loading}
                  onChange={handleMockSwitchChanged}
                  color="primary"
                />
              </Tooltip>
              <ConfirmDialog
                open={mockConfirmDialogOpen}
                handleClose={handleCloseMockConfirmDialogOpen}
                title="モック設定"
                contentText={isMock ? 'モック設定を解除します。' : 'モック設定を行います。'}
              />
            </Grid>
            <Grid item xs={2}></Grid>
          </>
          )}
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={sxCategory}>ウォークスルーパラメータ</Typography>
          </Grid>
          <Grid item xs={4} sx={sxLabelItem}>
            <FormLabel required sx={sxLabel}>取得判定待機時間</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="number"
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">秒</InputAdornment>
                  ),
                }}
                {...register(`action_wait_sec`, StoreConfigFormValidations.action_wait_sec)}
                error={Boolean(errors?.action_wait_sec)}
                helperText={errors?.action_wait_sec?.message}
                disabled={disableSensor}
              />
              <Help>
                <Typography>重量変化イベント発生時、待機時間後に取得判定を開始します。</Typography>
              </Help>
            </Box>
          </Grid>
          <Grid item xs={4} sx={sxLabelItem}>
            <FormLabel required sx={sxLabel}>確定タイムアウト</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="number"
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">分</InputAdornment>
                  ),
                }}
                {...register(`action_timeout_min`, StoreConfigFormValidations.action_timeout_min)}
                error={Boolean(errors?.action_timeout_min)}
                helperText={errors?.action_timeout_min?.message}
                disabled={disableSensor}
              />
              <Help>
                <Typography>購入判定が「未確定」になった場合、タイムアウト時間後、確定処理はタイムアウトします。</Typography>
              </Help>
            </Box>
          </Grid>
          <Grid item xs={4} sx={sxLabelItem}>
            <FormLabel required sx={sxLabel}>チェックアウト待機時間</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="number"
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">秒</InputAdornment>
                  ),
                }}
                {...register(`checkout_wait_sec`, StoreConfigFormValidations.checkout_wait_sec)}
                error={Boolean(errors?.checkout_wait_sec)}
                helperText={errors?.checkout_wait_sec?.message}
                disabled={disableSensor}
              />
              <Help>
                <Typography>退店後、待機時間後にチェックアウト処理を開始します。</Typography>
              </Help>
            </Box>
          </Grid>

          <Grid item xs={4} sx={sxLabelItem}>
            <FormLabel required sx={sxLabel}>最低重量変化量</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="number"
                size="small"
                inputProps={{ step: '0.1' }}
                hiddenLabel
                variant="outlined"
                margin="dense"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">g</InputAdornment>
                  ),
                }}
                {...register(`weight_threshold`, StoreConfigFormValidations.weight_threshold)}
                error={Boolean(errors?.weight_threshold)}
                helperText={errors?.weight_threshold?.message}
                disabled={disableSensor}
              />
              <Help>
                <Typography>最低重量変化量を下回る重量変化は購入判定の対象外となります。</Typography>
              </Help>
            </Box>
          </Grid>

          <Grid item xs={4} sx={sxLabelItem}>
            <FormLabel required sx={sxLabel}>距離判定しきい値</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="number"
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">cm</InputAdornment>
                  ),
                }}
                {...register(`dist_threshold`, StoreConfigFormValidations.dist_threshold)}
                error={Boolean(errors?.dist_threshold)}
                helperText={errors?.dist_threshold?.message}
                disabled={disableSensor}
              />
              <Help>
                <Typography>複数名が判定エリアにいる場合、閾値より離れた人物は除外されます。</Typography>
              </Help>
            </Box>
          </Grid>

          <Grid item xs={4} sx={sxLabelItem}>
            <FormLabel required sx={sxLabel}>ハンドトラッキング範囲</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="number"
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">cm</InputAdornment>
                  ),
                }}
                {...register(`hand_tracking_range`, StoreConfigFormValidations.hand_tracking_range)}
                error={Boolean(errors?.hand_tracking_range)}
                helperText={errors?.hand_tracking_range?.message}
                disabled={disableSensor}
              />
              <Help>
                <Typography>ハンドトラッキングによる判定の際、有効となる手の位置とセグメント座標の距離を指定します。</Typography>
              </Help>
            </Box>
          </Grid>

          <Grid item xs={4} sx={sxLabelItem}>
            <FormLabel required sx={sxLabel}>ハンドトラッキングの手距離閾値</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="number"
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">cm</InputAdornment>
                  ),
                }}
                {...register(`hand_dist_threshold`, StoreConfigFormValidations.hand_dist_threshold)}
                error={Boolean(errors?.hand_dist_threshold)}
                helperText={errors?.hand_dist_threshold?.message}
                disabled={disableSensor}
              />
              <Help>
                <Typography>ハンドトラッキングによる判定時、最も近い手の閾値距離以内に別の人の手があった場合、その手も候補として残します。</Typography>
              </Help>
            </Box>
          </Grid>

          <Grid item xs={4} sx={sxLabelItem}>
            <FormLabel required sx={sxLabel}>ハンドトラッキング判定インターバル</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="number"
                size="small"
                inputProps={{ step: '10' }}
                hiddenLabel
                variant="outlined"
                margin="dense"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">ミリ秒</InputAdornment>
                  ),
                }}
                {...register(`hand_tracking_window_interval_ms`, StoreConfigFormValidations.hand_tracking_window_interval_ms)}
                error={Boolean(errors?.hand_tracking_window_interval_ms)}
                helperText={errors?.hand_tracking_window_interval_ms?.message}
                disabled={disableSensor}
              />
              <Help>
                <Typography>ハンドトラッキングによる判定時、インターバルを基準にタイムウィンドを拡張します。</Typography>
              </Help>
            </Box>
          </Grid>

          <Grid item xs={4} sx={sxLabelItem}>
            <FormLabel required sx={sxLabel}>ハンドトラッキング/重量変化の時間差分</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="number"
                size="small"
                inputProps={{ step: '10' }}
                hiddenLabel
                variant="outlined"
                margin="dense"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">ミリ秒</InputAdornment>
                  ),
                }}
                {...register(`hand_tracking_action_diff_ms`, StoreConfigFormValidations.hand_tracking_action_diff_ms)}
                error={Boolean(errors?.hand_tracking_action_diff_ms)}
                helperText={errors?.hand_tracking_action_diff_ms?.message}
                disabled={disableSensor}
              />
              <Help>
                <Typography>ハンドトラッキングによる判定時、重量変化時刻よりも差分時間ずらして判定します。</Typography>
              </Help>
            </Box>
          </Grid>

          <Grid item xs={4} sx={sxLabelItem}>
            <FormLabel required sx={sxLabel}>入店タイムアウト</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="number"
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">秒</InputAdornment>
                  ),
                }}
                {...register(`checkin_timeout_sec`, StoreConfigFormValidations.checkin_timeout_sec)}
                error={Boolean(errors?.checkin_timeout_sec)}
                helperText={errors?.checkin_timeout_sec?.message}
                disabled={disableSensor}
              />
              <Help>
                <Typography>認証時、タイムアウト時間までに入店エリアを通過しなければ、タイムアウトになります。</Typography>
              </Help>
            </Box>
          </Grid>
          <Grid item xs={4} sx={sxLabelItem}>
            <FormLabel required sx={sxLabel}>退店タイムアウト</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="number"
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">分</InputAdornment>
                  ),
                }}
                {...register(`exit_timeout_min`, StoreConfigFormValidations.exit_timeout_min)}
                error={Boolean(errors?.exit_timeout_min)}
                helperText={errors?.exit_timeout_min?.message}
                disabled={disableSensor}
              />
              <Help>
                <Typography>入店後、退店せずにタイムアウト時間が経過すると、強制的に退店となります。</Typography>
              </Help>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={sxCategory}>動線分析パラメータ</Typography>
          </Grid>

          <Grid item xs={4} sx={sxLabelItem}>
            <FormLabel required sx={sxLabel}>移動判定速度しきい値</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="number"
                size="small"
                inputProps={{ step: '0.1' }}
                hiddenLabel
                variant="outlined"
                margin="dense"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">m/秒</InputAdornment>
                  ),
                }}
                {...register(`flow_velocity_move_threshold`, StoreConfigFormValidations.flow_velocity_move_threshold)}
                error={Boolean(errors?.flow_velocity_move_threshold)}
                helperText={errors?.flow_velocity_move_threshold?.message}
                disabled={disableSensor}
              />
              <Help>
                <Typography>動線分析での移動判定速度しきい値を設定します。</Typography>
              </Help>
            </Box>
          </Grid>

          <Grid item xs={4} sx={sxLabelItem}>
            <FormLabel required sx={sxLabel}>停止判定しきい値</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="number"
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">ミリ秒</InputAdornment>
                  ),
                }}
                {...register(`flow_stay_ms_threshold`, StoreConfigFormValidations.flow_stay_ms_threshold)}
                error={Boolean(errors?.flow_stay_ms_threshold)}
                helperText={errors?.flow_stay_ms_threshold?.message}
                disabled={disableSensor}
              />
              <Help>
                <Typography>動線分析での静止判定時間しきい値を設定します。</Typography>
              </Help>
            </Box>
          </Grid>

          <Grid item xs={4} sx={sxLabelItem}>
            <FormLabel required sx={sxLabel}>棚までの距離しきい値</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="number"
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">mm</InputAdornment>
                  ),
                }}
                {...register(`flow_dist_threshold`, StoreConfigFormValidations.flow_dist_threshold)}
                error={Boolean(errors?.flow_dist_threshold)}
                helperText={errors?.flow_dist_threshold?.message}
                disabled={disableSensor}
              />
              <Help>
                <Typography>動線分析での棚隣接判定有効距離を設定します。</Typography>
              </Help>
            </Box>
          </Grid>

          <Grid item xs={4} sx={sxLabelItem}>
            <FormLabel required sx={sxLabel}>手伸ばし判定距離しきい値</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="number"
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">mm</InputAdornment>
                  ),
                }}
                {...register(`flow_hand_tracking_range`, StoreConfigFormValidations.flow_hand_tracking_range)}
                error={Boolean(errors?.flow_hand_tracking_range)}
                helperText={errors?.flow_hand_tracking_range?.message}
                disabled={disableSensor}
              />
              <Help>
                <Typography>動線分析での手伸ばし判定有効距離を設定します。</Typography>
              </Help>
            </Box>
          </Grid>

          <Grid item xs={4} sx={sxLabelItem}>
            <FormLabel required sx={sxLabel}>最大追跡データ時間</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="number"
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">分</InputAdornment>
                  ),
                }}
                {...register(`tracking_max_min`, StoreConfigFormValidations.tracking_max_min)}
                error={Boolean(errors?.tracking_max_min)}
                helperText={errors?.tracking_max_min?.message}
                disabled={disableSensor}
              />
              <Help>
                <Typography>動線分析に保持する追跡情報の最大保持時間を設定します。</Typography>
              </Help>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={sxCategory}>その他</Typography>
          </Grid>
          <Grid item xs={4} sx={sxLabelItem}>
            <FormLabel required sx={sxLabel}>位置情報保存期間</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="number"
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">秒</InputAdornment>
                  ),
                }}
                {...register(`tracking_range_sec`, StoreConfigFormValidations.tracking_range_sec)}
                error={Boolean(errors?.tracking_range_sec)}
                helperText={errors?.tracking_range_sec?.message}
                disabled={disableSensor}
              />
              <Help>
                <Typography>アクションに保持する人物の位置情報の保存秒数です。</Typography>
              </Help>
            </Box>
          </Grid>

          <Grid item xs={4} sx={sxLabelItem}>
            <FormLabel required sx={sxLabel}>店内画像タイムアウト</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="number"
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">分</InputAdornment>
                  ),
                }}
                {...register(`snapshot_timeout_min`, StoreConfigFormValidations.snapshot_timeout_min)}
                error={Boolean(errors?.snapshot_timeout_min)}
                helperText={errors?.snapshot_timeout_min?.message}
                disabled={disableSensor}
              />
              <Help>
                <Typography>店内カメラ利用時、画像がアップロードされる時間のタイムアウト値です。</Typography>
              </Help>
            </Box>            
          </Grid>

          <Grid item xs={4} sx={sxLabelItem}>
            <FormLabel sx={sxLabel}>通知先Slack URL</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="string"
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                fullWidth
                InputProps={{}}
                {...register(`notify_slack_url`, StoreConfigFormValidations.notify_slack_url)}
                error={Boolean(errors?.notify_slack_url)}
                helperText={errors?.notify_slack_url?.message}
              />
              <Help>
                <Typography>入店や退店などのイベントをSlackに通知します。</Typography>
              </Help>
            </Box>
          </Grid>

          <Grid item xs={4} sx={sxLabelItem}>
            <FormLabel sx={sxLabel}>通知先SNSトピックARN</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="string"
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                fullWidth
                InputProps={{}}
                {...register(`notify_sns_topic`, StoreConfigFormValidations.notify_sns_topic)}
                error={Boolean(errors?.notify_slack_url)}
                helperText={errors?.notify_sns_topic?.message}
              />
              <Help>
                <Typography>入店や退店などのイベントをSNSトピックに送信します。</Typography>
              </Help>
            </Box>            
          </Grid>

        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={12} sx={{
            margin: 3,
            textAlign: 'center',
          }}>
             <Button 
               type="submit"
               color="primary"
               variant="contained"
               disabled={!changed && (!isDirty || !isValid)} 
             >
              保存
             </Button>
          </Grid>
        </Grid>
      </form>
      <SnackbarAlert
        open={notifyOpen}
        onClose={handleNotifyClosed}
      >
        店舗設定を更新しました。
      </SnackbarAlert>
      <AlertDialog
        open={Boolean(alertMessage !== undefined)}
        onClose={onCloseAlertDialog}
        title="店舗設定の更新エラー"
        message={alertMessage}
      />
    </>
  )
}
