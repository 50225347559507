import React from 'react'
import {
  ArrowDownward as Down,
  ArrowUpward as Up,
} from '@mui/icons-material'

type WeightLabelProps = {
  value: number | undefined
  markable?: boolean
}

export const WeightLabel: React.FC<WeightLabelProps> = ({ value, markable }) => {
  if (value === undefined) return (<span>-</span>)
  return (
    <span>
      {markable ? (<>{Math.round(Math.abs(value) * 10) / 10}g</>) : (<>{Math.round(value * 10) / 10}g</>)}
      {markable && value < 0 && (<Down fontSize="small" style={{ verticalAlign: 'middle' }}/>)}
      {markable && 0 < value && (<Up fontSize="small" style={{ verticalAlign: 'middle' }}/>)}
    </span>
  )
}

