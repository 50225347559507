import React from 'react'
import {
  Link,
} from 'react-router-dom'
import {
  Box,
  Container,
  Typography,
  Alert,
  AlertTitle,
} from '@mui/material';
import logo from 'logo_s.png'

export const SystemError: React.FC = () => {
  return (
    <Container sx={{ padding: 4 }}>
      <Link to="/">
        <Box component="img" src={logo} sx={{ margin: 4 }} alt="パスレジ"/>
      </Link>
      <Alert severity="warning" variant="outlined">
        <AlertTitle>System Error</AlertTitle>
        <Typography>システムエラーが発生しました。管理者に連絡ください。</Typography>
      </Alert>
    </Container>
  )
}
