import React, {
} from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'


interface AlertDialogProps {
  open: boolean
  onClose: () => void
  title: string
  message?: string
}

export const AlertDialog: React.FC<AlertDialogProps> = ({ open, onClose, title, message }) =>  {

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>閉じる</Button>
      </DialogActions>
    </Dialog>
  )

}