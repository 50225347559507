import React, {
} from 'react'
import {
  Group,
  Circle,
  Line,
  Text,
} from 'react-konva'
import {
  formatPersonId,
} from 'utils'
import * as d3 from 'd3'

export interface PersonModel {
  person_id: number
  color: string
  x: number
  y: number
  rhx: number
  rhy: number
  lhx: number
  lhy: number
  scale?: number
  opacity?: number
  text?: string
}

const colors = d3.scaleOrdinal(d3.schemeCategory10).range()

export const toPersonModel = (person: any): PersonModel => {
  const model: PersonModel = {
    person_id: person.person_id,
    color: colors[person.person_id % 10],
    x: person.hd[0] / 10,
    y: person.hd[1] / 10,
    rhx: person.rh[0] / 10,
    rhy: person.rh[1] / 10,
    lhx: person.lh[0] / 10,
    lhy: person.lh[1] / 10,
  }
  return model
}

type PersonProps = {
  model: PersonModel
}

export const Person: React.FC<PersonProps> = ({ model }) => {
  return (
    <Group>
      <Circle
        x={model.x}
        y={model.y}
        radius={7 * (model.scale || 1)}
        fill={model.color}
        opacity={model.opacity || 1}
      />
      {/* PersonId or Text */}
      <Text
        text={model.text || formatPersonId(model.person_id)}
        x={model.x + 5}
        y={model.y}
        padding={2}
        fill={model.color}
      />
      {0 < model.rhx && 0 < model.rhy &&
        <Group>
          <Line
            points={[model.x, model.y, model.rhx, model.rhy]}
            strokeWidth={2}
            stroke={model.color}
          />
          <Circle
            x={model.rhx}
            y={model.rhy}
            radius={3}
            fill={model.color}
          />
        </Group>
      }
      {0 < model.lhx && 0 < model.lhy &&
        <Group>
          <Line
            points={[model.x, model.y, model.lhx, model.lhy]}
            strokeWidth={2}
            stroke={model.color}
          />
          <Circle
            x={model.lhx}
            y={model.lhy}
            radius={3}
            fill={model.color}
          />
        </Group>
      }
    </Group>
  )
}