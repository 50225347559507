import React, {
} from 'react'
import {
  Grid,
  Typography,
  Link,
} from '@mui/material'
import {
  CloudDownload as Download,
} from '@mui/icons-material'
import {
  QueryExecution,
} from 'models'
import {
  DataSizeLabel,
} from 'components'

interface QueryExecutionPanelProps {
  data: QueryExecution
  caption: string
}

export const QueryExecutionPanel: React.FC<QueryExecutionPanelProps> = ({
  data,
  caption,
}) => {
  return (
  <Grid container title={caption} style={{ marginBottom: 5 }}>
    <Grid item xs={12} style={{ margin: 3 }}>
      <Typography variant="h6">{caption}</Typography>
    </Grid>
    <Grid item xs={2}>
      <Typography variant="h6" style={{ marginLeft: 10 }}>クエリ</Typography>
    </Grid>
    <Grid item xs={10}>
      <Typography variant="body2">{data.query}</Typography>
    </Grid>
    <Grid item xs={2}>
      <Typography variant="h6" style={{ marginLeft: 10 }}>ステータス</Typography>
    </Grid>
    <Grid item xs={10}>
      <Typography variant="body2">{data.state}</Typography>
    </Grid>
    <Grid item xs={2}>
      <Typography variant="h6" style={{ marginLeft: 10 }}>スキャンデータ</Typography>
    </Grid>
    <Grid item xs={10}>
      <Typography variant="body2">
        <DataSizeLabel value={data.data_scanned_in_bytes}/>
      </Typography>
    </Grid>
    <Grid item xs={2}>
      <Typography variant="h6" style={{ marginLeft: 10 }}>データ</Typography>
    </Grid>
    <Grid item xs={10}>
      <Typography variant="body2">
        {data.output_link ? (
        <Link underline="hover" color="primary" href={data.output_link}>{data.output_location}<Download fontSize="inherit"/>(<DataSizeLabel value={data.data_output_in_bytes}/>)</Link>
        ): (
        <>{data.output_location}</>
        )}
      </Typography>
    </Grid>
  </Grid>
  )
}