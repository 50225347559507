
export const sx = {
  forms: {
    margin: 0,
  },
  category: {
    marginTop: 2,
    marginBottom: 1,
    paddingBottom: 0.5,
    borderBottom: '1px solid lightgray',
  },
  subCategory: {
    marginTop: 1,
    marginLeft: 2,
    marginRight: 2,
    marginBottom: 0.5,
    paddingBottom: 0.2,
    borderBottom: '1px solid lightgray',
  },
  labelItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  label: {
    paddingRight: 2,
    color: '#333333',
  },
  formControl: {
  },
  action: {
    marginLeft: 2,
  },
  camerasForm: {
    paddingLeft: 3,
    paddingRight: 3,
  },
  nodeList: {
    paddingLeft: 3,
    paddingRight: 3,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: '2px',
  },
}