import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  useParams,
  useNavigate,
} from 'react-router-dom'
import {
  Grid,
  Typography,
  Input,
  List,
  ListItem,
  IconButton,
  Toolbar,
  SxProps,
  Theme,
} from '@mui/material';
import {
  Edit,
} from '@mui/icons-material'
import QRCode from 'qrcode.react'
import {
  useApp,
} from 'contexts'
import {
  Credential,
} from 'models'
import {
  MainContents,
  BackButton,
  Loading,
  DurationLabel,
} from 'components'
import { rot13 } from 'utils'
import {
  PermissionsEditDialog,
  ScopesEditDialog,
} from './components'

const sxLabel: SxProps<Theme> = {
  fontWeight: 'bold',
  paddingTop: 1,
  marginLeft: 1,
}
const sxField: SxProps<Theme> = {
  marginLeft: 2,
  marginRight: 2,
  marginBottom: 1,
}

export const CredentialsCredential: React.FC = () => {
  const { clientId } = useParams()
  if (clientId === undefined) throw new Error('unhandled routing')
  const navigate = useNavigate()
  const { getApi, defaultApiErrorHandler } = useApp();
  const [credential, setCredential] = useState<Credential>()
  const [openPermissionsEditDialog, setOpenPermissionsEditDialog] = useState(false)
  const [openScopesEditDialog, setOpenScopesEditDialog] = useState(false)

  useEffect(() => {
    getApi()
      .fetchCredential(clientId)
      .then((result) => {
        result.scopes = result.scopes.sort()
        setCredential(result)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, clientId])

  const handleBack = useCallback(() => {
    navigate(`/credentials/`)
  }, [navigate])
  const clickPermissionsEditDialog = useCallback(() => {
    setOpenPermissionsEditDialog(true)
  }, [])
  const clickScopesEditDialog = useCallback(() => {
    setOpenScopesEditDialog(true)
  }, [])
  const handleDialogClosed = useCallback((credential: Credential | undefined) => {
    if (credential) setCredential(credential)
    setOpenPermissionsEditDialog(false)
    setOpenScopesEditDialog(false)
  }, [])

  return (
    <MainContents>
      <Toolbar variant="dense" disableGutters>
        <BackButton onClick={handleBack} />
        <Typography variant="h5" sx={{ marginLeft: 1 }} >{credential?.client_name}</Typography>
      </Toolbar>
      <Loading visible={credential === undefined} />
      {credential && (
        <>
          <Grid container spacing={0}>
            <Grid item xs={8}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography sx={sxLabel}>クライアントID</Typography>
                </Grid>
                <Grid item xs={12} sx={sxField}>
                  <Input
                    value={credential.client_id}
                    fullWidth
                    readOnly
                    style={{
                      backgroundColor: 'whitesmoke',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={sxLabel}>シークレット</Typography>
                </Grid>
                <Grid item xs={12} sx={sxField}>
                  <Input
                    value={credential.client_secret}
                    fullWidth
                    readOnly
                    style={{
                      backgroundColor: 'whitesmoke',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={sxLabel}>アクセストークンの有効期限</Typography>
                </Grid>
                <Grid item xs={12} sx={sxField}>
                  <DurationLabel value={credential.access_token_validity_sec} />
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={sxLabel}>ID トークンの有効期限</Typography>
                </Grid>
                <Grid item xs={12} sx={sxField}>
                  <DurationLabel value={credential.id_token_validity_sec} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} sx={{ marginTop: 2, padding: 2 }}>
              <QRCode value={rot13(`${credential.client_id}:${credential.client_secret}`)} />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography sx={sxLabel}>
                パーミッション
                <IconButton
                  color="primary"
                  size="small"
                  sx={{ marginLeft: 1 }}
                  disabled={(credential.permissions.indexOf('*') !== -1)}
                  onClick={clickPermissionsEditDialog}>
                  <Edit fontSize="small" />
                </IconButton>
              </Typography>
              <PermissionsEditDialog credential={credential} open={openPermissionsEditDialog} onClose={handleDialogClosed} />
            </Grid>
            <Grid item xs={12} sx={sxField}>
              <List dense>
                {credential.permissions.map(permission => (
                  <ListItem key={permission}>{permission}</ListItem>
                ))}
              </List>
              {credential.permissions.length === 0 && (
                <Typography>パーミッションはありません。</Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography sx={sxLabel}>
                スコープ
                <IconButton
                  color="primary"
                  size="small"
                  sx={{ marginLeft: 1 }}
                  onClick={clickScopesEditDialog}>
                  <Edit fontSize="small" />
                </IconButton>
              </Typography>
              <ScopesEditDialog credential={credential} open={openScopesEditDialog} onClose={handleDialogClosed} />
            </Grid>
            <Grid item xs={12} sx={sxField}>
              <List dense>
                {credential.scopes.map(scope => (
                  <ListItem key={scope}>{scope}</ListItem>
                ))}
              </List>
              {credential.scopes.length === 0 && (
                <Typography>スコープはありません。</Typography>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </MainContents>
  )
}