import React, {
} from 'react'
import {
  Typography,
} from '@mui/material'
import {
  green,
  red,
  grey,
} from '@mui/material/colors'
import {
  CheckCircleOutline as Online,
  HighlightOff as ConnectionLost,
  NotInterested as Inactive,
} from '@mui/icons-material'

type PingStatusProps = {
  pingStatus: string | undefined
}

export const PingStatus: React.FC<PingStatusProps> = ({ pingStatus }) => {
  if (pingStatus === 'Online') {
    return (
      <Typography variant="body2" style={{ color: green[800] }}>
        <Online fontSize="small" style={{ verticalAlign: 'middle' }}/>
        <span style={{ verticalAlign: 'middle', marginRight: 3 }}>オンライン</span>
      </Typography>
    )
  } else if (pingStatus === 'ConnectionLost') {
    return (
      <Typography variant="body2" style={{ color: red[800] }}>
        <ConnectionLost fontSize="small" style={{ verticalAlign: 'middle' }}/>
        <span style={{ verticalAlign: 'middle', marginRight: 3 }}>接続が失われました</span>
      </Typography>
    )
  } else if (pingStatus === 'Inactive') {
    return (
      <Typography variant="body2" style={{ color: grey[800] }}>
        <Inactive fontSize="small" style={{ verticalAlign: 'middle' }}/>
        <span style={{ verticalAlign: 'middle', marginRight: 3 }}>無効</span>
      </Typography>
    )
  } else {
    return (<></>)
  }
}
