import React, {
  useEffect,
  useState,
  useCallback,
} from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
} from '@mui/material';
import {
  useApp,
} from 'contexts'
import {
  ProgressScreen,
} from 'components'
import {
  Credential,
  Scope,
} from 'models'

type ScopesEditDialogProps = {
  open: boolean
  onClose: (updated: Credential | undefined) => void
  credential: Credential
}

export const ScopesEditDialog: React.FC<ScopesEditDialogProps> = ({ credential, open, onClose }) => {
  const { getApi, defaultApiErrorHandler } = useApp()
  const [scopes, setScopes] = useState<Scope[]>([])
  const [checked, setChecked] = useState<string[]>(credential.scopes)
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    getApi().fetchScopes()
      .then((result) => {
        setScopes(result.items.sort((s1, s2) => s1.name.localeCompare(s2.name)))
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler])

  const handleToggle = useCallback((scope: string) => {
    const idx = checked.indexOf(scope)
    const newChecked = [...checked]
    if (idx === -1) {
      newChecked.push(scope)
    } else {
      newChecked.splice(idx, 1)
    }
    setChecked(newChecked)
  }, [checked])

  const handleCancel = useCallback(() => {
    onClose(undefined)
  }, [onClose])
  const handleApply = useCallback(() => {
    setIsUpdating(true)
    getApi()
      .updateCredential(credential.client_id, undefined, checked)
      .then(result => {
        setIsUpdating(false)
        onClose(result)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, onClose, credential, checked])

  return (
    <Dialog
      maxWidth="xl"
      open={open}
      onClose={handleCancel}
      disableEscapeKeyDown
    >
      <DialogTitle>スコープの設定</DialogTitle>
      <DialogContent sx={{ margin: 0 }}>
        <List>
          {scopes.map(scope => (
            <ListItem key={scope.name} button onClick={() => handleToggle(scope.name)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(scope.name) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText id={scope.name} primary={scope.name} secondary={scope.desc} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={handleCancel}>キャンセル</Button>
        <Button color="primary" variant="contained" onClick={handleApply} >適用</Button>
      </DialogActions>
      <ProgressScreen open={isUpdating} />
    </Dialog>
  )
}