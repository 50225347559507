import React from 'react'
import {
  Tooltip,
} from '@mui/material'
import {
  red,
} from '@mui/material/colors'

type RateLabelProps = {
  count: number
  total: number
  errorRate?: boolean
  errorThreshold?: number
}

export const RateLabel: React.FC<RateLabelProps> = ({ count, total, errorRate, errorThreshold = 50 }) => {
  const rate = total !== 0 ? Math.round(1000 * count / total) / 10 : undefined
  return (
  <Tooltip title={total !== 0 ? `${count} / ${total}` : 'none'}>
    <span style={errorRate && rate !== undefined && errorThreshold <= rate ? { color: red[800] } : undefined}>{rate === undefined ? '-' : `${rate}%`}</span>
  </Tooltip>
  )
}

