import React from 'react'
import {
  SvgIcon,
  SvgIconProps,
} from '@mui/material'

export const StoreEvent: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M17.8,3.5l1-1L17.6,1.3,15.5,3.4H3A2.5,2.5,0,0,0,.4,5.9v8.4a2.6,2.6,0,0,0,1.8,2.4l-1,1,1.2,1.2,2.1-2.1H17a2.5,2.5,0,0,0,2.6-2.5V5.9A2.6,2.6,0,0,0,17.8,3.5ZM1.6,5.9A1.3,1.3,0,0,1,3,4.6H14.3L11.8,7.1H1.6ZM3,15.6a1.3,1.3,0,0,1-1.4-1.3V8.9H10L6.7,12.2v-.9H4.3v2.4h.9L3.3,15.6Zm15.4-1.3A1.3,1.3,0,0,1,17,15.6H5.7l6.7-6.7h6Zm0-7.2H14.2l2.5-2.5H17a1.3,1.3,0,0,1,1.4,1.3Z"/>
    <rect x="10.1" y="11.3" width="6" height="0.9"/>
    <rect x="10.1" y="13.1" width="4.8" height="0.9"/>
  </SvgIcon>
)


