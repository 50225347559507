import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Button,
} from '@mui/material';
import {
  useApp,
} from 'contexts'
import {
  ProgressScreen,
  WarnigMessage,
} from 'components'
import {
  DeviceType,
} from 'models'

type DeviceRegisterDialogProps = {
  type: DeviceType,
  open: boolean
  onClose: () => void
  onRegistered: () => void
}

export const DeviceRegisterDialog: React.FC<DeviceRegisterDialogProps> = ({ type, open, onClose, onRegistered }) => {
  const { getApi, getIot, defaultApiErrorHandler } = useApp()
  const [thingNames, setThingNames] = useState<string[]>([]);
  const [selectedThingNames, setSelectedThingNames] = useState<string[]>([]);
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    if (open === false) return
    setSelectedThingNames([])
    getIot().listThings({
      attributeName: 'status',
      attributeValue: 'unregistered',
    })
      .then((result) => {
        const things = result.things?.filter(t => t.thingTypeName === type) || []
        setThingNames(things.map(t => t.thingName).filter((n): n is string => n !== undefined))
      })
      .catch(defaultApiErrorHandler)
  }, [getIot, defaultApiErrorHandler, open, type])

  const handleChecked = useCallback((thingName: string) => {
    const currentIndex = selectedThingNames.indexOf(thingName);
    const newSelectedThingNames = [...selectedThingNames]
    if (currentIndex === -1) {
      newSelectedThingNames.push(thingName)
    } else {
      newSelectedThingNames.splice(currentIndex, 1)
    }
    setSelectedThingNames(newSelectedThingNames)
    console.log(newSelectedThingNames)
  }, [selectedThingNames])

  // 登録
  const handleRegister = useCallback(() => {
    setIsUpdating(true)
    Promise.all(
      selectedThingNames.map(thingName => getApi().registerDevice(thingName))
    ).then(() => {
      onRegistered()
    })
      .catch(defaultApiErrorHandler)
      .finally(() => {
        setIsUpdating(false)
        setThingNames([])
        setSelectedThingNames([])
      })
  }, [getApi, defaultApiErrorHandler, onRegistered, selectedThingNames])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
    >
      <DialogTitle>デバイスを登録</DialogTitle>
      <DialogContent>
        <Typography>追加するデバイス（モノ）を選択し、「登録」をクリックしてください。</Typography>
        <WarnigMessage
          hidden={0 < thingNames.length}
          title="登録可能なデバイスはありません"
        />
        <List sx={{ marginTop: 0.5, marginLeft: 1 }}>
          {thingNames.map(thingName => (
            <ListItem key={thingName} dense disableGutters>
              <ListItemIcon>
                <Checkbox
                  checked={selectedThingNames.indexOf(thingName) !== -1}
                  color="primary"
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                  onClick={() => handleChecked(thingName)}
                />
              </ListItemIcon>
              <ListItemText primary={thingName} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>キャンセル</Button>
        <Button color="primary" variant="contained" onClick={handleRegister} disabled={selectedThingNames.length === 0} >登録</Button>
      </DialogActions>
      <ProgressScreen open={isUpdating} />
    </Dialog>
  )
}
