import React, {
  useState,
  useEffect,
  useRef,
} from 'react'
import Konva from 'konva'
import {
  Group,
  Line,
  Text,
  Transformer,
} from 'react-konva'
import {
  AreaModel,
} from './Area'

export interface DiagonalAreaModel {
  name: string
  x1: number
  y1: number
  x2: number
  y2: number
  variant: 'shelf' | 'shelf_low'
  color: string
  heights?: number[]
}

export const diagonal_shelf_depth = 20

export const isAreaModel = (model: AreaModel | DiagonalAreaModel): model is AreaModel => {
  return (model as any).width !== undefined
}

export const isDiagonalAreaModel = (model: AreaModel | DiagonalAreaModel): model is DiagonalAreaModel => {
  return (model as any).x1 !== undefined
}

type DiagonalAreaProps = {
  model: DiagonalAreaModel
  offsetX: number
  offsetY: number
  isSelected: boolean
  onSelect: () => void
}

const toPoints = (model: DiagonalAreaModel): number[] => {
  const width = Math.sqrt(Math.pow(model.x1 - model.x2, 2) + Math.pow(model.y1 - model.y2, 2))
  console.log(width)
  const dx = Math.abs(Math.floor(diagonal_shelf_depth * (model.y2 - model.y1) / width))
  const dy = Math.abs(Math.floor(diagonal_shelf_depth * (model.x2 - model.x1) / width))
  if (model.x1 < model.x2 && model.y1 < model.y2) {
    const x1d = model.x1 + dx
    const y1d = model.y1 - dy
    const x2d = model.x2 + dx
    const y2d = model.y2 - dy
    console.log(x1d, y1d, x2d, y2d)
    return [model.x1, model.y1, x1d, y1d, x2d, y2d, model.x2, model.y2]  
  } else if (model.x1 < model.x2 && model.y2 < model.y1) {
    const x1d = model.x1 - dx
    const y1d = model.y1 - dy
    const x2d = model.x2 - dx
    const y2d = model.y2 - dy
    console.log(x1d, y1d, x2d, y2d)
    return [model.x1, model.y1, x1d, y1d, x2d, y2d, model.x2, model.y2]  
  } else if (model.x2 < model.x1 && model.y1 < model.y2) {
    const x1d = model.x1 + dx
    const y1d = model.y1 + dy
    const x2d = model.x2 + dx
    const y2d = model.y2 + dy
    console.log(x1d, y1d, x2d, y2d)
    return [model.x1, model.y1, x1d, y1d, x2d, y2d, model.x2, model.y2]  
  } else {
    const x1d = model.x1 - dx
    const y1d = model.y1 + dy
    const x2d = model.x2 - dx
    const y2d = model.y2 + dy
    console.log(x1d, y1d, x2d, y2d)
    return [model.x1, model.y1, x1d, y1d, x2d, y2d, model.x2, model.y2]  
  }
}

export const DiagonalArea: React.FC<DiagonalAreaProps> = ({
  model,
  offsetX,
  offsetY,
  isSelected,
  onSelect,
}) => {
  const grpRef = useRef<Konva.Group>(null)
  const trRef = useRef<Konva.Transformer>(null)
  const [points, setPoints] = useState<number[]>([0, 0, 0, 0])
  const [textX, setTextX] = useState<number>(0)
  const [textY, setTextY] = useState<number>(0)

  useEffect(() => {
    const points = toPoints(model)
    console.log(points)
    setPoints(points)
    setTextX(Math.max(points[0], points[2], points[4], points[6]))
    setTextY(Math.max(points[1], points[3], points[5], points[7]))
  }, [model])
  useEffect(() => {
    if (!isSelected) return
    if (grpRef.current) {
      trRef.current?.nodes([grpRef.current])
      trRef.current?.getLayer()?.batchDraw()
    }
  }, [isSelected])
  
  return (
  <Group x={-offsetX} y={-offsetY}>
    <Group
      ref={grpRef}
      onClick={onSelect}
    >
      <Line
        points={points}
        fill={model.color}
        opacity={0.6}
        closed={true}
      />
      <Line
        points={points}
        stroke={model.color}
        strokeWidth={3}
      />
    </Group>
    <Group
      x={textX}
      y={textY}
    >
      <Text
        text={model.name}
        fill={model.color}
      />
      {/* (x, y) */}
      <Text
        text={`(${model.x1}, ${model.y1})`}
        y={12}
        fill={model.color}
        visible={isSelected}
      />
      <Text
        text={`(${model.x2}, ${model.y2})`}
        y={24}
        fill={model.color}
        visible={isSelected}
      />
    </Group>
    {isSelected &&
    <Transformer
      ref={trRef}
      resizeEnabled={false}
      rotateEnabled={false}
    />
    }
  </Group>
  )
}