import { PubSub } from 'aws-amplify'
import { ZenObservable } from 'zen-observable-ts'
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers'
import { DevicePowerStatus, DeviceServiceStatus, DeviceServiceError } from 'models'

const ENV_ERROR = (name: string): string => { throw new Error(`${name} is not defined.`) }
const REGION: string = process.env.REACT_APP_AWS_REGION || ENV_ERROR('REACT_APP_AWS_REGION')
const IOT_PUBSUB_ENDPOINT: string = process.env.REACT_APP_IOT_PUBSUB_ENDPOINT || ENV_ERROR('REACT_APP_IOT_PUBSUB_ENDPOINT')

export interface DeviceStatusValue {
  value: {
    current: {
      state: {
        reported: {
          power: DevicePowerStatus,
          service: DeviceServiceStatus,
          service_error: DeviceServiceError,
        },  
      },
    },
  },
}

// https://stackoverflow.com/questions/44734124/access-the-value-of-symbolid-property-on-an-object
export const findTopicName = (value: DeviceStatusValue) => {
  const topicSymbol = Object.getOwnPropertySymbols(value.value).find(s => s.toString() === 'Symbol(topic)');
  if (!topicSymbol) throw new Error('Symbol(topic) is not found.');
  return (value.value as any)[topicSymbol] as string;
}

export const subscribe = <T> (topic: string, next: ((value: T) => void | ZenObservable.Observer<T>)) => {
  let subscribe: (ZenObservable.Subscription | undefined) = undefined
  const newSubscribe = () => {
    subscribe = PubSub.subscribe(topic).subscribe(
      next,
      (err) => {
        console.log(err)
        reconnectPubSub()
        newSubscribe()
      }
    )  
  }
  newSubscribe()
  return {
    disconnect: () => {
      console.log('disconnected')
      subscribe?.unsubscribe()
    }
  }
}

const reconnectPubSub = () => {
  // @ts-ignore
  // HACK... stupid amazon
  (PubSub as any)._pluggables[0] = new AWSIoTProvider({
    aws_pubsub_region: REGION,
    aws_pubsub_endpoint: IOT_PUBSUB_ENDPOINT,
  });
}
