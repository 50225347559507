import React, {
} from 'react'
import {
  red,
  blue,
} from '@mui/material/colors'
import { DateTime } from 'luxon'

interface DateLabelProps {
  date: string
  format?: string
}

const getStyle = (dt: DateTime) => {
  switch(dt.get('weekday')) {
    case 7: // Sun
      return { color: red[800] }
    case 6: // Sat
      return { color: blue[600] }
    default:
      return undefined
  }
}

export const DateLabel: React.FC<DateLabelProps> = ({
  date,
  format = 'yyyyMMdd',
}) => {
  const dt = DateTime.fromFormat(date, format).setLocale('ja')
  return (<span style={getStyle(dt)}>{dt.toFormat('d(ccc)')}</span>)
}
