import React from 'react'
import {
  Routes,
  Route,
} from 'react-router-dom'
import { Logs } from './Logs'
import { BootLogs } from './BootLogs'
import { LogsStreams } from './LogsStreams'

export const LogsRoutes: React.FC = () => (
<Routes>
  <Route index element={<Logs/>}/>
  <Route path="streams" element={<LogsStreams/>}/>
  <Route path="boot" element={<BootLogs/>}/>
</Routes>
)
