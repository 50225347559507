import { Item } from './Item'

export interface ShelvesLayout {
  storeCode: string
  shelves: ShelfLayout[]
}

export interface ShelfLayout {
  shelfCode: string
  segments: SegmentLayout[][]
  weightThreshold?: number
  weightFluctuation?: boolean
  disabled?: boolean
}

export interface SegmentLayout {
  segmentCode: string
  item?: Item
  marked?: boolean
}

export const getMinItemWeight = (shelf: ShelfLayout): number | undefined => {
  const weights = shelf.segments.map(r => r.map(s => s.item)).flat().filter((i): i is Item => i !== undefined).map(i => i.weight)
  return (0 < weights.length) ? Math.min(...weights) : undefined  
}
