import React, {
  useState,
  useEffect,
} from 'react'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import {
  grey,
} from '@mui/material/colors'
import {
  EdgeStatus,
} from 'models'
import {
  subscribe,
} from 'iot'
import {
  EdgeModuleStatusMark,
} from 'components'

export interface EdgeTableModel {
  thing_name?: string;
  action_status?: EdgeStatus
  passage_status?: EdgeStatus
  tracking_status?: EdgeStatus
  weight_status?: EdgeStatus
  logs_status?: EdgeStatus
}

interface EdgeTableProps {
  model: EdgeTableModel,
}

export const EdgeTable: React.FC<EdgeTableProps> = ({ model }) => {
  const [actionStatus, setActionStatus] = useState<EdgeStatus>()
  const [passageStatus, setPassageStatus] = useState<EdgeStatus>()
  const [trackingStatus, setTrackingStatus] = useState<EdgeStatus>()
  const [weightStatus, setWeightStatus] = useState<EdgeStatus>()
  const [logsStatus, setLogsStatus] = useState<EdgeStatus>()

  // subscribe device shadow update
  useEffect(() => {
    setActionStatus(model.action_status)
    setPassageStatus(model.passage_status)
    setTrackingStatus(model.tracking_status)
    setWeightStatus(model.weight_status)
    setLogsStatus(model.logs_status)
    if (model.thing_name === undefined) return;
    const subscriber: any = subscribe<any>(`$aws/things/${model.thing_name}/shadow/name/status/update/documents`, (data) => {
      const status = data.value?.current?.state?.reported || {}
      setActionStatus(status['edge_action'])
      setPassageStatus(status['edge_passage'])
      setTrackingStatus(status['edge_tracking'])
      setWeightStatus(status['edge_weight'])
      setLogsStatus(status['edge_logs'])
    })
    return () => {
      if (subscriber) subscriber.disconnect()
    }
  }, [model])

  return (
    <TableContainer sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <Table size="small">
        <TableHead>
          <TableRow sx={{ backgroundColor: grey[50] }}>
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell padding="checkbox">
              <EdgeModuleStatusMark status={actionStatus} />
            </TableCell>
            <TableCell padding="none">
              action
            </TableCell>
            <TableCell>
              {actionStatus || 'unknown'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell padding="checkbox">
              <EdgeModuleStatusMark status={passageStatus} />
            </TableCell>
            <TableCell padding="none">
              passage
            </TableCell>
            <TableCell>
              {passageStatus || 'unknown'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell padding="checkbox">
              <EdgeModuleStatusMark status={logsStatus} />
            </TableCell>
            <TableCell padding="none">
              logs
            </TableCell>
            <TableCell>
              {logsStatus || 'unknown'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell padding="checkbox">
              <EdgeModuleStatusMark status={weightStatus} />
            </TableCell>
            <TableCell padding="none">
              weight
            </TableCell>
            <TableCell>
              {weightStatus || 'unknown'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell padding="checkbox">
              <EdgeModuleStatusMark status={trackingStatus} />
            </TableCell>
            <TableCell padding="none">
              tracking
            </TableCell>
            <TableCell>
              {trackingStatus || 'unknown'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}