import React, {
  useState,
  useCallback,
} from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
} from '@mui/material';
import {
  useApp,
} from 'contexts'
import {
  ProgressScreen,
} from 'components'
import {
  Credential,
} from 'models'

type PermissionsEditDialogProps = {
  open: boolean
  onClose: (updated: Credential | undefined) => void
  credential: Credential
}

export const PermissionsEditDialog: React.FC<PermissionsEditDialogProps> = ({ credential, open, onClose }) => {
  const { getApi, defaultApiErrorHandler, getStores } = useApp()
  const [checked, setChecked] = useState<string[]>(credential.permissions)
  const [isUpdating, setIsUpdating] = useState(false)

  const handleToggle = useCallback((storeCode: string) => {
    const idx = checked.indexOf(storeCode)
    const newChecked = [...checked]
    if (idx === -1) {
      newChecked.push(storeCode)
    } else {
      newChecked.splice(idx, 1)
    }
    setChecked(newChecked)
  }, [checked])

  const handleCancel = useCallback(() => {
    onClose(undefined)
  }, [onClose])
  const handleApply = useCallback(() => {
    setIsUpdating(true)
    getApi()
      .updateCredential(credential.client_id, checked, undefined)
      .then(result => {
        setIsUpdating(false)
        onClose(result)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, onClose, credential, checked])

  return (
    <Dialog
      maxWidth="xl"
      open={open}
      onClose={handleCancel}
      disableEscapeKeyDown
    >
      <DialogTitle>パーミッションの設定</DialogTitle>
      <DialogContent sx={{ margin: 0 }}>
        <List>
          {getStores().map(store => (
            <ListItem key={store.store_code} button onClick={() => handleToggle(store.store_code)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(store.store_code) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText id={store.store_code} primary={store.store_code} secondary={store.store_name} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={handleCancel}>キャンセル</Button>
        <Button color="primary" variant="contained" onClick={handleApply} >適用</Button>
      </DialogActions>
      <ProgressScreen open={isUpdating} />
    </Dialog>
  )
}