import React, {
  useState,
  useCallback,
} from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import {
  useApp,
} from 'contexts'
import {
  ShelfLayout,
} from 'models'
import {
  ProgressScreen,
} from 'components'
import { getSegmentCodesFromLayout } from './forms'
import { ShelfEditor } from './ShelfEditor'

type EditDialogProps = {
  layout: ShelfLayout
  open: boolean
  onClose: () => void
  onUpdate: () => void
}

export const EditDialog: React.FC<EditDialogProps> = ({ layout, open, onClose, onUpdate }) => {
  const { getApi } = useApp()
  const [updated, setUpdated] = useState<ShelfLayout>()
  const [isUpdating, setIsUpdating] = useState(false)

  // 保存
  const onSubmit = useCallback(() => {
    if (!updated) return
    setIsUpdating(true)
    console.log(updated)
    const segmentCodes = getSegmentCodesFromLayout(updated.shelfCode, updated)
    getApi()
      .updateShelfSegments(updated.shelfCode, segmentCodes)
      .then(() => {
        setIsUpdating(false)
        onUpdate()
      })
  }, [getApi, updated, onUpdate])

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
    >
      <DialogTitle>棚を編集</DialogTitle>
      <DialogContent sx={{ display: 'flex', width: 'fit-content', margin: 0.5 }}>
        <ShelfEditor
          layout={layout}
          onUpdate={setUpdated}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>キャンセル</Button>
        <Button color="primary" variant="contained" onClick={onSubmit} disabled={!Boolean(updated)} >更新</Button>
      </DialogActions>
      <ProgressScreen open={isUpdating} />
    </Dialog>
  )
}