import React from 'react'
import {
  Box,
  IconButton,
  Tooltip,
} from '@mui/material'
import { FilterList } from '@mui/icons-material'

interface FilterButtonProps {
  hidden?: boolean
  marginLeft?: any
  marginRight?: any
  size?: 'medium' | 'small'
  title?: string
  onClick?: () => void
  disabled?: boolean
}

export const FilterButton: React.FC<FilterButtonProps> = ({
  hidden,
  marginLeft,
  marginRight,
  size = 'medium',
  title = 'フィルター',
  onClick,
  disabled,
}) => {
  return (
    <Box hidden={hidden} marginLeft={marginLeft} marginRight={marginRight}>
      <Tooltip title={title}>
        <IconButton size={size} onClick={onClick} disabled={disabled}>
          <FilterList/>
        </IconButton>
      </Tooltip>
    </Box>
  )
}
