import React, {
  useEffect,
  useState,
  useCallback,
} from 'react'
import { useForm } from 'react-hook-form'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Box,
} from '@mui/material';
import {
  useApp,
} from 'contexts'
import {
  ProgressScreen,
} from 'components'
import { StoreAddFormData, StoreAddFormValidations } from './forms'

type AddDialogProps = {
  open: boolean
  onClose: (needToReload: boolean) => void
}

export const AddDialog: React.FC<AddDialogProps> = ({ open, onClose }) => {
  const [merchantEnable, setMerchantEnable] = useState(true)
  const [sensorEnable, setSensorEnable] = useState(true)
  const [isUpdating, setIsUpdating] = useState(false)
  const { getApi, reloadStores } = useApp()
  const { register, trigger, setValue, handleSubmit, formState, formState: { errors } } = useForm<StoreAddFormData>({
    mode: 'onBlur',
  })

  useEffect(() => {
    trigger('merchant_code')
  }, [merchantEnable, trigger])

  const handleMerchantEnableChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setMerchantEnable(event.target.checked)
    if (!event.target.checked) setValue('merchant_code', '')
  }, [setValue])
  const handleSensorEnableChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSensorEnable(event.target.checked)
  }, [])
  // 保存
  const onSubmit = useCallback((data: StoreAddFormData) => {
    console.log(data)
    setIsUpdating(true)
    // Blank to undefined
    const merchantCode = data.merchant_code || undefined
    getApi()
      .setupStore(data.store_code, data.store_name, merchantCode, !sensorEnable)
      .then(_ => {
        reloadStores().then(() => {
          onClose(true)
          setIsUpdating(false)  
        })
      })
  }, [getApi, onClose, reloadStores, sensorEnable])

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>店舗登録</DialogTitle>
        <DialogContent>
          <Box sx={(theme) => ({
            minWidth: 600,
            margin: theme.spacing(0),
          })}>
            <Grid container>
              <Grid item sm={12}>
                <FormControlLabel
                  control={(
                  <Switch
                    checked={merchantEnable}
                    onChange={handleMerchantEnableChanged}
                    color="primary"
                  />
                  )}
                  label="マーチャント連係を有効にする"
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  label="マーチャントコード"
                  size="small"
                  margin="dense"
                  inputProps={{
                    minLength: 4,
                    maxLength: 17,
                  }}
                  required={merchantEnable}
                  disabled={!merchantEnable}
                  {...register(`merchant_code`, {
                    ...StoreAddFormValidations.merchant_code,
                    validate: (value: string) => {
                      console.log(`validate: ${merchantEnable}`)
                      if (!merchantEnable) return true
                      if (value === '' || value === undefined) return 'マーチャントコードを入力してください'
                      if (value.length < 4) return 'マーチャントコードは4文字以上で入力してください'
                      if (17 < value.length) return 'マーチャントコードは17文字以内で入力してください'
                      if (!value.match(/^[a-z][a-z0-9-]{3,17}$/)) return 'マーチャントコードは英小文字と数字/ハイフン（先頭不可）で入力してください'
                      return true
                    },
                  })}
                  error={Boolean(errors?.merchant_code)}
                  helperText={errors?.merchant_code?.message}  
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  label="店舗コード"
                  size="small"
                  margin="dense"
                  inputProps={{
                    minLength: 4,
                    maxLength: 17,
                  }}
                  required
                  {...register(`store_code`, {
                    ...StoreAddFormValidations.store_code,
                    validate: async (storeCode: string) => {
                      const stores = await getApi().fetchStores()
                      const storeCodes = stores?.items.map(store => store.store_code)
                      console.log(storeCodes, storeCode)
                      return storeCodes?.indexOf(storeCode) === -1 || `${storeCode}は既に登録されています`
                    },
                  })}
                  error={Boolean(errors?.store_code)}
                  helperText={errors?.store_code?.message}  
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  label="店舗名"
                  size="small"
                  margin="dense"
                  required
                  fullWidth
                  {...register(`store_name`, StoreAddFormValidations.store_name)}
                  error={Boolean(errors?.store_name)}
                  helperText={errors?.store_name?.message}  
                />
              </Grid>
              <Grid item sm={12}>
                <FormControlLabel
                  control={(
                  <Switch
                    checked={sensorEnable}
                    onChange={handleSensorEnableChanged}
                    color="primary"
                  />
                  )}
                  label="センサー連携を有効にする"
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={() => onClose(false)}>キャンセル</Button>
          <Button color="primary" variant="contained" type="submit" disabled={!formState.isValid} >登録</Button>
        </DialogActions>
      </form>
      <ProgressScreen open={isUpdating} />
    </Dialog>
  );
}