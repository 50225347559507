import React, {
} from 'react'
import {
  Backdrop,
  CircularProgress,
} from '@mui/material'

interface ProgressScreenProps {
  open: boolean
  colorVariant?: 'normal'|'light'
}

export const ProgressScreen: React.FC<ProgressScreenProps> = ({ open, colorVariant }) =>  {
  return (
    <Backdrop
      open={open}
      style={{
        zIndex: 99999,
        backgroundColor: (colorVariant === 'light') ? 'rgb(0,0,0,0.05)' : undefined,
      }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
