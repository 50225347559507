import {
  ShelfLayout,
} from 'models'

export type ShelfRegisterFormData = {
  shelf_code_short: string
}

export const ShelfRegisterFormValidations = {
  shelf_code_short: {
    required: {
      value: true,
      message: '棚コードを入力してください',
    },
    maxLength: {
      value: 4,
      message: '棚コードは4文字以内で入力してください'
    },
    pattern: {
      value: /^[A-Z0-9]{1,4}$/,
      message: '棚コードは英大文字と数字で入力してください'
    },
  }
}

export type ShelfConfigFormData = {
  weight_threshold: string
}

export const ShelfConfigFormValidations = {
  weight_threshold: {
    min: {
      value: 5.0,
      message: '5g以上で入力してください',
    },
    max: {
      value: 100,
      message: '100g以下で入力してください',
    },
  },
}

export const getSegmentCodesFromLayout = (shelfCode: string, layout: ShelfLayout) => {
  const rowLengths = layout.segments.map(row => row.length)
  return rowLengths.map((rowLen, i) => {
    const idx = rowLengths.slice(0, i).reduce((p,c) => p + c, 0)
    return Array.from(new Array(rowLen), (_, i) => `${shelfCode}_${i + idx + 1}`)
  })    
}
