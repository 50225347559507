import React, {
  useState,
  useEffect,
} from 'react'
import {
  Popover,
  Typography,
} from '@mui/material'
import {
  SegmentLayout,
} from 'models'
import { useInterval } from 'hooks'
import { ItemImg } from './ItemImg'
import { WeightLabel } from './WeightLabel'
import { DateTime } from 'luxon'

interface SegmentProps {
  model: SegmentLayout
  showItem?: boolean
  showItemWeight?: boolean
  minHeight?: number
  backgroundColor?: string
}

export const Segment: React.FC<SegmentProps>  = ({ model, showItem, showItemWeight, minHeight, backgroundColor = 'honeydew' }) => (
  <div style={{
    border: '1px solid gray',
    minHeight: minHeight || 80,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: (showItem && model.item && !model.item.image_url.endsWith('no_image.png')) ? 'flex-end' : 'center',
    backgroundColor: (showItem && model.item && model.item.image_url.endsWith('no_image.png')) || model.marked ? backgroundColor : undefined,
    flexFlow: (showItem && model.item && model.item.image_url.endsWith('no_image.png')) ? 'column' : undefined,
  }}>
    <SegmentContent model={model} showItem={showItem} showItemWeight={showItemWeight}/>
  </div>
)

interface SegmentContentProps {
  model: SegmentLayout
  showItem?: boolean
  showItemWeight?: boolean
}

const SegmentContent: React.FC<SegmentContentProps>  = ({ model, showItem, showItemWeight }) => {
  if (showItem && model.item) {
    if (model.item.image_url.endsWith('no_image.png')) {
      return (
      <>
        <Typography variant="body2">{model.item.item_name.ja}</Typography>
        {showItemWeight && (
        <Typography variant="body2"><WeightLabel value={model.item.weight}/></Typography>
        )}
      </>
      )
    } else {
      return (
      <>
        <ItemImg item={model.item}/>
        {showItemWeight && (
        <div style={{
            position: 'absolute',
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 2,
            paddingBottom: 2,
            backgroundColor: 'rgba(240, 240, 240, 0.7)',
        }}>
          <WeightLabel value={model.item.weight}/>
        </div>
        )}
      </>
      )
    }
  } else {
    return (<>{model.segmentCode.split('_').pop()}</>)
  }
}


interface SegmentMonitorProps {
  model: SegmentLayout
  timestamp: number
  value: number | null
}

export const SegmentMonitor: React.FC<SegmentMonitorProps>  = ({ model, timestamp, value }) => {
  const [check, setCheck] = useState(false)
  const [lastUpdated, setLastUpdated] = useState(DateTime.now().toSeconds())
  const [timeout, setTimeout] = useState(false)
  const [bgColor, setBgColor] = useState('lightgray')
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement|null>(null);
  const popover = Boolean(anchorEl);

  useEffect(() => {
    if (value === null) return
    if (value <= -100) {
      setBgColor('orange')
    } else {
      setBgColor('lightgreen')
    }
    setTimeout(false)
    setLastUpdated(DateTime.now().toSeconds())
  }, [model, timestamp, value])

  useInterval(() => {
    setCheck(true)
  }, 20000) // 20 sec
  useEffect(() => {
    if (!check) return
    if (lastUpdated + 30 < DateTime.now().toSeconds()) {
      setBgColor('salmon')
      setTimeout(true)
    }
    setCheck(false)
  }, [check, lastUpdated, value])

  return (
  <>
    <div 
      style={{
        border: '1px solid gray',
        minHeight: 50,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: bgColor,
      }}
      aria-owns={popover ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onClick={(event) => {setAnchorEl(event.currentTarget);console.log('onMouseEnter', event.currentTarget)}}
    >
      <Typography
      >
        {(value !== null && timeout === false) ? value : '-'}
      </Typography>
    </div>
    <Popover
      id={`item_${model.segmentCode}`}
      open={popover}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      // disableRestoreFocus
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {model.item
      ? <>
          <ItemImg item={model.item} maxWidth={240} />
          <Typography align="center" >{model.item.item_name.ja}({model.item.weight}g)</Typography>
        </>
      : <Typography>No Item</Typography>
      }
    </Popover>
  </>
  )
}
