import React, {
  useState,
  useEffect,
} from 'react'
import {
  Grid,
} from '@mui/material'
import {
  useApp,
} from 'contexts'
import {
  Store,
} from 'models'
import {
  MainContents,
  MainContentsHeader,
} from 'components'
import {
  StoreCard,
} from './components'

export const Dashboard: React.FC = () => {
  const [stores, setStores] = useState<Store[]>([])
  const { getApi, defaultApiErrorHandler } = useApp()

  useEffect(() => {
    getApi()
      .fetchStores()
      .then((result) => {
        setStores(result.items)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler])

  return (
    <MainContents>
      <MainContentsHeader
        title="Dashboard"
      />
      <Grid container spacing={1}>
      {stores.map(store => (
        <Grid item key={store.store_code} sm={12} md={6} lg={4} >
          <StoreCard store={store}/>
        </Grid>
      ))}
      </Grid>
    </MainContents>
  )
}
