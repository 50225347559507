import React, {
  useEffect,
  useRef,
} from 'react'
import * as d3 from 'd3'

const width = 400
const height = 10
const margin = 4

interface WeightBarProps {
  value: number
  itemWeight?: number
  itemName?: string
  qty?: number
  maxQty?: number
}

export const WeightBar: React.FC<WeightBarProps> = ({
  value,
  itemWeight,
  qty,
  maxQty = 2,
}) => {
  const ref = useRef<SVGSVGElement | null>(null)

  useEffect(() => {
    if (itemWeight === undefined) return
    const maxValue = itemWeight * (maxQty + 0.2)
    const scale = d3.scaleLinear().domain([0, maxValue]).range([0, width])
    const svgElm = ref.current
    if (svgElm === null) return
    const svg = d3.select(svgElm)

    const tickData = Array.from([...new Array(maxQty + 1)]).map((_, idx) => idx * itemWeight)
    const ticks = svg.selectAll('path.tick').data(tickData)
    ticks
      .join(
        enter => enter
          .append('path')
          .attr('class', 'tick')
          .attr('d', d => `M ${scale(d)} 0 V ${margin + width}`)
          .attr('stroke', 'gray')
      ,
      exit => exit
        .remove()
      ,
    )

    const color = (() => {
      if (qty === undefined) return 'crimson'
      return qty < 0 ? 'darkblue' : 'darkgreen'
    })()
    const bars = svg.selectAll('rect.val').data([value])
    bars
      .join(
        enter => enter
          .append('rect')
          .attr('x', 0)
          .attr('y', margin)
          .attr('height', height)
          .attr('width', d => scale(Math.abs(d)))
          .attr('fill', color)
        ,
        exit => exit
          .remove()
        ,
      )
  }, [ref, value, itemWeight, maxQty, qty])

  return (
  <svg
    ref={ref}
    width={width}
    height={height + margin}
  >
    <g>
      <rect className="bg" x={0} y={margin} width={width} height={height} fill="whitesmoke"/>
    </g>
  </svg>
  )
}