import {
  Theme,
  createTheme as createMuiTheme,
} from '@mui/material';
import { PaletteOptions } from '@mui/material/styles';
import {
  teal,
  green,
  purple,
  blue,
} from "@mui/material/colors";

export const createTheme = (): Theme =>
  createMuiTheme({
    typography: {
      fontSize: 13,
      h1: {
        fontSize: '2em',
      },
      h2: {
        fontSize: '1.6em',
      },
      h3: {
        fontSize: '1.4em',
      },
      h4: {
        fontSize: '1.2em',
      },
      h5: {
        fontSize: '1.1em',
      },
      h6: {
        fontSize: '1.0em',
      },
      button: {
        textTransform: 'none',
      },
    },
    palette: getMuiPallet(),
  })


const getMuiPallet = (): PaletteOptions | undefined => {
  const env = process.env.REACT_APP_ENV
  switch (env) {
    case 'PRD':
      return undefined // default
    case 'STG':
      return {
        primary: {
          main: teal[700],
          contrastText: '#fff',
        },
      }
    case 'ITG':
      return {
        primary: {
          main: purple[700],
          contrastText: '#fff',
        },
      }
    default:
      return {
        primary: {
          main: green[800],
          contrastText: '#fff',
        },
      }
  }
}

export const getEnvPrimaryColor = (): string => {
  const env = process.env.REACT_APP_ENV
  switch (env) {
    case 'PRD':
      return blue[600] // default
    case 'STG':
      return teal[700]
    case 'ITG':
      return purple[700]
    default:
      return green[800]
  }
}
