import React from 'react'
import {
  Paper,
} from '@mui/material'

export const MainContents: React.FC = ({ children }) => (
  <Paper style={{
    padding: 15,
  }}>
    {children}
  </Paper>
)
