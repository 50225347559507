import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from 'react'
import {
  Location,
  FloorItem,
  Shelf,
} from 'models'
import {
  Loading,
  AreaModel,
  DiagonalAreaModel,
  createColors,
  getStoresAndArea,
  getAreasFromFloorItems,
  getAreasFromShelves,
  getAreasLowFromShelves,
  getDiagonalAreasFromShelves,
  getDiagonalAreasLowFromShelves,
} from 'components'
import {
  useApp,
} from './app.context'

export interface StoreLayoutModel {
  storeCode: string
  locations: Location[],
  floorItems: FloorItem[],
  shelves: Shelf[],
  store: AreaModel
  setStore: React.Dispatch<React.SetStateAction<AreaModel>>
  locationAreas: AreaModel[]
  setLocationAreas: React.Dispatch<React.SetStateAction<AreaModel[]>>
  itemAreas: AreaModel[]
  setItemAreas: React.Dispatch<React.SetStateAction<AreaModel[]>>
  shelfAreas: AreaModel[]
  setShelfAreas: React.Dispatch<React.SetStateAction<AreaModel[]>>
  shelfLowAreas: AreaModel[]
  setShelfLowAreas: React.Dispatch<React.SetStateAction<AreaModel[]>>
  diagonalShelfAreas: DiagonalAreaModel[]
  setDiagonalShelflAreas: React.Dispatch<React.SetStateAction<DiagonalAreaModel[]>>
  diagonalShelfLowAreas: DiagonalAreaModel[]
  setDiagonalShelfLowlAreas: React.Dispatch<React.SetStateAction<DiagonalAreaModel[]>>
  reset: () => void
}

const initStore: AreaModel = {
  name: 'STORE',
  variant: 'store',
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  color: 'gray',
}
const initalModel: StoreLayoutModel = {
  storeCode: '',
  locations: [],
  floorItems: [],
  shelves: [],
  store: initStore,
  setStore: () => {},
  locationAreas: [],
  setLocationAreas: () => {},
  itemAreas: [],
  setItemAreas: () => {},
  shelfAreas: [],
  setShelfAreas: () => {},
  shelfLowAreas: [],
  setShelfLowAreas: () => {},
  diagonalShelfAreas: [],
  setDiagonalShelflAreas: () => {},
  diagonalShelfLowAreas: [],
  setDiagonalShelfLowlAreas: () => {},
  reset: () => {},
}

// create the context
export const StoreLayoutContext = createContext<StoreLayoutModel>(initalModel)
export const useStoreLayout = () => useContext(StoreLayoutContext)

export interface StoreLayoutProviderProps {
  storeCode: string
}
// StoreLayoutProvider
export const StoreLayoutProvider: React.FC<StoreLayoutProviderProps> = ({ storeCode, children }) => {
  const { getApi } = useApp()
  const [loading, setLoading] = useState(true)
  const [locations, setLocations] = useState<Location[]>([])
  const [floorItems, setFloorItems] = useState<FloorItem[]>([])
  const [shelves, setShelves] = useState<Shelf[]>([])
  const [store, setStore] = useState<AreaModel>(initStore)
  const [locationAreas, setLocationAreas] = useState<AreaModel[]>([])
  const [itemAreas, setItemAreas] = useState<AreaModel[]>([])
  const [shelfAreas, setShelfAreas] = useState<AreaModel[]>([])
  const [shelfLowAreas, setShelfLowAreas] = useState<AreaModel[]>([])
  const [diagonalShelfAreas, setDiagonalShelflAreas] = useState<DiagonalAreaModel[]>([])
  const [diagonalShelfLowAreas, setDiagonalShelfLowlAreas] = useState<DiagonalAreaModel[]>([])

  const reload = useCallback(() => {
    setLoading(true)
    Promise.all([
      getApi().fetchLocations(storeCode),
      getApi().fetchFloorItems(storeCode),
      getApi().fetchShelves(storeCode),
    ]).then(([locationsResult, floorItemsResult, shelvesResult]) => {
      console.log('loaded area infomations')
      const locations = locationsResult.items
      const floorItems = floorItemsResult.items
      const shelves = shelvesResult.items
      const colors = createColors(locations, shelves)
      const [store, locationAreas] = getStoresAndArea(storeCode, locations, colors)
      const itemAreas = getAreasFromFloorItems(floorItems)
      const shelfAreas = getAreasFromShelves(shelves, colors)
      const shelfLowAreas = getAreasLowFromShelves(shelves, colors)
      const diagonalShelfAreas = getDiagonalAreasFromShelves(shelves, colors)
      const diagonalShelfLowAreas = getDiagonalAreasLowFromShelves(shelves, colors)
      console.log(store, locationAreas, itemAreas, shelfAreas)
      console.log(diagonalShelfAreas, diagonalShelfLowAreas)
      setLocations(locations)
      setFloorItems(floorItems)
      setShelves(shelves)
      setStore(store)
      setLocationAreas(locationAreas)
      setItemAreas(itemAreas)
      setShelfAreas(shelfAreas)
      setShelfLowAreas(shelfLowAreas)
      setDiagonalShelflAreas(diagonalShelfAreas)
      setDiagonalShelfLowlAreas(diagonalShelfLowAreas)
      setLoading(false)
    })
  }, [getApi, storeCode])
  // initial loading
  useEffect(() => {
    reload()
  }, [reload])

  return (
    <StoreLayoutContext.Provider value={{
      storeCode,
      locations,
      floorItems,
      shelves,
      store,
      locationAreas,
      itemAreas,
      shelfAreas,
      shelfLowAreas,
      diagonalShelfAreas,
      diagonalShelfLowAreas,
      setStore,
      setLocationAreas,
      setItemAreas,
      setShelfAreas,
      setShelfLowAreas,
      setDiagonalShelflAreas,
      setDiagonalShelfLowlAreas,
      reset: reload,
    }}>
      <Loading visible={loading} />
      {!loading && children}
    </StoreLayoutContext.Provider>
  )
}