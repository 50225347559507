import React, {
  useState,
  useCallback,
} from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import {
  useApp,
} from 'contexts'
import {
  Replay,
  ReplayRequestPayload,
} from 'models'
import {
  DatePicker,
  ProgressScreen,
} from 'components'
import { DateTime } from 'luxon'

interface ReplayRequestDialogProps {
  storeCode: string
  open: boolean
  onClose: () => void
  onRequested: (replay: Replay) => void
}

export const ReplayRequestDialog: React.FC<ReplayRequestDialogProps> = ({ storeCode, open, onClose, onRequested }) => {
  const { getApi, defaultApiErrorHandler } = useApp()
  const [date, setDate] = useState(new Date())
  const [time, setTime] = useState('14:00')
  const [duration, setDuration] = useState(5)
  const [updating, setUpdating] = useState(false)

  const handleDateChanged = useCallback((dt: DateTime) => {
    setDate(dt.toJSDate())
  }, [setDate])
  const handleTimeChanged = useCallback((evt: any) => {
    console.log(evt.target.value)
    setTime(evt.target.value)
  }, [])
  const handleDurationChanged = useCallback((evt: any) => {
    console.log(evt.target.value)
    setDuration(Number(evt.target.value))
  }, [])

  const handleCanceled = useCallback(() => {
    onClose()
  }, [onClose])
  // 更新
  const handleRequested = useCallback(() => {
    setUpdating(true)
    const payload: ReplayRequestPayload = {
      store_code: storeCode,
      date_time: `${DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')}T${time}`,
      duration_min: duration,
    }
    getApi().requestReplay(payload)
      .then((replay) => {
        onRequested(replay)
        setUpdating(false)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, onRequested, storeCode, date, time, duration])

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
    >
      <ProgressScreen colorVariant="light" open={open && updating} />
      <DialogTitle>リプレイデータ作成</DialogTitle>
      <DialogContent>
        <Grid container spacing={0} sx={{ alignItems: 'center' }}>
          <Grid item xs={3}>日時</Grid>
          <Grid item xs={9}>
            <DatePicker
              onChange={handleDateChanged}
              disableNavigate
              defaultDate={DateTime.fromJSDate(date)}
              showUnderline
            />
            <TextField
              type="time"
              defaultValue={time}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                stpe: 60, // 1min
              }}
              margin="dense"
              size="small"
              variant="standard"
              onChange={handleTimeChanged}
              sx={{ marginLeft: 1 }}
            />
          </Grid>
          <Grid item xs={3}>期間（分）</Grid>
          <Grid item xs={9}>
            <TextField
              type="number"
              defaultValue={duration}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                stpe: 1, // 1min
                min: 2,
                max: 30,
              }}
              margin="dense"
              size="small"
              variant="standard"
              onChange={handleDurationChanged}
            />
          </Grid>
        </Grid>
        <Typography variant="body2" sx={{ marginTop: 2, marginLeft: 2 }}>※当日のリプレイデータを作るにはAthena DBの更新が必要です</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          variant="contained"
          onClick={handleCanceled}
        >
          キャンセル
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleRequested}
        >
          作成
        </Button>
      </DialogActions>
    </Dialog>
  )
}