import {
  Location,
  Shelf,
} from 'models'

export const getStoreCodeIn = (code: string): string => code.split('_')[0]
export const toLocationName = (location: Location): string => location.location_code.split('_')[1]
export const toShelfName = (shelf: Shelf): string => shelf.shelf_code.split('_')[1]
export const toShelf = (code: string) => code.split('_').splice(-1)[0]
export const toSegment = (segmentCode: string) => {
  const codes = segmentCode.split('_')
  return `${codes[1]}_${codes[2]}`
}
export const SegmentSorter = (segmentCode1: string, segmentCode2: string): number => {
  const parts1 = segmentCode1.split('_')
  const parts2 = segmentCode2.split('_')
  if (parts1.length === 3) {
    const r1 = parts1[0].localeCompare(parts2[0])
    if (r1 !== 0) return r1
    const r2 = parts1[1].localeCompare(parts2[1])
    if (r2 !== 0) return r2
    return Number(parts1[2]) - Number(parts2[2])
  } else if (parts1.length === 2) {
    const r1 = parts1[0].localeCompare(parts2[0])
    if (r1 !== 0) return r1
    return Number(parts1[1]) - Number(parts2[1])
  } else {
    return Number(parts1[0]) - Number(parts2[0])
  }
}