import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import {
  useParams,
} from 'react-router-dom'
import {
  Grid,
  Box,
  Typography,
  FormLabel,
  TextField,
  Input,
  InputAdornment,
  Button,
  IconButton,
  Switch,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
} from '@mui/material';
import {
  AddBox as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  ElectricalServices as PlugIcon,
} from '@mui/icons-material'
import {
  useForm,
} from 'react-hook-form'
import {
  useApp,
} from 'contexts'
import {
  Plug,
  isEnvActive,
  BootHours,
} from 'models'
import {
  Help,
  ProgressScreen,
  WarnigMessage,
} from 'components'
import {
  sx,
  sxCategory,
  sxReadOnlyInput,
  sxField,
} from './sx'
import {
  StoreBootConfigFormData,
  StoreBootConfigFormValidations,
  toFormData,
  toBootConfiguration,
} from './boot_config/forms'
import {
  BootHoursTable,
  ExcludeDateList,
  AddBootHoursDialog,
  AddExcludeDateDialog,
  AddPlugDialog,
  EditPlugDialog,
} from './boot_config'

export const StoresBootConfig: React.FC = () => {
  const { storeCode } = useParams()
  if (storeCode === undefined) throw new Error('unhandled routing')
  const [plugs, setPlugs] = useState<Plug[]>()
  const [loading, setLoading] = useState(true)
  const [addPlugDialogOpen, setAddPlugDialogOpen] = useState(false)
  const [editPlugsIndex, setEditPlugsIndex] = useState(-1)
  const [env, setEnv] = useState<string>()
  const [scheduledBootEnable, setScheduledBootEnable] = useState(false)
  const [bootScheduleDryRun, setBootScheduleDryRun] = useState(false)
  const [bootHours, setBootHours] = useState<BootHours[][]>()
  const [excludeDates, setExcludeDates] = useState<string[]>([])
  const [addBootHoursDialogOpen, setAddBootHoursDialogOpen] = useState(false)
  const [addExcludeDatesDialogOpen, setAddExcludeDatesDialogOpen] = useState(false)
  const [active, setActive] = useState(false)
  const { getApi, apiErrorHandler, defaultApiErrorHandler } = useApp()
  const { register, handleSubmit, reset, formState: { errors } } = useForm<StoreBootConfigFormData>({
    mode: 'onBlur',
  })

  useEffect(() => {
    Promise.all([
      getApi().fetchBootConfiguration(storeCode),
      getApi().fetchBootSchedule(storeCode),
      getApi().fetchEnv(storeCode),
    ])
      .then(([bootConfig, bootSchedule, env]) => {
        setPlugs(bootConfig.plugs)
        reset(toFormData(bootConfig))
        setEnv(env.env)
        setActive(isEnvActive(env))
        setLoading(false)
        setScheduledBootEnable(!bootSchedule.disabled)
        setBootScheduleDryRun(bootSchedule.dry_run)
        setBootHours(bootSchedule.boot_hours)
        setExcludeDates(bootSchedule.exclude_dates)
        console.log(`fetch done. ${storeCode}`)
      })
      .catch(defaultApiErrorHandler)
  }, [storeCode, getApi, defaultApiErrorHandler, reset])

  const handlePlugAdded = useCallback((plug: Plug) => {
    console.log(plug)
    const newPlugs = [...plugs || [], plug]
    setPlugs(newPlugs)
    setAddPlugDialogOpen(false)
  }, [plugs])
  const handlePlugEditClicked = useCallback((idx: number) => {
    console.log(idx)
    setEditPlugsIndex(idx)
  }, [])
  const handlePlugDelete = useCallback((idx: number) => {
    console.log(idx)
    const newPlugs = (plugs || []).filter((_, i) => idx !== i)
    console.log(newPlugs)
    setPlugs(newPlugs)
  }, [plugs])
  const handlePlugEdited = useCallback((plug: Plug) => {
    setEditPlugsIndex(-1)
    if (plugs === undefined || plugs.length === 0) return
    const newPlugs = plugs.map((p, i) => (i === editPlugsIndex) ? plug : p)
    console.log(newPlugs)
    setPlugs(newPlugs)
  }, [plugs, editPlugsIndex])
  const handleScheduledBootEnableSwitchChanged = useCallback((_: React.ChangeEvent<HTMLInputElement>) => {
    setScheduledBootEnable(!scheduledBootEnable)
  }, [scheduledBootEnable])
  const handleBootHoursChanged = useCallback((updated: BootHours[][]) => {
    setBootHours(updated)
  }, [])
  const handleAddBootHoursClicked = useCallback(() => {
    setAddBootHoursDialogOpen(true)
  }, [])
  const handleAddBootHoursDialogClosed = useCallback(() => {
    setAddBootHoursDialogOpen(false)
  }, [])
  const handleBootHoursAdded = useCallback((dayOfWeek: number, newBootHours: BootHours) => {
    console.log(dayOfWeek, newBootHours)
    setAddBootHoursDialogOpen(false)
    if (bootHours === undefined) return
    const updated = bootHours.map((bh, dow) => (dayOfWeek === dow) ? [...bh, newBootHours].sort((bh1, bh2) => bh1.from.localeCompare(bh2.from)) : [...bh])
    setBootHours(updated)
  }, [bootHours])
  const handleAddExcludeDatesClicked = useCallback(() => {
    setAddExcludeDatesDialogOpen(true)
  }, [])
  const handleAddExcludeDatesDialogClosed = useCallback(() => {
    setAddExcludeDatesDialogOpen(false)
  }, [])
  const handleExcludeDatesAdded = useCallback((date: string) => {
    if (excludeDates.indexOf(date) === -1) {
      const newExcludeDates = [date, ...excludeDates].sort()
      setExcludeDates(newExcludeDates)
    }
    setAddExcludeDatesDialogOpen(false)
  }, [excludeDates])

  const onSubmit = useCallback((data: StoreBootConfigFormData) => {
    if (plugs === undefined || bootHours === undefined) return
    setLoading(true)
    Promise.all([
      getApi().updateBootConfiguration(toBootConfiguration(storeCode, plugs, data)),
      getApi().updateBootSchedule({
        store_code: storeCode,
        boot_hours: bootHours,
        dry_run: bootScheduleDryRun,
        disabled: !scheduledBootEnable,
        exclude_dates: excludeDates,
      }),
    ])
      .then(([updatedBootConfiguration, _]) => {
        reset(toFormData(updatedBootConfiguration))
        setPlugs(updatedBootConfiguration.plugs)
        setLoading(false)
      })
      .catch(apiErrorHandler)
  }, [storeCode, plugs, bootHours, excludeDates, scheduledBootEnable, bootScheduleDryRun, getApi, reset, apiErrorHandler])

  return (
    <>
      <ProgressScreen colorVariant="light" open={loading} />
      <WarnigMessage
        title="起動設定を更新できません"
        message={`${env}環境のダッシュボードで更新してください`}
        hidden={env === undefined || active}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={sxCategory}>基本設定</Typography>
          </Grid>
          <Grid item xs={4} sx={sx.labelItem}>
            <FormLabel sx={sx.label}>店舗コード</FormLabel>
          </Grid>
          <Grid item xs={6}>
            <Input
              fullWidth
              value={storeCode}
              readOnly
              style={{
                backgroundColor: 'whitesmoke',
              }}
              sx={sxReadOnlyInput}
            />
          </Grid>
          <Grid item xs={2}></Grid>

          <Grid item xs={4} sx={sx.labelItem}>
            <FormLabel required sx={sx.label}>店舗起動タイムアウト</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="number"
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">秒</InputAdornment>
                  ),
                }}
                {...register(`boot_timeout_sec`, StoreBootConfigFormValidations.boot_timeout_sec)}
                error={Boolean(errors?.boot_timeout_sec)}
                helperText={errors?.boot_timeout_sec?.message}
              />
              <Help>
                <Typography>「店舗起動」のタイムアウトを設定します。</Typography>
              </Help>
            </Box>
          </Grid>

          <Grid item xs={4} sx={sx.labelItem}>
            <FormLabel required sx={sx.label}>店舗停止タイムアウト</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="number"
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">秒</InputAdornment>
                  ),
                }}
                {...register(`shutdown_timeout_sec`, StoreBootConfigFormValidations.shutdown_timeout_sec)}
                error={Boolean(errors?.shutdown_timeout_sec)}
                helperText={errors?.shutdown_timeout_sec?.message}
              />
              <Help>
                <Typography>「店舗停止」のタイムアウトを設定します。</Typography>
              </Help>
            </Box>
          </Grid>

          <Grid item xs={4} sx={sx.labelItem}>
            <FormLabel required sx={sx.label}>店舗再起動タイムアウト</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="number"
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">秒</InputAdornment>
                  ),
                }}
                {...register(`reboot_timeout_sec`, StoreBootConfigFormValidations.reboot_timeout_sec)}
                error={Boolean(errors?.reboot_timeout_sec)}
                helperText={errors?.reboot_timeout_sec?.message}
              />
              <Help>
                <Typography>「店舗再起動」のタイムアウトを設定します。</Typography>
              </Help>
            </Box>
          </Grid>

          <Grid item xs={4} sx={sx.labelItem}>
            <FormLabel required sx={sx.label}>サービス起動タイムアウト</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="number"
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">秒</InputAdornment>
                  ),
                }}
                {...register(`restart_service_timeout_sec`, StoreBootConfigFormValidations.restart_service_timeout_sec)}
                error={Boolean(errors?.restart_service_timeout_sec)}
                helperText={errors?.restart_service_timeout_sec?.message}
              />
              <Help>
                <Typography>「サービス再起動」のタイムアウトを設定します。</Typography>
              </Help>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" sx={sxCategory}>
              <Box sx={{ display: 'flex' }}>
                <Box>プラグ</Box>
                <Tooltip title="プラグの追加">
                  <Box component="span">
                    <IconButton
                      aria-label="add-camera"
                      color="primary"
                      size="small"
                      sx={{ marginLeft: 'auto', marginRight: 1, }}
                      onClick={() => setAddPlugDialogOpen(true)}
                      disabled={!active}
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} sx={sxField}>
            <List dense>
              {plugs && plugs.map((plug, idx) => (
                <ListItem key={`plug_${idx}`} sx={{ paddingRight: '90px' }}>
                  <ListItemIcon>
                    <PlugIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={plug.name}
                    secondary={plug.desc}
                  />
                  <ListItemSecondaryAction>
                    <Tooltip title="説明の更新">
                      <Box component="span">
                        <IconButton
                          size="small"
                          edge="end"
                          disabled={!active}
                          onClick={() => handlePlugEditClicked(idx)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Box>
                    </Tooltip>
                    <Tooltip title="プラグの削除">
                      <Box component="span">
                        <IconButton
                          size="small"
                          edge="end"
                          disabled={!active}
                          onClick={() => handlePlugDelete(idx)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            {plugs && plugs.length === 0 && (
              <Box sx={{ margin: 1 }}>設定されたプラグはありません</Box>
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" sx={sxCategory}>通知設定</Typography>
          </Grid>

          <Grid item xs={4} sx={sx.labelItem}>
            <FormLabel sx={sx.label}>通知先Slackパス</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="string"
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                fullWidth
                InputProps={{}}
                {...register(`slack_path`, StoreBootConfigFormValidations.slack_path)}
                error={Boolean(errors?.slack_path)}
                helperText={errors?.slack_path?.message}
              />
              <Help>
                <Typography>起動停止イベントを通知するSlackアプリのパスを設定します。</Typography>
              </Help>
            </Box>
          </Grid>

          <Grid item xs={4} sx={sx.labelItem}>
            <FormLabel sx={sx.label}>通知先チャンネル</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="string"
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                fullWidth
                InputProps={{}}
                {...register(`slack_notify_channel`, StoreBootConfigFormValidations.slack_notify_channel)}
                error={Boolean(errors?.slack_notify_channel)}
                helperText={errors?.slack_notify_channel?.message}
              />
              <Help>
                <Typography>通知用のチャンネル名を設定します。</Typography>
              </Help>
            </Box>
          </Grid>

          <Grid item xs={4} sx={sx.labelItem}>
            <FormLabel sx={sx.label}>アラート先チャンネル</FormLabel>
          </Grid>
          <Grid item xs={8}>
            <Box sx={sxField}>
              <TextField
                type="string"
                size="small"
                hiddenLabel
                variant="outlined"
                margin="dense"
                fullWidth
                InputProps={{}}
                {...register(`slack_alert_channel`, StoreBootConfigFormValidations.slack_alert_channel)}
                error={Boolean(errors?.slack_alert_channel)}
                helperText={errors?.slack_alert_channel?.message}
              />
              <Help>
                <Typography>アラート用のチャンネル名を設定します。</Typography>
              </Help>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" sx={sxCategory}>スケジュール起動</Typography>
          </Grid>
          <Grid item xs={4} sx={sx.labelSelect}>
            <FormLabel sx={sx.label}>店舗を定期起動する</FormLabel>
          </Grid>
          <Grid item xs={6}>
            <Switch
              checked={scheduledBootEnable}
              onChange={handleScheduledBootEnableSwitchChanged}
              color="primary"
              disabled={!active}
            />
          </Grid>

          <Grid item xs={4} sx={sx.labelItem}>
            <FormLabel sx={sx.label}>スケジュール</FormLabel>
          </Grid>
          <Grid item xs={8} sx={sxField}>
            <Box sx={{ display: 'flex', alignItems: 'start' }}>
              {bootHours && (
                <BootHoursTable
                  bootHours={bootHours}
                  onChanged={handleBootHoursChanged}
                  handleAddClicked={handleAddBootHoursClicked}
                  readOnly={!active}
                />
              )}
              <ExcludeDateList
                excludeDates={excludeDates}
                onChanged={setExcludeDates}
                handleAddClicked={handleAddExcludeDatesClicked}
                readOnly={!active}
              />
            </Box>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={12} sx={{
              margin: 3,
              textAlign: 'center',
            }}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!active}
              >
                保存
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <AddPlugDialog
        open={addPlugDialogOpen}
        onAdded={handlePlugAdded}
        onClose={() => setAddPlugDialogOpen(false)}
      />
      {plugs && editPlugsIndex !== -1 && (
        <EditPlugDialog
          open={Boolean(editPlugsIndex !== -1)}
          plug={plugs[editPlugsIndex]}
          onEdited={handlePlugEdited}
          onClose={() => setEditPlugsIndex(-1)}
        />
      )}
      {bootHours && (
        <AddBootHoursDialog
          open={addBootHoursDialogOpen}
          onClose={handleAddBootHoursDialogClosed}
          onAdded={handleBootHoursAdded}
          bootHours={bootHours}
        />
      )}
      <AddExcludeDateDialog
        open={addExcludeDatesDialogOpen}
        onClose={handleAddExcludeDatesDialogClosed}
        onAdded={handleExcludeDatesAdded}
        excludeDates={excludeDates}
      />
    </>
  )
}