import { UnixTimestamp } from './types'

export interface SegmentQuantities {
  segment_code: string
  item_code?: string
  item_name?: string
  item_weight?: number
  quantities: Quantitiy[]
}

export interface Quantitiy {
  timestamp: UnixTimestamp
  value_diff: number
  qty?: number
}

export interface SegmentQuantitiesFilter {
  mode: 'all' | 'has_quantities' | 'has_error_quantities'
}

export const filterSegmentQuantities = (
  items: SegmentQuantities[],
  filter: SegmentQuantitiesFilter,
): SegmentQuantities[] => {
  switch(filter.mode) {
    case 'has_quantities':
      return items.filter(i => 0 < i.quantities.length)
    case 'has_error_quantities':
      return items.filter(i => i.quantities.find(q => q.qty === undefined))
    default:
      return [...items]
  }
}

export const sortSegmentQuantities = (d1 :SegmentQuantities, d2 :SegmentQuantities) => {
  const total1 = d1.quantities.length
  const total2 = d2.quantities.length
  if (total1 === 0 || total2 === 0) return total2 - total1
  const count1 = d1.quantities.filter(q => q.qty === undefined).length
  const count2 = d2.quantities.filter(q => q.qty === undefined).length
  return (100 * count2 / total2) - (100 * count1 / total1)
}