import {
  Theme,
  SxProps,
} from '@mui/material';

export const sxHeader: SxProps<Theme> = {
  color: '#333333',
};
export const sxGrid: SxProps<Theme> = {
  margin: (theme) => theme.spacing(0.5),
};
export const sxTitle: SxProps<Theme> = {
  color: (theme) => theme.palette.text.secondary,
};
export const sxSectionTitle: SxProps<Theme> = {
  marginTop: (theme) => theme.spacing(2),
  paddingBottom: (theme) => theme.spacing(1),
  borderBottom: '1px lightgray solid',
};
export const sxNotice: SxProps<Theme> = {
  marginTop: (theme) => theme.spacing(1),
  marginBottom: (theme) => theme.spacing(2),
};
