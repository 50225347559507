import {
  Store,
  Configuration,
  StoreMode,
} from 'models'

export type StoreConfigFormData = {
  store_name: string
  action_timeout_min: string
  action_wait_sec: string
  checkout_wait_sec: string
  weight_threshold: string
  dist_threshold: string
  hand_tracking_range: string;
  hand_dist_threshold: string;
  hand_tracking_window_interval_ms: string;
  hand_tracking_action_diff_ms: string;
  tracking_range_sec: string
  snapshot_timeout_min: string
  checkin_timeout_sec: string
  exit_timeout_min: string
  notify_slack_url: string
  notify_sns_topic: string
  flow_velocity_move_threshold: string
  flow_stay_ms_threshold: string
  flow_dist_threshold: string
  flow_hand_tracking_range: string
  tracking_max_min: string
}

export const StoreConfigFormValidations = {
  store_name: {
    required: {
      value: true,
      message: '店舗名を入力してください',
    },
  },
  action_wait_sec: {
    required: {
      value: true,
      message: '取得判定待機時間を入力してください',
    },
    pattern: {
      value: /^-?[0-9]+$/,
      message: '整数で取得判定待機時間を入力してください',
    },
    min: {
      value: 1,
      message: '1秒以上で入力してください',
    },
    max: {
      value: 300,
      message: '300秒以下で入力してください',
    },
  },
  action_timeout_min: {
    required: {
      value: true,
      message: '確定タイムアウトを入力してください',
    },
    pattern: {
      value: /^-?[0-9]+$/,
      message: '整数で確定タイムアウトを入力してください',
    },
    min: {
      value: 0,
      message: '0分以上で入力してください',
    },
    max: {
      value: 120,
      message: '120分以下で入力してください',
    },
  },
  checkout_wait_sec: {
    required: {
      value: true,
      message: 'チェックアウト待機時間を入力してください',
    },
    pattern: {
      value: /^-?[0-9]+$/,
      message: '整数でチェックアウト待機時間を入力してください',
    },
    min: {
      value: 0,
      message: '0秒以上で入力してください',
    },
    max: {
      value: 10,
      message: '10秒以下で入力してください',
    },
  },
  weight_threshold: {
    required: {
      value: true,
      message: '最低重量変化量を入力してください',
    },
    min: {
      value: 5.0,
      message: '5g以上で入力してください',
    },
    max: {
      value: 100,
      message: '100g以下で入力してください',
    },
  },
  dist_threshold: {
    required: {
      value: true,
      message: '距離判定しきい値を入力してください',
    },
    min: {
      value: 10,
      message: '10cm以上で入力してください',
    },
    max: {
      value: 100,
      message: '100cm以下で入力してください',
    },
  },
  hand_tracking_range: {
    required: {
      value: true,
      message: 'ハンドトラッキング範囲を入力してください',
    },
    min: {
      value: 10,
      message: '10cm以上で入力してください',
    },
    max: {
      value: 40,
      message: '40cm以下で入力してください',
    },
  },
  hand_dist_threshold: {
    required: {
      value: true,
      message: 'ハンドトラッキング時、手の距離の閾値を入力してください',
    },
    min: {
      value: 0,
      message: '0cm以上で入力してください',
    },
    max: {
      value: 50,
      message: '50cm以下で入力してください',
    },
  },
  hand_tracking_window_interval_ms: {
    required: {
      value: true,
      message: 'ハンドトラッキング判定時、時間幅の間隔を入力してください',
    },
    min: {
      value: 100,
      message: '100ms以上で入力してください',
    },
    max: {
      value: 1500,
      message: '1500ms以下で入力してください',
    },
  },
  hand_tracking_action_diff_ms: {
    required: {
      value: true,
      message: 'ハンドトラッキング時刻と重量変化時刻と差分を入力してください',
    },
    min: {
      value: 100,
      message: '100ms以上で入力してください',
    },
    max: {
      value: 1500,
      message: '1500ms以下で入力してください',
    },
  },
  tracking_range_sec: {
    required: {
      value: true,
      message: '位置情報保存期間を入力してください',
    },
    min: {
      value: 5,
      message: '5秒以上で入力してください',
    },
    max: {
      value: 120,
      message: '120秒以下で入力してください',
    },
  },
  snapshot_timeout_min: {
    required: {
      value: true,
      message: '店内画像タイムアウトを入力してください',
    },
    min: {
      value: 3,
      message: '3分以上で入力してください',
    },
    max: {
      value: 30,
      message: '30分以下で入力してください',
    },
  },
  checkin_timeout_sec: {
    required: {
      value: true,
      message: '入店タイムアウトを入力してください',
    },
    min: {
      value: 1,
      message: '1秒以上で入力してください',
    },
    max: {
      value: 60,
      message: '60秒以下で入力してください',
    },
  },
  exit_timeout_min: {
    required: {
      value: true,
      message: '退店タイムアウトを入力してください',
    },
    min: {
      value: 5,
      message: '5分以上で入力してください',
    },
    max: {
      value: 120,
      message: '120分以下で入力してください',
    },
  },
  notify_slack_url: {
  },
  notify_sns_topic: {
  },
  flow_velocity_move_threshold: {
    required: {
      value: true,
      message: '移動判定速度しきい値を入力してください',
    },
    min: {
      value: 0.1,
      message: '0.1以上で入力してください',
    },
    max: {
      value: 1.0,
      message: '1.0以下で入力してください',
    },
  },
  flow_stay_ms_threshold: {
    required: {
      value: true,
      message: '停止判定判定しきい値を入力してください',
    },
    min: {
      value: 500,
      message: '500以上で入力してください',
    },
    max: {
      value: 10000,
      message: '10000以下で入力してください',
    },
  },
  flow_dist_threshold: {
    required: {
      value: true,
      message: '棚までの距離しきい値を入力してください',
    },
    min: {
      value: 100,
      message: '100以上で入力してください',
    },
    max: {
      value: 3000,
      message: '3000以下で入力してください',
    },
  },
  flow_hand_tracking_range: {
    required: {
      value: true,
      message: '手伸ばし判定距離しきい値を入力してください',
    },
    min: {
      value: 100,
      message: '100以上で入力してください',
    },
    max: {
      value: 1000,
      message: '1000以下で入力してください',
    },
  },
  tracking_max_min: {
    required: {
      value: true,
      message: '最大追跡データ時間を入力してください',
    },
    min: {
      value: 5,
      message: '5以上で入力してください',
    },
    max: {
      value: 60,
      message: '60以下で入力してください',
    },
  },
}

export const toFormData = (store: Store, config: Configuration): StoreConfigFormData => ({
  store_name: store.store_name,
  action_timeout_min: `${config.action_timeout_min}`,
  action_wait_sec: `${config.action_wait_sec}`,
  checkout_wait_sec: `${config.checkout_wait_sec || 0}`,
  dist_threshold: `${config.dist_threshold}`,
  weight_threshold: `${config.weight_threshold}`,
  hand_tracking_range: `${config.hand_tracking_range}`,
  hand_dist_threshold: `${config.hand_dist_threshold}`,
  hand_tracking_window_interval_ms: `${config.hand_tracking_window_interval_ms}`,
  hand_tracking_action_diff_ms: `${config.hand_tracking_action_diff_ms}`,
  tracking_range_sec: `${config.tracking_range_sec}`,
  snapshot_timeout_min: `${config.snapshot_timeout_min}`,
  checkin_timeout_sec: `${config.checkin_timeout_sec}`,
  exit_timeout_min: `${config.exit_timeout_min}`,
  notify_slack_url: config.notify_slack_url || '',
  notify_sns_topic: config.notify_sns_topic || '',
  flow_velocity_move_threshold: `${config.flow_velocity_move_threshold}`,
  flow_stay_ms_threshold: `${config.flow_stay_ms_threshold}`,
  flow_dist_threshold: `${config.flow_dist_threshold}`,
  flow_hand_tracking_range: `${config.flow_hand_tracking_range}`,
  tracking_max_min: `${config.tracking_max_min}`,
})

export const toConfiguration = (storeCode: string, storeMode: StoreMode, disableEventHook: boolean, disableSensor: boolean, data: StoreConfigFormData): Configuration => ({
  store_code: storeCode,
  store_mode: storeMode,
  action_timeout_min: Number(data.action_timeout_min),
  action_wait_sec: Number(data.action_wait_sec),
  checkout_wait_sec: Number(data.checkout_wait_sec),
  weight_threshold: Number(data.weight_threshold),
  dist_threshold: Number(data.dist_threshold),
  hand_tracking_range: Number(data.hand_tracking_range),
  hand_dist_threshold: Number(data.hand_dist_threshold),
  hand_tracking_window_interval_ms: Number(data.hand_tracking_window_interval_ms),
  hand_tracking_action_diff_ms: Number(data.hand_tracking_action_diff_ms),
  snapshot_timeout_min: Number(data.snapshot_timeout_min),
  tracking_range_sec: Number(data.tracking_range_sec),
  checkin_timeout_sec: Number(data.checkin_timeout_sec),
  exit_timeout_min: Number(data.exit_timeout_min),
  notify_slack_url: data.notify_slack_url,
  notify_sns_topic: data.notify_sns_topic,
  disable_event_hook: disableEventHook,
  flow_velocity_move_threshold: Number(data.flow_velocity_move_threshold),
  flow_stay_ms_threshold: Number(data.flow_stay_ms_threshold),
  flow_dist_threshold: Number(data.flow_dist_threshold),
  flow_hand_tracking_range: Number(data.flow_hand_tracking_range),
  tracking_max_min: Number(data.tracking_max_min),
  disable_sensor: disableSensor,
})
