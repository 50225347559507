import React, {
  useState,
  useCallback,
} from 'react'
import {
  useParams,
} from 'react-router-dom'
import { Toolbar, FormControlLabel, Switch, Button } from '@mui/material';
import {
  Edit,
} from '@mui/icons-material'
import {
  StoreLayoutProvider,
} from 'contexts'
import {
  StoreLayout,
} from 'components'
import { LayoutEditDialog } from './layout/LayoutEditDialog'

export const StoresLayout: React.FC = () => {
  const { storeCode } = useParams()
  if (storeCode === undefined) throw new Error('unhandled routing')
  const [showAreas, setShowAreas] = useState(true)
  const [showItems, setShowItems] = useState(true)
  const [showShelvesLow, setShowShelvesLow] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const handleChangeShowAreas = useCallback(() => {
    setShowAreas(!showAreas)
  }, [showAreas])
  const handleChangeShowItems = useCallback(() => {
    setShowItems(!showItems)
  }, [showItems])
  const handleChangeShowAreasLow = useCallback(() => {
    setShowShelvesLow(!showShelvesLow)
  }, [showShelvesLow])
  const handleEdit = useCallback(() => {
    setOpenDialog(true)
  }, [])
  const handleSave = useCallback(() => {
    setOpenDialog(false)
    // load()
  }, [])
  const handleClose = useCallback(() => {
    setOpenDialog(false)
  }, [])

  return (
    <>
      <Toolbar variant="dense" disableGutters sx={{ padding: 0 }}>
        <FormControlLabel
          control={(
            <Switch
              color="primary"
              size="small"
              checked={showAreas}
              onChange={handleChangeShowAreas}
            />
          )}
          label="判定エリア表示"
        />
        <FormControlLabel
          control={(
            <Switch
              color="primary"
              size="small"
              checked={showItems}
              onChange={handleChangeShowItems}
            />
          )}
          label="棚/その他表示"
        />
        <FormControlLabel
          control={(
            <Switch
              color="primary"
              size="small"
              checked={!showShelvesLow}
              onChange={handleChangeShowAreasLow}
            />
          )}
          label="棚エリア上段表示"
        />
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Edit />}
          sx={{ marginLeft: 'auto' }}
          onClick={handleEdit}
        >
          編集
        </Button>
      </Toolbar>
      <StoreLayoutProvider storeCode={storeCode}>
        <StoreLayout
          width={870}
          height={600}
          hiddenAreas={!showAreas}
          hiddenItems={!showItems}
          showShelvesLow={showShelvesLow}
        />
        <LayoutEditDialog
          open={openDialog}
          onSave={handleSave}
          onClose={handleClose}
        />
      </StoreLayoutProvider>
    </>
  )
}