import {
  GateKeeperDevice,
  GateKeeperDeviceUpdatePayload,
  AwslogsLevel,
} from 'models'

export interface GateKeeperFormData {
  agent_interval: number
  agent_service_start_timeout: number
  service_interval: number
  service_enter_area: string
  service_exit_area: string
  service_module_gate: string
  service_module_checkin: string
  service_module_audio: string
}

export const toFormData = (device: GateKeeperDevice): GateKeeperFormData => {
  return {
    agent_interval: device.agent_properties.interval,
    agent_service_start_timeout: device.agent_properties.service_start_timeout || 120,
    service_interval: device.service_properties.interval || 60,
    service_enter_area: device.service_properties.enter_area,
    service_exit_area: device.service_properties.exit_area,
    service_module_gate: device.service_properties.module_gate,
    service_module_checkin: device.service_properties.module_checkin,
    service_module_audio: device.service_properties.module_audio,
  }
}

export const dataToPayload = (
  data: GateKeeperFormData,
  storeCode: string,
  agentAwslogsLevel: AwslogsLevel,
  serviceAwslogsLevel: AwslogsLevel,
  test_mode: boolean,
): GateKeeperDeviceUpdatePayload => {
  return {
    store_code: storeCode !== '_' ? storeCode : '',
    properties: {},
    agent_properties: {
      awslogs_level: agentAwslogsLevel,
      interval: Number(data.agent_interval),
      service_start_timeout: Number(data.agent_service_start_timeout),
    },
    service_properties: {
      awslogs_level: serviceAwslogsLevel,
      interval: Number(data.service_interval),
      enter_area: data.service_enter_area,
      exit_area: data.service_exit_area,
      module_gate: data.service_module_gate,
      module_checkin: data.service_module_checkin,
      module_audio: data.service_module_audio,
      test_mode,
    }
  }
}

export const GateKeeperFormValidations = {
  agent_interval: {
    required: {
      value: true,
      message: '送信間隔を入力してください',
    },
    min: {
      value: 50,
      message: '送信間隔は50〜300で入力してください',
    },
    max: {
      value: 300,
      message: '送信間隔は50〜300で入力してください',
    },
  },
  agent_service_start_timeout: {
    required: {
      value: true,
      message: 'サービス起動タイムアウトを入力してください',
    },
    min: {
      value: 60,
      message: 'サービス起動タイムアウトは60〜600で入力してください',
    },
    max: {
      value: 600,
      message: 'サービス起動タイムアウトは60〜600で入力してください',
    },
  },
  service_interval: {
    required: {
      value: true,
      message: '送信間隔を入力してください',
    },
    min: {
      value: 50,
      message: '送信間隔は50〜300で入力してください',
    },
    max: {
      value: 300,
      message: '送信間隔は50〜300で入力してください',
    },
  },
  service_enter_area: {
    required: {
      value: true,
      message: '入店エリア名を入力してください',
    },
  },
  service_exit_area: {
    required: {
      value: true,
      message: '退店エリア名を入力してください',
    },
  },
  service_module_gate: {
    required: {
      value: true,
      message: 'ゲートモジュール名を入力してください',
    },
  },
  service_module_checkin: {
    required: {
      value: true,
      message: 'チェックインモジュール名を入力してください',
    },
  },
  service_module_audio: {
    required: {
      value: true,
      message: '音声モジュール名を入力してください',
    },
  },
}