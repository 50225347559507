import React, {
  useEffect,
  useRef,
} from 'react'
import {
  Paper,
  Typography,
  Box,
} from '@mui/material';
import {
  Help,
} from 'components'
import * as d3 from 'd3'

const width = 430
const height = 40
const margin = 4

type StatusPanelVariant = 'standard' | 'warning' | 'error'
interface StatusPanelProps {
  variant: StatusPanelVariant
  title: string
  subTitle: string
  counts: number[]
  totalCount: number
  desc?: string
  helpText?: string
}

export const StatusPanel: React.FC<StatusPanelProps> = ({
  variant,
  title,
  subTitle,
  counts,
  totalCount,
  helpText,
  desc,
}) => {
  const ref = useRef<SVGSVGElement | null>(null)

  useEffect(() => {
    const svgElm = ref.current
    const data = totalCount !== 0 ? [...counts, (totalCount - d3.sum(counts))] : [...counts, 1]
    if (svgElm === null) return
    const svg = d3.select(svgElm)
    const max = totalCount !== 0 ? totalCount : 1
    const rate = d3
      .scaleLinear()
      .domain([0, max])
      .range([0, width - margin * 2])
    const [colors, opacities] = getColorsAndOpacities(variant, data.length)
    const bars = svg.selectAll('rect').data(data)
    bars
      .join(
        enter => enter
          .append('rect')
          .attr('x', (_, i) => margin + rate(d3.sum(data.slice(0, i))))
          .attr('y', 0)
          .attr('height', height)
          .attr('width', d => rate(d))
          .attr('fill', (_, i) => colors(`${i}`))
          .attr('opacity', (_, i) => opacities(`${i}`))
        ,
        update => update
          .attr('x', (_, i) => margin + rate(d3.sum(data.slice(0, i))))
          .attr('width', d => rate(d))
        // TODO Trastion
        ,
        exit => exit
          .remove()
        ,
      )
    if (desc === undefined) return
    const text = svg.selectAll('text').data([desc])
    text
      .join(
        enter => enter
          .append('text')
          .text(d => d)
          .attr('x', width / 2)
          .attr('y', height / 2)
          .attr('fill', 'black')
          .attr('font-size', '1em')
          .attr('font-family', 'sans-serif')
          .style('text-anchor', 'middle')
          .style('dominant-baseline', 'middle')
        ,
        update => update
          .text(d => d)
        ,
      )
  }, [ref, variant, counts, totalCount, desc])

  return (
    <Paper variant="outlined" sx={{ position: 'relative', width: width }}>
      {helpText && (
        <Box sx={{ position: 'absolute', right: 0 }}>
          <Help >
            <Typography>{helpText}</Typography>
          </Help>
        </Box>
      )}
      <Typography sx={{ merginTop: 1, fontSize: '1.5em', textAlign: 'center', color: (t) => t.palette.text.primary }}>
        {title}
      </Typography>
      <Typography sx={{ marginBottom: 0.5, fontSize: '0.9em', textAlign: 'center', color: (t) => t.palette.text.secondary }}>
        {subTitle}
      </Typography>
      <svg
        ref={ref}
        width={width}
        height={height}
      />
    </Paper>
  )
}

const getColorsAndOpacities = (variant: StatusPanelVariant, len: number): [d3.ScaleOrdinal<string, string>, d3.ScaleOrdinal<string, number>] => {
  // const colors = d3.scaleOrdinal<string>()
  // .domain(['0', '1']) //['0', '1']
  // .range(['lightgreen', 'lightpink'])
  // const opacities = d3.scaleOrdinal<number>()
  // .domain(['0', '1']) //['0', '1']
  // .range([0.8, 0.7])
  const domain = Array.from(new Array(len)).map((_, idx) => `${idx}`)
  const colorRange = (() => {
    if (variant === 'error') {
      return Array.from(new Array(len)).map((_, idx) => (idx !== len - 1) ? 'lightgreen' : 'lightpink')
    } else if (variant === 'warning') {
      return Array.from(new Array(len)).map((_, idx) => (idx !== len - 1) ? 'gold' : 'lightgray')
    }
    return Array.from(new Array(len)).map(() => 'lightsteelblue')
  })()
  const opacityRange = (() => {
    if (variant === 'error' || variant === 'warning') {
      return Array.from(new Array(len)).map((_, idx) => (idx !== len - 1) ? 1 - (0.2 * idx) : 0.6)
    }
    return Array.from(new Array(len)).map((_, idx) => 1 - (0.4 * idx))
  })()
  console.log(domain, colorRange, opacityRange)
  return [
    d3.scaleOrdinal<string>().domain(domain).range(colorRange),
    d3.scaleOrdinal<number>().domain(domain).range(opacityRange),
  ]
}

