import React, {
  useState,
  useCallback,
} from 'react'
import {
  Grid,
  Paper,
  Dialog,
  DialogContent,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Box,
} from '@mui/material';
import {
  Delete,
  Close,
} from '@mui/icons-material'
import {
  useApp,
} from 'contexts'
import {
  Obscure,
} from 'models'
import {
  TimestampLabel,
  WeightLabel,
  ConfirmDialog,
} from 'components'
import {
  toSegment,
} from 'utils'
import { ValueDetailPanel } from './ValueDetailPanel'
import { ActionMovies } from './ActionMovies'
import { ConfirmPanel } from './ConfirmPanel'

const getMovieActionId = (obscrue: Obscure): string => {
  if (obscrue.action_id) return obscrue.action_id
  if (obscrue.candidates && obscrue.candidates.length !== 0) return obscrue.candidates[0].action_id
  return `${obscrue.segment_code}_${obscrue.timestamp}_0`
}

const getConfirmLabel = (obscure: Obscure): string => {
  if (obscure.person_id === undefined && obscure.qty === undefined) return '人物と商品数量'
  if (obscure.person_id === undefined) return '人物'
  return '商品数量'
}

export interface WalkthroughConfirmDialogProps {
  open: boolean
  obscure: Obscure
  onClose: () => void
}

export const WalkthroughConfirmDialog: React.FC<WalkthroughConfirmDialogProps> = ({
  open,
  obscure,
  onClose
}) => {
  const { getApi, defaultApiErrorHandler, getStore } = useApp()
  const [deleteOpen, setDeleteOpen] = useState(false)

  const handleDelete = useCallback((isOk: boolean) => {
    console.log(`handleDelete: ${isOk}`)
    if (isOk === false) {
      setDeleteOpen(false)
      return
    }
    getApi()
      .confirmAction({
        store_code: obscure.store_code,
        segment_code: obscure.segment_code,
        timestamp: obscure.timestamp,
        actions: [],
        walkthroughs: [],
      })
      .then(() => {
        setDeleteOpen(false)
        onClose()
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, onClose, obscure])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
    >
      <DialogContent sx={{ padding: 2, backgroundColor: t => t.palette.background.default }}>
        <Toolbar variant="dense">
          <Typography variant="h3">{getConfirmLabel(obscure)}の確定</Typography>
          <Tooltip title="削除" onClick={() => setDeleteOpen(true)}>
            <IconButton color="primary" style={{ marginLeft: 'auto' }} size="large">
              <Delete />
            </IconButton>
          </Tooltip>
          <Tooltip title="キャンセル" onClick={onClose}>
            <IconButton size="large">
              <Close />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <Grid container>
          <Grid item xs={4}>
            <Paper sx={{ padding: 1, marginTop: 1, marginBottom: 1 }}>
              <Typography>
                <TimestampLabel value={obscure.timestamp} sx={{ marginRight: 0.5 }} />
              </Typography>
              <Typography>
                <Box component="span" sx={{ marginRight: 0.5 }}>{toSegment(obscure.segment_code)}</Box>
                <Box component="span" sx={{ marginRight: 0.5 }}>{getStore(obscure.store_code).store_name}</Box>
              </Typography>
              <Typography>
                <WeightLabel value={obscure.value_diff} markable />
              </Typography>
            </Paper>
            <ActionMovies
              actionId={getMovieActionId(obscure)}
            />
            {obscure.value_details && 0 < obscure.value_details.length && (
              <ValueDetailPanel
                valueDiff={obscure.value_diff}
                valueDetails={obscure.value_details}
              />
            )}
          </Grid>
          <Grid item xs={8}>
            <ConfirmPanel
              obscure={obscure}
              onConfirmed={onClose}
            />
          </Grid>
        </Grid>
        <ConfirmDialog
          open={deleteOpen}
          title="削除の確認"
          contentText={`削除します。よろしいですか？`}
          okText="削除"
          handleClose={handleDelete}
        />
      </DialogContent>
    </Dialog>
  );
}
