import React, {
} from 'react'
import {
  Tooltip,
} from '@mui/material'
import {
  green,
  orange,
} from '@mui/material/colors'
import {
  FiberManualRecord as Circle,
} from '@mui/icons-material'
import {
  DevicePowerStatus,
  DeviceServiceStatus,
  DeviceServiceError,
} from 'models'


type DeviceStatusMarkProps = {
  powerStatus: DevicePowerStatus | undefined
  serviceStatus: DeviceServiceStatus | undefined
  serviceError: DeviceServiceError | undefined
}

export const DeviceStatusMark: React.FC<DeviceStatusMarkProps> = ({ powerStatus, serviceStatus, serviceError }) => {
  if (powerStatus === undefined) {
    return (<Circle color="disabled" />)
  } else if (powerStatus === 'error') {
    return (
      <Tooltip title="デバイスエラー">
        <Circle color="error" />
      </Tooltip>
    )
  } else if (powerStatus === 'off') {
    return (
      <Tooltip title="停止">
        <Circle color="disabled" />
      </Tooltip>
    )
  } else if (serviceError !== undefined) {
    return (
      <Tooltip title="サービスエラー">
        <Circle style={{ color: orange[500] }} />
      </Tooltip>
    )
  } else if (serviceStatus === 'starting') {
    return (
      <Tooltip title="サービス起動中">
        <Circle style={{ color: green[200] }} />
      </Tooltip>
    )
  } else if (serviceStatus === 'running') {
    return (
      <Tooltip title="サービス起動中">
        <Circle style={{ color: green[500] }} />
      </Tooltip>
    )
  } else {
    return (
      <Tooltip title="サービス停止中">
        <Circle style={{ color: green[200] }} />
      </Tooltip>
    )
  }
}
