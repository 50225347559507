import React, {
  useCallback,
} from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import {
  useStoreLayout,
} from 'contexts'

type AreaAddFormData = {
  x: string
  y: string
}

const AreaAddFormValidations = {
  x: {
    required: {
      value: true,
      message: 'Xオフセットを入力してください',
    },
  },
  y: {
    required: {
      value: true,
      message: 'Xオフセットを入力してください',
    },
  },
}

export interface SettingsDialogProps {
  open: boolean
  onClose: () => void
}

export const SettingsDialog: React.FC<SettingsDialogProps> = ({ open, onClose }) => {
  const { store, locationAreas, shelfAreas, itemAreas, setStore, setLocationAreas, setShelfAreas, setItemAreas } = useStoreLayout()
  const { register, handleSubmit, formState, formState: { errors } } = useForm<AreaAddFormData>({
    mode: 'onBlur',
  })
  const onSubmit = useCallback((data: AreaAddFormData) => {
    const [dx, dy] = [Number(data.x) - store.x, Number(data.y) - store.y]
    const newLocationAreas = [...locationAreas]
    newLocationAreas.forEach(a => {
      a.x += dx
      a.y += dy
    })
    const newShelfAreas = [...shelfAreas]
    newShelfAreas.forEach(s => {
      s.x += dx
      s.y += dy
    })
    const newItemAreas = [...itemAreas]
    newItemAreas.forEach(i => {
      i.x += dx
      i.y += dy
    })
    setStore({
      ...store,
      x: store.x + dx,
      y: store.y + dy,
    })
    setLocationAreas(newLocationAreas)
    setShelfAreas(newShelfAreas)
    setItemAreas(newItemAreas)
    onClose()
  }, [store, locationAreas, shelfAreas, itemAreas, setStore, setLocationAreas, setShelfAreas, setItemAreas, onClose])

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>店舗設定</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                label="Xオフセット"
                size="small"
                margin="dense"
                type="number"
                required
                defaultValue={store.x}
                inputProps={{ step: '10' }}
                {...register('x', {
                  validate: (x: string) => {
                    if (!x.match(/¥d+/)) return 'Xオフセットは10単位で入力してください'
                    if (Number(x) % 10 !== 0) return 'Xオフセットは10単位で入力してください'
                    return true
                  },
                  ...AreaAddFormValidations.x,
                })}
                error={Boolean(errors?.x)}
                helperText={errors?.x?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Yオフセット"
                size="small"
                margin="dense"
                type="number"
                required
                defaultValue={store.y}
                inputProps={{ step: '10' }}
                {...register('y', {
                  validate: (y: string) => {
                    if (!y.match(/¥d+/)) return 'Yオフセットは10単位で入力してください'
                    if (Number(y) % 10 !== 0) return 'Yオフセットは10単位で入力してください'
                    return true
                  },
                  ...AreaAddFormValidations.y,
                })}
                error={Boolean(errors?.y)}
                helperText={errors?.y?.message}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={onClose}>キャンセル</Button>
          <Button color="primary" variant="contained" type="submit" disabled={!formState.isValid}>OK</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
