import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useForm } from 'react-hook-form'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  FormLabel,
  FormControl,
  FormControlLabel,
  TextField,
  Button,
  Switch,
} from '@mui/material'
import {
  useApp,
} from 'contexts'
import {
  GateKeeperDevice,
  GateKeeperDeviceUpdatePayload,
  AwslogsLevel,
} from 'models'
import {
  ProgressScreen,
} from 'components'
import { StoresSelect } from './StoresSelect'
import { AwslogsLevelSelect } from './AwslogsLevelSelect'
import {
  GateKeeperFormData,
  GateKeeperFormValidations,
  toFormData,
  dataToPayload,
} from './forms_gk'
import { sx } from './sx'

interface GateKeeperSettingsDialogProps {
  open: boolean
  device: GateKeeperDevice
  onClose: () => void
  onUpdated: (device: GateKeeperDevice, restartService: boolean) => void
}

export const GateKeeperSettingsDialog: React.FC<GateKeeperSettingsDialogProps> = ({ open, device, onClose, onUpdated }) => {
  const { getApi, defaultApiErrorHandler } = useApp()
  const [storeCode, setStoreCode] = useState(device.store_code)
  const [agentAwslogsLevel, setAgentAwslogsLevel] = useState(device.agent_properties.awslogs_level)
  const [serviceAwslogsLevel, setServiceAwslogsLevel] = useState(device.service_properties.awslogs_level)
  const [testMode, setTestMode] = useState(device.service_properties.test_mode)
  const [restartService, setRestartService] = useState(true)
  const { register, handleSubmit, reset, formState, formState: { errors } } = useForm<GateKeeperFormData>({
    mode: 'onBlur',
  })
  const { isDirty, isValid } = formState;
  const [changed, setChanged] = useState(false)
  const [updating, setUpdating] = useState(false)

  // reset form
  useEffect(() => {
    reset(toFormData(device))
    setAgentAwslogsLevel(device.agent_properties.awslogs_level)
    setServiceAwslogsLevel(device.service_properties.awslogs_level)
  }, [device, reset])

  const handleCanceled = useCallback(() => {
    onClose()
  }, [onClose])
  const handleRestartService = useCallback((_, checked) => {
    setRestartService(checked)
  }, [])

  const storeCodeChanged = useCallback((newStoreCode: string) => {
    setStoreCode(newStoreCode)
    setChanged(true)
  }, [])
  const agentAwslogsLevelChanged = useCallback((newLevel: AwslogsLevel) => {
    setAgentAwslogsLevel(newLevel)
    setChanged(true)
  }, [])
  const serviceAwslogsLevelChanged = useCallback((newLevel: AwslogsLevel) => {
    setServiceAwslogsLevel(newLevel)
    setChanged(true)
  }, [])
  const handleTestModeChanged = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    setTestMode(evt.target.checked)
    setChanged(true)
  }, [])

  // 更新
  const onSubmit = useCallback((data: GateKeeperFormData) => {
    setUpdating(true)
    const payload: GateKeeperDeviceUpdatePayload = dataToPayload(data, storeCode, agentAwslogsLevel, serviceAwslogsLevel, testMode)
    getApi().updateGateKeeperDevice(device.thing_name, payload)
      .then((device) => {
        onUpdated(device, restartService)
        setUpdating(false)
      })
      .catch(defaultApiErrorHandler)
  }, [getApi, defaultApiErrorHandler, onUpdated, device, restartService, storeCode, agentAwslogsLevel, serviceAwslogsLevel, testMode])

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
    >
      <ProgressScreen colorVariant="light" open={open && updating} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{device.thing_name}</DialogTitle>
        <DialogContent>
          <Grid container spacing={0} sx={sx.forms}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={sx.category}>全般</Typography>
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel sx={sx.label}>店舗</FormLabel>
            </Grid>
            <Grid item xs={9}>
              <StoresSelect storeCode={storeCode} onChange={storeCodeChanged} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" sx={sx.category}>エージェント</Typography>
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>CloudWatch Logs</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <AwslogsLevelSelect awslogsLevel={agentAwslogsLevel} onChange={agentAwslogsLevelChanged} />
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>送信間隔</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <FormControl sx={sx.formControl}>
                <TextField
                  type="number"
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  margin="dense"
                  required
                  inputProps={{
                    max: 300,
                    min: 50,
                  }}
                  {...register(`agent_interval`, GateKeeperFormValidations.agent_interval)}
                  error={Boolean(errors?.agent_interval)}
                  helperText={errors?.agent_interval?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>サービス開始タイムアウト</FormLabel>
            </Grid>
            <Grid item xs={9}>
              <FormControl sx={sx.formControl}>
                <TextField
                  type="number"
                  size="small"
                  hiddenLabel
                  variant="outlined"
                  margin="dense"
                  required
                  inputProps={{
                    max: 600,
                    min: 60,
                  }}
                  {...register(`agent_service_start_timeout`, GateKeeperFormValidations.agent_service_start_timeout)}
                  error={Boolean(errors?.agent_interval)}
                  helperText={errors?.agent_service_start_timeout?.message}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" sx={sx.category}>サービス</Typography>
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>CloudWatch Logs</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <AwslogsLevelSelect awslogsLevel={serviceAwslogsLevel} onChange={serviceAwslogsLevelChanged} />
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>送信間隔</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <FormControl sx={sx.formControl}>
                <TextField
                  type="number"
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  margin="dense"
                  required
                  inputProps={{
                    max: 300,
                    min: 50,
                  }}
                  {...register(`service_interval`, GateKeeperFormValidations.service_interval)}
                  error={Boolean(errors?.service_interval)}
                  helperText={errors?.service_interval?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>入店エリア</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <TextField
                hiddenLabel
                variant="outlined"
                size="small"
                margin="dense"
                required
                {...register(`service_enter_area`, GateKeeperFormValidations.service_enter_area)}
                error={Boolean(errors?.service_enter_area)}
                helperText={errors?.service_enter_area?.message}
              />
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>退店エリア</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <TextField
                hiddenLabel
                variant="outlined"
                size="small"
                margin="dense"
                required
                {...register(`service_exit_area`, GateKeeperFormValidations.service_exit_area)}
                error={Boolean(errors?.service_exit_area)}
                helperText={errors?.service_exit_area?.message}
              />
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>ゲートモジュール</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <TextField
                hiddenLabel
                variant="outlined"
                size="small"
                margin="dense"
                required
                {...register(`service_module_gate`, GateKeeperFormValidations.service_module_gate)}
                error={Boolean(errors?.service_module_gate)}
                helperText={errors?.service_module_gate?.message}
              />
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>チェックインモジュール</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <TextField
                hiddenLabel
                variant="outlined"
                size="small"
                margin="dense"
                required
                {...register(`service_module_checkin`, GateKeeperFormValidations.service_module_checkin)}
                error={Boolean(errors?.service_module_checkin)}
                helperText={errors?.service_module_checkin?.message}
              />
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>音声モジュール</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <TextField
                hiddenLabel
                variant="outlined"
                size="small"
                margin="dense"
                required
                {...register(`service_module_audio`, GateKeeperFormValidations.service_module_audio)}
                error={Boolean(errors?.service_module_audio)}
                helperText={errors?.service_module_audio?.message}
              />
            </Grid>
            <Grid item xs={3} sx={sx.labelItem}>
              <FormLabel required sx={sx.label}>テストモード</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <Switch
                checked={testMode}
                onChange={handleTestModeChanged}
              />
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            label="デバイスを再起動し、変更を反映する"
            control={
              <Switch
                checked={restartService}
                onChange={handleRestartService}
              />
            }
            sx={sx.action}
          />
          <Button
            color="inherit"
            variant="contained"
            sx={sx.action}
            onClick={handleCanceled}
          >
            キャンセル
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            sx={sx.action}
            disabled={!changed && (!isDirty || !isValid)}
          >
            保存
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}