import React from 'react'
import {
  Box,
} from '@mui/material'

type DurationLabelProps = {
  value: number // sec
}

export const DurationLabel: React.FC<DurationLabelProps> = ({ value }) => {
  const days = Math.floor(value / 86400)
  const hour = Math.floor(value / 3600)
  const min = Math.floor(value / 60) % 60
  const sec = Math.floor(value) % 60
  return (
    <Box component="span">
      {0 < days && (<>{days} 日 {hour % 24 != 0 && `${hour % 24} 時間`}</>)}
      {days == 0 && 0 < hour && (<>{hour} 時間 {min} 分</>)}
      {hour === 0 && 0 < min && (<>{min} 分 {sec} 秒</>)}
      {hour === 0 && min === 0 && (<>{sec} 秒</>)}
    </Box>
  )
}
