import React from 'react'
import {
  Chip,
} from '@mui/material'
import {
  Person as PersonIcon,
  People as PeopleIcon,
  PriorityHigh as UndetectIcon,
} from '@mui/icons-material'
import {
  ObscureCandidate,
} from 'models'
import {
  formatPersonId,
} from 'utils'

export interface ConfirmPersonLabelProps {
  personId?: number
  candidates?: ObscureCandidate[]
}

export const ConfirmPersonLabel: React.FC<ConfirmPersonLabelProps> = ({ personId, candidates }) => {
  if (personId) {
    return (
      <Chip
        variant="outlined"
        size="small"
        icon={<PersonIcon />}
        label={formatPersonId(personId)}
        color="default"
      />
    )
  } else if (candidates) {
    return (
      <Chip
        variant="outlined"
        size="small"
        icon={<PeopleIcon />}
        label="未確定"
        color="secondary"
      />
    )
  } else {
    return (
      <Chip
        variant="outlined"
        size="small"
        icon={<UndetectIcon />}
        label="未検知"
        color="secondary"
      />
    )
  }
}
