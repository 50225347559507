import React from 'react'
import {
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom'
import {
  useApp,
} from 'contexts'
import { Main } from './pages/main/Main'
import {
  NoPermissions,
  ResouceNotFound,
  SystemError,
} from 'pages/errors'
import {
  Loading,
} from 'components'
import { Dashboard } from 'pages/dashboard/Dashboard'
import { StoresRoutes } from 'pages/stores/StoresRoutes'
import { WalkthroughRoutes } from 'pages/walkthrough/WalkthroughRoutes'
import { AnalysisRoutes } from 'pages/analysis/AnalysisRoutes'
import { DeviceRoutes } from 'pages/device/DeviceRoutes'
import { MonitorRoutes } from 'pages/monitor/MonitorRoutes'
import { StatisticsRoutes } from 'pages/statistics/StatisticsRoutes'
import { LogsRoutes } from 'pages/logs/LogsRoutes'
import { OperatorsRoutes } from 'pages/operators/OperatorsRoutes'
import { CredentialsRoutes } from 'pages/credentials/CredentialsRoutes'

export const AppRouter: React.FC = () => {
  console.log('##AppRouter')
  const { isReady, getStores } = useApp()
  const location = useLocation()
  if (!isReady) {
    return (<Loading />)
  }
  // 店舗未登録時は /stores にリダイレクトする
  if (getStores().length === 0 && location.pathname !== '/stores') {
    return (<Navigate to="/stores" />)
  }
  return (
    <Routes>
      <Route path="/" element={<Main />}>
        <Route index element={<Dashboard />} />
        <Route path="stores/*" element={<StoresRoutes />} />
        <Route path="walkthrough/*" element={<WalkthroughRoutes />} />
        <Route path="analysis/*" element={<AnalysisRoutes />} />
        <Route path="device/*" element={<DeviceRoutes />} />
        <Route path="monitor/*" element={<MonitorRoutes />} />
        <Route path="statistics/*" element={<StatisticsRoutes />} />
        <Route path="logs/*" element={<LogsRoutes />} />
        <Route path="operators/*" element={<OperatorsRoutes />} />
        <Route path="credentials/*" element={<CredentialsRoutes />} />
      </Route>
      <Route path="/system_error" element={<SystemError />} />
      <Route path="/no_permissions" element={<NoPermissions />} />
      <Route path="/not_found" element={<ResouceNotFound />} />
    </Routes>
  )
}
